export const STATUSES = {
  active: 'active',
  canceled: 'canceled',
  rejected: 'rejected',
  requested: 'requested',
  reminded: 'reminded',
  waiting: 'waiting',
  activated: 'activated',
  deactivated: 'deactivated',
  notSent: 'not_sent',
  success: 'successful',
  error: 'error',
  paid: 'paid',
  paying: 'paying',
  pending: 'pending',
  unpaid: 'unpaid',
  expired: 'expired',
  denied: 'denied',
  onHold: 'on_hold',
  chargebacked: 'chargebacked',
  refunded: 'refunded',
  issued: 'issued',
  processed: 'processed',
  debt: 'debt',
  partlyPaid: 'partly_paid',
  partiallyPaid: 'partially_paid',
  notActive: 'not_active',
  registered: 'registered',
  skipped: 'skipped',
}

export const getStatusesLabels = () => ({
  canceled: I18n.t('react.shared.state.canceled'),
  rejected: I18n.t('react.shared.state.rejected'),
  waiting: I18n.t('react.shared.state.waiting'),
  successful: I18n.t('react.shared.state.successful'),
  error: I18n.t('react.shared.state.error'),
  paid: I18n.t('react.shared.state.paid'),
  pending: I18n.t('react.shared.state.pending'),
  unpaid: I18n.t('react.shared.state.unpaid'),
  expired: I18n.t('react.shared.state.expired'),
  denied: I18n.t('react.shared.state.denied'),
  refunded: I18n.t('react.shared.state.refunded'),
  partially_paid: I18n.t('react.shared.state.partially_paid'),
  registered: I18n.t('react.shared.state.registered'),
})
