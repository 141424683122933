import { COLORS } from '../../constants/general.constants'

// TODO: update with using createUseStyles
const buttonInputGroupStyles = () => {
  const { eloBlue, eloWhite, eloGrey60 } = COLORS

  return {
    buttonInputGroup: {
      display: 'flex',
      height: 36,
      marginLeft: 20,

      '& .input-counter': {
        border: '1px solid #dedede',
        borderRadius: 5,
        fontSize: 14,
        textAlign: 'center',
        color: eloGrey60,
        width: 38,
        outline: 0,
        paddingTop: 3,

        '&:focus': {
          border: `1px solid ${eloBlue}`,
        },

        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          '-moz-appearance': 'none',
          margin: 0,
        },

        '&[type=number]': {
          '-webkit-appearance': 'textfield',
          '-moz-appearance': 'textfield',
        },
      },
    },

    counterButton: {
      backgroundColor: eloWhite,
      width: 27,
      padding: '6px 0px 3px 0px',
      borderTopLeftRadius: 2,
      border: 'solid 1px #dedede',
      margin: 0,
      outline: 'none',
      '&:active': {
        backgroundColor: '#d3d3d3',
      },
    },

    buttonCount: {
      backgroundColor: 'transparent',
      border: 0,
      paddingTop: 5,
      '&:focus': {
        outline: 'none',
      },
    },

    buttonContent: {
      color: eloBlue,
      '&:focus': {
        outline: 'none',
      },
    },
  }
}

export default buttonInputGroupStyles
