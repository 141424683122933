import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'react-jss'
import classNames from 'classnames'

import withVideoStyles from 'shared/components/content-page/preview/blocks/video/Video.styles'

export const Code = (props) => {
  const { classes, code, dataTestId, previewMode } = props

  return (
    <div
      className={classNames(classes.code, {
        [classes.codePreview]: previewMode,
      })}
      data-testid={dataTestId}
    >
      <div className={classes.codeOverlay} />
      <span>{code}</span>
    </div>
  )
}

Code.propTypes = {
  /**
   * React JSS classes
   */
  classes: PropTypes.shape({
    code: PropTypes.string,
    codeOverlay: PropTypes.string,
    codePreview: PropTypes.string,
  }),
  /**
   * Video code
   */
  code: PropTypes.string,
  /**
   * Is being used for testing purposes
   */
  dataTestId: PropTypes.string,
  /**
   * Indicates whether it's Page Builder preview of component
   */
  previewMode: PropTypes.bool,
}

Code.defaultProps = {
  classes: {},
  code: '1234',
  dataTestId: 'video-code',
  previewMode: false,
}

const CodeWIthVideoStyles = withVideoStyles(Code)

/**
 * VideoCode - Page Builder's VideoCode section of video block with react-jss injection
 */
export const VideoCode = (props) => {
  const { content } = props

  return (
    <ThemeProvider theme={content}>
      <CodeWIthVideoStyles {...props} />
    </ThemeProvider>
  )
}

VideoCode.propTypes = {
  /**
   * Content object
   */
  content: PropTypes.shape({}),
}

VideoCode.defaultProps = {
  content: {},
}
