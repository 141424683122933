import React from 'react'
import classNames from 'classnames'
import { EloCheckboxIcon } from '@elo-ui/components/icons/regular'

const ImageOptionComponent = (props) => {
  const { Image = () => {} } = props.data

  return !props.isMulti || (props.isMulti && !props.isSelected) ? (
    <div
      className={classNames('elo-react-select__option', { 'elo-react-select__option--is-selected': props.isSelected })}
      onClick={() => {
        props.selectOption(props.data)
      }}
    >
      <div className='elo-react-select__option--wrapper'>
        <Image />
      </div>
      {props.isSelected && <EloCheckboxIcon />}
    </div>
  ) : null
}

export { ImageOptionComponent }
