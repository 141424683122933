import React from 'react'
import classNames from 'classnames'
import { EloCheckboxIcon } from '@elo-ui/components/icons/regular'

const ImageTextSubtitleOptionComponent = (props) =>
  !props.isMulti || (props.isMulti && !props.isSelected) ? (
    <div
      className={classNames('elo-react-select__option', { 'elo-react-select__option--is-selected': props.isSelected })}
      onClick={() => {
        props.selectOption(props.data)
      }}
    >
      <div className='elo-react-select__option--wrapper'>
        {props.data.avatar ? (
          <img src={props.data.avatar} className='elo-react-select__option--avatar' />
        ) : (
          <span className='elo-react-select__option--avatar'>{props.data.title.slice(0, 1)}</span>
        )}
        <div>
          <div className='elo-react-select__option--title'>{props.data.title}</div>
          <div className='elo-react-select__option--subtitle'>{props.data.subtitle}</div>
        </div>
      </div>

      {props.isSelected && <EloCheckboxIcon />}
    </div>
  ) : null

export { ImageTextSubtitleOptionComponent }
