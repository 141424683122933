import React, { Component } from 'react'
import { ThemeProvider } from 'react-jss'
import PropTypes from 'prop-types'

import { useI18n } from '@elo-kit/components/i18n/i18n'

import { FREE_PRICE } from 'constants/general.constants'

import StyledSellerProductsList from '../seller-products-list/SellerProductsList'
import StyledSellerProductsPagination from '../seller-products-pagination/SellerProductsPagination'

class SellerProductsPreviewContainer extends Component {
  render() {
    const { block, initStore, previewMode, sellerUsername, handleScrollChange, I18n } = this.props

    const { content, id } = block

    const theme = {
      ...content,
      categorySelectFieldContentBefore: I18n.t('shared.common.category'),
    }

    return (
      <ThemeProvider theme={theme}>
        <>
          <StyledSellerProductsList
            {...this.props}
            freeDefaultText={FREE_PRICE}
            buyNowText={I18n.t('shared.common.buy_now')}
            defaultSearchPlaceholder={I18n.t('shared.common.search')}
            noProductsMessage={I18n.t('react.shared.no_products_found')}
            userName={sellerUsername}
          />
          {!previewMode && (
            <StyledSellerProductsPagination
              initStore={initStore}
              blockId={id}
              handleScrollChange={handleScrollChange}
            />
          )}
        </>
      </ThemeProvider>
    )
  }
}

SellerProductsPreviewContainer.propTypes = {
  /** Block object */
  block: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    content: PropTypes.object,
  }),
  /** Property for check is PageBuilder in preview mode */
  previewMode: PropTypes.bool,
  /** Store object */
  initStore: PropTypes.object,
  /** Browser history object */
  historyPush: PropTypes.func,
  /** Products list recieved from contentPageStore */
  sellerProducts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  /** Seller username recieved from contentPageStore */
  sellerUsername: PropTypes.string,
  /** Seller id recieved from contentPageStore */
  sellerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Consent form recieved from contentPageStore */
  sellerConsentForm: PropTypes.string,
  /** Buy product function */
  handleBuyProduct: PropTypes.func,
  /** Property for check is mobile menu active */
  mobileMenuActive: PropTypes.bool,
}

SellerProductsPreviewContainer.defaultProps = {
  block: {
    content: {},
  },
  mobileMenuActive: false,
  sellerProducts: [],
  sellerUsername: '',
  handleBuyProduct: () => {},
  historyPush: () => {},
}

const SellerProductsPreview = (props) => {
  const I18n = useI18n()

  return <SellerProductsPreviewContainer I18n={I18n} {...props} />
}

export default SellerProductsPreview
