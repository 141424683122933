import React, { Component, ReactNode } from 'react'
import classNames from 'classnames'

import {
  NUMBER_FIELD_MIN,
  NUMBER_FIELD_MAX,
  NUMBER_FIELD_STEP,
  TEXT_FIELD_AUTOCOMPLETE,
} from '@elo-kit/constants/forms.constants'

import { isInteger } from '@elo-kit/utils/validators.utils'
import { InfoTooltip } from '@elo-kit/components/info-tooltip'
import { FieldError } from '@elo-kit/components/elo-ui/field-error'
import { I18nType, useI18n } from '@elo-kit/components/i18n/i18n'

import { AnyCallback } from 'types/helpers'

import './_number-field.scss'

/**
 * NumberField - Form Number Field
 */

interface Props {
  value?: number | string
  onChange?: AnyCallback
  labelClassName?: string
  className?: string
  staticText?: any
  min?: number | string
  max?: number | string
  step?: number | string
  name?: string
  disabled?: boolean
  label?: string
  placeholder?: string
  errorText?: string
  isValid?: boolean
  outlined?: boolean
  onBlur?: AnyCallback
  fixed?: number | boolean
  errorId?: string
  required?: boolean
  onArrowClick?: AnyCallback
  tooltipId?: string
  withArrows?: boolean
  tooltipTitle?: boolean
  prefix?: ReactNode
  prefixInsideField?: boolean
  prefixNormalField?: boolean
  I18n?: I18nType
}
interface State {
  value: number | string | undefined
  valid: boolean
}
const defaultProps = {
  min: NUMBER_FIELD_MIN,
  max: NUMBER_FIELD_MAX,
  step: NUMBER_FIELD_STEP,
  disabled: false,
  isValid: true,
  errorText: '',
  onBlur: () => {},
  onArrowClick: () => {},
  withArrows: true,
  tooltipTitle: false,
}
class NumberFieldContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      value: props.value,
      valid: true,
    }
  }
  value: number | string | null | undefined
  static displayName = 'NumberField'
  static defaultProps = defaultProps

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { value } = this.props

    const shouldUpdateValue = prevProps.value !== value && prevState.value !== value

    if (shouldUpdateValue) {
      // eslint-disable-next-line
      this.setState({
        value,
      })
    }
  }

  changeValue = (param: number) => () => {
    const { step, onChange, fixed } = this.props

    const { value: currentValue } = this.state

    const callOnChange = () => {
      const { value } = this.state
      const { onArrowClick } = this.props

      onChange && onChange(value)
      onArrowClick && onArrowClick(value)
    }

    this.setState(
      () => ({
        // TODO:
        // @ts-ignore
        value: (Number(currentValue) || 0) + (param ? step : -step),
      }),
      () => {
        if (fixed) {
          const { value } = this.state

          this.setState(
            () => ({
              // TODO:
              // @ts-ignore
              value: value.toFixed(fixed),
            }),
            callOnChange
          )
        } else {
          callOnChange()
        }
      }
    )
  }

  increment = () => this.changeValue(1)()

  decrement = () => this.changeValue(0)()

  onFieldChange = ({ target }: { target: { value: string } }) => {
    const { max, required, onChange } = this.props

    const newValue = Number(target.value)
    let protectedNewValue: number | string | undefined = ''

    switch (true) {
      case target.value === '':
        protectedNewValue = ''
        break
      case !isInteger(newValue):
        protectedNewValue = ''
        break
      case newValue > Number(max):
        protectedNewValue = max
        break
      default:
        protectedNewValue = newValue
    }

    this.setState(
      {
        value: protectedNewValue,
        valid: !(required && !protectedNewValue),
      },
      () => {
        onChange && onChange(protectedNewValue)
      }
    )
  }

  // Validate min only on blur
  onFieldBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { min, onChange, required, onBlur } = this.props

    const newValue = Number(e.target.value)
    let protectedNewValue: number | string | undefined = newValue

    if (newValue <= Number(min)) {
      protectedNewValue = min
      this.setState({
        value: protectedNewValue,
      })
    }

    this.setState(
      {
        valid: !(required && !protectedNewValue),
      },
      () => {
        onChange && onChange(protectedNewValue)
        onBlur && onBlur(protectedNewValue)
      }
    )
  }

  render() {
    const {
      className,
      disabled,
      isValid,
      withArrows,
      label,
      max,
      min,
      name,
      placeholder,
      tooltipId,
      required,
      errorText,
      labelClassName,
      errorId,
      outlined,
      staticText,
      tooltipTitle,
      prefix,
      prefixInsideField,
      prefixNormalField,
      I18n,
    } = this.props

    const { valid, value } = this.state

    const fieldClassNames = classNames('field number-field', className, {
      'number-field--disabled': disabled,
      'number-field--required': required,
      'number-field--with-static-text': staticText,
    })

    const fieldControlClassNames = classNames('field__control number-field__control', {
      'number-field__control--error': !valid || !isValid,
      'text-field__control--with-prefix': prefix,
      'text-field__control--with-prefix-normal-field': prefixNormalField,
    })

    const arrowUpClasses = classNames('number-field__control-arrows--up', {
      'number-field__control-arrows--disabled': Number(value) >= Number(max),
    })

    const arrowDownClasses = classNames('number-field__control-arrows--down', {
      'number-field__control-arrows--disabled': !value || Number(value) <= Number(min),
    })

    const labelClasses = classNames(
      {
        [String(labelClassName)]: labelClassName,
      },
      'field__label'
    )

    const valueToString = String(this.value)
    const staticTextClassNames = classNames('number-field__static-text', {
      'number-field__static-text--one': valueToString.length === 1,
      'number-field__static-text--two': valueToString.length === 2,
      'number-field__static-text--three': valueToString.length === 3,
      'number-field__static-text--four': valueToString.length === 4,
      'number-field__static-text--five': valueToString.length === 5,
    })

    const prefixClasses = classNames('text-field__prefix input-group-prepend', {
      'text-field__prefix--inside-field': prefixInsideField,
    })

    const valueToShow = value === 0 ? 0 : `${value || ''}`

    const tooltipTitles = {
      max_keys_amount: I18n.t('react.cabinet.help_icon.max_keys_amount.title'),
    }

    const tooltipContent = {
      bonus: I18n.t('react.cabinet.help_icon.bonus.content'),
      buy_button_border_radius: I18n.t('react.cabinet.help_icon.buy_button_border_radius.content'),
      apply_coupon_button_border_radius: I18n.t('react.cabinet.help_icon.apply_coupon_button_border_radius.content'),
      max_keys_amount: I18n.t('react.cabinet.help_icon.max_keys_amount.content'),
      automatic_quiz_rating: I18n.t('react.cabinet.help_icon.automatic_quiz_rating.content'),
      video_code_from: I18n.t('react.cabinet.help_icon.video_code_from.content'),
      video_code_till: I18n.t('react.cabinet.help_icon.video_code_till.content'),
      video_code_duration: I18n.t('react.cabinet.help_icon.video_code_duration.content'),
      cookies_expire: I18n.t('react.cabinet.help_icon.cookies_expire.content'),
    }

    return (
      <div className={fieldClassNames}>
        {label && (
          <div className={labelClasses}>
            <span>{label}</span>
            {tooltipId && (
              <InfoTooltip
                id={`${tooltipId}_popover`}
                title={tooltipTitle && tooltipTitles[tooltipId]}
                body={tooltipContent[tooltipId]}
              />
            )}
          </div>
        )}

        {(!valid || !isValid) && <FieldError id={errorId} errorText={errorText} outlined={outlined} />}

        <div className='number-field__control-wrapper'>
          {prefix && <div className={prefixClasses}>{prefix}</div>}

          <input
            type='text'
            id={name}
            name={name}
            value={valueToShow}
            className={fieldControlClassNames}
            placeholder={placeholder}
            readOnly={disabled}
            autoComplete={TEXT_FIELD_AUTOCOMPLETE.off}
            onChange={this.onFieldChange}
            onBlur={this.onFieldBlur}
          />

          {staticText && valueToString && <span className={staticTextClassNames}>{staticText}</span>}

          {withArrows && (
            <div className='number-field__control-arrows'>
              <div className={arrowUpClasses} onClick={this.increment} />
              <div className={arrowDownClasses} onClick={this.decrement} />
            </div>
          )}
        </div>
      </div>
    )
  }
}

export const NumberField = (props) => {
  const I18n = useI18n()

  return <NumberFieldContainer I18n={I18n} {...props} />
}
