import { COLOR_PICKER_DEFAULT_COLORS_PRESET } from 'constants/forms.constants'
import { LocalStorageService } from 'utils/local-storage.utils'

import { isValidColor } from './validators.utils'

const COLOR_PRESET_LOCAL_STORAGE_KEY = 'COLOR_PRESET_LOCAL_STORAGE_KEY'
const COLOR_PRESET_MAX_ITEMS = 21
const COLOR_DELIM = ','

/**
 * @function isPresetFeatureSupported Check whether feature is supported
 *
 * @return {Boolean} true, if supported
 */
export const isPresetFeatureSupported = () => LocalStorageService.isLocalStorageSupported()

/**
 * @function getColorPreset Gets color's preset
 *
 * @return {Array} Color Prest
 */
export const getColorPreset = () => {
  let preset = LocalStorageService.getItem(COLOR_PRESET_LOCAL_STORAGE_KEY) || ''
  if (Array.isArray(preset)) {
    preset = preset.join(COLOR_DELIM)
  }

  const parsedPreset = (preset || '').split(COLOR_DELIM) || []

  return parsedPreset.filter((color) => color)
}

/**
 * @function addColorToPreset Adds color to preset
 *
 * @param {String} color Color to add
 */
export const addColorToPreset = (color) => {
  const parsedPreset = getColorPreset()

  if (color && isValidColor(color)) {
    const colorsToCheckMatch = COLOR_PICKER_DEFAULT_COLORS_PRESET.concat(parsedPreset)
    const isColorExist = colorsToCheckMatch.find(
      (presetColor) => color.toUpperCase() === (presetColor && presetColor.toUpperCase())
    )

    if (!isColorExist) {
      /* Rotate Colors */
      if (parsedPreset.length >= COLOR_PRESET_MAX_ITEMS) {
        parsedPreset.pop()
        parsedPreset.unshift(color)
      } else {
        parsedPreset.push(color)
      }
    }
  }

  return LocalStorageService.setItem(COLOR_PRESET_LOCAL_STORAGE_KEY, parsedPreset.join(COLOR_DELIM))
}
