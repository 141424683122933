import React, { Component } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import withStyles, { ThemeProvider } from 'react-jss'
import PropTypes from 'prop-types'

import {
  PAYMENT_FORMS,
  PAYMENT_PROVIDERS,
  STRIPE_ELEMENT_ROOT_ID,
  KLARNA_ROOT_ID,
} from 'constants/paymentSettingShared.constants'
import { getPaymentFormsLabels } from '@elo-kit/constants/paymentSetting.constants'
import { ShopRootStore } from 'shop/stores/shopRoot.store'
import {
  injectStripeScript,
  createStripeClient,
  createStripeElements,
  createStripeP24,
  isStripeInjected,
  handleSofortTransaction,
  handleP24Transaction,
  handleIdealTransaction,
  handleStripeCardTransaction,
  createStripeIdeal,
  createStripeKlarna,
  convertPriceForStripe,
  getKlarnaFormSettings,
} from '@elo-kit/utils/stripe.utils'
import { getPubKey } from 'utils/paymentSettingShared.utils'
import { getFraudSessionIdentifier, insertFraudPaypalSnippet } from 'utils/ordersShared.utils'

import { notify } from 'libs/common/notify'
import { PricingPlansList } from '@elo-kit/components/pricing-plans-list'
import { CheckboxField } from '@elo-kit/components/form/checkbox-field/CheckboxField'
import { LoadingMask } from '@elo-kit/components/loading-mask'
import { useI18n } from '@elo-kit/components/i18n/i18n'
import { KLARNA_KEY, KLARNA_SUBSCRIPTIONS } from 'constants/options.constants'
import { PAYMENT_PLANS } from 'constants/pricingPlans.constants'
import { useSharedStores } from 'shared/hooks/use-shared-stores'
import { createKibanaApi } from 'shop/api/kibana.api'
import { apiClient } from 'utils/requests.utils'
import autoPayModalStyles from './AutoPayModalStyles'

const KLARNA_PLANS = [PAYMENT_PLANS.oneTime]
const KLARNA_PLANS_WITH_SUBS = [...KLARNA_PLANS, PAYMENT_PLANS.subscription, PAYMENT_PLANS.limitedSubscription]
/**
 * AutoPay modal component
 */
export class AutoPayModalContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      isSubmitDisabled: false,
      stripeElementLoading: false,
      stripeElementError: '',
      payerFullName: '',
      payerCountryCode: '',
      payerEmail: '',
      pricingPlanId: null,
      cancellationTermsChecked: false,
      fraudSessionIdentifier: null,
      activePricingPlan: {},
      klarnaPlans: [],
      isKlarnaAvailable: false,
    }
    this.kibanaApi = createKibanaApi(ShopRootStore?.apiClient ?? apiClient)
    this.stripeClient = null
    this.stripeElements = null
    this.stripeElement = null
    this.loggedPricingPlanId = null
  }

  initStripe = () => {
    if (!isStripeInjected()) {
      injectStripeScript(this.handleStripeOnLoad)
    } else {
      this.handleStripeOnLoad()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.klarnaPlans.includes(prevState.activePricingPlan?.form) &&
      this.isKlarna() &&
      !this.state.stripeInit &&
      !this.state.isKlarnaAvailable &&
      !this.state.stripeElementLoading
    ) {
      this.initStripe()
      this.setState({ stripeInit: true })
    }
  }

  sellerHasApp = (key) => this.props.sellerItem?.optionKeys?.includes(key)

  isKlarna = () => this.props.sellable?.orderPaymentForm === PAYMENT_FORMS.klarna

  componentDidMount = async () => {
    const { sellable, productId, fetchProduct, sellerItem } = this.props
    const { id: sellerId, sofortProvider } = sellerItem

    const { data: product, success } = (await fetchProduct(productId, { sellerId })) || {}

    if (success && sellable.orderPaymentForm === PAYMENT_FORMS.klarna) {
      this.setState({ activePricingPlan: product?.pricingPlans[0], pricingPlanId: product?.pricingPlans[0]?.id })
    }

    const hasKlarna = this.sellerHasApp(KLARNA_KEY)
    const hasKlarnaSubs = this.sellerHasApp(KLARNA_SUBSCRIPTIONS)

    const klarnaPlans = hasKlarna ? KLARNA_PLANS : []

    this.setState({
      klarnaPlans: hasKlarnaSubs && hasKlarna ? KLARNA_PLANS_WITH_SUBS : klarnaPlans,
      isKlarnaAvailable: hasKlarna && sellerItem.klarnaEnabled,
    })

    switch (sellable.orderPaymentForm) {
      case PAYMENT_FORMS.sofort: {
        const isStripeSofort =
          sofortProvider === PAYMENT_PROVIDERS.stripe || sofortProvider === PAYMENT_PROVIDERS.elopageConnect

        if (isStripeSofort) {
          this.initStripe()
        }
        break
      }
      case PAYMENT_FORMS.applePay:
      case PAYMENT_FORMS.googlePay:
      case PAYMENT_FORMS.klarna:
      case PAYMENT_FORMS.card: {
        this.initStripe()
        break
      }
      case PAYMENT_FORMS.p24: {
        this.setState(
          {
            isSubmitDisabled: true,
          },
          () => this.initStripe()
        )
        break
      }
      case PAYMENT_FORMS.paypal: {
        const fraudSessionIdentifier = getFraudSessionIdentifier({
          paypalMerchantId: sellerItem.paypalMerchantId,
          paypalProvider: sellable.orderPaymentProvider,
          paymentForm: sellable.orderPaymentForm,
        })

        if (fraudSessionIdentifier) {
          insertFraudPaypalSnippet(fraudSessionIdentifier, sellerItem.paypalMerchantId)
          this.setState({ fraudSessionIdentifier: fraudSessionIdentifier })
        }
        break
      }

      default:
        break
    }
  }

  getKlarnaStripeElementPayload = (activePricingPlan) => {
    const { currenciesStore, sellerItem } = this.props
    const { activePricingPlan: plan } = activePricingPlan ? { activePricingPlan } : this.state
    const price = [PAYMENT_PLANS.limitedSubscription, PAYMENT_PLANS.subscription].includes(plan?.form)
      ? plan.prefs?.firstAmount
      : plan?.prefs?.price

    const klarnaParams = {
      mode: 'payment',
      paymentMethodTypes: [PAYMENT_FORMS.klarna],
      currency: currenciesStore.getKey(plan?.currencyId),
      amount: convertPriceForStripe(price),
      ...(sellerItem.klarnaProvider === PAYMENT_PROVIDERS.elopageConnect
        ? { onBehalfOf: sellerItem.elopageConnectAccountId }
        : {}),
    }
    if (this.state.loggedPricingPlanId !== plan.id) {
      this.kibanaApi.reportKlarnaParams(sellerItem.id, {
        ...klarnaParams,
        source: 'autopay',
      })

      this.setState({ loggedPricingPlanId: plan.id })
    }

    return klarnaParams
  }

  handleStripeOnLoad = async () => {
    const { sellable, sellerItem, manageOrder } = this.props

    const resp = (await manageOrder(sellerItem.username, sellable.orderToken)) || {}
    const { data = {} } = resp
    const { moneyHolder, payer = {} } = data || {}

    const { fullName, email, countryCode } = payer || {}
    const isKlarna = sellable.orderPaymentForm === PAYMENT_FORMS.klarna
    const isP24 = sellable.orderPaymentForm === PAYMENT_FORMS.p24
    const isIdeal = sellable.orderPaymentForm === PAYMENT_FORMS.ideal

    if (
      isKlarna &&
      (!this.state.klarnaPlans.includes(this.state.activePricingPlan?.form) || !this.state.isKlarnaAvailable)
    ) {
      return
    }

    const {
      cardProvider,
      sofortProvider,
      sepaProvider,
      applePayProvider,
      googlePayProvider,
      p24Provider,
      idealProvider,
      klarnaProvider,
    } = moneyHolder || {}

    this.setState({
      stripeElementLoading: true,
      payerFullName: fullName,
      payerCountryCode: countryCode,
      payerEmail: email,
    })

    const providers = {
      cardProvider,
      sofortProvider,
      sepaProvider,
      applePayProvider,
      googlePayProvider,
      p24Provider,
      idealProvider,
      klarnaProvider,
    }
    const pubKey = (moneyHolder || {})[getPubKey(sellable?.orderPaymentForm, providers)]

    if (pubKey) {
      this.stripeClient = createStripeClient(pubKey)

      if (isKlarna) {
        this.stripeElements = createStripeElements(this.stripeClient, this.getKlarnaStripeElementPayload())
      } else {
        this.stripeElements = createStripeElements(this.stripeClient)
      }

      if (isP24 || isIdeal || isKlarna) {
        if (isP24) {
          this.stripeElement = createStripeP24(this.stripeElements)
        }

        if (isIdeal) {
          this.stripeElement = createStripeIdeal(this.stripeElements)
        }

        if (isKlarna) {
          this.stripeElement = createStripeKlarna(this.stripeElements, getKlarnaFormSettings())
        }

        this.stripeElement?.mount(`#${isKlarna ? KLARNA_ROOT_ID : STRIPE_ELEMENT_ROOT_ID}`)
        this.stripeElement?.on('ready', () => {
          this.setState({
            stripeElementLoading: false,
            stripeInit: true,
          })
        })
        this.stripeElement?.on('change', ({ complete, error }) => {
          if (error) {
            this.setState({
              stripeElementError: error.message,
            })
          } else {
            this.setState({
              stripeElementError: '',
            })

            if (complete) {
              this.setState({
                isSubmitDisabled: false,
              })
            }
          }
        })
      }

      if (
        sellable.orderPaymentForm === PAYMENT_FORMS.applePay ||
        sellable.orderPaymentForm === PAYMENT_FORMS.googlePay
      ) {
        this.setState({
          isSubmitDisabled: false,
        })
      }
    }
  }

  getActivePricingPlan = (pricingPlanId) => {
    const { product } = this.props
    return product.pricingPlans?.find((plan) => plan.id === pricingPlanId)
  }

  onSelectPlan = (id) => {
    const activePricingPlan = this.getActivePricingPlan(id)
    this.setState({ pricingPlanId: id, activePricingPlan })

    if (this.state.klarnaPlans.includes(activePricingPlan?.form) && this.isKlarna()) {
      this.updateKlarna(activePricingPlan)
    } else if (this.stripeElement && !this.stripeElement?._destroyed && this.isKlarna()) {
      this.setState({ stripeInit: false })
      this.stripeElement.destroy()
    }
  }

  updateKlarna = (activePricingPlan) => {
    if (this.stripeElements && !this.stripeElement?._destroyed) {
      this.stripeElements.update(this.getKlarnaStripeElementPayload(activePricingPlan))
    } else {
      this.initStripe()
    }
  }

  handleModalSubmit = () => {
    const {
      historyPush,
      membershipViewType,
      membershipSellable,
      sellerItem,
      toggleCustomLoading,
      fetchSellable,
      closeModal,
      toggleOrderCompletedModal,
      productId,
      sellable,
      acceptPostsell,
      getMP3dsBrowserMetaData,
    } = this.props
    const { pricingPlanId, fraudSessionIdentifier, activePricingPlan } = this.state
    const isKlarna = sellable.orderPaymentForm === PAYMENT_FORMS.klarna

    if (
      membershipViewType === 'seller' ||
      (isKlarna && (!this.state.klarnaPlans.includes(activePricingPlan?.form) || !this.state.isKlarnaAvailable))
    ) {
      historyPush(this.getCheckoutUrl())
    } else {
      toggleCustomLoading()

      fetchSellable(membershipSellable).then(() => {
        toggleCustomLoading()
        const parentOrderIsFree = sellable.orderPaymentForm === 'free'

        if (!parentOrderIsFree) {
          this.setState({
            isSubmitDisabled: true,
            isLoading: true,
          })
          const isCard = sellable.orderPaymentForm === PAYMENT_FORMS.card
          const isMangoPay = sellable.orderPaymentProvider === PAYMENT_PROVIDERS.mangoPay

          let acceptPostsellData = {
            pricingPlanId,
            productId,
          }

          if (isCard && isMangoPay) {
            const mp3dsBrowserMetaData = getMP3dsBrowserMetaData()

            acceptPostsellData = {
              ...acceptPostsellData,
              ...mp3dsBrowserMetaData,
            }
          }

          if (fraudSessionIdentifier) {
            const paypalFraudData = {
              paypalFraudToken: fraudSessionIdentifier,
            }

            acceptPostsellData = {
              ...acceptPostsellData,
              ...paypalFraudData,
            }
          }

          acceptPostsell(sellerItem.username, sellable.orderToken, acceptPostsellData).then((resp) => {
            const { data, success } = resp || {}
            const { redirectLink, clientSecret, paymentMethod, successLink } = data || {}
            const { sofortProvider } = sellerItem

            if (success) {
              const shouldHandleStripe = redirectLink && clientSecret && this.stripeClient
              const isP24 = sellable.orderPaymentForm === PAYMENT_FORMS.p24
              const isIdeal = sellable.orderPaymentForm === PAYMENT_FORMS.ideal
              const isStripeCard = sellable.orderPaymentForm === PAYMENT_FORMS.card
              const isStripeSofort =
                sellable.orderPaymentForm === PAYMENT_FORMS.sofort &&
                (sofortProvider === PAYMENT_PROVIDERS.stripe || sofortProvider === PAYMENT_PROVIDERS.elopageConnect)
              const isDigitalPayment =
                sellable.orderPaymentForm === PAYMENT_FORMS.applePay ||
                sellable.orderPaymentForm === PAYMENT_FORMS.googlePay

              if (shouldHandleStripe && isP24) {
                const { payerFullName, payerEmail } = this.state
                handleP24Transaction(this.stripeClient, this.stripeP24, redirectLink, clientSecret, {
                  fullName: payerFullName,
                  email: payerEmail,
                  tosShowAndAccepted: false,
                })
              }

              if (shouldHandleStripe && isIdeal) {
                const { payerFullName, payerEmail } = this.state
                handleIdealTransaction(this.stripeClient, this.stripeIdeal, redirectLink, clientSecret, {
                  fullName: payerFullName,
                  email: payerEmail,
                })
              }

              if (shouldHandleStripe && isStripeSofort) {
                const { payerCountryCode, payerFullName, payerEmail } = this.state
                const { planPrefs = {} } = sellable || {}
                const { stripeSofortSepa, elopageConnectSofortSepa } = sellerItem

                const billingDetails =
                  planPrefs?.sofortSepa && (stripeSofortSepa || elopageConnectSofortSepa)
                    ? {
                        billing_details: {
                          name: payerFullName,
                          email: payerEmail,
                        },
                      }
                    : {}
                handleSofortTransaction(this.stripeClient, redirectLink, clientSecret, {
                  countryCode: payerCountryCode,
                  billingDetails,
                })
              }

              if (shouldHandleStripe && (isStripeCard || isDigitalPayment)) {
                if (paymentMethod) {
                  handleStripeCardTransaction(this.stripeClient, { id: paymentMethod }, clientSecret).then((result) => {
                    const { error, paymentIntent } = result
                    if (paymentIntent) {
                      window.location.href = redirectLink
                    } else if (error.message) {
                      notify('error', error.message)
                    }
                  })
                }
              }

              if (!shouldHandleStripe || !(isP24 || isIdeal || isStripeCard || isStripeSofort || isDigitalPayment)) {
                if (redirectLink) {
                  window.location.href = redirectLink
                } else {
                  closeModal()
                  toggleOrderCompletedModal()
                }
              }

              if (isKlarna && successLink) {
                window.location.href = successLink
              }
            } else {
              this.setState({
                isSubmitDisabled: false,
                isLoading: false,
              })
            }
          })
        } else {
          historyPush(this.getCheckoutUrl())
        }
      })
    }
  }

  toggleCancellationTerms = () => {
    const { cancellationTermsChecked } = this.state

    this.setState({
      cancellationTermsChecked: !cancellationTermsChecked,
    })
  }

  renderPaymentSystem = (paymentForm) => {
    const { classes, I18n } = this.props
    const { stripeElementError, stripeElementLoading } = this.state

    switch (paymentForm) {
      case PAYMENT_FORMS.ideal:
      case PAYMENT_FORMS.p24:
      case PAYMENT_FORMS.klarna:
        return (
          <>
            <div className={classes.p24Notes}>{I18n.t('react.payer.autopay.p24_notes')}</div>
            <div id={paymentForm === PAYMENT_FORMS.klarna ? KLARNA_ROOT_ID : STRIPE_ELEMENT_ROOT_ID} />
            {stripeElementLoading && <div className='lmask' />}
            {stripeElementError && <div>{stripeElementError}</div>}
          </>
        )
      default:
        null
    }
  }

  getCheckoutUrl = () => {
    const { sellerItem, product } = this.props
    const { pricingPlanId } = this.state
    return `/s/${sellerItem.username}/${product.slug}/payment?pricing_plan_id=${pricingPlanId}`
  }

  render() {
    const {
      sellerItem,
      membershipViewType,
      closeModal,
      classes,
      sellable,
      product,
      LinkComponent,
      convertToPrice,
      amountCrossed,
      isWindows,
      I18n,
    } = this.props

    const { isSubmitDisabled, isLoading, pricingPlanId, cancellationTermsChecked } = this.state
    const parentOrderIsFree = sellable.orderPaymentForm === 'free'
    const cancellationTerms = product.cancellationTerms || {}
    const isValid = product.free || ((!cancellationTerms.checkboxVisible || cancellationTermsChecked) && pricingPlanId)
    const sellerViewActive = membershipViewType === 'seller'
    const isWithPayment = !parentOrderIsFree && sellable.orderToken && !product.free

    return (
      <Modal className='elo-modal elo-modal--medium' isOpen>
        <ModalHeader>
          <div className={classes.modalHeader}>{I18n.t('react.shared.buy_product')}</div>
          <i className='fas fa-times elo-modal__close-btn' onClick={closeModal} />
        </ModalHeader>
        <ModalBody className={classes.modalBody}>
          {isWithPayment && (
            <div className={classes.changePaymethod}>
              <span className={classes.changePaymethodVia}>
                {this.isKlarna()
                  ? I18n.t('react.shared.pay_with_klarna')
                  : I18n.t('react.shared.pay_via_method', {
                      method: getPaymentFormsLabels()[sellable.orderPaymentForm],
                    })}
              </span>
              &nbsp;
              {I18n.t('react.shared.or')}
              &nbsp;
              <LinkComponent
                className={classes.changePaymethodLink}
                to={this.getCheckoutUrl()}
                href={this.getCheckoutUrl()}
              >
                {I18n.t('react.payer.common.use_another_paymethod')}
              </LinkComponent>
            </div>
          )}

          {isLoading && isWithPayment ? <LoadingMask /> : null}

          <div className={classes.modal}>
            {!product.free ? (
              <>
                <div className={classes.pricingPlanContainer}>
                  <PricingPlansList
                    onSelectPlan={this.onSelectPlan}
                    selectedId={pricingPlanId}
                    pricingPlans={product.pricingPlans || []}
                    forForm
                    convertToPrice={convertToPrice}
                    amountCrossed={amountCrossed}
                    isWindows={isWindows}
                  />
                </div>

                {isWithPayment && <div>{this.renderPaymentSystem(sellable.orderPaymentForm)}</div>}

                <div className={classes.cancellationTerms}>
                  <div
                    className='seller-terms'
                    dangerouslySetInnerHTML={{
                      __html: I18n.t('react.shared.accept_terms.payment', {
                        terms_href: `/s/${sellerItem.username}/document/terms_of_business`,
                      }),
                    }}
                  />
                  {cancellationTerms.checkboxVisible ? (
                    <CheckboxField
                      name='cancellationTerms'
                      label={<div dangerouslySetInnerHTML={{ __html: cancellationTerms.label }} />}
                      checked={cancellationTermsChecked}
                      onChange={this.toggleCancellationTerms}
                    />
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: cancellationTerms.label }} />
                  )}
                </div>
              </>
            ) : (
              <div className={classes.cancellationTerms}>{I18n.t('react.payer.autopay.free_purchase_description')}</div>
            )}
            <div className={`elo-modal__bottom-buttons ${classes.btnContainer}`}>
              <button onClick={closeModal} className={classes.cancelButton} type='button'>
                {I18n.t('react.shared.close')}
              </button>
              <button
                type='button'
                onClick={this.handleModalSubmit}
                className={classes.buyButton}
                disabled={!isValid || isSubmitDisabled}
              >
                {parentOrderIsFree || sellerViewActive || product.free
                  ? I18n.t('react.shared.next')
                  : I18n.t('react.shared.button.buy_now')}
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

AutoPayModalContainer.propTypes = {
  /** Sellable item */
  sellable: PropTypes.object, // sellablesStore.item
  /** Product id */
  productId: PropTypes.shape([PropTypes.number, PropTypes.string]),
  /** Fetch product function */
  fetchProduct: PropTypes.func, // productsStore.fetchItem
  /** Seller item */
  sellerItem: PropTypes.object, // sellersStore.item
  /** Manage order function */
  manageOrder: PropTypes.func, // shop/api/orders.api
  /** Browser history push */
  historyPush: PropTypes.func,
  /** Membership view type received from contentPageStore */
  membershipViewType: PropTypes.string,
  /** Membership sellable id received from contentPageStore */
  membershipSellable: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Function that toggle custom loading */
  toggleCustomLoading: PropTypes.func, // sellablesStore.toggleCustomLoading
  /** Fetch sellable function */
  fetchSellable: PropTypes.func, // sellablesStore.fetchItem
  /** Function that close modal */
  closeModal: PropTypes.func,
  /** Function that toggle order complete modal */
  toggleOrderCompletedModal: PropTypes.func,
  /** Product item */
  product: PropTypes.object, // productsStore.item
  /** Accept postsell function */
  acceptPostsell: PropTypes.func, // shop/api/funnels.api
  /** Classes object */
  classes: PropTypes.object,
  /** Wrapper link for url */
  LinkComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /** Convert price function received from currencyStore */
  convertToPrice: PropTypes.func, // currenciesStore.convertToPrice
  /** Function that cross amount value with style for windows */
  amountCrossed: PropTypes.func, // helpers.utils
  /** Boolean value that say you is windows operating system get from navigator of browser */
  isWindows: PropTypes.bool, // browser.utils
  getMP3dsBrowserMetaData: PropTypes.func, // requests.utils
}
AutoPayModalContainer.defaultProps = {
  sellable: {},
  sellerItem: {},
  product: {},
  classes: {},
  LinkComponent: 'a',
  fetchProduct: /* istanbul ignore next */ () => {},
  convertToPrice: /* istanbul ignore next */ () => {},
  amountCrossed: /* istanbul ignore next */ () => {},
  getMP3dsBrowserMetaData: /* istanbul ignore next */ () => {},
  I18n: {
    t: () => {},
  },
}

export const AutoPayModalWithStyles = withStyles(autoPayModalStyles)(AutoPayModalContainer)

const AutoPayModal = (props) => {
  const { theme } = props
  const I18n = useI18n()
  const { currenciesStore } = useSharedStores()

  return (
    <ThemeProvider theme={theme}>
      <AutoPayModalWithStyles I18n={I18n} {...props} currenciesStore={currenciesStore} />
    </ThemeProvider>
  )
}

AutoPayModal.displayName = 'AutoPayModal'
AutoPayModal.propTypes = {
  theme: PropTypes.object,
}
AutoPayModal.defaultProps = {
  theme: {},
}

export default AutoPayModal
