import { PRODUCTS_LIST } from '@elo-kit/constants/contentPage.constants'
import { COLORS } from '@elo-kit/constants/general.constants'
import { getFontStyles } from '@elo-kit/utils/fonts.utils'
import { cleanupFonts } from 'utils/fonts.utils'

// TODO: update with using createUseStyles
const productListStyles = (theme = {}) => {
  const { eloBlack, eloBlue, eloWhite } = COLORS
  const productFont = cleanupFonts(theme[PRODUCTS_LIST.productFont]) || 'Montserrat Medium'
  return {
    hideBlock: {
      display: 'none',
    },

    productsListSection: {
      width: '100%',
      backgroundColor: theme[PRODUCTS_LIST.background] || eloWhite,
    },

    productsContainer: {
      maxWidth: '1140px',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      margin: '0 auto',
      paddingTop: theme[PRODUCTS_LIST.showCategories] ? '30px' : '0',
    },

    productWidgetContainer: {
      width: '270px',
      display: 'flex',
      flexDirection: 'column',
      boxShadow: '0 6px 20px 0 #00000026',
      margin: '0 30px 60px',
      borderRadius: '6px',
      '& a': {
        textDecoration: 'none',
      },
      '&:hover': {
        boxShadow: '0 6px 20px 0 #00000050',
        textDecoration: 'none',
      },
    },

    accessRestrictedGridLayer: {
      position: 'absolute',
      backgroundColor: 'rgba(0,0,0,0.5)',
      width: '270px',
      height: '270px',
      zIndex: 1,
      borderRadius: '6px',
    },

    accessRestrictedGridIcon: {
      position: 'relative',
      left: '108px',
      top: '100px',
      color: 'snow',
      fontSize: '62px',
    },

    accessRestrictedListLayer: {
      position: 'absolute',
      backgroundColor: 'rgba(0,0,0,0.5)',
      width: '120px',
      height: '120px',
      zIndex: 1,
      borderRadius: '6px',
    },

    accessRestrictedListIcon: {
      position: 'relative',
      left: '42px',
      top: '18px',
      color: 'snow',
      fontSize: '42px',
    },

    disableLink: {
      pointerEvents: 'none',
    },

    productDetails: {
      height: '100%',
      padding: '30px',
      backgroundColor: theme[PRODUCTS_LIST.productBackground] || eloWhite,
      borderRadius: '0px 0px 6px 6px',
      flex: 1,
      minHeight: 80,
    },

    productTitle: {
      display: 'flex',
      justifyContent: 'center',
      overflow: 'hidden',
      textAlign: 'center',
      height: '100%',
      width: '100%',
      fontSize: theme[PRODUCTS_LIST.productSize] ? `${theme[PRODUCTS_LIST.productSize]}px` : '18px',
      fontFamily: productFont,
      ...getFontStyles(theme[PRODUCTS_LIST.productStyle]),
      color: theme[PRODUCTS_LIST.productColor] || eloBlack,
      wordBreak: 'break-word',
      lineHeight: 1.2,

      '@media (max-width: 576px)': {
        fontSize: theme[PRODUCTS_LIST.productSize] > 26 ? 26 : theme[PRODUCTS_LIST.productSize],
      },
    },

    productTitleWithProgress: {
      height: 'auto',
    },

    noCoverContainer: {
      fontSize: '100px',
      color: eloWhite,
    },
    progressDetails: {
      display: 'flex',
      alignItems: 'end',
      justifyContent: 'space-between',
      fontFamily: productFont,
      color: theme[PRODUCTS_LIST.productColor] || eloBlack,
      fontSize: '13px',
      marginTop: '6px',
    },
    productProgress: {
      maxWidth: 210,
      width: 210,
      marginTop: '20px',
      height: '100%',
      '& .progress': {
        backgroundColor: theme[PRODUCTS_LIST.progressBackground] || '#efedef',
        height: 4,
      },
      '& .progress-bar': {
        backgroundColor: theme[PRODUCTS_LIST.progressColor] || eloBlue,
      },

      '@media (max-width: 991px)': {
        width: '100%',
      },
    },

    controlsContainer: {
      maxWidth: '1140px',
      width: '100%',
      margin: '0 auto 0',
      padding: 20,
      display: 'flex',
      justifyContent: 'space-between',

      '& .builder-field': {
        marginBottom: 0,
      },

      '@media (max-width: 576px)': {
        margin: '0 auto',
        flexWrap: 'wrap',
        justifyContent: 'center',
        '& .builder-field': {
          marginBottom: 20,
        },
        '& .builder-field:last-child': {
          marginBottom: 0,
        },
      },
    },

    // list-view classes
    list: {
      height: '100%',
      width: '100%',
      margin: '0 auto',
      position: 'relative',
      maxWidth: '1140px',
    },

    item: {
      display: 'flex',
      alignItems: 'center',
      padding: '30px 15px',
      borderBottom: '1px solid #eaeaea4d',
    },

    listBackgroundImageContainer: {
      height: 120,
      borderRadius: 6,
      backgroundColor: '#efedef',
      color: eloWhite,
      fontSize: 50,
      width: 120,
      minWidth: 120,
      display: 'flex',
      boxShadow: '0 6px 20px 0 #00000026',
      marginRight: 50,
      position: 'relative',

      '& > div': {
        borderRadius: 6,
      },

      '&:hover': {
        boxShadow: '0 6px 20px 0 #00000050',
        textDecoration: 'none',
        '& i': {
          color: eloWhite,
        },
      },

      '@media (max-width: 991px)': {
        marginRight: 25,
        width: 120,
        minWidth: 120,
        height: 120,
      },
    },

    listProductTitle: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      textAlign: 'left',
      lineHeight: 1.4,
      marginBottom: 10,
      height: 'auto',
    },

    productCover: {
      backgroundSize: 'cover',
      width: '100%',
      backgroundPosition: 'center',
      borderRadius: 6,
      height: 120,
    },

    listProductDetails: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',

      '&:hover': {
        textDecoration: 'none',
        opacity: 0.9,
        color: theme[PRODUCTS_LIST.productColor] || eloBlack,
        cursor: 'pointer',
      },
    },

    listProductDetailsContainer: {
      '@media (max-width: 991px)': {
        width: '100%',
      },
    },

    description: {
      fontSize: theme[PRODUCTS_LIST.descriptionSize] ? `${theme[PRODUCTS_LIST.descriptionSize]}px` : '14px',
      fontFamily: cleanupFonts(theme[PRODUCTS_LIST.descriptionFont]) || 'Montserrat Reg',
      ...getFontStyles(theme[PRODUCTS_LIST.descriptionStyle]),
      color: theme[PRODUCTS_LIST.descriptionColor] || eloBlack,
      wordBreak: 'break-word',
      display: 'block',

      '@media (max-width: 991px)': {
        display: 'none',
      },
    },
  }
}

export default productListStyles
