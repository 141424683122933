import React from 'react'
import { components } from 'react-select'
import { EloCloseIcon } from '@elo-ui/components/icons/regular'

const MultiValueRemove = (props) => (
  <components.MultiValueRemove {...props}>
    <EloCloseIcon />
  </components.MultiValueRemove>
)

export { MultiValueRemove }
