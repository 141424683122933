import { COLORS } from '@elo-kit/constants/general.constants'

const { eloRed, eloGreen } = COLORS

const codeInputStyles = () => ({
  codeInput: {
    position: 'relative',

    '& .fa-times-circle': {
      color: eloRed,
      fontSize: 16,
      position: 'absolute',
      right: '10px',
      top: '13px',
    },

    '& .fa-check-circle': {
      color: eloGreen,
      fontSize: 16,
      position: 'absolute',
      right: '10px',
      top: '13px',
    },
  },

  codeInputControl: {
    boxSizing: 'border-box',
    padding: '12px 35px 12px 13px',
    lineHeight: '44px',
    height: '44px',
    border: '1px solid #d9d9d9',
    borderRadius: 4,
    backgroundColor: '#fff',
    width: '120px',
    fontFamily: 'Montserrat Medium',

    '&:focus:not(& .code-input--error)': {
      border: '1px solid #1e84d7',
    },

    '&:focus:not(& .code-input--success)': {
      border: '1px solid #1e84d7',
    },

    '&::placeholder': {
      color: '#a8a8a8',
      fontSize: 13,
      fontFamily: 'Montserrat Light',
      lineHeight: 1.4,
    },
  },

  codeInputControlError: {
    border: `1px solid ${eloRed}`,
  },

  codeInputControlSuccess: {
    border: `1px solid ${eloGreen}`,
  },
})

export default codeInputStyles
