import React, { useEffect, useState, useRef } from 'react'

import { useIsVisible } from './use-is-visible'

interface Props {
  lowQualitySrc: string
  fullQualitySrc: string
  alt?: string
  ratio?: number
  style?: any
  className?: string
  onLoad?: (e: any) => void
}

const LazyImage: React.FC<Props> = ({ lowQualitySrc, fullQualitySrc, alt, ratio, style = {}, className, onLoad }) => {
  const ref = useRef<HTMLImageElement>()
  const isVisible = useIsVisible(ref)
  const [src, setSrc] = useState(lowQualitySrc)
  const [aspectRatio, setAspectRatio] = useState(ratio)
  const [size, setSize] = useState<{ w?: number | string; h?: number | string }>({ w: 100 })

  const handleLoad = (e) => {
    if (src === fullQualitySrc) {
      const target = e.target as HTMLImageElement
      setAspectRatio(target.naturalWidth / target.naturalHeight)
      setSize({
        w: aspectRatio > 1 ? 'initial' : ref.current?.parentElement?.clientWidth,
        h: aspectRatio > 1 ? ref.current?.parentElement?.clientHeight : 'initial',
      })
      if (onLoad) {
        onLoad(e)
      }
    }
  }

  const setFullLink = () => {
    const img = new Image()
    img.onload = (e) => {
      setSrc(fullQualitySrc)
      handleLoad(e)
    }
    img.src = fullQualitySrc
  }

  useEffect(() => {
    if (isVisible && src !== fullQualitySrc) {
      setFullLink()
    }
  }, [isVisible])

  return (
    <img
      // @ts-ignore
      ref={ref}
      className={className}
      loading='lazy'
      alt={alt}
      style={{
        minWidth: style.width || '100%',
        minHeight: src !== fullQualitySrc ? '100%' : 'initial',
        maxWidth: size.w,
        maxHeight: size?.h,
        filter: src !== fullQualitySrc ? 'blur(3px)' : 'none',
        transition: 'filter 0.3s linear',
        aspectRatio: `${aspectRatio}`,
        ...style,
      }}
      src={src}
      onLoad={handleLoad}
    />
  )
}
export { LazyImage }
