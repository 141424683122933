import React from 'react'
import { components } from 'react-select'

import { AnyCallback } from 'types/helpers'

interface Props {
  cx?: AnyCallback
  getStyles?: AnyCallback
  selectProps?: {
    menuIsOpen?: boolean
    instanceId?: string
  }
}
export const DropdownIndicator = (props: Props) => {
  const { selectProps = {} } = props
  const { menuIsOpen = false, instanceId = 'arrow' } = selectProps

  const iconClassName = menuIsOpen
    ? 'fas fa-sort-up elo-select-field__dropdown-indicator--arrow-up-position'
    : 'fas fa-sort-down'

  return (
    <components.DropdownIndicator {...props}>
      <div id={`${instanceId}_icon`}>
        <i className={iconClassName} />
      </div>
    </components.DropdownIndicator>
  )
}
