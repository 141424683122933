import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import withStyles, { ThemeProvider } from 'react-jss'

import { useI18n } from '@elo-kit/components/i18n/i18n'

import orderCompleteModalStyles from './OrderCompletedModal.styles'

/**
 * Order Complete Modal
 */
export class OrderCompletedModal extends Component {
  render() {
    const {
      closeModal,
      classes,
      isOpen,
      locales = {
        modalTitle: this.props.I18n.t('react.payer.common.thank_you_for_order'),
        modalButton: this.props.I18n.t('react.shared.close'),
        modalDescription: this.props.I18n.t('react.payer.common.your_order_is_added'),
      },
    } = this.props

    return (
      <Modal className='elo-modal elo-modal--medium' isOpen={isOpen}>
        <ModalHeader>
          <i className='fas fa-times elo-modal__close-btn' onClick={closeModal} />
          <div className={classes.orderCompletedBody}>
            <i className={classNames('fas fa-check-circle', classes.orderComletedCheck)} />
            <span className={classes.orderCompletedText}>{locales.modalTitle}</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className={classes.orderCompletedDescription}>{locales.modalDescription}</div>
          <div className={classNames(classes.centeredBtnContainer, 'elo-modal__bottom-button')}>
            <button type='button' onClick={closeModal} className={classes.cancelButton}>
              {locales.modalButton}
            </button>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

OrderCompletedModal.propTypes = {
  /** React JSS classes */
  classes: PropTypes.object,
  /** Close Modal handler */
  closeModal: PropTypes.func,
  /** Indicate whether modal is opened */
  isOpen: PropTypes.bool,
  /** Locales for the modal */
  locales: PropTypes.object,
  I18n: PropTypes.any,
}

OrderCompletedModal.defaultProps = {
  classes: {},
}

/** OrderCompletedModal with React JSS Styles */
export const OrderCompletedModalWithStyles = withStyles(orderCompleteModalStyles)(OrderCompletedModal)

/** OrderCompletedModal with React JSS Theme Provider */
export const OrderCompletedModalWithTheme = (props) => {
  const { theme } = props
  const I18n = useI18n()

  return (
    <ThemeProvider theme={theme}>
      <OrderCompletedModalWithStyles I18n={I18n} {...props} />
    </ThemeProvider>
  )
}

OrderCompletedModalWithTheme.defaultProps = {
  theme: {},
}

OrderCompletedModalWithTheme.propTypes = {
  theme: PropTypes.object,
}
