import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { YES } from '@elo-kit/constants/general.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'

/**
 * Button funnel - Page Builder's funnel block
 */

export const ButtonFunnel = (props) => {
  const {
    classes,
    content: { buttonAnimation, buttonText, type },
    onAcceptClick,
    onSkipClick,
    defaultButtonText,
    previewMode,
  } = props
  const buttonClasses = classNames(classes.button, {
    [classes.shadow]: !buttonAnimation,
    [classes[buttonAnimation]]: !!buttonAnimation,
  })
  return (
    <button
      type='button'
      className={buttonClasses}
      onClick={type === YES ? onAcceptClick : onSkipClick}
      {...(previewMode && {
        'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.funnel.button,
        'data-highlighter-selector': '',
      })}
    >
      {buttonText || defaultButtonText}
    </button>
  )
}

ButtonFunnel.displayName = 'ButtonFunnel'
ButtonFunnel.propTypes = {
  /**
   * React JSS classes
   */
  classes: PropTypes.object,
  /**
   * Content block object
   */
  content: PropTypes.object,
  /**
   * Accept click
   */
  onAcceptClick: PropTypes.func,
  /**
   * Skip click
   */
  onSkipClick: PropTypes.func,
  /**
   * Default button text
   */
  defaultButtonText: PropTypes.string,
  /** Preview mode in page builder */
  previewMode: PropTypes.bool,
}

ButtonFunnel.defaultProps = {
  classes: {},
  content: {},
  onAcceptClick: /* istanbul ignore next */ () => {},
  onSkipClick: /* istanbul ignore next */ () => {},
  defaultButtonText: 'NO, THANKS',
  previewMode: false,
}
