import React from 'react'
import PropTypes from 'prop-types'
import withStyles, { ThemeProvider } from 'react-jss'

import { useI18n } from '@elo-kit/components/i18n/i18n'

import videoRenderingStyles from './VideoRendering.styles'

/**
 *  Preview when video rendering
 */
const VideoRenderingContainer = (props) => {
  const I18n = useI18n()
  const { classes, videoRenderingDefaultText = I18n.t('react.cabinet.digitals.video_rendering_warning') } = props

  return (
    <div className={classes.videoRenderingContainer}>
      <div className={classes.videoRendering}>
        <i className='fas fa-hourglass-start' />
        <div className={classes.videoRenderingText}>{videoRenderingDefaultText}</div>
      </div>
    </div>
  )
}

const VideoRenderingContainerWithStyles = withStyles(videoRenderingStyles)(VideoRenderingContainer)

VideoRenderingContainer.propTypes = {
  /** Default text for rendering video */
  videoRenderingDefaultText: PropTypes.string,
  /** Classes object */
  classes: PropTypes.object,
}
VideoRenderingContainer.defaultProps = {
  classes: {},
}

const VideoRendering = ({ theme, ...restProps }) => (
  <ThemeProvider theme={theme}>
    <VideoRenderingContainerWithStyles {...restProps} />
  </ThemeProvider>
)

VideoRendering.displayName = 'VideoRendering'
VideoRendering.propTypes = {
  theme: PropTypes.object,
}
VideoRendering.defaultProps = {
  theme: {},
}

export default VideoRendering
