export const BUTTON_ANIMATIONS = {
  shadow: 'shadow',
  transparency: 'transparency',
  coloredShadow: 'coloredShadow',
  stretching: 'stretching',
  bordering: 'bordering',
  increasing: 'increasing',
  swing: 'swing',
  fillOutlineSwitch: 'fillOutlineSwitch',
  noAnimation: 'noAnimation',
}
