import { getFontStyles } from '@elo-kit/utils/fonts.utils'

import { COLORS, PADDING_UNITS, ZERO_DATA } from '@elo-kit/constants/general.constants'
import { DEFAULT_FILES_PADDING, BUTTON_STYLE } from '@elo-kit/constants/contentPage.constants'

import { buttonAnimations } from '@elo-kit//utils/animations.utils'
import { getPadding } from '@elo-kit/utils/contentBlock.utils'
import { cleanupFonts } from 'utils/fonts.utils'

// TODO: update with using createUseStyles
const filesStyles = (theme) => {
  const { eloBlack, eloBlue, eloWhite } = COLORS
  const paddingUnits = theme.paddingUnits || PADDING_UNITS.px
  const defaultPadding = paddingUnits === PADDING_UNITS.px ? DEFAULT_FILES_PADDING : ZERO_DATA

  return {
    container: {
      height: '100%',
      maxWidth: '100%',
      position: 'relative',
    },
    filesContainer: {
      position: 'relative',
      paddingTop: getPadding(theme.paddingTop, defaultPadding, theme.paddingUnits),
      paddingBottom: getPadding(theme.paddingBottom, defaultPadding, theme.paddingUnits),
      paddingRight: getPadding(theme.paddingRight, defaultPadding, theme.paddingUnits),
      paddingLeft: getPadding(theme.paddingLeft, defaultPadding, theme.paddingUnits),
    },
    fileContainer: {
      display: 'flex',
      width: `${theme.width}%`,
      maxWidth: `${theme.maxWidth}px`,
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      padding: '8px 0',
      borderBottom: 'solid 1px #97979733',
      '&:last-child': {
        borderBottom: 'none',
      },
      '& .elo-btn': {
        alignSelf: 'flex-start',
        minWidth: 'unset',
        marginLeft: 10,
      },
    },
    noFileContainer: {
      minHeight: '300px',
      width: '100%',
      background: '#efedef',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& i': {
        fontSize: '100px',
        color: eloWhite,
      },
    },
    fileDetails: {
      display: 'flex',
      width: '75%',
      '& i': {
        fontSize: '37px',
        color: theme.buttonColor || '#b7b7b7',
      },
      '&.audio-player': {
        width: '100%',
        marginRight: '20px',
        '@media (max-width: 576px)': {
          marginRight: 10,
        },
      },
      '& .file-item-preview': {
        width: '37px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    fileInfoContainer: {
      width: '100%',
      paddingLeft: '10px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    fileName: {
      marginLeft: '10px',
      marginRight: '10px',
      wordBreak: 'break-word',
      color: theme.textColor || eloBlack,
      fontSize: theme.textSize ? `${theme.textSize}px` : '13px',
      fontFamily: cleanupFonts(theme.textFont) || 'Montserrat Medium',
      fontStyle: theme.textStyle || 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: '100%',
      '@media (max-width: 576px)': {
        fontSize: theme.textSize > 18 ? 18 : theme.textSize,
      },
      '&.file': {
        justifyContent: 'space-between',
        width: '100%',
        marginLeft: '0',
        marginRight: '0',
        '@media (max-width: 768px)': {
          display: 'flex',
          flexDirection: 'column',
          lineHeight: '1.2',
        },
      },
      '& .audio-name': {
        alignSelf: 'flex-start',
      },
      '& .duration': {
        color: theme.textColor || eloBlack,
        fontSize: theme.textSize ? `${theme.textSize}px` : '13px',
        alignSelf: 'flex-start',
        opacity: 0.4,
        '@media (max-width: 576px)': {
          fontSize: theme.textSize > 18 ? 18 : theme.textSize,
        },
      },
    },
    audioProgressBar: {
      width: '100%',
      marginTop: '10px',
      textAlign: 'center',
      height: '3px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    audioButton: {
      cursor: 'pointer',
      color: theme.buttonColor || eloBlue,
      fontSize: '30px',
      textAlign: 'center',
      minWidth: '35px',
      alignSelf: 'center',
      '&.play': {
        fontSize: '18px',
      },
      '&.audio-button': {
        fontSize: '30px',
        color: theme.buttonColor || eloBlue,
      },
    },
    button: {
      background: theme.buttonViewStyle === BUTTON_STYLE.filled ? theme.buttonColor || '#1e84d7' : 'none',
      color: [[theme.buttonTextColor || COLORS.eloBlue], '!important'],
      border: `1px solid ${theme.buttonColor || COLORS.eloBlue}`,
      padding: '8px 5px 6px',
      borderRadius: `${theme.radius === undefined ? 17 : theme.radius}px`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: cleanupFonts(theme.buttonFont) || 'Montserrat Reg',
      fontSize: `${theme.buttonSize || 12}px`,
      cursor: 'pointer',
      wordBreak: 'break-word',
      textAlign: 'center',
      lineHeight: 1.5,
      textDecoration: 'none',
      ...getFontStyles(theme.buttonStyle || 'bold'),
      minWidth: 104,
      whiteSpace: 'nowrap',
      '&:focus': {
        outline: 'none',
      },
      '&:active': {
        opacity: 0.7,
      },
      '&:hover': {
        color: theme.buttonTextColor || COLORS.eloBlue,
        textDecoration: theme.buttonStyle === 'underline' ? theme.buttonStyle : 'none',
      },
      '@media (max-width: 576px)': {
        fontSize: theme.buttonSize > 14 ? 14 : theme.buttonSize,
      },
    },

    ...buttonAnimations(theme),
  }
}

export default filesStyles
