import { PRODUCTS_LIST, BLOCK_VIEW } from '@elo-kit/constants/contentPage.constants'
import { COLORS } from '@elo-kit/constants/general.constants'

const { eloBlue, eloWhite } = COLORS

const productListStyles = (theme) => ({
  paginationContainer: {
    backgroundColor: theme[PRODUCTS_LIST.background] || eloWhite,
    paddingTop: theme[PRODUCTS_LIST.view] === BLOCK_VIEW.list ? 30 : 10,
    paddingBottom: 30,
    marginTop: [0, '!important'],
    justifyContent: ['center', '!important'],
    '& .elo-pagination__page--active': {
      backgroundColor: `${theme[PRODUCTS_LIST.priceBackground] || eloBlue} !important`,
    },
    '& a.elo-pagination__link': {
      cursor: 'pointer',
      color: `${theme[PRODUCTS_LIST.priceBackground] || eloBlue} !important`,

      '&:hover': {
        opacity: 0.8,
      },

      '&--active': {
        color: `${theme[PRODUCTS_LIST.priceColor] || eloWhite} !important`,
      },
    },
    '& .elo-pagination__page:hover': {
      background: 'none',
    },
  },
  paginationContainerDisabled: {
    paddingBottom: 0,
  },
})

export default productListStyles
