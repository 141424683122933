import { COLORS } from '@elo-kit/constants/general.constants'

import { hex2rgb } from '@elo-kit/utils/themes.utils'
import { getFontStyles } from '@elo-kit/utils/fonts.utils'
import { cleanupFonts } from 'utils/fonts.utils'

const pictureStyles = (theme) => ({
  searchContainer: {
    '&.elo-search-field': {
      borderRadius: 20,
      width: 270,
      backgroundColor: theme.searchBackground || '#ffffff',
    },
    '& .elo-search-field__control': {
      width: 270,
      borderRadius: 20,
      padding: '2px 40px 0 20px',
      height: 40,
      color: theme.searchColor || COLORS.eloBlue,
      fontSize: `${theme.searchSize || 13}px`,
      fontFamily: cleanupFonts(theme.searchFont) || 'Montserrat Reg',
      ...getFontStyles(theme.searchStyle || 'normal'),
      borderColor: hex2rgb(theme.searchActiveColor || COLORS.eloBlue, 0.3),
      backgroundColor: theme.searchBackground || '#ffffff',
      '&:focus': {
        borderColor: `${hex2rgb(theme.categoryActiveColor || COLORS.eloBlue)}`,
      },
    },
    '& .elo-search-field__icon': {
      color: theme.searchActiveColor || COLORS.eloBlue,
      fontSize: 16,
      top: 12,
      right: 19,
    },
  },
})

export default pictureStyles
