import React from 'react'

import './_warning-message.scss'

interface Props {
  title: string
  message: string
}

export const WarningMessage: React.FC<Props> = ({ title, message }) => (
  <div className='warning-message'>
    <div>
      <i className='fas fa-exclamation-triangle' />
      <span className='warning-message__title'>{title}</span>
    </div>
    <div className='warning-message__message'>{message}</div>
  </div>
)
