import { createUseStyles } from 'react-jss'
import { getAlignment } from '@elo-kit/utils/contentBlock.utils'
import { DEFAULT_PICTURE_PADDING } from '@elo-kit/constants/contentPage.constants'
import { COLORS, PADDING_UNITS } from '@elo-kit/constants/general.constants'

export const usePictureStyles = createUseStyles((theme) => {
  const paddingUnits = theme.paddingUnits || PADDING_UNITS.px

  return {
    imageContainer: {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: getAlignment(theme.horizontalAlignment),
      alignItems: getAlignment(theme.verticalAlignment),
      backgroundColor: theme.backgroundColor || COLORS.eloWhite,
      paddingTop: `${theme.paddingTop || DEFAULT_PICTURE_PADDING}${paddingUnits}`,
      paddingBottom: `${theme.paddingBottom || DEFAULT_PICTURE_PADDING}${paddingUnits}`,
      paddingRight: `${theme.paddingRight || DEFAULT_PICTURE_PADDING}${paddingUnits}`,
      paddingLeft: `${theme.paddingLeft || DEFAULT_PICTURE_PADDING}${paddingUnits}`,
    },
    noImageContainer: {
      minHeight: '300px',
      height: '100%',
      width: '100%',
      background: COLORS.eloGrey20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& i': {
        fontSize: '100px',
        color: COLORS.eloWhite,
      },
    },
  }
})
