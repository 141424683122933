import React from 'react'
import classNames from 'classnames'
import { Nullable } from 'types/helpers'

import { LazyBackgroundImage } from '@elo-kit/components/lazy-background-image/LazyBackgroundImage'
import './_cover-widget.scss'

/**
 * Widget for cover/image
 */
interface IconClass {
  container: string
  icon: string
}
interface Props {
  img?: string
  iconClasses?: IconClass
  noImageClasses?: Nullable<string>
  unresponsive?: boolean
  onClick?: () => void
  children?: string
  className?: string
  cover: {
    file: {
      s100: string
      s640: string
    }
  }
}

export const CoverWidget = (props: Props) => {
  const {
    children,
    iconClasses,
    img,
    onClick,
    noImageClasses = 'fas fa-camera cover-widget__no-image-icon',
    unresponsive,
    className,
    cover,
  } = props

  const icon = iconClasses && (
    <div className={iconClasses.container}>
      <i className={iconClasses.icon} />
    </div>
  )

  const coverClasses = classNames(
    {
      'cover-widget__image': img,
      'cover-widget__no-image': !img,
      'cover-widget__image--unresponsive': img && unresponsive,
      'cover-widget__no-image--unresponsive': !img && unresponsive,
      'cover-widget__clickable': onClick,
    },
    className
  )

  return (
    <div className='cover-widget__wrapper'>
      <div onClick={onClick} className={coverClasses}>
        {img && (
          <LazyBackgroundImage
            block={{
              cover: {
                file: {
                  original: cover?.file?.s640,
                  s640: cover?.file?.s100,
                },
              },
            }}
            wrapperStyles={{
              borderRadius: '6px 6px 0 0',
            }}
          />
        )}
        {!img && <i className={noImageClasses || 'fas fa-camera cover-widget__no-image-icon'} />}
        {iconClasses && icon}
      </div>
      {children}
    </div>
  )
}

CoverWidget.displayName = 'CoverWidget'
