import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import classnames from 'classnames'

import eloSliderStyles from './EloSlider.styles'

/**
 * EloSliderBody - Sliders Body
 */
class EloSliderBodyComponent extends Component {
  static Item = ({ children }) => <>{children}</>

  constructor(props) {
    super(props)

    this.state = {
      currentPosition: 0,
    }
  }

  componentDidUpdate(prevProps) {
    const { slideItems = [] } = this.props

    if (slideItems.length === 1 && slideItems.length !== prevProps.slideItems.length) {
      // eslint-disable-next-line
      this.setState({
        currentPosition: 0,
      })
    }
  }

  isFirstElementSelected = () => {
    const { currentPosition } = this.state

    return currentPosition === 0
  }

  isSecondFirstElementSelected = () => {
    const { currentPosition } = this.state

    return currentPosition === 1
  }

  isLastElementSelected = () => {
    const { currentPosition } = this.state
    const { slideItems } = this.props

    return currentPosition === slideItems.length - 1
  }

  isSecondLastElementSelected = () => {
    const { currentPosition } = this.state
    const { slideItems } = this.props

    return currentPosition === slideItems.length - 2
  }

  getElementClass = (position) => {
    const { classes, slideItems, style = {} } = this.props
    const { currentPosition } = this.state

    const distance = currentPosition - position
    const isLast = slideItems.length - 1 === position
    const isSecondLast = slideItems.length - position === 2
    const isFirst = position === 0
    const isSecondFirst = position === 1
    const onlyOneElement = slideItems.length === 1
    const isRightNext = (distance === -1 || (this.isLastElementSelected() && isFirst)) && !onlyOneElement
    const isLeftNext = (distance === 1 || (this.isFirstElementSelected() && isLast)) && !onlyOneElement
    const isActive = currentPosition === position

    return classnames(classes.eloSliderElement, {
      [classes.activeElement]: isActive,
      [classes.hiddenElement]: style.modifier === 1 && (isLeftNext || isRightNext),
      [classes.rightNext]: isRightNext,
      [classes.rightHidden]:
        distance === -2 ||
        (this.isLastElementSelected() && isSecondFirst) ||
        (this.isSecondLastElementSelected() && isFirst),
      [classes.leftNext]: isLeftNext,
      [classes.leftHidden]:
        distance === 2 ||
        (this.isFirstElementSelected() && isSecondLast) ||
        (this.isSecondFirstElementSelected() && isLast),
    })
  }

  changeCurrentPosition = (modifier) => {
    const { slideItems, onSlide } = this.props
    const { currentPosition } = this.state

    const newPos = currentPosition + modifier

    if (newPos === slideItems.length) {
      this.setState({
        currentPosition: 0,
      })
    } else if (newPos >= 0) {
      this.setState({
        currentPosition: newPos,
      })
    } else {
      this.setState({
        currentPosition: slideItems.length - 1,
      })
    }

    if (onSlide) {
      onSlide()
    }
  }

  render() {
    const {
      classes,
      dynamicHeight,
      eloSliderContainerClasses,
      id,
      itemsHeights = {},
      slideItems,
      previewType,
    } = this.props

    const { currentPosition } = this.state

    const containerClasses = classnames(
      classes.eloSliderContainer,
      'elo-slider-container',
      {
        [`elo-slider-${id}`]: id,
        'elo-slider': !id,
      },
      eloSliderContainerClasses
    )

    const arrowClasses = classnames({
      'elo-slider-arrow__with-zindex': !previewType,
    })

    const showArrows = slideItems.length > 1
    const height = itemsHeights[currentPosition]

    return (
      <div className={containerClasses} style={dynamicHeight ? { height } : {}}>
        {showArrows && (
          <i className={`fas fa-chevron-left ${arrowClasses}`} onClick={() => this.changeCurrentPosition(-1)} />
        )}

        {slideItems.map((slideItem, index) => {
          const key = `key-${index}`

          return (
            <div id='elo-slider-item' key={key} className={this.getElementClass(index)}>
              {slideItem}
            </div>
          )
        })}

        {showArrows && (
          <i className={`fas fa-chevron-right ${arrowClasses}`} onClick={() => this.changeCurrentPosition(1)} />
        )}
      </div>
    )
  }
}

EloSliderBodyComponent.propTypes = {
  /**
   * React JSS classes
   */
  classes: PropTypes.object,
  /**
   * Slider ID
   */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Slider Items
   */
  slideItems: PropTypes.array,
  /**
   * Slider Styles
   */
  style: PropTypes.shape({
    activeElementScale: PropTypes.number,
    modifier: PropTypes.number,
  }),
  /**
   * Dynamic Height flag
   */
  dynamicHeight: PropTypes.bool,
  /**
   * Indicates that it's preview mode
   */
  previewType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /**
   * Specifies slider items height
   */
  itemsHeights: PropTypes.object,
  /**
   * Slider Container Class Names
   */
  eloSliderContainerClasses: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /**
   * On Slide Callback
   */
  onSlide: PropTypes.func,
}

EloSliderBodyComponent.defaultProps = {
  classes: {},
  slideItems: [],
  itemsHeights: {},
}

export const EloSliderBody = withStyles(eloSliderStyles)(EloSliderBodyComponent)
