import React from 'react'
import { components } from 'react-select'

const ImageSingleValue = (props) => {
  if (props.data.Image) {
    const { Image } = props.data
    return (
      <components.SingleValue {...props}>
        <Image />
      </components.SingleValue>
    )
  }
}

export { ImageSingleValue }
