import React from 'react'
import classNames from 'classnames'

import './_warning-info.scss'

interface Props {
  className?: string
  closeMessage?: () => void
  iconClassName?: string
  isModal?: boolean
  message?: React.ReactNode
  component?: boolean
  style?: object
}
export const WarningInfo: React.FC<Props> = ({
  className,
  closeMessage,
  iconClassName = 'fas fa-exclamation-triangle',
  isModal,
  message,
  component,
  style = {},
}) => {
  const warningClass = classNames(
    'warning-info',
    {
      'warning-info__modal': isModal,
    },
    className
  )

  return (
    <div className={warningClass} style={style}>
      {iconClassName && <i className={iconClassName} />}
      {component ? (
        message
      ) : (
        <div
          dangerouslySetInnerHTML={{
            __html: String(message),
          }}
        />
      )}
      {closeMessage && <i className='fas fa-times close-btn' onClick={closeMessage} />}
    </div>
  )
}
