import React, { useState } from 'react'
import classNames from 'classnames'

import { useI18n } from '@elo-kit/components/i18n/i18n'

import './_product-access.scss'

interface Props {
  password: string
  setPasswordIsValid: () => void
  title?: string
  description?: string
  placeholder?: string
  buttonText?: string
  errorMessage?: string
}

/**
 * Product Access - component where product password is checking, if it is set
 */
export const ProductAccess: React.FC<Props> = (props) => {
  const I18n = useI18n()
  const {
    password,
    setPasswordIsValid,
    title = I18n.t('react.shop.access_password.title'),
    description = I18n.t('react.shop.access_password.need_access'),
    placeholder = I18n.t('react.shop.access_password.ph'),
    buttonText = I18n.t('react.shared.button.continue'),
    errorMessage = I18n.t('react.shop.access_password.not_valid'),
  } = props

  const [enteredPassword, setEnteredPassword] = useState('')
  const [error, setError] = useState(false)

  const handlePasswordChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget
    setEnteredPassword(value)
    setError(false)
  }

  const handleSubmit = () => {
    if (password === enteredPassword) {
      setPasswordIsValid()
    } else {
      setError(true)
    }
  }

  const inputClasses = classNames('product-access__field elo-field', {
    'product-access__field--error': error,
  })

  return (
    <div className='product-access__container'>
      <div className='product-access'>
        <div className='product-access__header'>
          <div className='product-access__title'>{title}</div>
          <div className='product-access__description'>{description}</div>
        </div>

        {error && (
          <div className='elo-field__error'>
            {errorMessage}
            <i className='fas fa-exclamation-circle' />
          </div>
        )}
        <input
          onChange={handlePasswordChange}
          className={inputClasses}
          placeholder={placeholder}
          type='password'
          value={enteredPassword}
        />

        <button
          className='elo-btn orange product-access__btn'
          onClick={handleSubmit}
          disabled={!enteredPassword}
          type='button'
        >
          {buttonText}
        </button>
      </div>
    </div>
  )
}
