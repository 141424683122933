import React, { Component } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const propTypes = {
  id: PropTypes.string.isRequired,
  checkedLabel: PropTypes.string.isRequired,
  uncheckedLabel: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  activeGreen: PropTypes.bool,
}

const defaultProps = {
  activeGreen: false,
}

@observer
class TextToggleField extends Component {
  render() {
    const { id, checkedLabel, uncheckedLabel, handleChange, checked, activeGreen, className } = this.props
    const containerClasses = classNames('elo-text-toggle', className, {
      'elo-text-toggle__new': activeGreen,
    })
    return (
      <div className={containerClasses}>
        <input id={id} type='checkbox' onChange={handleChange} defaultChecked={checked} />
        <label htmlFor={id}>
          <div className='elo-text-toggle__switch' data-checked={checkedLabel} data-unchecked={uncheckedLabel} />
        </label>
      </div>
    )
  }
}

TextToggleField.displayName = 'TextToggleField'
TextToggleField.propTypes = propTypes
TextToggleField.defaultProps = defaultProps

export { TextToggleField }
