import React from 'react'
import classNames from 'classnames'

import { SIZES } from '@elo-kit/constants/general.constants'

import { WarningInfo } from '@elo-kit/components/elo-ui/warning-info'
import { EloModal } from '@elo-kit/components/elo-ui/elo-modal'
import { useI18n } from '@elo-kit/components/i18n/i18n'

import { AnyCallback } from 'types/helpers'

import './_confirmation_modal.scss'

const SIZES_VALUES = Object.values(SIZES)
type Size = (typeof SIZES_VALUES)[number]

interface Props {
  size?: Size
  isOpen?: boolean
  toggle?: () => void
  submit?: AnyCallback
  title?: string
  submitDisabled?: boolean
  submitButtonChild?: string
  message?: string
  className?: string
  onlySubmit?: boolean
  cancelButtonChild?: string
  titleIcon?: React.ReactNode
  onCancel?: () => void
  warningMessage?: string
  headerIcon?: React.ReactNode
  close?: () => void
  children?: React.ReactNode | unknown[]
  cancel?: boolean
  customSubmitButtonProps?: object
  testId?: string
  onClose?: () => void
  centered?: boolean
  zIndex?: number | string
}

export const ConfirmationModal = (props: Props) => {
  const I18n = useI18n()
  const {
    isOpen,
    toggle,
    submit,
    title,
    submitDisabled,
    submitButtonChild = I18n.t('react.shared.button.yes'),
    message,
    className,
    onlySubmit,
    cancelButtonChild,
    titleIcon = <i className='fas fa-exclamation-triangle' />,
    onCancel,
    warningMessage,
    headerIcon,
    close,
    children,
    cancel,
    customSubmitButtonProps,
    testId,
    size = SIZES.small,
    onClose,
    centered,
    zIndex,
  } = props

  const confirmationTitle = (
    <div className='confirmation-modal__title'>
      {titleIcon}
      <div>{title}</div>
    </div>
  )

  const confirmationModalClasses = classNames('confirmation-modal', className)
  return (
    <EloModal
      title={confirmationTitle}
      size={size}
      className={confirmationModalClasses}
      {...{
        isOpen,
        submit,
        submitButtonChild,
        toggle,
        submitDisabled,
        onlySubmit,
        cancelButtonChild,
        onCancel,
        headerIcon,
        customSubmitButtonProps,
        close,
        cancel,
        testId,
        onClose,
        centered,
        zIndex,
      }}
    >
      {message && <div className='confirmation-modal__message' dangerouslySetInnerHTML={{ __html: message }} />}
      {warningMessage && <WarningInfo message={warningMessage} />}
      {children}
    </EloModal>
  )
}

ConfirmationModal.displayName = 'ConfirmationModal'
