import { cleanupFonts } from 'utils/fonts.utils'

import { COLORS } from '../../../../../../constants/general.constants'

const orderCompleteModalStyles = ({
  autobuyModalTitleColor,
  sectionFontFamily,
  sectionFontSize,
  sectionFontStyle,
} = {}) => {
  const { eloBlack, eloBlue, eloWhite } = COLORS

  return {
    modalHeader: {
      fontSize: sectionFontSize ? `${sectionFontSize}px` : '18px',
      fontFamily: cleanupFonts(sectionFontFamily) || 'Montserrat Medium',
      fontStyle: sectionFontStyle || 'regular',
      fontWeight: 500,
    },
    modalBody: {
      paddingTop: '15px!important',
    },
    orderCompletedBody: {
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
    },
    p24Notes: {
      textAlign: 'center',
      alignItems: 'center',
      marginTop: '20px',
      marginBottom: '10px',
      fontSize: '13px',
    },
    orderComletedCheck: {
      fontSize: '30px',
      color: '#3cb27a',
    },
    orderCompletedDescription: {
      fontSize: '13px',
      textAlign: 'center',
      color: '#787878',
    },
    orderCompletedText: {
      color: eloBlack,
      fontSize: '22px',
      fontFamily: 'Montserrat Medium',
      marginLeft: '10px',
    },
    pricingPlanContainer: {
      border: 'solid 1px #efedef',
      padding: '25px 25px 10px 25px',
      borderRadius: '4px',
    },
    btnContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    centeredBtnContainer: {
      textAlign: 'center',
      marginTop: '65px',
    },
    autoBuyModalHeader: {
      fontSize: '18px',
      fontFamily: 'Montserrat Medium',
      fontStyle: 'regular',
      fontWeight: 500,
      color: autobuyModalTitleColor || eloBlack,
    },
    cancelButton: {
      fontSize: 12,
      fontFamily: 'Montserrat Reg',
      cursor: 'pointer',
      borderRadius: 17,
      minWidth: 104,
      padding: '9px 20px 7px 20px',
      lineHeight: 1.5,
      border: 'solid 1px #cecece',
      color: '#979797',
      backgroundColor: 'transparent',
      paddingTop: 8,
      paddingBottom: 6,
      textAlign: 'center',
      '&:hover': {
        borderColor: '#b4b3b3',
      },
      '&:active': {
        borderColor: '#9c9c9c',
      },
    },
    buyButton: {
      backgroundColor: '#ffb004',
      fontSize: 12,
      fontFamily: 'Montserrat Reg',
      cursor: 'pointer',
      borderRadius: 17,
      minWidth: 104,
      padding: '9px 20px 7px 20px',
      lineHeight: 1.5,
      border: 'none',
      color: eloWhite,
      paddingTop: 8,
      paddingBottom: 6,
      textAlign: 'center',
      marginLeft: 15,
      '&:hover': {
        backgroundColor: '#ff9604',
      },
      '&:active': {
        backgroundColor: '#ff7104',
      },
      '&:disabled': {
        backgroundColor: '#9E9E9E',
        opacity: 0.4,
        pointerEvents: 'none',
      },
    },
    changePaymethod: {
      textAlign: 'center',
      fontSize: '12px',
      color: '#9e9e9e',
      marginBottom: '15px',
    },
    changePaymethodVia: {
      color: eloBlack,
    },
    changePaymethodLink: {
      color: eloBlue,
    },
    cancellationTerms: {
      backgroundColor: '#f5f5f5',
      borderRadius: '4px',
      padding: '15px 25px',
      margin: '20px 0',
      color: '#787878',
      fontSize: '12px',
      lineHeight: 1.5,
    },
  }
}

export default orderCompleteModalStyles
