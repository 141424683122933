import React from 'react'
import { MobXProviderContext } from 'mobx-react'

import { PageBuilderStore } from '../stores/pageBuilder.store'
import { HighlighterStore } from '../stores/highlighter.store'

interface PageBuilderStores {
  pageBuilderStore: PageBuilderStore
  coversStore
  pricingPlansStore
  productsStore
  sellerFontsStore
  productGroupsStore
  contentPageStore
  contentBlockStore
  highlighterStore: HighlighterStore
  dndModel
  contentPageTemplatesStore
  handleContentPageChange
  contentPageLogsStore
  themePagesStore
  pageBuilderModalsStore
  contentPageCategoriesStore
  lessonsStore
  contentBlockTemplatesStore
  contentBlockSharedTemplatesStore
  contentPageSharedTemplatesStore
  shopThemesStore
  membershipThemesStore
  sellerStore
  courseThemesStore
  affiliateLandingsStore
}

export const usePageBuilderStores = () => React.useContext(MobXProviderContext) as unknown as PageBuilderStores
