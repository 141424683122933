import React, { PureComponent } from 'react'
import withStyles, { ThemeProvider } from 'react-jss'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import {
  POPULAR_PRODUCTS,
  BLOCK_VIEW,
  BUTTON_TYPE,
  PAYMENT_OPTION,
  DEFAULT_POPULAR_PRODUCTS_LIST,
} from '@elo-kit/constants/contentPage.constants'
import {
  CUSTOM_CLASS_NAME_OPTION,
  EXTRAS_PRODUCTS_CLASS_NAMES,
  POPULAR_PRODUCTS_CLASS_NAMES,
} from '@elo-kit/constants/customCss.constants'
import { PRODUCT_TYPE_IDS } from '@elo-kit/constants/products.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { ELOPAGE_CABINETS } from '@elo-kit/constants/general.constants'

import { sortItemsByPositions } from '@elo-kit/utils/helpers.utils'
import { getThemeProps } from '@elo-kit/utils/block.utils'
import { hasWindow } from '@elo-kit/utils/browser.utils'

import popularProductsStyles from 'shared/components/content-page/preview/blocks/popular-products/PopularProducts.styles'
import { LoadingMask } from '@elo-kit/components/loading-mask/LoadingMask'
import { useI18n } from '@elo-kit/components/i18n/i18n'
import { NoProductsFound } from '@elo-kit/components/page-builder/blocks/products-search/no-products-found/NoProductsFound'
import { getElopageConfig } from 'utils/elopageConfig.utils'
import { LazyBackgroundImage } from '@elo-kit/components/lazy-background-image/LazyBackgroundImage'

import ProductsSearch from '../../products-search/product-search'
import GridPopularProducts from '../grid-popular-products'
import { OrderCompletedModalWithStyles as OrderCompletedModal } from '../modals/order-complete-modal'
import ListPopularProducts from '../list-popular-products'
import SliderPopularProducts from '../slider-popular-products'

import AutoPayModal from '../../../components/auto-pay-modal'

export class ProductsList extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      productsList:
        this.props.contentProducts &&
        this.props.contentProducts.length &&
        this.props.contentPageStore?.popularProducts[this.props?.contentProducts?.join('-')]
          ? this.props?.contentPageStore?.popularProducts[this.props.contentProducts?.join('-')]
          : [],
      loading: false,
      AutoPayModalProps: {},
      orderCompletedModal: false,
      mobileView: false,
      searchQuery: null,
    }
  }

  componentDidMount() {
    const { previewMode, setProductsCustomLoading, fetchPopularProducts, contentProducts } = this.props
    const isNextApp = getElopageConfig('isNextApp')

    window.addEventListener('resize', this.checkIsMobileView)

    if (!isNextApp) {
      if (contentProducts && contentProducts.length) {
        setProductsCustomLoading(true)
        fetchPopularProducts({ id: contentProducts }).then(({ data }) => {
          const { list = [] } = data || {}
          this.updateProductList(sortItemsByPositions(contentProducts, list))
        })
      } else {
        this.updateProductList(previewMode ? DEFAULT_POPULAR_PRODUCTS_LIST : [])
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { previewMode, setProductsCustomLoading, fetchPopularProducts, contentProducts } = this.props
    const oldIds = prevProps?.block?.content[POPULAR_PRODUCTS.products]
    const newIds = contentProducts
    const oldAndNewIdsAreNotEqual = String(oldIds) !== String(newIds)

    if (newIds && newIds.length) {
      if (oldAndNewIdsAreNotEqual) {
        this.toggleLoading()
        setProductsCustomLoading(true)
        const queryParams = { id: newIds }
        fetchPopularProducts(queryParams).then(({ data }) => {
          const { list = [] } = data || {}
          this.updateProductList(sortItemsByPositions(newIds, list))
          this.toggleLoading()
        })
      }
    } else if (oldAndNewIdsAreNotEqual) {
      const { productsList } = this.state
      const productList = previewMode && !productsList.length ? DEFAULT_POPULAR_PRODUCTS_LIST : []
      this.updateProductList(productList)
    }
    const isIE = typeof Event !== 'function'
    const event = isIE ? document.createEvent('Event') : new Event('resize')

    if (isIE) {
      event.initEvent('resize', false, false)
    }

    if (hasWindow && event) {
      window.dispatchEvent(event)
    }
  }

  componentWillUnmount() {
    const { setProductsCustomLoading } = this.props
    setProductsCustomLoading(false)
  }

  updateProductList = (productsList) => this.setState({ productsList })

  updateSearchQuery = (searchQuery) => this.setState({ searchQuery })

  updateAutoPayModalProps = (AutoPayModalProps) => this.setState({ AutoPayModalProps })

  toggleOrderCompletedModal = () => {
    const { orderCompletedModal } = this.state

    this.setState({ orderCompletedModal: !orderCompletedModal })
  }

  getProductsList = () => {
    const { contentProducts } = this.props
    const { productsList } = this.state

    return contentProducts && contentProducts.length
      ? sortItemsByPositions(contentProducts, productsList)
      : productsList
  }

  toggleLoading = () => {
    const { loading } = this.state

    this.setState({ loading: !loading })
  }

  handleSearch = (data) => {
    const {
      block: { content },
      fetchPopularProducts,
      contentProducts,
    } = this.props
    const sliderView = content[POPULAR_PRODUCTS.view] === BLOCK_VIEW.slider

    if (data) {
      this.updateSearchQuery(data.name || data.query)
    }

    if (contentProducts && contentProducts.length && !sliderView) {
      this.toggleLoading()
      fetchPopularProducts({
        id: contentProducts,
        ...data,
      }).then((resp) => {
        const { data: popularProductData } = resp || {}
        const { list = [] } = popularProductData || {}
        this.updateProductList(sortItemsByPositions(contentProducts, list))
        this.toggleLoading()
      })
    }
  }

  toggleAutoPayModal = (product) => {
    const { sellerItem, historyPush } = this.props
    const { AutoPayModalProps } = this.state
    const { form, id, slug } = product || {}

    if (form === PRODUCT_TYPE_IDS.eventTickets) {
      historyPush(`/s/${sellerItem.username}/${slug}/payment`)
    } else {
      this.updateAutoPayModalProps({
        active: !AutoPayModalProps.active,
        productId: id,
      })
    }
  }

  checkIsMobileView = () => {
    const isMobileView = window.innerWidth <= 991

    if (this.state.mobileView !== isMobileView) {
      this.setState({
        mobileView: isMobileView,
      })
    }
  }

  handleButtonClick = (product) => {
    const {
      username,
      block: { content },
      view,
      historyPush,
    } = this.props
    const { slug } = product

    const productLink = `/s/${username}/${slug}`

    const redirectWithScrollTop = (url) => {
      const isNextApp = getElopageConfig('isNextApp')

      if (!isNextApp) {
        window.scrollTo(0, 0)
      }

      historyPush(url)
    }

    const goToProduct = () => (slug ? redirectWithScrollTop(productLink) : {})
    const goToPayment = () => (slug ? redirectWithScrollTop(`${productLink}/payment`) : {})

    switch (view) {
      case ELOPAGE_CABINETS.shop:
        if (content[POPULAR_PRODUCTS.buttonType] === BUTTON_TYPE.details) {
          goToProduct()
        } else {
          goToPayment()
        }
        break
      case ELOPAGE_CABINETS.payer: {
        if (content[POPULAR_PRODUCTS.buttonType] === BUTTON_TYPE.details) {
          goToProduct()
        } else if (content[POPULAR_PRODUCTS.paymentOption] === PAYMENT_OPTION.checkout) {
          goToPayment()
        } else {
          this.toggleAutoPayModal(product)
        }
        break
      }
      default:
        break
    }
  }

  render() {
    const {
      getUnit,
      classes,
      block: { content, id },
      sellerItem,
      view,
      previewMode,
      inEdit,
      block,
      convertToPriceWithoutCurrency,
      LinkComponent,
      formatPrice,
      sellable,
      fetchProduct,
      manageOrder,
      historyPush,
      fetchSellable,
      product,
      acceptPostsell,
      membershipViewType,
      membershipSellable,
      toggleCustomLoading,
      convertToPrice,
      amountCrossed,
      isWindows,
      getMP3dsBrowserMetaData,
      I18n,
      contentPageStore,
    } = this.props
    const { mobileView, searchQuery, loading, AutoPayModalProps } = this.state
    const listView = content[POPULAR_PRODUCTS.view] === BLOCK_VIEW.list
    const gridView = content[POPULAR_PRODUCTS.view] === BLOCK_VIEW.grid
    const sliderView = !listView && !gridView
    const showNoProductsFound = !previewMode && !this.getProductsList().length && !!searchQuery
    const useDefaultProductsList = !this.getProductsList().length && previewMode && !searchQuery

    const viewProps = {
      products: useDefaultProductsList ? DEFAULT_POPULAR_PRODUCTS_LIST : this.getProductsList(),
      productsContent: contentPageStore?.popularProducts?.content,
      handleButtonClick: this.handleButtonClick,
      mobileView,
      buttonText: content[POPULAR_PRODUCTS.buttonText] || I18n.t('shared.common.buy_now'),
      freeButtonText: content[POPULAR_PRODUCTS.freeButtonText],
      buttonAnimation: content.buttonAnimation,
      inEdit,
      previewMode,
      id,
      userName: sellerItem.username,
      getUnit,
      defaultButtonText: I18n.t('shared.common.buy_now'),
      freeDefaultText: I18n.t('react.shared.free'),
      netDefaultText: I18n.t('shared.common.net'),
      convertToPriceWithoutCurrency,
      freeProductText: I18n.t('react.shared.free'),
      defaultBtnText: I18n.t('shared.common.buy_now'),
      theme: getThemeProps(block),
    }

    const productsPreviewClasses = classNames(
      'products-slider-preview',
      POPULAR_PRODUCTS_CLASS_NAMES.containerClassName,
      content[CUSTOM_CLASS_NAME_OPTION],
      {
        [classes.hideBlock]: !previewMode && !this.getProductsList().length && !searchQuery && !loading,
        [classes.productsPreview]: previewMode || (!!this.getProductsList().length && !!searchQuery),
      }
    )

    const colorContainerClasses = classNames('w-100 position-absolute')

    return (
      <>
        <div className={productsPreviewClasses}>
          <div className={colorContainerClasses} />
          <LazyBackgroundImage block={block} position='top' />
          <div className={classNames(POPULAR_PRODUCTS_CLASS_NAMES.controlsClassName)}>
            {!sliderView && content.showSearch && (
              <div
                className={classNames(
                  EXTRAS_PRODUCTS_CLASS_NAMES.controlsContainerClassName,
                  classes.controlsContainer
                )}
              >
                <ProductsSearch
                  block={block}
                  handleFetch={this.handleSearch}
                  defaultSearchPlaceholder={I18n.t('shared.common.search')}
                  view={view}
                  highlighterId={BLOCK_MENU_HIGHLIGHT_IDS.popularProducts.search}
                  previewMode={previewMode}
                />
              </div>
            )}

            {!sliderView && showNoProductsFound && (
              <NoProductsFound block={block} noProductsMessage={I18n.t('react.shared.no_products_found')} />
            )}

            {listView && <ListPopularProducts {...viewProps} />}
            {gridView && (
              <GridPopularProducts
                {...{
                  ...viewProps,
                  freeProductPriceText: I18n.t('react.shared.free'),
                  LinkComponent,
                  formatPrice,
                }}
              />
            )}
            {sliderView && <SliderPopularProducts {...viewProps} />}

            {loading && <LoadingMask dark />}
          </div>
        </div>

        <OrderCompletedModal
          closeModal={this.toggleOrderCompletedModal}
          isOpen={this.orderCompletedModal}
          locales={{
            modalTitle: I18n.t('react.payer.common.thank_you_for_order'),
            modalButton: I18n.t('react.shared.close'),
            modalDescription: I18n.t('react.payer.common.your_order_is_added'),
          }}
        />

        {AutoPayModalProps.active && (
          <AutoPayModal
            {...{
              sellable,
              fetchProduct,
              sellerItem,
              manageOrder,
              historyPush,
              fetchSellable,
              product,
              acceptPostsell,
              LinkComponent,
              membershipViewType,
              membershipSellable,
              toggleCustomLoading,
              convertToPrice,
              amountCrossed,
              isWindows,
              getMP3dsBrowserMetaData,
            }}
            closeModal={this.toggleAutoPayModal}
            productId={AutoPayModalProps.productId}
            toggleOrderCompletedModal={this.toggleOrderCompletedModal}
          />
        )}
      </>
    )
  }
}

ProductsList.propTypes = {
  /** Block object */
  block: PropTypes.object,
  /** Property for check is PageBuilder in preview mode */
  previewMode: PropTypes.bool,
  /** Set custom product loading */
  setProductsCustomLoading: PropTypes.func, // contentPageStore.setProductsCustomLoading
  /** Fetch popular products */
  fetchPopularProducts: PropTypes.func, // contentPageStore.fetchPopularProducts
  /** Browser history push */
  historyPush: PropTypes.func,
  /** Seller item */
  sellerItem: PropTypes.object, // sellersStore.item
  /** Username from url */
  username: PropTypes.string, // match.params.username
  /** View(cabinet/shop) received from contentPageStore */
  view: PropTypes.string, // contentPageStore.view
  /** React JSS classes */
  classes: PropTypes.object,
  /** Return currency sign from currency key */
  getUnit: PropTypes.func, // currenciesStore.getUnit
  /** In edit mode or not */
  inEdit: PropTypes.bool,
  /** Convert price without currency function */
  convertToPriceWithoutCurrency: PropTypes.func, // (value) => value.formatMoney(2, ',', '.')
  /** Wrapper link for url */
  LinkComponent: PropTypes.func,
  /** Products from block content convert toJS */
  contentProducts: PropTypes.oneOfType([PropTypes.array, PropTypes.string]), // toJS(block.content[POPULAR_PRODUCTS.products])
  /** Get price with value and currency */
  formatPrice: PropTypes.func, // (value, currencySign) => `${value.formatMoney(2, ',', '.')}${currencySign}`
  /** Sellable item */
  sellable: PropTypes.object, // sellablesStore.item
  /** Product id */
  productId: PropTypes.shape([PropTypes.number, PropTypes.string]),
  /** Fetch product function */
  fetchProduct: PropTypes.func, // productsStore.fetchItem
  /** Manage order function */
  manageOrder: PropTypes.func, // shop/api/orders.api
  /** Membership view type received from contentPageStore */
  membershipViewType: PropTypes.string,
  /** Membership sellable id received from contentPageStore */
  membershipSellable: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Function that toggle custom loading */
  toggleCustomLoading: PropTypes.func, // sellablesStore.toggleCustomLoading
  /** Fetch sellable function */
  fetchSellable: PropTypes.func, // sellablesStore.fetchItem
  /** Function that close modal */
  closeModal: PropTypes.func,
  /** Function that toggle order complete modal */
  toggleOrderCompletedModal: PropTypes.func,
  /** Product item */
  product: PropTypes.object, // productsStore.item
  /** Accept postsell function */
  acceptPostsell: PropTypes.func, // shop/api/funnels.api
  /** Convert price function received from currencyStore */
  convertToPrice: PropTypes.func, // currenciesStore.convertToPrice
  /** Function that cross amount value with style for windows */
  amountCrossed: PropTypes.func, // helpers.utils
  /** Boolean value that say you is windows operating system get from navigator of browser */
  isWindows: PropTypes.bool, // browser.utils
  /** getMP3dsBrowserMetaData received from requests utils */
  getMP3dsBrowserMetaData: PropTypes.func,
}
ProductsList.defaultProps = {
  block: {
    content: {},
  },
  sellerItem: {},
  classes: {},
  getUnit: /* istanbul ignore next */ () => {},
  convertToPriceWithoutCurrency: /* istanbul ignore next */ () => {},
  setProductsCustomLoading: /* istanbul ignore next */ () => {},
  fetchPopularProducts: /* istanbul ignore next */ () => ({
    then: () => {},
  }),
  LinkComponent: 'a',
  sellable: {},
  product: {},
  fetchProduct: /* istanbul ignore next */ () => {},
  convertToPrice: /* istanbul ignore next */ () => {},
  amountCrossed: /* istanbul ignore next */ () => {},
  getMP3dsBrowserMetaData: /* istanbul ignore next */ () => {},
  I18n: {
    t: () => {},
  },
}

const StyledProductsList = withStyles(popularProductsStyles)(ProductsList)

export const PopularProductsBlock = (props) => {
  const { block } = props
  const I18n = useI18n()

  return (
    <ThemeProvider theme={getThemeProps(block)}>
      <StyledProductsList I18n={I18n} {...props} />
    </ThemeProvider>
  )
}

PopularProductsBlock.displayName = 'PopularProductsBlock'
PopularProductsBlock.propTypes = {
  /** Block object */
  block: PropTypes.object,
}
PopularProductsBlock.defaultProps = {
  block: {
    content: {},
  },
}

export default PopularProductsBlock
