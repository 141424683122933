import type { PayPalMessagesComponentProps } from '@paypal/react-paypal-js'

export const PAYPAL_MESSAGE_PROPS: PayPalMessagesComponentProps = {
  placement: 'product',
  style: {
    layout: 'text',
    logo: {
      type: 'inline',
      position: 'right',
    },
    text: {
      size: 14,
    },
  },
}

export const PAYPAL_MESSAGE_PROPS_DARK: PayPalMessagesComponentProps = {
  placement: 'product',
  style: {
    layout: 'text',
    logo: {
      type: 'primary',
      position: 'right',
    },
    text: {
      color: 'white',
      size: 14,
    },
  },
}

export const KLARNA_MESSAGE_LIGHT = {
  theme: 'light',
}

export const KLARNA_MESSAGE_DARK = {
  theme: 'night',
}
