import React from 'react'

interface Props {
  buttonColor: string
}

export const Forward: React.FC<Props> = ({ buttonColor }) => (
  <svg width={31} height={34}>
    <g>
      <g transform='translate(-664.000000, -954.000000)'>
        <g transform='translate(408.000000, 819.000000)'>
          <g transform='translate(257.000000, 135.000000)'>
            <path
              style={{
                fill: buttonColor,
              }}
              d='M12.3,22.5v-8.5h-1.3l-2.4,0.7L9,16.3l1.5-0.4v6.6H12.3z M17.1,22.7c1,0,1.7-0.3,2.3-0.8 c0.6-0.5,0.9-1.2,
              0.9-2.1c0-0.9-0.3-1.6-0.8-2S18.2,17,17.4,17c-0.4,0-0.7,0-1.1,0.1l0,0l0.1-1.5h3.6v-1.6h-5.2l-0.2,4.1
              l1.1,0.7c0.5-0.2,1-0.3,1.4-0.3s0.8,0.1,1.1,0.3c0.3,0.2,0.4,0.5,0.4,0.8c0,0.4-0.1,0.7-0.4,0.9
              c-0.2,0.2-0.6,0.3-1,0.3 c-0.7,0-1.3-0.3-2-0.9l0,0L14,21.4C14.8,22.2,15.9,22.7,17.1,22.7z'
            />
            <g>
              <path
                style={{
                  fill: buttonColor,
                }}
                d='M14.5,33.5c-1.4,0-2.7-0.2-4-0.5c-2.8-0.7-5.3-2.2-7.3-4.3C0.5,25.8-1,22-1,18 C-1,9.5,5.9,2.5,14.5,2.5
                c0.6,0,1,0.4,1,1s-0.4,1-1,1C7,4.5,1,10.6,1,18c0,3.5,1.3,6.8,3.7,9.3c1.7,1.8,3.9,3.1,6.3,3.8
                c1.1,0.3,2.3,0.5,3.5,0.5C22,31.5,28,25.5,28,18c0-0.6,0.4-1,1-1s1,0.4,1,1C30,26.6,23.1,33.5,14.5,33.5z'
              />
              <path
                style={{
                  fill: buttonColor,
                }}
                d='M11.4,0.3l4.8,2.4c0.1,0.1,0.2,0.3,0.1,0.4c0,0,0,0.1-0.1,0.1l-3.9,3.8
                c-0.1,0.1-0.3,0.1-0.4,0c0,0-0.1-0.1-0.1-0.2l-1-6.3c0-0.2,0.1-0.3,0.2-0.3
                C11.3,0.3,11.3,0.3,11.4,0.3z'
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
