import { BUTTON_STYLE } from '../constants/contentPage.constants'

export const buttonAnimations = (
  theme: {
    radius?: number
    buttonViewStyle?: keyof typeof BUTTON_STYLE
    buttonColor?: string
  } = {}
) => ({
  noAnimation: {},

  shadow: {
    '&:hover': {
      '-webkit-box-shadow': '0px 8px 15px 0px rgba(0, 0, 0, 0.25)',
      '-moz-box-shadow': '0px 8px 15px 0px rgba(0, 0, 0, 0.25)',
      transition: 'all 0.4s ease 0s',
    },
  },

  textShadow: {
    '&:hover': {
      textShadow: '0px 0px 6px rgba(255, 255, 255, 1)',
      '-webkit-box-shadow': '0px 8px 15px 0px rgba(0, 0, 0, 0.25)',
      '-moz-box-shadow': '0px 8px 15px 0px rgba(0, 0, 0, 0.25)',
      transition: 'all 0.2s ease 0s',
    },
  },

  transparency: {
    '&:hover': {
      opacity: 0.8,
    },
  },

  stretching: {
    '&:hover': {
      letterSpacing: 3,
      '-webkit-box-shadow': '0px 5px 16px 0px rgba(0, 0, 0, 0.29)',
      '-moz-box-shadow': '0px 5px 16px 0px rgba(0, 0, 0, 0.29)',
      transition: 'all 0.3s ease 0s',
    },
  },

  '@keyframes increasingAnimation': {
    '0%': {
      transform: 'scale(1.17)',
    },
    '80%': {
      transform: 'scale(1.2)',
    },
  },

  increasing: {
    animationName: '$increasingAnimation',
    animationDuration: '1s',
    animationTimingFunction: 'ease-in-out',
    transition: '.2s ease-in-out 0s',
    '&:hover': {
      transform: 'scale(1.25)',
    },
  },

  bordering: {
    '&:hover': {
      transition: 'all 0.5s',
      borderRadius: theme.radius === undefined || theme.radius > 0 ? 0 : 30,
    },
  },

  '@keyframes swingAnimation': {
    '15%': {
      '-webkit-transform': 'translateX(5px)',
      transform: 'translateX(5px)',
    },
    '30%': {
      '-webkit-transform': 'translateX(-5px)',
      transform: 'translateX(-5px)',
    },
    '50%': {
      '-webkit-transform': 'translateX(3px)',
      transform: 'translateX(3px)',
    },
    '65%': {
      '-webkit-transform': 'translateX(-3px)',
      transform: 'translateX(-3px)',
    },
    '80%': {
      '-webkit-transform': 'translateX(2px)',
      transform: 'translateX(2px)',
    },
    '100%': {
      '-webkit-transform': 'translateX(0)',
      transform: 'translateX(0)',
    },
  },

  swing: {
    '&:hover': {
      '-webkit-animation': '$swingAnimation 1s ease',
      animation: '$swingAnimation 1s ease',
      '-webkit-animation-iteration-count': 1,
      'animation-iteration-count': 1,
    },
  },

  fillOutlineSwitch: {
    transition: 'all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s',
    position: 'relative',
    '&:hover': {
      background:
        theme.buttonViewStyle === BUTTON_STYLE.outlined
          ? `${theme.buttonColor || '#1e84d7'} !important`
          : 'none !important',
    },
  },

  coloredShadow: {
    '&:hover': {
      '-webkit-box-shadow': `0px 6px 12px 0px ${theme.buttonColor || '#1e84d7'}`,
      '-moz-box-shadow': `0px 6px 12px 0px ${theme.buttonColor || '#1e84d7'}`,
      transition: 'all 0.4s ease 0s',
    },
  },
})
