import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { get } from 'utils/lodash.utils'

import { PICTURE_CLASS_NAMES, CUSTOM_CLASS_NAME_OPTION } from '@elo-kit/constants/customCss.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { getThemeProps } from '@elo-kit/utils/block.utils'
import { LazyImage } from '@elo-kit/components/lazy-image/LazyImage'
import { usePictureStyles } from 'shared/components/content-page/preview/blocks/picture/Picture.styles'

export const Picture = ({ block, previewMode }) => {
  const cover = get(block, 'cover', {})
  const good = get(block, 'goods[0]', '')

  const goodSrc = get(cover, 'file.custom', '') || get(cover, 'file.original', '') || get(good, 'digital.file.icon', '')

  const classes = usePictureStyles({
    theme: getThemeProps(block),
  })
  const { imageContainer, noImageContainer } = classes
  const containerClasses = classNames(
    PICTURE_CLASS_NAMES.containerClassName,
    block.content[CUSTOM_CLASS_NAME_OPTION],
    'content-block-picture',
    goodSrc || !previewMode ? imageContainer : noImageContainer
  )

  return (
    <div
      className={containerClasses}
      {...(previewMode && {
        'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.picture.image,
        'data-highlighter-selector': '.preview-img',
      })}
    >
      {goodSrc ? (
        <LazyImage
          lowQualitySrc={cover?.file?.s640 || goodSrc}
          fullQualitySrc={goodSrc}
          alt='loaded_image'
          className='preview-img'
          style={{
            maxHeight: 'initial',
            width: `${block?.content?.width || 100}%`,
            maxWidth: `${block?.content?.maxWidth || 100}% !important`,
          }}
        />
      ) : (
        previewMode && <i className='far fa-image' />
      )}
    </div>
  )
}

Picture.propTypes = {
  previewMode: PropTypes.bool,
  block: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    content: PropTypes.object,
    goods: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  }),
}

Picture.defaultProps = {
  previewMode: false,
  block: {
    content: {},
    goods: [],
  },
}
