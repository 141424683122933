import { COLORS } from '../../../constants/general.constants'

const { eloRed } = COLORS

// TODO: update with using createUseStyles
const inputFieldWithValidationStyles = () => ({
  formGroup: {
    marginBottom: 16,

    '& input': {
      fontFamily: 'Montserrat Reg',
      fontSize: 12,
      height: 40,
      lineHeight: 40,
      paddingTop: 7,
    },
  },

  requiredInputLabel: {
    '&::after': {
      color: eloRed,
      content: '*',
      fontSize: 18,
      paddingLeft: 2,
    },
  },

  formControl: {
    display: 'block',
    width: '100%',
    height: 'calc(1.5em + 0.75rem + 2px)',
    padding: '6px 12px',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.5,
    color: '#495057',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #ced4da',
    borderRadius: 4,
    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
  },

  isInvalid: {
    borderColor: '#dc3545',
    paddingRight: 'calc(1.5em + 0.75rem)',
    // eslint-disable-next-line max-len
    backgroundImage:
      "url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e\")",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right calc(0.375em + 0.1875rem) center',
    backgroundSize: 'calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)',
  },

  isValid: {
    borderColor: '#28a745',
    paddingRight: 'calc(1.5em + 0.75rem)',
    // eslint-disable-next-line max-len
    backgroundImage:
      "url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e\")",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right calc(0.375em + 0.1875rem) center',
    backgroundSize: 'calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)',
  },

  invalidFeedback: {
    display: 'block',
    width: '100%',
    marginTop: 4,
    fontSize: '80%',
    color: '#dc3545',
  },

  validFeedback: {
    display: 'block',
    width: '100%',
    marginTop: 4,
    fontSize: '80%',
    color: '#28a745',
  },
})

export default inputFieldWithValidationStyles
