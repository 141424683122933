import React, { Component, Fragment } from 'react'
import { PopoverBody, Popover } from 'reactstrap'
import classNames from 'classnames'
import Popper from '@popperjs/core'

import { POSITIONS } from '@elo-kit/constants/general.constants'
import { createId } from '@elo-kit/utils/general.utils'
import { ConfirmationModal } from '@elo-kit/components/elo-ui/confirmation-modal'
import { I18nType, useI18n } from '@elo-kit/components/i18n/i18n'

import { AnyCallback, Nullable } from 'types/helpers'

export const SPACE_ORIENTATION = {
  horizontal: 'h',
  vertical: 'v',
}

import './_tooltip-menu.scss'

const SPACE_ORIENTATION_VALUES = Object.values(SPACE_ORIENTATION)
type spaceOrientation = (typeof SPACE_ORIENTATION_VALUES)[number]

interface Item {
  id?: string | number
  show?: boolean
  action?: Nullable<AnyCallback>
  label?: string
  content?: any
  keepPopoverAfterAction?: string
  bodyText?: string
  showInfo?: boolean
  showPDF?: boolean
  titleText?: string
  useConfirmation?: boolean
}
interface Props {
  container?: string | HTMLElement | React.RefObject<HTMLElement>
  placement?: Popper.Placement
  iconMenu?: boolean
  id: string | number
  popoverClass?: string
  popoverContainerClass?: string
  menuItems?: Item[]
  iconOrientation?: spaceOrientation
  children?: ((togglePopover: () => void) => React.ReactNode) | React.ReactNode
  tabIndex?: number
  customIcon?: (params: { onClick: () => void; id: string }) => React.ReactNode
  alignRight?: boolean
  alignCenter?: boolean
  shouldFocusOnFirstItem?: boolean
  trackPopoverOpen?: () => void
  defaultModalTitleText?: string
  submitButtonChild?: string
  cancelButtonChild?: string
  I18n?: I18nType
}
interface State {
  popoverOpen: boolean
  confirmationModalOpened: boolean
  confirmationAction: Nullable<AnyCallback>
  modalBodyText: string
  modalTitleText: string
  displayName?: string
}

const defaultProps = {
  placement: POSITIONS.left,
  iconMenu: false,
  iconOrientation: SPACE_ORIENTATION.horizontal,
  menuItems: [],
  I18n: {
    t: () => {},
  },
}

export class TooltipMenuContainer extends Component<Props, State> {
  constructor(args: Props) {
    super(args)

    this.state = {
      popoverOpen: false,
      confirmationModalOpened: false,
      confirmationAction: null,
      modalBodyText: '',
      modalTitleText: '',
    }
  }

  static defaultProps = defaultProps
  static displayName = 'TooltipMenu'

  componentDidUpdate() {
    const { shouldFocusOnFirstItem } = this.props

    if (shouldFocusOnFirstItem) {
      const firstTooltipMenuItem = document.getElementsByClassName('tooltip-menu__item')[0]

      if (firstTooltipMenuItem) {
        ;(firstTooltipMenuItem as HTMLElement)?.focus()
      }
    }
  }

  setModalBodyText = (modalBodyText: string) => this.setState({ modalBodyText })

  setModalTitleText = (modalTitleText: string) => this.setState({ modalTitleText })

  toggleConfirmationModal = (action: Nullable<AnyCallback> = null) => {
    const { confirmationModalOpened } = this.state

    this.setState({ confirmationModalOpened: !confirmationModalOpened })
    this.setState({ confirmationAction: action })
  }

  togglePopover = () => {
    const { trackPopoverOpen } = this.props
    const { popoverOpen } = this.state

    if (trackPopoverOpen) {
      trackPopoverOpen()
    }

    this.setState({ popoverOpen: !popoverOpen })
  }

  handleTooltipMenuClick = (item: Item) => {
    item.action && item.action()
    if (!item.keepPopoverAfterAction) {
      this.togglePopover()
    }
  }

  render() {
    const { I18n } = this.props
    const {
      children,
      container,
      id,
      iconMenu,
      iconOrientation,
      placement,
      popoverClass,
      popoverContainerClass,
      menuItems,
      customIcon,
      alignRight,
      alignCenter,
      tabIndex = 0,
      defaultModalTitleText = I18n.t('react.common.are_you_sure'),
      submitButtonChild = I18n.t('react.cabinet.common.confirm'),
      cancelButtonChild,
    } = this.props
    const { confirmationModalOpened, confirmationAction, popoverOpen, modalBodyText, modalTitleText } = this.state

    const popoverClassNames = classNames(
      'tooltip-menu__popover',
      {
        'tooltip-menu__popover--with-icons': iconMenu,
      },
      popoverClass
    )

    const containerClassNames = classNames('tooltip-menu__container', {
      'tooltip-icon-menu': iconMenu,
      'tooltip-menu__container--right': alignRight,
      'tooltip-menu__container--center': alignCenter,
    })

    const popoverContainerClassNames = classNames('tooltip-menu__popover-container', popoverContainerClass)

    const target = `menu-tooltip-with-icons-${id}`

    if (!children && menuItems?.every((item) => !item.show)) {
      return null
    }

    return (
      <>
        <div
          className={!customIcon ? containerClassNames : ''}
          aria-expanded
          aria-haspopup
          id={target}
          onKeyDown={this.togglePopover}
          role='button'
          tabIndex={tabIndex}
        >
          {customIcon ? (
            customIcon({
              onClick: this.togglePopover,
              id: target,
            })
          ) : (
            <i className={`fas fa-ellipsis-${iconOrientation} tooltip-menu__icon`} />
          )}
          <Popover
            className={popoverContainerClassNames}
            innerClassName={String(popoverClassNames)}
            isOpen={popoverOpen}
            toggle={this.togglePopover}
            {...{
              container,
              placement,
              target,
            }}
          >
            <PopoverBody>
              {typeof children === 'function' ? children(this.togglePopover) : children}
              {menuItems?.map((item, index) => {
                const {
                  action,
                  bodyText = '',
                  content,
                  id: itemId,
                  label,
                  show,
                  showInfo,
                  showPDF,
                  titleText = '',
                  useConfirmation,
                } = item

                const handleClick = () => {
                  if (useConfirmation) {
                    this.toggleConfirmationModal(action)
                    this.togglePopover()
                    this.setModalBodyText(bodyText)
                    this.setModalTitleText(titleText)
                  } else {
                    this.handleTooltipMenuClick(item)
                  }
                }

                return (
                  <Fragment key={createId(String(id), itemId, index)}>
                    {show && (
                      <button
                        aria-expanded
                        id={createId(String(id), itemId, index)}
                        className='tooltip-menu__item'
                        key={createId(String(id), itemId, index)}
                        onClick={handleClick}
                        onKeyPress={handleClick}
                      >
                        {iconMenu ? (
                          <i className={String(label)} id={`menu-tooltip-icon-${id}-${itemId}`} />
                        ) : (
                          !!label && label
                        )}
                        {content}
                      </button>
                    )}
                    {showPDF && <div className='elo-table__details tooltip-menu__item--pdf'>{content}</div>}
                    {showInfo && <div className='tooltip-menu__item--info'>{content}</div>}
                  </Fragment>
                )
              })}
            </PopoverBody>
          </Popover>
        </div>
        <ConfirmationModal
          isOpen={confirmationModalOpened}
          submit={confirmationAction}
          close={this.toggleConfirmationModal}
          toggle={this.toggleConfirmationModal}
          title={modalTitleText || defaultModalTitleText}
          message={modalBodyText}
          submitButtonChild={submitButtonChild}
          cancelButtonChild={cancelButtonChild}
        />
        {popoverOpen && <div className='tooltip-menu-close' onClick={this.togglePopover} />}
      </>
    )
  }
}

export const TooltipMenu = (props) => {
  const I18n = useI18n()

  return <TooltipMenuContainer I18n={I18n} {...props} />
}
