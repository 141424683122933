import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { SketchPicker } from 'react-color'

import { COLOR_PICKER_DEFAULT_COLORS_PRESET, COLOR_PICKER_DEFAULT_VALUE } from 'constants/forms.constants'

import { InfoTooltip } from '@elo-kit/components/info-tooltip/InfoTooltip'
import { CheckboxField } from '@elo-kit/components/form/checkbox-field/CheckboxField'
import { useI18n } from '@elo-kit/components/i18n/i18n'

import { addColorToPreset, getColorPreset, isPresetFeatureSupported } from 'utils/colorPicker.utils'

const propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  defaultColor: PropTypes.string,
  withCustomPreset: PropTypes.bool,
  checkboxDisabled: PropTypes.bool,
  tooltipStyles: PropTypes.shape({}),
}

const defaultProps = {
  defaultColor: COLOR_PICKER_DEFAULT_VALUE,
  disabled: false,
  withCustomPreset: true,
  checkboxDisabled: false,
  tooltipStyles: {},
}

export class ColorPickerFieldContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showPicker: false,
    }
  }

  togglePicker = () => {
    const { showPicker } = this.state
    const { withCustomPreset, color } = this.props

    this.setState((prevState) => ({
      showPicker: !prevState.showPicker,
    }))

    if (withCustomPreset && showPicker) {
      addColorToPreset(color)
    }
  }

  render() {
    const {
      className,
      label,
      disabled,
      color,
      defaultColor,
      onChange,
      tooltipId,
      withCustomPreset,
      onCheckboxChange,
      checkboxState,
      checkboxDisabled,
      tooltipInnerClassName,
      I18n,
    } = this.props

    const { showPicker } = this.state

    const colorValue = color || defaultColor

    const fieldClassNames = classNames(
      'field color-picker-field',
      {
        'field--disabled': disabled || (onCheckboxChange && checkboxDisabled),
      },
      className
    )

    const wrapperClassName = classNames({
      'with-checkbox': Boolean(onCheckboxChange),
    })

    const colorsPreset = withCustomPreset && isPresetFeatureSupported() && getColorPreset()
    const colorsPresetToUse =
      colorsPreset && colorsPreset.length
        ? COLOR_PICKER_DEFAULT_COLORS_PRESET.concat(colorsPreset)
        : COLOR_PICKER_DEFAULT_COLORS_PRESET

    const tooltipTitles = {
      background_color: I18n.t('react.cabinet.help_icon.background_color.content'),
      general_color: I18n.t('react.cabinet.help_icon.general_color.content'),
      payment_bg_color: I18n.t('react.cabinet.help_icon.payment_bg_color.content'),
      payment_page_color: I18n.t('react.cabinet.help_icon.payment_page_color.content'),
      payment_recommendation_badge_color: I18n.t('react.cabinet.help_icon.payment_recommendation_badge_color.content'),
      // prettier-ignore
      payment_recommendation_badge_text_color: I18n.t('react.cabinet.help_icon.payment_recommendation_badge_text_color.content'),
      payment_buy_btn_color: I18n.t('react.cabinet.help_icon.payment_buy_btn_color.content'),
      payment_buy_btn_font_color: I18n.t('react.cabinet.help_icon.payment_buy_btn_font_color.content'),
      apply_coupon_btn_color: I18n.t('react.cabinet.help_icon.apply_coupon_btn_color.content'),
      apply_coupon_btn_font_color: I18n.t('react.cabinet.help_icon.apply_coupon_btn_font_color.content'),
      // prettier-ignore
      mobile_app_general_settings_shop_general_background: I18n.t('react.cabinet.help_icon.mobile_app_general_settings_shop_general_background.content'),
      // prettier-ignore
      mobile_app_general_settings_shop_icon: I18n.t('react.cabinet.help_icon.mobile_app_general_settings_shop_icon.content'),
      // prettier-ignore
      mobile_app_general_settings_shop_text: I18n.t('react.cabinet.help_icon.mobile_app_general_settings_shop_text.content'),
      // prettier-ignore
      mobile_app_general_settings_shop_button_text: I18n.t('react.cabinet.help_icon.mobile_app_general_settings_shop_button_text.content'),
      // prettier-ignore
      mobile_app_general_settings_shop_button_background: I18n.t('react.cabinet.help_icon.mobile_app_general_settings_shop_button_background.content'),
      // prettier-ignore
      mobile_app_general_settings_shop_product_background: I18n.t('react.cabinet.help_icon.mobile_app_general_settings_shop_product_background.content'),
      // prettier-ignore
      mobile_app_general_settings_general_background: I18n.t('react.cabinet.help_icon.mobile_app_general_settings_general_background.content'),
      mobile_app_general_settings_icon: I18n.t('react.cabinet.help_icon.mobile_app_general_settings_icon.content'),
      mobile_app_general_settings_text: I18n.t('react.cabinet.help_icon.mobile_app_general_settings_text.content'),
      // prettier-ignore
      mobile_app_general_settings_button_text: I18n.t('react.cabinet.help_icon.mobile_app_general_settings_button_text.content'),
      // prettier-ignore
      mobile_app_general_settings_button_background: I18n.t('react.cabinet.help_icon.mobile_app_general_settings_button_background.content'),
    }

    return (
      <div className={fieldClassNames}>
        <div className={wrapperClassName}>
          {label && (
            <label className='field__label'>
              {label}
              {tooltipId && (
                <InfoTooltip
                  id={`${tooltipId}_popover`}
                  title=''
                  body={tooltipTitles[tooltipId]}
                  innerClassName={tooltipInnerClassName}
                />
              )}
            </label>
          )}

          {onCheckboxChange && (
            <CheckboxField
              checked={checkboxState}
              onChange={() => onCheckboxChange(!checkboxState)}
              className='checkbox-field--no-margin'
              disabled={checkboxDisabled}
            />
          )}
        </div>

        <div className='color-picker-field__control-wrapper'>
          <div className='field__control color-picker-field__control' onClick={this.togglePicker}>
            <div className='color-picker-field__color-value'>{colorValue}</div>
            <div className='color-picker-field__color-label' style={{ backgroundColor: colorValue }} />
          </div>

          {showPicker && (
            <div>
              <div className='color-picker-field__picker-cover' onClick={this.togglePicker} />
              <SketchPicker
                color={colorValue}
                disableAlpha
                onChangeComplete={(color) => onChange(color)}
                presetColors={colorsPresetToUse}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}

ColorPickerFieldContainer.displayName = 'ColorPickerField'
ColorPickerFieldContainer.propTypes = propTypes
ColorPickerFieldContainer.defaultProps = defaultProps

export const ColorPickerField = (props) => {
  const I18n = useI18n()

  return <ColorPickerFieldContainer I18n={I18n} {...props} />
}
