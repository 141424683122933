import React from 'react'
import classNames from 'classnames'

import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { AUDIO_CLASS_NAMES } from '@elo-kit/constants/customCss.constants'

interface Props {
  backgroundImage?: string
  previewMode?: boolean
  classes?: Record<string, string>
}

export const AudioPlayerImage: React.FC<Props> = ({ backgroundImage, classes = {}, previewMode }) => (
  <>
    {backgroundImage ? (
      <div
        className={classNames(AUDIO_CLASS_NAMES.imageContainerClassName, classes.audioImage)}
        {...(previewMode && {
          'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.audioPlayer.image,
          'data-highlighter-selector': '',
        })}
      >
        <div className={classes.fileBackground} />
      </div>
    ) : (
      previewMode && (
        <div
          className={classNames(AUDIO_CLASS_NAMES.imageContainerClassName, classes.audioImage)}
          {...(previewMode && {
            'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.audioPlayer.image,
            'data-highlighter-selector': '',
          })}
        >
          <i className='far fa-image' />
        </div>
      )
    )}
  </>
)
