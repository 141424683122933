import { cleanupFonts } from 'utils/fonts.utils'

import { PRODUCTS_LIST } from '../../../../constants/contentPage.constants'
import { getFontStyles } from '../../../../utils/fonts.utils'
import { COLORS } from '../../../../constants/general.constants'

const { eloBlack, eloBlue } = COLORS

const categoryTabsStyles = (theme) => ({
  categoriesListContainer: {
    maxWidth: '1140px',
    width: '100%',
    margin: '0 auto 0',
    padding: '30px 15px 0',
  },

  categoriesList: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    borderBottom: '1px solid #dee2e6',
    paddingLeft: 0,
    marginBottom: 0,

    '@media (max-width: 991px)': {
      flexWrap: 'nowrap',
      width: '100%',
      overflow: 'scroll',
      scrollBehavior: 'smooth',
      scrollbarWidth: 'none',
      '-ms-overflow-style': 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },

  category: {
    color: theme[PRODUCTS_LIST.categoryColor] || eloBlue,
    fontSize: `${theme[PRODUCTS_LIST.categorySize] || 15}px`,
    fontFamily: cleanupFonts(theme[PRODUCTS_LIST.categoryFont]) || 'Montserrat Medium',
    ...getFontStyles(theme[PRODUCTS_LIST.categoryStyle] || 'normal'),
    padding: '17px 30px 9px',
    lineHeight: 1,
    border: '1px solid transparent',
    borderTopLeftRadius: '0.25rem',
    borderTopRightRadius: '0.25rem',
    display: 'block',
    cursor: 'pointer',

    '&:hover': {
      opacity: 0.8,
    },

    '@media (max-width: 991px)': {
      padding: '0 20px 10px 20px',
      border: 'none',
    },

    '@media (max-width: 576px)': {
      fontSize: theme[PRODUCTS_LIST.categorySize] > 16 ? 16 : theme[PRODUCTS_LIST.categorySize],
    },
  },

  activeCategory: {
    color: theme[PRODUCTS_LIST.categoryActiveColor] || eloBlack,
    borderColor: '#eaeaea #eaeaea #fff',

    '@media (max-width: 991px)': {
      padding: '0 20px 10px 20px',
      border: 'none',
      borderBottom: `3px solid ${theme[PRODUCTS_LIST.categoryColor] || eloBlue}`,
    },
  },

  categoryContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: 0,
    listStyle: 'none',
    marginBottom: '-1px',
    minWidth: 130,
    justifyContent: 'center',
    wordBreak: 'break-word',

    '@media (max-width: 991px)': {
      marginBottom: 0,
    },
  },

  tabDescription: {
    marginTop: 20,
    color: theme[PRODUCTS_LIST.categoryDescriptionColor] || eloBlack,
    fontSize: `${theme[PRODUCTS_LIST.categoryDescriptionSize] || 14}px`,
    fontFamily: cleanupFonts(theme[PRODUCTS_LIST.categoryDescriptionFont]) || 'Montserrat Reg',
    ...getFontStyles(theme[PRODUCTS_LIST.categoryDescriptionStyle] || 'normal'),
  },
})

export default categoryTabsStyles
