import { COLORS } from '@elo-kit/constants/general.constants'

const { eloPrimary, eloWhite } = COLORS

const wistiaEmbedStyles = () => ({
  wistiaEmbedErrorContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '737px',
    with: '100%',
    backgroundColor: eloPrimary,
  },

  wistiaEmbedErrorTitle: {
    fontFamily: 'ES Klarheit Elopage',
    fontSize: '28px',
    textAlign: 'center',
    color: eloWhite,
  },

  wistiaEmbedErrordescription: {
    fontFamily: 'Inter',
    fontSize: '16px',
    textAlign: 'center',
    color: eloWhite,
  },
})

export default wistiaEmbedStyles
