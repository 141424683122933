import React from 'react'
import classNames from 'classnames'
import { EloButton } from '@elo-kit/components/elo-button'
import './infoActionBar.scss'

/**
 * Error Info - show information about error with action button
 */

interface Props {
  message?: string
  buttonAction?: () => void
  buttonTitle?: string
  icon?: React.ReactFragment
  orange?: boolean
}

export const InfoActionBar: React.FC<Props> = ({
  message = '',
  buttonAction,
  icon,
  buttonTitle,
  orange,
}): JSX.Element => {
  const classes = classNames('info-action-bar', { orange: orange })

  return (
    <div className={classes}>
      {icon || <i className='fas fa-exclamation-circle'></i>}
      <div
        dangerouslySetInnerHTML={{
          __html: message,
        }}
      />
      {buttonAction && (
        <EloButton onClick={buttonAction} white outlined>
          {buttonTitle}{' '}
        </EloButton>
      )}
    </div>
  )
}
