import React from 'react'
import classNames from 'classnames'

import './_boolean-label.scss'

interface Props {
  active?: boolean
  justText?: boolean
  activeMessageContent?: string
  inactiveMessageContent?: string
}

/**
 * Boolean Label - indicates whether boolean condition true or false
 */
export const BooleanLabel: React.FC<Props> = ({
  active = true,
  justText = false,
  activeMessageContent = 'Yes',
  inactiveMessageContent = 'No',
}) => {
  const labelClasses = classNames('boolean-label', {
    'boolean-label--active': active,
    'boolean-label--just-text': justText,
    'boolean-label--just-text--active': justText && active,
  })

  return <span className={labelClasses}>{active ? activeMessageContent : inactiveMessageContent}</span>
}

BooleanLabel.displayName = 'BooleanLabel'
