import React from 'react'
import classNames from 'classnames'

import { TooltipMenu } from '@elo-kit/components/elo-ui/tooltip-menu'
import { useI18n } from '@elo-kit/components/i18n/i18n'

import { POSITIONS } from '@elo-kit/constants/general.constants'
import { AUDIO_CLASS_NAMES } from '@elo-kit/constants/customCss.constants'
import { AUDIO_PLAYER_OPTIONS } from '@elo-kit/constants/block.constants'

interface Props {
  audioSpeed?: number
  classes?: Record<string, string>
  handleAudioSpeedChange: (speed: number) => void
  goodId?: string | number
  defaultSpeedText?: string
}

export const AudioPlayerSpeed: React.FC<Props> = (props) => {
  const I18n = useI18n()
  const {
    audioSpeed = 1,
    classes = {},
    handleAudioSpeedChange,
    goodId = '1',
    defaultSpeedText = I18n.t('react.shared.speed'),
  } = props

  return (
    <div className={classNames(AUDIO_CLASS_NAMES.speedContainerClassName, classes.audioSpeed)}>
      <TooltipMenu
        id={goodId}
        customIcon={(props) => (
          <i {...props} className='fas fa-tachometer-alt'>
            &nbsp;
            <span>
              {defaultSpeedText}: {`${audioSpeed}x`}
            </span>
          </i>
        )}
        placement={POSITIONS.left}
        iconMenu
      >
        {(togglePopover) => (
          <div className={classes.audioOptionsSpeed}>
            {AUDIO_PLAYER_OPTIONS.map((speed) => {
              const audioOptionClasses = classNames('audio-option', {
                'audio-option--active': speed === audioSpeed,
              })
              return (
                <button
                  key={speed}
                  className={audioOptionClasses}
                  onClick={() => {
                    handleAudioSpeedChange(speed)
                    togglePopover()
                  }}
                >
                  {`${speed}x`}
                </button>
              )
            })}
          </div>
        )}
      </TooltipMenu>
    </div>
  )
}
