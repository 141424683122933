import React from 'react'

import {
  LEMONWAY_LEGITIMATION_FILE_TYPES,
  MANGOPAY_LEGITIMATION_DOCUMENTS,
} from '@elo-kit/constants/paymentSetting.constants'

import { FILE_ICONS, IMAGE_EXTENSIONS } from '@elo-kit/constants/files.constants'

interface Digital {
  file: {
    icon: string
  }
}
interface File {
  digital?: Digital
  docType?: string
  icon?: string
  imgUrl?: string
  name?: string
  preview?: string
  s100?: string
  thumb?: string
}
interface Props {
  extension?: string
  file?: File
  iconDefault?: React.ReactNode
  setIcon?: () => void
  uploaded?: boolean
}
/**
 * FileIcon - Shows Icon depending on extension & state
 */
export const FileIcon = (props: Props) => {
  const { extension = '', file = {}, iconDefault = '', setIcon, uploaded = false } = props

  if (IMAGE_EXTENSIONS.includes(extension)) {
    const { digital, docType, icon, imgUrl, name, preview, s100, thumb } = file

    const hasImage = s100 || thumb || icon || imgUrl

    const legitimationDocument =
      LEMONWAY_LEGITIMATION_FILE_TYPES.includes(docType || '') ||
      MANGOPAY_LEGITIMATION_DOCUMENTS.includes(docType || '')

    const shouldSetIcon = !(hasImage || digital || legitimationDocument)

    if (shouldSetIcon) {
      setIcon && setIcon()
    }

    const backgroundImageUrl = hasImage || iconDefault || (digital && digital?.file?.icon) || preview

    return !!backgroundImageUrl ? (
      <img alt={name} className='cover__img' src={String(backgroundImageUrl)} />
    ) : (
      <div className='cover__no-cover'>
        <i className='fas fa-camera' />
      </div>
    )
  }

  const isFileUploaded = Object.keys(file).length > 1 || uploaded

  return isFileUploaded ? (
    <div className='file-item-preview icon'>
      <i className={FILE_ICONS[extension as keyof typeof FILE_ICONS] || 'fas fa-file'} />
    </div>
  ) : null
}
