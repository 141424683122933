import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withStyles, { ThemeProvider } from 'react-jss'

import { EXTRAS_PRODUCTS_CLASS_NAMES } from '@elo-kit/constants/customCss.constants'
import noProductsFoundStyles from './NoProductsFound.styles'

export const NoProductsMessage = ({ classes, title }) => (
  <div className={classNames(EXTRAS_PRODUCTS_CLASS_NAMES.productNotFoundContainerClassName, classes.noProductsMessage)}>
    {title}
  </div>
)

export const NoProductsMessageWithStyles = withStyles(noProductsFoundStyles)(NoProductsMessage)

NoProductsMessage.propTypes = {
  /**
   * React JSS classes
   */
  classes: PropTypes.object,
  /**
   * Content block object
   */
  block: PropTypes.shape({
    content: PropTypes.object,
  }),
  /**
   * Message
   */
  title: PropTypes.string,
}

NoProductsMessage.defaultProps = {
  classes: {},
}

export class NoProductsFound extends Component {
  render() {
    const { block, noProductsMessage } = this.props
    return (
      <ThemeProvider theme={block.content}>
        <NoProductsMessageWithStyles title={noProductsMessage} />
      </ThemeProvider>
    )
  }
}

NoProductsFound.propTypes = {
  /**
   * Content block object
   */
  block: PropTypes.shape({
    content: PropTypes.object,
  }),
  /**
   * Message
   */
  noProductsMessage: PropTypes.string,
}

NoProductsFound.defaultProps = {
  block: {
    content: {},
  },
}
