import { uuid } from 'utils/uuid'

import { TRANSFER_PROVIDERS } from 'constants/transfers.constants'
import { PAYMENT_FORMS } from 'constants/paymentSettingShared.constants'

export const loadFraudnetConfig = () => {
  const script = document.createElement('script')
  script.src = 'https://c.paypal.com/da/r/fb.js'
  document.body.appendChild(script)
}

export const getFraudSessionIdentifier = ({ paypalMerchantId, paypalProvider, paymentForm }) => {
  if (paypalMerchantId && paypalProvider === TRANSFER_PROVIDERS.paypalRest && paymentForm === PAYMENT_FORMS.paypal) {
    return uuid()
  }

  return null
}

export const insertFraudPaypalSnippet = (fraudSessionIdentifier, paypalMerchantId) => {
  const script = document.createElement('script')
  script.type = 'application/json'
  script.setAttribute('fncls', 'fnparams-dede7cc5-15fd-4c75-a9f4-36c430ee3a99')
  script.innerHTML = JSON.stringify({
    f: fraudSessionIdentifier,
    s: `${paypalMerchantId}_checkout-page`,
  })
  document.body.appendChild(script)

  loadFraudnetConfig()
}
