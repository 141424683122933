import withStyles from 'react-jss'

import { COLORS, PADDING_UNITS, POSITIONS } from '@elo-kit/constants/general.constants'
import {
  DEFAULT_VIDEO_WIDTH,
  DEFAULT_VIDEO_PADDING,
  DEFAULT_VIDEO_MAX_WIDTH,
} from '@elo-kit/constants/contentPage.constants'

import { getPosition, getAlignment, getBackgroundImageCss } from '@elo-kit/utils/themes.utils'
import { getFontStyles } from '@elo-kit/utils/fonts.utils'
import { cleanupFonts } from 'utils/fonts.utils'

// TODO: update with using createUseStyles
export const videoStyles = (theme = {}) => {
  const {
    background,
    backgroundColor,
    backgroundImage,
    bottomOffset,
    font,
    fontColor,
    fontSize,
    fontStyle,
    height,
    horizontalAlignment,
    horizontalPosition,
    integrationsBackground,
    maxWidth,
    transparency,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingUnits,
    verticalAlignment,
    verticalPosition,
    width,
    zIndex,
  } = theme

  return {
    emptyVideo: {
      position: 'relative',
      minHeight: '300px',
      width: '100%',
      background: COLORS.eloGrey20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      '& i': {
        fontSize: '100px',
        color: COLORS.eloWhite,
      },
    },
    code: {
      width: `${width}%`,
      height: `${height}%`,
      position: 'absolute',
      top: getPosition(verticalPosition, height, bottomOffset),
      left: getPosition(horizontalPosition, width),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: cleanupFonts(font) || 'Montserrat Medium',
      fontSize: `${fontSize || 30}px`,
      ...getFontStyles(fontStyle),
      color: fontColor || COLORS.eloWhite,
      wordBreak: 'keep-all',
      '& span': {
        zIndex: 2,
      },
    },
    codePreview: {
      top: `calc(${getPosition(verticalPosition, height)} - ${verticalPosition === POSITIONS.bottom ? '32px' : 0})`,
    },
    codeOverlay: {
      backgroundColor: background,
      opacity: transparency === 0 ? 0 : transparency || 1,
      width: '100%',
      height: '100%',
      position: 'absolute',
    },
    codesSection: {
      backgroundColor: integrationsBackground || COLORS.eloWhite,
      padding: 20,
      paddingTop: 30,
    },
    codesContainer: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    codeInputContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 20,
      marginRight: 30,
    },
    codesTitle: {
      fontFamily: 'Montserrat Medium',
      fontSize: 13,
      color: COLORS.eloBlack,
      textTransform: 'uppercase',
      marginBottom: 20,
    },
    videoContainer: {
      display: 'flex',
      height: '100%',
      backgroundImage: getBackgroundImageCss(backgroundImage),
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      justifyContent: getAlignment(horizontalAlignment),
      alignItems: getAlignment(verticalAlignment),
    },
    containerOverlay: {
      background: backgroundColor || COLORS.eloWhite,
      opacity: transparency,
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: zIndex || 1,
    },
    videoPlayerContainer: {
      width: `${width || DEFAULT_VIDEO_WIDTH}%`,
      maxWidth: `${maxWidth || DEFAULT_VIDEO_MAX_WIDTH}px`,
      paddingTop: `${paddingTop || DEFAULT_VIDEO_PADDING}${paddingUnits || PADDING_UNITS.px}`,
      paddingBottom: `${paddingBottom || DEFAULT_VIDEO_PADDING}${paddingUnits || PADDING_UNITS.px}`,
      paddingRight: `${paddingRight || DEFAULT_VIDEO_PADDING}${paddingUnits || PADDING_UNITS.px}`,
      paddingLeft: `${paddingLeft || DEFAULT_VIDEO_PADDING}${paddingUnits || PADDING_UNITS.px}`,
      zIndex: 2,
    },
    vimeoVideo: {
      zIndex: 1,
      width: `${width || DEFAULT_VIDEO_WIDTH}%`,
      maxWidth: `${maxWidth || DEFAULT_VIDEO_MAX_WIDTH}px`,
    },
    vimeoVideoContainer: {
      paddingTop: `${paddingTop || DEFAULT_VIDEO_PADDING}${paddingUnits || PADDING_UNITS.px}`,
      paddingBottom: `${paddingBottom || DEFAULT_VIDEO_PADDING}${paddingUnits || PADDING_UNITS.px}`,
      paddingRight: `${paddingRight || DEFAULT_VIDEO_PADDING}${paddingUnits || PADDING_UNITS.px}`,
      paddingLeft: `${paddingLeft || DEFAULT_VIDEO_PADDING}${paddingUnits || PADDING_UNITS.px}`,
    },
  }
}

export default withStyles(videoStyles)
