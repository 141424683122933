import React from 'react'
import { components } from 'react-select'

import { AnyCallback } from 'types/helpers'

interface Props {
  cx?: AnyCallback
  getStyles?: AnyCallback
}
export const ClearIndicator = (props: Props) => (
  <components.ClearIndicator {...props}>
    <i className='far fa-trash-alt' />
  </components.ClearIndicator>
)
