import React, { Component } from 'react'
import Select from 'react-select'
import classNames from 'classnames'

import { FieldError } from '@elo-kit/components/elo-ui/field-error'
import { InfoTooltip } from '@elo-kit/components/info-tooltip'
import { I18nType, useI18n } from '@elo-kit/components/i18n/i18n'

import { AnyCallback } from 'types/helpers'

import { ClearIndicator } from './clear-indicator/ClearIndicator'
import { DropdownIndicator } from './dropdown-indicator/DropdownIndicator'

import './_select-field.scss'

interface Components {
  ClearIndicator?: React.ReactNode
  DropdownIndicator?: React.ReactNode
  IndicatorSeparator?: React.ReactNode
}
interface Option {
  label?: any
  value?: any
}
interface Value {
  label?: string
  value?: any
}
interface Props {
  cashSelectedOption?: boolean
  className?: string
  classNamePrefix?: string
  containerClassName?: string
  components: Components
  dataTestId?: string
  defaultValue?: any
  disabled?: boolean
  errorId?: string
  errorMessage?: string
  filterOption?: AnyCallback
  formatOptionLabel?: AnyCallback
  isClearable?: boolean
  isMulti?: boolean
  label?: string
  name?: string
  menuPlacement?: string
  noOptionsMessage?: string
  onChange: AnyCallback
  options?: Option[]
  placeholder?: string
  required?: boolean
  requiredValidationMessage?: string
  searchable?: boolean
  tooltipBody?: string
  tooltipTitle?: string
  useObjectValue?: boolean
  valid?: boolean
  value?: string | Value
  valueKey?: string
  notSaveValue?: boolean
  tooltipId?: string
  inputTestId?: string
  instanceId?: string
  useSnakeCaseName?: string
  I18n?: I18nType
  showRequired?: boolean
}
interface State {
  showIsRequiredMessage: boolean
  selectedOption: any
}

const defaultProps = {
  cashSelectedOption: true,
  classNamePrefix: 'elo-select-field',
  components: {
    ClearIndicator,
    DropdownIndicator,
  },
  dataTestId: 'select-field',
  isClearable: false,
  menuPlacement: 'auto',
  options: [],
  required: false,
  searchable: true,
  useObjectValue: false,
  valid: true,
  valueKey: 'tags',
  showRequired: false,
}

export class SelectFieldContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      showIsRequiredMessage: false,
      selectedOption: null,
    }
  }

  static displayName = 'SelectField'
  static defaultProps = defaultProps

  setSelectedOption = (selectedOption: any) => this.setState({ selectedOption })

  setShowIsRequiredMessage = (showIsRequiredMessage: boolean) => this.setState({ showIsRequiredMessage })

  onFieldChange = (option: any) => {
    const { cashSelectedOption, required, onChange, useObjectValue } = this.props

    if (required) {
      this.setShowIsRequiredMessage(false)
    }

    if (useObjectValue) {
      onChange(option)
    } else {
      if (cashSelectedOption) {
        this.setSelectedOption(option)
      }
      onChange(option?.value)
    }
  }

  onBlur = () => {
    const { value, required } = this.props

    if (!value && required) {
      this.setShowIsRequiredMessage(true)
    }
  }

  render() {
    const {
      className,
      classNamePrefix,
      containerClassName,
      components,
      dataTestId,
      defaultValue,
      disabled,
      errorId,
      errorMessage,
      filterOption,
      formatOptionLabel,
      isClearable,
      isMulti,
      label,
      menuPlacement,
      name,
      noOptionsMessage,
      options,
      placeholder,
      requiredValidationMessage,
      searchable,
      tooltipBody = true,
      tooltipTitle = false,
      useObjectValue,
      valid,
      value,
      valueKey,
      notSaveValue,
      tooltipId,
      inputTestId,
      instanceId,
      showRequired,
      useSnakeCaseName,
      required,
    } = this.props
    const { selectedOption, showIsRequiredMessage } = this.state

    // Use saved selectedOption to avoid find on every change
    let selectValue = selectedOption && !useObjectValue ? selectedOption : value
    let selectDefaultValue: any = defaultValue
    // Set option object as value
    if (typeof selectValue === 'string' || typeof selectValue === 'number') {
      selectValue = options?.find((option) => String(option.value) === String(selectValue))
    }
    // Set option object as default value
    if (selectDefaultValue && (typeof selectDefaultValue === 'string' || typeof selectValue === 'number')) {
      selectDefaultValue = options?.find((option) => String(option.value) === String(selectValue))
    }
    const fieldContainerClassNames = classNames('field elo-select-container', containerClassName, {
      'field--disabled': disabled,
      'elo-select-container__required': showRequired,
      'field--required': required,
    })
    const fieldClassNames = classNames('elo-select-field', className, {
      searchable,
      error: showIsRequiredMessage || !valid,
    })

    const tooltipTitles = {
      buyer_list_id: I18n.t('react.cabinet.help_icon.buyer_list_id.title'),
      buyer_campaign_id: I18n.t('react.cabinet.help_icon.buyer_campaign_id.title'),
    }

    const tooltipContent = {
      cashout_currency_select: I18n.t('react.cabinet.help_icon.cashout_currency_select.content'),
      fontWeight: I18n.t('react.cabinet.help_icon.fontWeight.content'),
      fontStyle: I18n.t('react.cabinet.help_icon.fontStyle.content'),
      fontDisplay: I18n.t('react.cabinet.help_icon.fontDisplay.content'),
      fallbackFont: I18n.t('react.cabinet.help_icon.fallbackFont.content'),
      buyer_list_id: I18n.t('react.cabinet.help_icon.buyer_list_id.content'),
      buyer_campaign_id: I18n.t('react.cabinet.help_icon.buyer_campaign_id.content'),
      order_bumps_border_animation: I18n.t('react.cabinet.help_icon.order_bumps_border_animation.content'),
      order_bumps_price_animation: I18n.t('react.cabinet.help_icon.order_bumps_price_animation.content'),
      buy_button_content: I18n.t('react.cabinet.help_icon.buy_button_content.content'),
      buy_button_position: I18n.t('react.cabinet.help_icon.buy_button_position.content'),
      payment_page_template: I18n.t('react.cabinet.help_icon.payment_page_template.content'),
      select_vat: I18n.t('react.cabinet.help_icon.select_vat.content'),
      csv_scheduling_frequency: I18n.t('react.cabinet.help_icon.csv_scheduling_frequency.content'),
      csv_scheduling_day: I18n.t('react.cabinet.help_icon.csv_scheduling_day.content'),
      csv_saved_columns: I18n.t('react.cabinet.help_icon.csv_saved_columns.content'),
      csv_decimal_separator: I18n.t('react.cabinet.help_icon.csv_decimal_separator.content'),
    }

    const showInfoToolTip = tooltipId && (tooltipTitle || tooltipBody)

    const tooltipTitleFromId = tooltipTitle && tooltipId && tooltipTitles[tooltipId]
    const tooltipBodyFromId = tooltipBody && tooltipId && tooltipContent[tooltipId]

    return (
      <div className={fieldContainerClassNames} data-testid={dataTestId}>
        {label && (
          <label className='field__label' htmlFor={name}>
            <span>{label}</span>
            {showInfoToolTip && (
              <InfoTooltip
                id={`${useSnakeCaseName || name}_popover`}
                title={tooltipTitleFromId}
                body={tooltipBodyFromId}
              />
            )}
          </label>
        )}

        {!valid && errorMessage && <FieldError id={errorId} errorText={errorMessage} />}

        {showIsRequiredMessage && (
          <div className='field__error'>
            {requiredValidationMessage}
            <i className='fa fa-exclamation-circle' />
          </div>
        )}
        <Select
          className={fieldClassNames}
          defaultValue={selectDefaultValue}
          isDisabled={disabled}
          isSearchable={searchable}
          onChange={this.onFieldChange}
          value={notSaveValue ? value : selectValue || ''}
          noOptionsMessage={() => noOptionsMessage}
          inputId={inputTestId}
          instanceId={instanceId || `${name || 'name'}-react-select`}
          {...{
            classNamePrefix,
            components,
            filterOption,
            formatOptionLabel,
            isClearable,
            isMulti,
            menuPlacement,
            options,
            placeholder,
            valueKey,
          }}
        />
      </div>
    )
  }
}

export const SelectField = (props) => {
  const I18n = useI18n()

  return <SelectFieldContainer I18n={I18n} {...props} />
}
