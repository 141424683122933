import React from 'react'
import { components } from 'react-select'

const ImageTextSubtitleMultiValue = (props) => (
  <components.MultiValue {...props} className='select-multi-value-subtitle' />
)

const ImageTextSubtitleMultiValueLabel = (props) => (
  <components.MultiValueLabel {...props}>
    <div className='elo-react-select__option--wrapper'>
      {props.data.avatar ? (
        <img src={props.data.avatar} className='elo-react-select__option--avatar' />
      ) : (
        <span className='elo-react-select__option--avatar'>{props.data.title.slice(0, 1)}</span>
      )}
      <div>
        <div className='elo-react-select__option--title'>{props.data.title}</div>
      </div>
    </div>
  </components.MultiValueLabel>
)

export { ImageTextSubtitleMultiValueLabel, ImageTextSubtitleMultiValue }
