import React, { Component } from 'react'
import { render } from 'react-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { action, observable } from 'mobx'
import withStyles from 'react-jss'

import { isEmpty } from '@elo-kit/utils/validators.utils'
import { getArrayItemById, randomWithMinMax } from '@elo-kit/utils/helpers.utils'
import { unmountDomElement } from '@elo-kit/utils/dom.utils'
import { ELOPAGE_CABINETS } from '@elo-kit/constants/general.constants'
import { VideoCode } from '@elo-kit/components/page-builder/blocks/video/video-code/VideoCode'
import { useI18n } from '@elo-kit/components/i18n/i18n'
import VideoRendering from '../video-rendering'
import wistiaEmbedStyles from './WistiaEmbed.styles'

const MIN_VIDEO_CODE = 1000
const MAX_VIDEO_CODE = 9999

const ErrorBlock = ({ classes }) => {
  const I18n = useI18n()
  return (
    <div className={classes.wistiaEmbedErrorContainer}>
      <h4 className={classes.wistiaEmbedErrorTitle}>{I18n.t('react.cabinet.digitals.error_title')}</h4>
      <p
        className={classes.wistiaEmbedErrordescription}
        dangerouslySetInnerHTML={{ __html: I18n.t('react.cabinet.digitals.error_description') }}
      />
    </div>
  )
}

const ErrorBlockWithStyles = withStyles(wistiaEmbedStyles)(ErrorBlock)
@observer
class WistiaEmbedContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      videoIsLoading: false,
      videoLoadingError: false,
      codeBottomOffset: 0,
      lessonStatusId: '',
    }
  }

  @observable showCode = false

  @action setCode = (codeValue) => (this.showCode = codeValue)

  componentDidMount() {
    const { block = {}, hashedId, activeLessonStatusId } = this.props
    const { codes = [] } = block.content || {}

    const script = document.createElement('script')
    const script1 = document.createElement('script')
    script.onerror = () => {
      this.setState({
        videoLoadingError: true,
      })
    }
    script1.onerror = () => {
      this.setState({
        videoLoadingError: true,
      })
    }
    script1.src = `https://fast.wistia.com/embed/medias/${hashedId}.jsonp`
    script1.setAttribute('id', `wistia-video-script-${hashedId}`)
    script1.async = true

    script.setAttribute('id', 'wistia-video-script')
    script.src = 'https://fast.wistia.com/assets/external/E-v1.js'
    script.async = true

    const wistiaVideoScript = document.getElementById('wistia-video-script')
    const wistiaVideoScript1 = document.getElementById(`wistia-video-script-${hashedId}`)

    if (!wistiaVideoScript) {
      document.body.appendChild(script)
    }

    if (!wistiaVideoScript1) {
      document.body.appendChild(script1)
    }

    this.setVideoIsLoading(true)

    if (codes && codes.length) {
      this.handleVideoCaptionsFetch()
    }

    // eslint-disable-next-line
    window._wq = window._wq || []

    this.setVideoSettings()

    this.setState({
      lessonStatusId: activeLessonStatusId,
    })
  }

  componentDidUpdate(prevProps) {
    const { block = {}, customThumbnail } = this.props
    const { codes = [], playbarDisabled } = block.content || {}
    const { codes: prevCodes, playbarDisabled: prevPlaybarDisabled } = (prevProps.block || {}).content || {}

    if (
      (prevCodes || []).length !== codes.length ||
      prevPlaybarDisabled !== playbarDisabled ||
      customThumbnail !== prevProps.customThumbnail
    ) {
      this.setVideoSettings()
    }
  }

  componentWillUnmount() {
    const { block = {}, deleteBlockVideoCodes, hideVideoCodes } = this.props
    const { codes = [] } = block.content || {}

    if (deleteBlockVideoCodes) {
      deleteBlockVideoCodes(block.id)
    }

    if (!hideVideoCodes && codes && !!codes.length) {
      this.removeCodesContainers()
    }

    const wistiaVideoScript = document.getElementById('wistia-video-script')

    unmountDomElement(wistiaVideoScript)
  }

  removeCodesContainers = (videoContainerId, codeContainerId) => {
    const videoContainer = document.getElementById(videoContainerId)
    const codesContainerForDelete = videoContainer
      ? videoContainer.getElementsByClassName('video-code-container')
      : document.getElementsByClassName('video-code-container')

    const codesContainersList = Array.from(codesContainerForDelete)

    codesContainersList.forEach((item) => {
      if (item.id !== codeContainerId) {
        unmountDomElement(item)
      }
    })
  }

  setVideoSettings = () => {
    const { block = {}, hideVideoCodes, view, customThumbnail, digitalId, hashedId, userEmail } = this.props

    const { codes = [], playbarDisabled } = block.content || {}
    const { setShowCode, setCodeBottomOffset } = this
    const showVideoCodes = !hideVideoCodes && codes && !!codes.length

    // eslint-disable-next-line
    window?._wq?.push({
      id: hashedId,
      options: {
        copyLinkAndThumbnailEnabled: false,
        email: view === ELOPAGE_CABINETS.payer ? userEmail : '',
        stillUrl: customThumbnail, // Make sure the image is the same aspect ratio and ideally full-resolution
        fakeFullscreen: showVideoCodes,
        playbar: !playbarDisabled,
      },
      onReady: (video) => {
        this.setVideoIsLoading(false)

        if (digitalId) {
          video.bind('play', () => {
            this.markDigitalAsNotSynced(digitalId)
          })
        }

        if (showVideoCodes) {
          const codeContainerId = `code-container-${block.id}`
          this.removeCodesContainers(video?.params?.container, codeContainerId)

          let codesContainer = document.getElementById(codeContainerId)

          if (!codesContainer) {
            codesContainer = document.createElement('div')
            codesContainer.setAttribute('id', `code-container-${block.id}`)
            codesContainer.setAttribute('class', 'video-code-container')
            video.chrome.appendChild(codesContainer)
          }

          codes.forEach((code) => {
            let codeFrom = Number(code.from)
            let codeTill = Number(code.till)
            const duration = Number(code.duration)
            if (codeTill - codeFrom !== duration) {
              codeFrom = randomWithMinMax(codeFrom, codeTill - duration)
              codeTill = codeFrom + duration
            }

            video.bind('betweentimes', codeFrom, codeTill, (withinInterval) => {
              const { videoHeight, controlBarHeight } = video?.controls?.bigPlayButton?.props || {}

              if (withinInterval) {
                setCodeBottomOffset(videoHeight, controlBarHeight)
                setShowCode(code.id, codesContainer)
              } else {
                setShowCode(false, codesContainer)
              }
            })

            video.bind('heightchange', () => {
              const { videoHeight, controlBarHeight } = video?.controls?.bigPlayButton?.props || {}

              setCodeBottomOffset(videoHeight, controlBarHeight)
            })
          })
        }
      },
    })
  }

  setVideoIsLoading = (value) => {
    this.setState({
      videoIsLoading: value,
    })
  }

  setShowCode = (codeId, codesContainer) => {
    const {
      block = {},
      previewMode,
      setVideoCode,
      getVideoCodeData,
      view,
      createVideoCode,
      activeLessonStatusId,
      minCode,
      maxCode,
    } = this.props

    const { lessonStatusId } = this.state

    this.setCode(codeId)

    const videoCode = getVideoCodeData(block.id, codeId)

    if (codeId && (isEmpty(videoCode) || !videoCode.code)) {
      const randomCode = randomWithMinMax(minCode, maxCode)

      setVideoCode(block.id, codeId, {
        code: randomCode,
        passed: false,
      })

      const isSelectedLesson = lessonStatusId === activeLessonStatusId

      if (!previewMode && view === ELOPAGE_CABINETS.payer && isSelectedLesson) {
        createVideoCode({
          contentBlockId: block.id,
          value: randomCode,
          contentBlockCaptionId: codeId,
        }).then(({ success }) => {
          if (success) {
            this.handleVideoCaptionsFetch()
          }
        })
      }
    }

    if (!previewMode) {
      this.appendVideoCode(codesContainer)
    }
  }

  appendVideoCode = (codesContainer) => {
    const { block = {}, getVideoCodeData, hideVideoCodes } = this.props
    const { codes } = block.content || {}
    const { codeBottomOffset } = this.state

    const codeContent = this.showCode && getArrayItemById(codes, this.showCode)
    const codeValue = this.showCode && getVideoCodeData(block.id, this.showCode)
    const shouldShowCode = this.showCode && !hideVideoCodes

    const codeElement = (
      <VideoCode
        content={{
          ...codeContent,
          bottomOffset: codeBottomOffset,
        }}
        code={codeValue?.code}
      />
    )

    if (codesContainer) {
      render(shouldShowCode ? codeElement : '', codesContainer)
    }
  }

  handleVideoCaptionsFetch = () => {
    const { block = {}, fetchVideoCodes, view } = this.props

    const { codes = [] } = block.content || {}

    if (view === ELOPAGE_CABINETS.payer || view === ELOPAGE_CABINETS.cabinet) {
      fetchVideoCodes({ contentBlockId: block.id }, codes)
    }
  }

  setCodeBottomOffset = (videoHeight, controlsHeight) => {
    this.setState({
      codeBottomOffset: controlsHeight / (videoHeight / 100),
    })
  }

  markDigitalAsNotSynced = (id) => {
    const { POST } = this.props

    POST(`/cabinet/digitals/${id}/mark_as_not_synced`)
  }

  render() {
    const {
      block = {},
      hashedId,
      view,
      previewMode,
      isPreview,
      hideVideoCodes,
      userEmail,
      locales = {
        videoRenderingDefaultText: this.props.I18n.t('react.cabinet.digitals.video_rendering_warning'),
      },
    } = this.props

    const { videoIsLoading, codeBottomOffset, videoLoadingError } = this.state
    const { codes = [], playerColor } = block?.content || {}

    const videoClasses = classNames(
      `wistia_embed wistia_async_${hashedId} videoFoam=true playerColor=${playerColor || 'fdb02a'}`,
      {
        [`email=${userEmail}`]: view === ELOPAGE_CABINETS.payer,
      }
    )

    return (
      <div
        className='wistia_responsive_padding'
        style={{
          position: 'relative',
        }}
      >
        <div
          className='wistia_responsive_wrapper'
          style={{
            height: '100%',
            left: 0,
            width: '100%',
          }}
        >
          <div
            className={videoClasses}
            style={{
              height: '100%',
              width: '100%',
            }}
          >
            &nbsp;
          </div>

          {previewMode && !hideVideoCodes && (
            <VideoCode
              content={{
                ...(codes[0] || {}),
                bottomOffset: codeBottomOffset,
              }}
              previewMode
            />
          )}
        </div>

        {isPreview && videoIsLoading && (
          <VideoRendering videoRenderingDefaultText={locales.videoRenderingDefaultText} />
        )}
        {videoLoadingError && <ErrorBlockWithStyles />}
      </div>
    )
  }
}

WistiaEmbedContainer.propTypes = {
  /** Digital ID */
  digitalId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Custom Thumbnail URL */
  customThumbnail: PropTypes.string,
  /** Active Lesson Status ID */
  activeLessonStatusId: PropTypes.string,
  /** Content block object */
  block: PropTypes.object,
  /** Elopage Cabinet view */
  view: PropTypes.string,
  /** Indicates preview type */
  isPreview: PropTypes.bool,
  /** Indicates preview mode */
  previewMode: PropTypes.bool,
  /** Hides video codes from the video */
  hideVideoCodes: PropTypes.bool,
  /** Method for the POST call */
  POST: PropTypes.func,
  /** Deletes video codes block */
  deleteBlockVideoCodes: PropTypes.func,
  /** Method for fetching video codes */
  fetchVideoCodes: PropTypes.func,
  /** Returns video code data */
  getVideoCodeData: PropTypes.func,
  /** Sets video code */
  setVideoCode: PropTypes.func,
  /** Creates video code */
  createVideoCode: PropTypes.func,
  /** Translated Locales  */
  locales: PropTypes.object,
  /** User's email */
  userEmail: PropTypes.string,
  /** Hashed Video Id */
  hashedId: PropTypes.string,
  /** Minimum video code value */
  minCode: PropTypes.number,
  /** Maximum video code value */
  maxCode: PropTypes.number,
}

WistiaEmbedContainer.defaultProps = {
  POST: () => {},
  setVideoCode: () => {},
  fetchVideoCodes: () => {},
  createVideoCode: () => {},
  deleteBlockVideoCodes: () => {},
  minCode: MIN_VIDEO_CODE,
  maxCode: MAX_VIDEO_CODE,
  isPreview: false,
  userEmail: '',
  I18n: {
    t: () => {},
  },
}

const WistiaEmbed = (props) => {
  const I18n = useI18n()

  return <WistiaEmbedContainer I18n={I18n} {...props} />
}

export { WistiaEmbedContainer }
export default WistiaEmbed
