import React, { Component } from 'react'
import { observer } from 'mobx-react'
import Datetime from 'react-datetime'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'

import { FieldError } from '@elo-kit/components/elo-ui/field-error/FieldError'
import { useI18n } from '@elo-kit/components/i18n/i18n'
import { DATE_FORMATS } from '@elo-kit/constants/dateTime.constants'

const propTypes = {
  className: PropTypes.string,
  dateFormat: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  formatToGet: PropTypes.string,
  shouldFormatDate: PropTypes.bool,
}

const defaultProps = {
  dateFormat: DATE_FORMATS.DDMMYYYY,
  disabled: false,
  shouldFormatDate: true,
}

@observer
class DateTimeFieldContainer extends Component {
  onDateChange = (pickerData, validator) => {
    const { displayTimeZone, formatToGet, onChange, shouldFormatDate } = this.props

    const isMomentDate = moment.isMoment(pickerData)
    const newDate = isMomentDate ? pickerData.format(formatToGet) : ''

    const formattedDateValue = shouldFormatDate ? newDate : pickerData
    const pickerDateString = isMomentDate ? pickerData.toISOString() : pickerData
    const fieldValue = displayTimeZone ? pickerDateString : formattedDateValue

    if (isMomentDate) {
      const isValid = validator ? validator(pickerData) : true

      if (isValid) {
        onChange(fieldValue)
      }
    } else if (pickerData == '') {
      onChange(fieldValue)
    }
  }

  render() {
    const {
      autoComplete,
      className,
      dateFormat,
      disabled,
      errors,
      isInvalid,
      isValidDate,
      locale,
      label,
      name,
      onChange,
      value,
      placeholder,
      isInTable,
      isTransparent,
      extendClass,
      shouldFormatDate,
      errorId,
      showErrorBorder,
      required,
      I18n,
      ...props
    } = this.props

    const fieldClassNames = classNames(
      'field date-time-field',
      {
        'field--disabled': disabled,
        'field--required': required,
      },
      className
    )

    const fieldControlClassNames = classNames('field__control date-time-field__control', {
      'date-time-field__control--error': isInvalid,
      'date-time-field__table--modal': isInTable,
      'date-time-field__control-transparent': isTransparent,
    })

    const formattedValue = moment(value, moment.ISO_8601)
    const inputValue = shouldFormatDate || moment.isMoment(value) ? formattedValue : value

    return (
      <div className={fieldClassNames}>
        {label && (
          <label className='field__label' htmlFor={name}>
            <span>{label}</span>
          </label>
        )}

        {isInvalid && !isInTable && <FieldError id={errorId} errorText={errors[name]} />}

        <div className='date-time-field__control-wrapper'>
          <Datetime
            closeOnSelect
            defaultValue={inputValue}
            value={inputValue}
            inputProps={{
              disabled,
              className: fieldControlClassNames,
              name,
              placeholder,
              autoComplete,
            }}
            locale={I18n.locale}
            onChange={(date) => this.onDateChange(date, isValidDate)}
            type='date'
            timeFormat={false}
            {...{
              dateFormat,
              name,
              isValidDate,
            }}
            className={extendClass}
            {...props}
          />
          {!isInTable && <i className='far fa-calendar-alt' />}
        </div>
      </div>
    )
  }
}

DateTimeFieldContainer.displayName = 'DateTimeField'
DateTimeFieldContainer.propTypes = propTypes
DateTimeFieldContainer.defaultProps = defaultProps

export const DateTimeField = (props) => {
  const I18n = useI18n()

  return <DateTimeFieldContainer I18n={I18n} {...props} />
}
