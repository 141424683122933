import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { PRODUCT_TYPE_IDS } from '@elo-kit/constants/products.constants'
import { createId } from '@elo-kit/utils/general.utils'
import { THEME_FORMS } from '@elo-kit/constants/themes.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { getDigitalIcon } from '@elo-kit/utils/block.utils'
import { FormattedTicketDates } from '@elo-kit/components/formatters/formatted-ticket-dates/FormattedTicketDates'
import { useI18n } from '@elo-kit/components/i18n/i18n'
import { LessonsTree } from '@elo-kit/components/page-builder/blocks/product/lessons-tree/LessonsTree'
import { cleanupHTMLStyleFonts } from 'utils/fonts.utils'

/**
 * Product Description - product's description
 */
export const ProductDescription = (props) => {
  const { description, terms } = props

  return (
    <>
      <div className='description fr-view' dangerouslySetInnerHTML={{ __html: cleanupHTMLStyleFonts(description) }} />
      <div className='terms fr-view mt-3' dangerouslySetInnerHTML={{ __html: cleanupHTMLStyleFonts(terms) }} />
      <div className='horizontal-separator' />
    </>
  )
}

ProductDescription.propTypes = {
  /**
   * Product's description
   */
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Product's terms
   */
  terms: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

ProductDescription.defaultProps = {
  description: '',
  terms: '',
}

/**
 * ProductGood - product's good
 */
export const ProductGood = (props) => {
  const { good, audioPlayer } = props
  const audioRef = useRef(null)

  useEffect(() => {
    audioPlayer?.init(audioRef.current)
  }, [audioRef])

  const handleClick = /* istanbul ignore next */ (e) => {
    if (e.currentTarget.dataset.type === 'playable') {
      e.preventDefault()
    }
  }

  return (
    <a
      ref={audioRef}
      className={`item good download-details ${good.digital.audio ? 'audio-player' : ''}`}
      data-type={good.digital.audio || good.digital.video ? 'playable' : 'default'}
      data-id={good.id}
      onClick={handleClick}
      data-style={good.style}
      data-duration-full={good.digital.duration && good.digital.duration.full}
      data-duration-demo={good.digital.duration && good.digital.duration.demo}
      data-src-full={good.digital.file && good.digital.file.full}
      data-src-demo={good.digital.file && good.digital.file.demo}
      data-param-full={good.digital.param && good.digital.param.full}
      data-param-demo={good.digital.param && good.digital.param.demo}
      data-speed={1.0}
    >
      <div className={`icon ${!good.digital.image ? 'no-image' : ''}`}>
        {good.digital.audio ? (
          <>
            <div className='fas audio-button'>
              <audio className='audio-play' preload='none'>
                <source src={good.digital.fileUrl} type='audio/mp3' />
              </audio>
            </div>
            <div className='duration'>00:00</div>
          </>
        ) : (
          <div
            className={good.digital.image ? 'target-preview file-item-preview' : 'file-item-preview'}
            data-good-id={good.id}
            data-good-name={good.digital.name || good.digital.fileName}
            data-good-url={good.digital.fileUrl}
            dangerouslySetInnerHTML={{ __html: getDigitalIcon(good.digital) }}
          />
        )}
      </div>

      <div className='col multiline name progressbar' title={good.name}>
        {good.digital.name || good.name}
      </div>
    </a>
  )
}

ProductGood.propTypes = {
  /**
   * Good's object
   */
  good: PropTypes.object,
}

ProductGood.defaultProps = {
  good: {
    data: {},
    digital: {},
  },
}

/**
 * ProductGoods - product's goods
 */
export const ProductGoods = (props) => {
  const { product, locales, previewMode, AudioPlayer } = props

  return (
    <>
      <div
        className='download-title'
        {...(previewMode && {
          'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.product.overviewAboutFiles,
          'data-highlighter-selector': '',
        })}
      >
        {locales.downloadTitle}
      </div>
      <div
        className='goods list shop'
        data-product-id={product.id}
        {...(previewMode && {
          'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.product.overviewAboutFiles,
          'data-highlighter-selector': '',
        })}
      >
        {product.goods.map((good, index) => (
          <ProductGood
            key={createId(product.id, index)}
            audioPlayer={AudioPlayer}
            productSlug={product.slug}
            good={good}
          />
        ))}
      </div>
    </>
  )
}

ProductGoods.propTypes = {
  /**
   * Audio Player
   */
  AudioPlayer: PropTypes.object,
  /**
   * Locales Object
   */
  locales: PropTypes.shape({
    downloadTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }),
  /**
   * Product's object
   */
  product: PropTypes.object,
  /** Indicates whether it's Page Builder preview of component */
  previewMode: PropTypes.bool,
}

ProductGoods.defaultProps = {
  locales: {
    downloadTitle: 'Overview about the files this product contains',
  },
  product: {
    goods: [],
  },
}

/**
 * ProductTabs - product's tabs
 */
export const ProductTabs = (props) => {
  const { lessons, locales, product, username, isAppActive, getByKey, courseItem, previewMode } = props

  const { prefs = {}, form: courseThemeForm } = courseItem

  const { lesson: { unfoldedMenus } = {} } = prefs

  const { is_preview: isPreview } = getByKey() || {}

  const { description, courseMenuUnfold, slug, terms, courseThemeId } = product

  const menuUnfold = courseThemeId && courseThemeForm !== THEME_FORMS.default ? unfoldedMenus : courseMenuUnfold

  return (
    <div className={classNames('course-content')}>
      <ProductDescription
        {...{
          description,
          terms,
        }}
      />

      <div
        className='lessons-list'
        {...(previewMode && {
          'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.product.coursePlan,
          'data-highlighter-selector': '',
        })}
      >
        <h4 className='title mb-4'>{locales.coursePlanTitle}</h4>
        <div className='list'>
          {lessons && lessons.length > 0 && (
            <LessonsTree
              {...{
                lessons,
                menuUnfold,
                slug,
                username,
                isPreview,
                isAppActive,
                previewButtonTitle: locales.lessonPreviewButtonTitle,
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

ProductTabs.propTypes = {
  /**
   * Function that checks whether app is active
   */
  isAppActive: PropTypes.func,
  /**
   * Thats gets value from window.Hashovka
   */
  getByKey: PropTypes.func,
  /**
   * Course item from courseThemesStore
   */
  courseItem: PropTypes.shape({
    prefs: PropTypes.object,
    form: PropTypes.string,
  }),
  /**
   * Locales Object
   */
  locales: PropTypes.shape({
    coursePlanTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    downloadTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    lessonPreviewButtonTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }),
  /**
   * Product's lessons
   */
  lessons: PropTypes.array,
  /**
   * Product's object
   */
  product: PropTypes.object,
  /**
   * Seller's username
   */
  username: PropTypes.string,
  /** Indicates whether it's Page Builder preview of component */
  previewMode: PropTypes.bool,
}

ProductTabs.defaultProps = {
  product: {},
  courseItem: {},
  getByKey: () => {},
  locales: {
    downloadTitle: 'Overview about the files this product contains',
    coursePlanTitle: 'Course plan',
    lessonPreviewButtonTitle: 'Preview',
  },
  lessons: [],
}

/**
 * Main Content - product's main content component
 */
export const MainContent = (props) => {
  const I18n = useI18n()
  const {
    product,
    username,
    content,
    lessons,
    countries,
    getFormattedTicketLocation,
    AudioPlayer,
    isAppActive,
    getByKey,
    courseItem,
    previewMode,
    locales = {
      availableTickets: I18n.t('react.shared.available_tickets'),
      coursePlanTitle: I18n.t('react.shared.course_plan'),
      downloadTitle: I18n.t('react.shared.download_files_list'),
      lessonPreviewButtonTitle: I18n.t('react.shared.preview'),
    },
    hideTickets,
  } = props

  const isCoursePreview = product.form === PRODUCT_TYPE_IDS.course && content.coursePreview
  const isDownloadPreview = product.form === PRODUCT_TYPE_IDS.download && content.downloadPreview
  const isTicketsPreview = product.form === PRODUCT_TYPE_IDS.eventTickets && content.ticketsPreview

  return (
    <div className='main-content' id='main-product-content'>
      {isCoursePreview ? (
        <ProductTabs
          locales={locales}
          product={product}
          username={username}
          lessons={lessons}
          isAppActive={isAppActive}
          getByKey={getByKey}
          courseItem={courseItem}
          previewMode={previewMode}
        />
      ) : (
        <div className='description-cont'>
          <ProductDescription description={product.description} terms={product.terms} />

          {isTicketsPreview && !hideTickets && (
            <>
              <div
                className='tickets-title'
                {...(previewMode && {
                  'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.product.availableTickets,
                  'data-highlighter-selector': '',
                })}
              >
                {locales.availableTickets}
              </div>
              <div
                className={classNames('tickets-details')}
                {...(previewMode && {
                  'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.product.availableTickets,
                  'data-highlighter-selector': '',
                })}
              >
                {product.tickets.map((ticket) =>
                  ticket.ticketDates.map((date, index) => (
                    <div
                      className='tickets-details-item fields-container fields-container--left-align'
                      key={createId(index)}
                    >
                      <div className='ticket-date'>
                        <FormattedTicketDates ticketDate={date} withAdditionalText />
                      </div>
                      <div className='fields-container__vertical-separator'>|</div>
                      <div className='ticket-location'>{getFormattedTicketLocation(ticket, countries)}</div>
                    </div>
                  ))
                )}
              </div>
            </>
          )}

          {isDownloadPreview && (
            <div
              className={classNames('goods-cont')}
              {...(previewMode && {
                'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.product.overviewAboutFiles,
                'data-highlighter-selector': '',
              })}
            >
              <ProductGoods locales={locales} product={product} AudioPlayer={AudioPlayer} previewMode={previewMode} />
            </div>
          )}
        </div>
      )}

      <div className='clearfix' />
    </div>
  )
}

MainContent.propTypes = {
  /**
   * Course item from courseThemesStore
   */
  courseItem: PropTypes.shape({
    prefs: PropTypes.object,
    form: PropTypes.string,
  }),
  /**
   * Function that checks whether app is active
   */
  isAppActive: PropTypes.func,
  /**
   * Thats gets value from window.Hashovka
   */
  getByKey: PropTypes.func,
  /**
   * Audio Player
   */
  AudioPlayer: PropTypes.object,
  /**
   * Countries List
   */
  countries: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  /**
   * Locales Object
   */
  locales: PropTypes.shape({
    availableTickets: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    coursePlanTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    downloadTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    lessonPreviewButtonTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }),
  /**
   * Product's lessons
   */
  lessons: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  /**
   * Product's object
   */
  product: PropTypes.object,
  /**
   * Product's content object
   */
  content: PropTypes.object,
  /**
   * Seller's username
   */
  username: PropTypes.string,
  /**
   * Function to format ticket location
   */
  getFormattedTicketLocation: PropTypes.func,
  /** Indicates whether it's Page Builder preview of component */
  previewMode: PropTypes.bool,
}

MainContent.defaultProps = {
  lessons: [],
  product: {},
}

export default MainContent
