import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import withStyles, { ThemeProvider } from 'react-jss'

import { EloArrowElbowDownRight } from '@elo-ui/components/icons/regular'
import { Ellipsis } from '@elo-kit/components/ellipsis/Ellipsis'

import lessonsTreeStyles from './LessonsTreeStyles'

/**
 * Category - main container for the Category
 */
class Category extends Component {
  constructor(...args) {
    super(...args)
    this.state = {
      isOpen: false,
    }
  }

  componentDidMount() {
    this.setCategoryOpen()
  }

  componentDidUpdate(prevProps) {
    const { menuUnfold } = this.props
    const { menuUnfold: prevMenuUnfold } = prevProps

    if (prevMenuUnfold !== menuUnfold) {
      this.setCategoryOpen()
    }
  }

  setCategoryOpen = () => {
    const { menuUnfold, lesson, isBooked } = this.props
    this.setState((prevstate) => ({
      ...prevstate,
      isOpen: menuUnfold || (lesson.children && isBooked && lesson.children.filter((item) => item.preview).length > 0),
    }))
  }

  toggleOpen = () =>
    this.setState((prevstate) => ({
      ...prevstate,
      isOpen: !prevstate.isOpen,
    }))

  render() {
    const { lesson, username, slug, menuUnfold, index, isBooked, previewButtonTitle } = this.props
    const { isOpen } = this.state
    const withChildren = lesson.children.length > 0
    const listItemClasses = classNames('heading list-group-item list-group-item-action', {
      'has-lessons': withChildren,
      'no-lessons': !withChildren,
    })
    const lessonsListClasses = classNames('lessons children list-group', {
      hidden: !isOpen,
    })

    return (
      <div className='category list-group'>
        <div className={listItemClasses}>
          <span className='lesson-name'>
            {lesson.children.length > 0 && (
              <i className={`fas fa-angle-${isOpen ? 'down' : 'right'} mr-2`} onClick={this.toggleOpen} />
            )}
            {lesson.name}
          </span>
        </div>
        {lesson.children.length > 0 && (
          <div className={lessonsListClasses}>
            {lesson.children.map((item, childIndex) => (
              <LessonPresenter
                lesson={item}
                key={`${index}.${childIndex + 1}`}
                index={`${index}.${childIndex + 1}`}
                username={username}
                slug={slug}
                menuUnfold={menuUnfold}
                isBooked={isBooked}
                previewButtonTitle={previewButtonTitle}
              />
            ))}
          </div>
        )}
      </div>
    )
  }
}

Category.displayName = 'Category'
Category.propTypes = {
  /**
   * Seller username
   */
  username: PropTypes.string,
  /**
   * Product slug
   */
  slug: PropTypes.string,
  /**
   * Lesson object
   */
  lesson: PropTypes.object,
  /**
   * Lesson number
   */
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Indicates that app is booked
   */
  isBooked: PropTypes.bool,
  /**
   * Indicates that menus unfolded by default
   */
  menuUnfold: PropTypes.bool,
  /**
   * Preview button text
   */
  previewButtonTitle: PropTypes.string,
}

/**
 * Lesson - main container for the Lesson
 */
const Lesson = ({ lesson, username, slug, isBooked, isPreview, previewButtonTitle }) => {
  // const isPreview = window.Hashovka.getByKey('search', 'is_preview') to add
  const preview = `/s/${username}/${slug}/preview?lesson_id=${lesson.id}${isPreview ? '&is_preview=true' : ''}`

  return (
    <>
      <div className='list-group-item list-group-item-action'>
        {lesson.preview && isBooked ? (
          <>
            <a href={preview} target='_blank' className='lesson has-preview'>
              <span className='lesson-name'>{lesson.name}</span>
            </a>
            <a href={preview} target='_blank' className='lesson has-preview ml-auto'>
              <div className='preview-button'>{previewButtonTitle}</div>
            </a>
          </>
        ) : (
          <span className='lesson-name'>{lesson.name}</span>
        )}
      </div>
      {lesson.quizEnabled && !lesson.static && (
        <div className='list-group-item'>
          <EloArrowElbowDownRight />
          <Ellipsis>{I18n.t('shared.common.quiz')}</Ellipsis>
        </div>
      )}
    </>
  )
}

Lesson.displayName = 'Lesson'
Lesson.propTypes = {
  /**
   * Seller username
   */
  username: PropTypes.string,
  /**
   * Product slug
   */
  slug: PropTypes.string,
  /**
   * Lesson object
   */
  lesson: PropTypes.object,
  /**
   * Indicates that app is booked
   */
  isBooked: PropTypes.bool,
  /**
   * Indicates preview mode
   */
  isPreview: PropTypes.bool,
  /**
   * Preview button text
   */
  previewButtonTitle: PropTypes.string,
}

/**
 * LessonPresenter - main container for the Lesson or Category
 */
const LessonPresenter = ({ menuUnfold, lesson, ...props }) =>
  lesson.isCategory ? (
    <Category
      {...{
        lesson,
        ...props,
        menuUnfold,
      }}
    />
  ) : (
    <Lesson
      {...{
        lesson,
        ...props,
      }}
    />
  )

LessonPresenter.displayName = 'LessonPresenter'
LessonPresenter.propTypes = {
  /**
   * Indicates that menus unfolded by default
   */
  menuUnfold: PropTypes.bool,
  /**
   * Lesson object
   */
  lesson: PropTypes.object,
}

/**
 * LessonsTree - main container for the Page Builder's LessonsTree Block
 */
export const LessonsTree = ({ lessons, isAppActive, ...props }) => (
  <div className='custom-shop-product-lessons-tree'>
    {isAppActive('lesson_preview')}
    {lessons.map((lesson, index) => (
      <LessonPresenter
        key={`${lesson.id}-${index + 1}`}
        index={index + 1}
        {...{
          ...props,
          lesson,
        }}
        isBooked={isAppActive('lesson_preview')}
      />
    ))}
  </div>
)

LessonsTree.displayName = 'LessonsTree'
LessonsTree.defaultProps = {
  lessons: [],
  isAppActive: () => {},
}
LessonsTree.propTypes = {
  /**
   * Lessons to render
   */
  lessons: PropTypes.array,
  /**
   * Indicates that menus unfolded by default
   */
  menuUnfold: PropTypes.bool,
  /**
   * Checking if app is booked
   */
  isAppActive: PropTypes.func,
  /**
   * Preview button text
   */
  previewButtonTitle: PropTypes.string,
}

const LessonsTreeWithContainer = ({ classes, ...props }) => (
  <div className={classes.productBottomContent}>
    <LessonsTree {...props} />
  </div>
)
LessonsTreeWithContainer.defaultProps = {
  classes: {},
}
LessonsTreeWithContainer.propTypes = {
  /**
   * Classes object
   */
  classes: PropTypes.object,
}

const LessonsTreeWithStyles = withStyles(lessonsTreeStyles)(LessonsTreeWithContainer)

export const LessonsTreeWithTheme = (props) => (
  <ThemeProvider theme={{}}>
    <LessonsTreeWithStyles {...props} />
  </ThemeProvider>
)
