import { cleanupFonts } from 'utils/fonts.utils'

import { PRODUCTS_LIST } from '../../../../constants/contentPage.constants'
import { COLORS } from '../../../../constants/general.constants'

import { hex2rgb } from '../../../../utils/themes.utils'
import { getFontStyles } from '../../../../utils/fonts.utils'

const { eloBlue, eloGrey50, eloWhite } = COLORS

const SELECT_FIELD_WIDTH = 270

// TODO: update with using createUseStyles
const categoriesDropdownStyles = (theme) => {
  const {
    categoryActiveColor,
    categoryBackground,
    categorySelectFieldContentBefore,
    [PRODUCTS_LIST.categoryColor]: categoryColor,
    [PRODUCTS_LIST.categoryFont]: categoryFont,
    [PRODUCTS_LIST.categorySize]: categorySize,
    [PRODUCTS_LIST.categoryStyle]: categoryStyle,
  } = theme

  const categoryFontFamily = cleanupFonts(categoryFont) || 'Montserrat Reg'
  const categoryFontSize = `${categorySize || 13}px`
  const categoryFontStyles = getFontStyles(categoryStyle || 'normal')

  const activeCategoryColor = categoryActiveColor || eloBlue
  const defaultCategoryColor = categoryColor || eloBlue

  const getBorderColor = (opacity) => hex2rgb(activeCategoryColor, opacity)

  return {
    dropdown: {
      '&.elo-select-container': {
        width: SELECT_FIELD_WIDTH,
        marginBottom: 0,
      },
      '&.elo-select-field': {
        width: SELECT_FIELD_WIDTH,
        color: defaultCategoryColor,
        fontSize: categoryFontSize,
        fontFamily: categoryFontFamily,
        ...categoryFontStyles,
        '& .elo-select-field__control': {
          minHeight: 40,
          padding: '2px 13px 0',
          backgroundColor: categoryBackground || eloWhite,
          borderColor: `${getBorderColor(0.3)} !important`,
          fontSize: categoryFontSize,
          fontFamily: categoryFontFamily,
          ...categoryFontStyles,
          '&:before': {
            content: `"${categorySelectFieldContentBefore}:"`,
            paddingRight: 10,
            color: eloGrey50,
          },
        },
        '& .elo-select-field__control--is-focused:hover': {
          borderColor: `${activeCategoryColor} !important`,
        },
        '& .elo-select-field__control--is-focused:not(:hover)': {
          borderColor: `${activeCategoryColor} !important`,
        },
        '& .elo-select-field__dropdown-indicator': {
          marginTop: '-6px',
          fontSize: 16,
          color: activeCategoryColor,
          paddingRight: 0,
        },
        '& .elo-select-field__dropdown-indicator--arrow-up-position': {
          paddingTop: 10,
        },
        '& .elo-select-field__option--is-focused': {
          backgroundColor: `${getBorderColor(0.2)}`,
          '&:active': {
            backgroundColor: `${getBorderColor(0.4)}`,
          },
        },
        '& .elo-select-field__option--is-selected': {
          backgroundColor: activeCategoryColor,
          color: '#ffff !important',
        },
        '& .elo-select-field__menu-notice': {
          color: defaultCategoryColor,
          fontSize: 13,
          fontStyle: 'italic',
        },
        '& .elo-select-field__input': {
          color: defaultCategoryColor,
        },
        '& .elo-select-field__option': {
          color: defaultCategoryColor,
          padding: '8px 28px',
        },
        '& .elo-select-field__single-value': {
          color: defaultCategoryColor,
          lineHeight: 1.8,
        },
        '& .elo-select-field__placeholder': {
          color: '#00000033',
        },
        '& .elo-select-field__menu': {
          zIndex: 2,
        },
        '& .elo-select-field__value-container': {
          padding: '0 !important',
        },
      },
    },
  }
}

export default categoriesDropdownStyles
