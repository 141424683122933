import React from 'react'

import { Back, Forward } from '../assets'

interface Props {
  audioSrc?: string
  audioStatus?: string
  audioButtonClass?: string
  buttonColor: string
  onPause?: React.ReactEventHandler<HTMLAudioElement>
  onPlay?: React.ReactEventHandler<HTMLAudioElement>
}

export const AudioPlayerButtons: React.FC<Props> = ({
  audioSrc,
  audioStatus,
  audioButtonClass,
  buttonColor,
  onPause = () => {},
  onPlay = () => {},
}) => (
  <>
    <i className='rewind-button' data-back='true'>
      <Back buttonColor={buttonColor} />
    </i>
    <i className={`fas audio-button ${audioButtonClass} ${audioStatus || 'pause'}`}>
      <audio onPause={onPause} onPlay={onPlay} className='audio-play' preload='metadata'>
        <source src={audioSrc} type='audio/mpeg' />
      </audio>
    </i>
    <i className='rewind-button' data-forward='true'>
      <Forward buttonColor={buttonColor} />
    </i>
  </>
)
