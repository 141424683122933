import React from 'react'
import { components } from 'react-select'
import { EloCheckboxIcon } from '@elo-ui/components/icons/regular'

const OptionWithActionsComponent = (props) => {
  const { Actions, ...rest } = props

  return !rest.isMulti || (rest.isMulti && !rest.isSelected) ? (
    <components.Option {...rest}>
      <div className='select-item'>
        <span>{rest.children}</span>
        {rest.isSelected && <EloCheckboxIcon />}
      </div>
      <Actions {...rest} />
    </components.Option>
  ) : null
}

export { OptionWithActionsComponent }
