import dynamic from 'next/dynamic'

const PdfReaderComponent = dynamic(() => import('./PdfReader').then((mod) => mod.PdfReaderComponent), {
  ssr: false,
})

import {
  useReaderStyles,
  PDF_CONTAINER_MAX_HEIGHT,
  PDF_TOOLBAR_HEIGHT,
  PDF_PAGINATOR_HEIGHT,
  PDF_PAGINATOR_WIDTH,
  PROGRESS_BAR_HEIGHT,
  PDF_MAX_BUTTON_SIZE,
  PDF_MAX_TEXT_SIZE,
  TOOLBAR_ITEMS_HEIGHT,
} from './PdfReader.styles'

export {
  PdfReaderComponent,
  useReaderStyles,
  PDF_CONTAINER_MAX_HEIGHT,
  PDF_TOOLBAR_HEIGHT,
  PDF_PAGINATOR_HEIGHT,
  PDF_PAGINATOR_WIDTH,
  PROGRESS_BAR_HEIGHT,
  PDF_MAX_BUTTON_SIZE,
  PDF_MAX_TEXT_SIZE,
  TOOLBAR_ITEMS_HEIGHT,
}
