import { buttonAnimations } from '@elo-kit/utils/animations.utils'
import { getFontStyles } from '@elo-kit/utils/fonts.utils'

import { BUTTON_STYLE, PRODUCTS_LIST, BLOCK_VIEW } from '@elo-kit/constants/contentPage.constants'
import { COLORS } from '@elo-kit/constants/general.constants'
import { cleanupFonts } from 'utils/fonts.utils'

const { eloBlack, eloBlue, eloWhite } = COLORS

const productListStyles = (theme) => ({
  hideBlock: { display: 'none' },

  productsListSection: {
    width: '100%',
    backgroundColor: theme[PRODUCTS_LIST.background] || eloWhite,
    minHeight: 200,
  },

  productsContainer: {
    maxWidth: 1140,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: '0 auto',
    paddingTop: 30,
  },

  productWidgetContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: 270,
    boxShadow: '0 6px 20px 0 #00000026',
    margin: '0 30px 60px',
    borderRadius: 6,

    '& .cover-widget__wrapper': {
      height: 'fit-content',
    },

    '& .cover-widget__no-image': {
      height: 270,
    },

    '& .cover-widget__image': {
      height: 270,
      backgroundSize: theme.imageCoverForm || 'cover',
    },

    '& a': {
      textDecoration: 'none',
    },

    '&:hover': {
      boxShadow: '0 6px 20px 0 #00000050',
      textDecoration: 'none',
    },
  },

  productWidgetContainerLink: {
    cursor: 'pointer',
  },

  productDetails: {
    height: '100%',
    backgroundColor: theme[PRODUCTS_LIST.productBackground] || eloWhite,
    borderRadius: '0px 0px 6px 6px',
    padding: '28px 20px',
    textAlign: 'center',
    flex: 1,
  },

  productTitle: {
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    textAlign: 'center',
    marginBottom: 15,
    width: '100%',
    fontSize: theme[PRODUCTS_LIST.productSize] ? `${theme[PRODUCTS_LIST.productSize]}px` : '18px',
    fontFamily: cleanupFonts(theme[PRODUCTS_LIST.productFont]) || 'Montserrat Medium',
    ...getFontStyles(theme[PRODUCTS_LIST.productStyle]),
    color: theme[PRODUCTS_LIST.productColor] || eloBlack,
    lineHeight: 1.2,

    '@media (max-width: 991px)': {
      marginBottom: 10,
    },

    '@media (max-width: 576px)': {
      fontSize: theme[PRODUCTS_LIST.productSize] > 26 ? 26 : theme[PRODUCTS_LIST.productSize],
    },
  },

  noCoverContainer: {
    fontSize: 100,
    color: eloWhite,
  },

  price: {
    fontFamily: cleanupFonts(theme[PRODUCTS_LIST.priceFont]) || 'Montserrat Medium',
    fontSize: `${theme[PRODUCTS_LIST.priceSize] || 20}px`,
    ...getFontStyles(theme[PRODUCTS_LIST.priceStyle || 'normal']),
    color: theme[PRODUCTS_LIST.priceColor] || eloWhite,

    '@media (max-width: 576px)': {
      fontSize: theme[PRODUCTS_LIST.priceSize] > 40 ? 40 : theme[PRODUCTS_LIST.priceSize],
    },
  },

  priceBlock: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 115,
    height: 42,
    backgroundColor: theme[PRODUCTS_LIST.priceBackground] || eloBlue,
    textAlign: 'center',
    zIndex: 1,
    top: 30,
    right: -15,
    borderRadius: `${theme[PRODUCTS_LIST.priceRadius] === undefined ? 4 : theme[PRODUCTS_LIST.priceRadius]}px`,

    '& > span': {
      marginTop: 2,
    },
  },
  paginationContainer: {
    backgroundColor: theme[PRODUCTS_LIST.background] || eloWhite,
    paddingTop: theme[PRODUCTS_LIST.view] === BLOCK_VIEW.list ? 30 : 10,
    paddingBottom: 30,
    marginTop: 0,
    justifyContent: 'center',
    '& .elo-pagination__page--active': {
      backgroundColor: `${theme[PRODUCTS_LIST.priceBackground] || eloBlue} !important`,
    },
    '& a.elo-pagination__link': {
      cursor: 'pointer',
      color: `${theme[PRODUCTS_LIST.priceBackground] || eloBlue}`,
      '&:hover': {
        opacity: 0.8,
      },
      '&--active': {
        color: `${theme[PRODUCTS_LIST.priceColor] || eloWhite} !important`,
      },
    },
    '& .elo-pagination__page:hover': {
      background: 'none',
    },
  },
  paginationContainerDisabled: {
    paddingBottom: 0,
  },

  button: {
    display: 'inline-block',
    padding: '6px 12px',
    textAlign: 'center',
    verticalAlign: 'middle',
    background: [
      theme.buttonViewStyle === BUTTON_STYLE.outlined ? 'none' : theme[PRODUCTS_LIST.btnColor] || eloBlue,
      '!important',
    ],
    border: `2px solid ${theme[PRODUCTS_LIST.btnColor] || eloBlue}`,
    borderRadius: `${theme.radius === undefined ? 4 : theme.radius}px`,
    color: [theme[PRODUCTS_LIST.btnTextColor] || eloWhite, '!important'],
    boxShadow: 'none',
    fontFamily: cleanupFonts(theme[PRODUCTS_LIST.buttonFont]) || 'Montserrat Reg',
    ...getFontStyles(theme[PRODUCTS_LIST.buttonStyle]),
    fontSize: `${theme[PRODUCTS_LIST.buttonSize] || 16}px`,
    wordBreak: 'break-word',
    width: '80%',

    '&:active': {
      opacity: 0.6,
    },
  },

  ...buttonAnimations(theme),
})

export default productListStyles
