import React, { Component } from 'react'
import { observable, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { InfoTooltip } from '@elo-kit/components/info-tooltip/InfoTooltip'
import { useI18n } from '@elo-kit/components/i18n/i18n'

import { isInteger } from 'utils/validators.utils'

import { NUMBER_FIELD_MIN, NUMBER_FIELD_MAX, NUMBER_FIELD_STEP } from 'constants/forms.constants'
import { COLORS } from '@elo-kit/constants/general.constants'

const { eloPrimary } = COLORS

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  tooltipId: PropTypes.string,
  isValid: PropTypes.bool,
  unit: PropTypes.string,
}

const defaultProps = {
  min: NUMBER_FIELD_MIN,
  max: NUMBER_FIELD_MAX,
  step: NUMBER_FIELD_STEP,
  disabled: false,
  isValid: true,
  unit: '%',
}

@observer
class RangeFieldContainer extends Component {
  @observable value = this.props.value
  @observable valid = true

  constructor(props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    const { value, id } = this.props
    const stylesTagId = `${id}-styles`

    if (!document.getElementById(stylesTagId)) {
      const styles = document.createElement('style')
      styles.id = stylesTagId
      document.body.appendChild(styles)
    }

    this.setBackground(value)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value != this.props.value) {
      this.value = this.props.value
      this.setBackground(this.value)
    }
  }

  increment = () => {
    const newValue = Number(this.value || 0) + this.props.step
    this.value = newValue
    this.props.onChange(this.value)
  }

  decrement = () => {
    const newValue = Number(this.value || 0) - this.props.step
    this.value = newValue
    this.props.onChange(this.value)
  }

  onFieldChange = (e) => {
    const { max, required, onChange } = this.props
    const newValue = Number(e.target.value)

    this.setBackground(newValue)

    switch (true) {
      case e.target.value === '':
        this.value = ''
        break
      case !isInteger(newValue):
        break
      case newValue > max:
        this.value = max
        break
      default:
        this.value = newValue
    }

    onChange(this.value)

    if (required && !this.value) {
      this.valid = false
    } else {
      this.valid = true
    }
  }

  // Validate min only on blur
  onFieldBlur = (e) => {
    const { min, onChange } = this.props
    const newValue = Number(e.target.value)

    if (newValue <= min) {
      this.value = min
    }

    onChange(this.value)
  }

  setBackground = (value) => {
    const { id, max, min } = this.props
    const stylesTagId = `${id}-styles`
    const prefs = ['webkit-slider-runnable-track', 'moz-range-track', 'ms-track']
    const styleSheet = document.getElementById(stylesTagId)
    const fillPercentage = ((value - min) / (max - min)) * 100
    let style = ''

    // Change rangeField background gradient for different browsers
    for (let i = 0; i < prefs.length; i += 1) {
      style += `#${id}.range-field__control-wrapper input::-${prefs[i]} {
        background: linear-gradient(to right, ${eloPrimary} 0%, ${eloPrimary} ${fillPercentage}%, #D7DADF ${fillPercentage}%, #D7DADF 100%);
        height: 8px;
        border-radius: 4px;
        border: none;
      }`
    }

    styleSheet.textContent = `${styleSheet.textContent} ${style}`
  }

  render() {
    const { name, disabled, min, max, tooltipId, id, className, label, placeholder, isValid, unit, I18n } = this.props

    const fieldClassNames = classNames('field range-field', { 'field--disabled': disabled }, className)

    const fieldControlClassNames = classNames('field__control range-field__control', {
      'range-field__control--error': !this.valid || !isValid,
    })

    const tooltipContent = {
      transparency: I18n.t('react.cabinet.help_icon.transparency.content'),
    }

    return (
      <div className={fieldClassNames}>
        {label && (
          <div className='field__label w-100'>
            {label}
            {tooltipId && <InfoTooltip id={`${tooltipId}_popover_${id}`} title='' body={tooltipContent[tooltipId]} />}
            <span className='ml-auto my-auto'>{`${this.value || 0}${unit}`}</span>
          </div>
        )}

        {!this.valid && (
          <div className='field__error'>
            {I18n.t('react.shared.validations.required')}
            <i className='fas fa-exclamation-circle' />
          </div>
        )}

        <div className='range-field__control-wrapper' id={id}>
          <input
            type='range'
            name={name}
            min={min}
            max={max}
            value={this.value}
            className={fieldControlClassNames}
            placeholder={placeholder}
            readOnly={disabled}
            onChange={this.onFieldChange}
          />
        </div>
      </div>
    )
  }
}

RangeFieldContainer.displayName = 'RangeField'
RangeFieldContainer.propTypes = propTypes
RangeFieldContainer.defaultProps = defaultProps

export const RangeField = (props) => {
  const I18n = useI18n()

  return <RangeFieldContainer I18n={I18n} {...props} />
}
