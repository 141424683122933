import { BUTTON_STYLE, POPULAR_PRODUCTS } from '@elo-kit/constants/contentPage.constants'
import { buttonAnimations } from '@elo-kit/utils/animations.utils'

import { COLORS } from '@elo-kit/constants/general.constants'

import { getFontStyle, getFontWeight, getFontStyles } from '@elo-kit/utils/fonts.utils'
import { cleanupFonts } from 'utils/fonts.utils'

const { eloBlack, eloBlue, eloWhite } = COLORS

// TODO: update with using createUseStyles
const popularProductsStyles = (theme) => ({
  hideBlock: { display: 'none' },
  backgroundImageContainer: {
    height: 270,
    borderRadius: '6px 6px 0 0',
    backgroundColor: '#efedef',
    color: eloWhite,
    fontSize: 85,
    width: '100%',
    display: 'flex',
    '& > div': {
      borderRadius: '6px 6px 0 0',
    },
  },
  productCover: {
    backgroundSize: 'cover',
    width: '100%',
    backgroundPosition: 'center',
  },
  productName: {
    marginBottom: '15px',
    wordBreak: 'break-word',
    fontFamily: cleanupFonts(theme[POPULAR_PRODUCTS.titleFont]) || 'Montserrat Medium',
    ...getFontStyles(theme[POPULAR_PRODUCTS.titleStyle]),
    color: theme[POPULAR_PRODUCTS.titleColor] || eloBlack,
    fontSize: `${theme[POPULAR_PRODUCTS.titleSize] || 20}px`,

    '@media (max-width: 576px)': {
      maxWidth: 335,
      fontSize: theme[POPULAR_PRODUCTS.titleSize] > 26 ? 26 : theme[POPULAR_PRODUCTS.titleSize],
    },
  },
  button: {
    padding: '6px 12px',
    textAlign: 'center',
    verticalAlign: 'middle',
    background: theme.buttonViewStyle === BUTTON_STYLE.outlined ? 'none' : theme.buttonColor || '#1e84d7',
    border: `2px solid ${theme.buttonColor || '#1e84d7'}`,
    borderRadius: `${theme.radius === undefined ? 4 : theme.radius}px`,
    color: [theme[POPULAR_PRODUCTS.buttonTextColor] || eloWhite, '!important'],
    fontFamily: cleanupFonts(theme[POPULAR_PRODUCTS.buttonFont]) || 'Montserrat Medium',
    ...getFontStyles(theme[POPULAR_PRODUCTS.buttonStyle]),
    fontSize: `${theme[POPULAR_PRODUCTS.buttonSize] || 16}px`,
    wordBreak: 'break-word',
    display: 'block',
    margin: '0 auto',

    '&:active': {
      opacity: 0.6,
    },
  },

  productsContainer: {
    maxWidth: 1140,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: '0 auto',
    paddingTop: 30,
  },
  productWidgetContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: 270,
    boxShadow: '0 6px 20px 0 #00000026',
    margin: '0 30px 60px',
    borderRadius: 6,

    '& .cover-widget__wrapper': {
      height: 'fit-content',
    },

    '& .cover-widget__no-image': {
      height: 270,
    },

    '& .cover-widget__image': {
      height: 270,
    },

    '& a': {
      textDecoration: 'none',
    },

    '&:hover': {
      boxShadow: '0 6px 20px 0 #00000050',
      textDecoration: 'none',
    },
  },
  productDetails: {
    height: '100%',
    backgroundColor: theme[POPULAR_PRODUCTS.productBackgroundColor] || eloWhite,
    borderRadius: '0px 0px 6px 6px',
    padding: '28px 20px',
    textAlign: 'center',
    flex: 1,
  },
  productTitle: {
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    textAlign: 'center',
    marginBottom: 15,
    width: '100%',
    fontSize: theme[POPULAR_PRODUCTS.productSize] ? `${theme[POPULAR_PRODUCTS.productSize]}px` : '18px',
    fontFamily: cleanupFonts(theme[POPULAR_PRODUCTS.productFont]) || 'Montserrat Medium',
    fontStyle: getFontStyle(theme[POPULAR_PRODUCTS.productStyle]),
    fontWeight: getFontWeight(theme[POPULAR_PRODUCTS.productStyle]),
    color: theme[POPULAR_PRODUCTS.productColor] || eloBlack,
    lineHeight: 1.2,

    '@media (max-width: 991px)': {
      marginBottom: 10,
    },
  },
  noCoverContainer: {
    fontSize: 100,
    color: eloWhite,
  },
  priceBlock: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 115,
    height: 42,
    backgroundColor: theme[POPULAR_PRODUCTS.priceBackground] || eloBlue,
    textAlign: 'center',
    zIndex: 1,
    top: 30,
    right: -15,
    borderRadius: `${theme[POPULAR_PRODUCTS.priceRadius] === undefined ? 4 : theme[POPULAR_PRODUCTS.priceRadius]}px`,

    '& > span': {
      marginTop: 2,
    },
  },
  gridButton: {
    width: '80%',
  },
  productBackground: {
    padding: '28px 20px',
    background: theme[POPULAR_PRODUCTS.productBackgroundColor] || eloWhite,
    borderRadius: '0 0 6px 6px',
  },
  slideItem: {
    width: 270,
    backgroundColor: eloWhite,
    transition: 'all .2s ease-in-out',
    borderRadius: 6,
    boxShadow: '0 6px 12px 0 rgb(0,0,0,0.15)',
  },
  slider: {
    height: '100%',
    maxWidth: 880,
    position: 'initial',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    textAlign: 'center',

    '& .elo-slider-container': {
      marginTop: 10,
    },

    '& .elo-slider-container > i': {
      color: [theme[POPULAR_PRODUCTS.arrowsColor] || eloWhite, '!important'],
      fontSize: 40,
      '&:hover': {
        opacity: 0.8,
      },
    },
  },

  // list-view classes
  list: {
    height: '100%',
    width: '100%',
    margin: '0 auto',
    position: 'relative',
    maxWidth: '1140px',
  },

  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '30px 15px',
    borderBottom: '1px solid #cdcdcd',

    '@media (max-width: 991px)': {
      flexWrap: 'wrap',
    },
  },

  content: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginRight: 50,

    '@media (max-width: 991px)': {
      width: 120,
      marginRight: 25,
    },
  },

  controlsContainer: {
    maxWidth: '1140px',
    width: '100%',
    margin: '0 auto 0',
    padding: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },

  listBackgroundImageContainer: {
    height: 120,
    borderRadius: 6,
    backgroundColor: '#efedef',
    color: eloWhite,
    fontSize: 50,
    width: 120,
    minWidth: 120,
    display: 'flex',
    boxShadow: '0 6px 20px 0 #00000026',
    marginRight: 50,
    position: 'relative',

    '& > div': {
      borderRadius: 6,
    },

    '@media (max-width: 991px)': {
      marginRight: 25,
      width: 120,
      minWidth: 120,
      height: 120,
    },
  },

  listProductTitle: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
    lineHeight: 1,
    marginBottom: 10,
  },

  listProductDetails: {
    width: '100%',
    marginRight: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',

    '&:hover': {
      textDecoration: 'none',
      opacity: 0.9,
      color: theme[POPULAR_PRODUCTS.productColor] || eloBlack,
    },

    '@media (max-width: 991px)': {
      marginRight: 0,
    },
  },

  payInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 230,
    maxWidth: 400,
    flexShrink: 0,
    justifyContent: 'center',

    '@media (max-width: 991px)': {
      width: '100%',
    },
  },

  listPriceContent: {
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  description: {
    fontSize: theme[POPULAR_PRODUCTS.descriptionSize] ? `${theme[POPULAR_PRODUCTS.descriptionSize]}px` : '14px',
    fontFamily: cleanupFonts(theme[POPULAR_PRODUCTS.descriptionFont]) || 'Montserrat Reg',
    ...getFontStyles(theme[POPULAR_PRODUCTS.descriptionStyle]),
    color: theme[POPULAR_PRODUCTS.descriptionColor] || eloBlack,
    wordBreak: 'break-word',
    display: 'block',

    '@media (max-width: 991px)': {
      display: 'none',
    },
  },

  currency: {
    fontSize: theme[POPULAR_PRODUCTS.currencySize] ? `${theme[POPULAR_PRODUCTS.currencySize]}px` : '30px',
    fontFamily: cleanupFonts(theme[POPULAR_PRODUCTS.currencyFont]) || 'Montserrat Medium',
    ...getFontStyles(theme[POPULAR_PRODUCTS.currencyStyle] || 'normal'),
    color: theme[POPULAR_PRODUCTS.currencyColor] || '#808080',
    paddingLeft: 6,
  },

  productDetailsContainer: {
    display: 'flex',
    width: '100%',
  },

  listPriceContainer: {
    display: 'block',
    lineHeight: 1.2,
    '@media (max-width: 991px)': {
      display: 'none',
    },
  },

  mobileListPriceContainer: {
    display: 'none',
    '@media (max-width: 991px)': {
      display: 'block',
      lineHeight: 1,
    },
  },

  oldPriceLine: {
    position: 'absolute',
    backgroundColor: '#ff4747',
    margin: '0 !important',
    top: 'calc(50% - 2px)',
    right: 0,
    left: 0,
  },

  oldPrice: {
    display: 'flex',

    '& div:first-child': {
      position: 'relative',
      padding: '0 3px',
      lineHeight: 0,

      '& span': {
        lineHeight: 1,
        fontSize: theme[POPULAR_PRODUCTS.oldPriceSize] ? `${theme[POPULAR_PRODUCTS.oldPriceSize]}px` : '14px',
        fontFamily: cleanupFonts(theme[POPULAR_PRODUCTS.oldPriceFont]) || 'Montserrat Reg',
        ...getFontStyles(theme[POPULAR_PRODUCTS.oldPriceStyle]),
        color: theme[POPULAR_PRODUCTS.oldPriceColor] || '#9e9e9e',
        paddingRight: 4,
      },
    },
  },

  oldPriceContainer: {
    display: 'flex',
    width: '100%',

    '@media (max-width: 991px)': {
      marginBottom: 5,
    },
  },

  listButton: {
    width: '100%',
    minHeight: 43,
    marginTop: 5,

    '@media (max-width: 991px)': {
      maxWidth: 335,
      marginTop: 20,
    },
  },

  price: {
    fontFamily: cleanupFonts(theme[POPULAR_PRODUCTS.priceFont]) || 'Montserrat Medium',
    fontSize: `${theme[POPULAR_PRODUCTS.priceSize] || 20}px`,
    ...getFontStyles(theme[POPULAR_PRODUCTS.priceStyle] || 'normal'),
    color: theme[POPULAR_PRODUCTS.priceColor] || eloWhite,
  },

  net: {
    fontFamily: 'Montserrat Reg',
    fontSize: 12,
    color: '#00000066',
    paddingLeft: 6,
  },

  ...buttonAnimations(theme),
})

export default popularProductsStyles
