import React from 'react'
import { components } from 'react-select'

const ImageMultiValueLabel = (props) => {
  if (props.data.Image) {
    const { Image } = props.data
    return (
      <components.MultiValueLabel {...props}>
        <Image />
      </components.MultiValueLabel>
    )
  }
}

export { ImageMultiValueLabel }
