import React from 'react'
import { components } from 'react-select'

const ImageTextSingleValue = (props) => (
  <components.SingleValue {...props}>
    <div className='elo-react-select__option--wrapper'>
      {props.data.avatar ? (
        <img src={props.data.avatar} className='elo-react-select__option--avatar' />
      ) : (
        <span className='elo-react-select__option--avatar'>{props.data.title.slice(0, 1)}</span>
      )}
      <div className='elo-react-select__option--title'>{props.data.title}</div>
    </div>
  </components.SingleValue>
)

export { ImageTextSingleValue }
