import React from 'react'
import withStyles from 'react-jss'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { PRODUCTS_LIST } from '@elo-kit/constants/contentPage.constants'
import { SELLER_PRODUCTS_LIST_CLASS_NAMES } from '@elo-kit/constants/customCss.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { FREE_PRICE } from 'constants/general.constants'
import { getElopageConfig } from 'utils/elopageConfig.utils'

import gridSellerProductsStyles from 'shared/components/content-page/preview/blocks/seller-products/GridSellerProducts.styles'
import { CoverWidget } from '@elo-kit/components/elo-ui/cover-widget'

/**
 * Grid seller product view
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const GridSellerProducts = (props) => {
  const {
    classes,
    convertToPrice,
    userName,
    previewMode,
    productsList,
    handleBuyProduct,
    content,
    historyPush,
    buyNowText,
    LinkComponent,
    productsContent,
  } = props

  return (
    <div className={classNames(SELLER_PRODUCTS_LIST_CLASS_NAMES.gridContainerClassName, classes.productsContainer)}>
      {productsList.map((product) => {
        const noCover = classNames(classes.noCoverContainer, 'far fa-image')
        const buttonClasses = classNames(classes.button, SELLER_PRODUCTS_LIST_CLASS_NAMES.gridButtonClassName, {
          [classes[content.buttonAnimation]]: !!content.buttonAnimation,
        })
        const widgetClasses = classNames(classes.productWidgetContainer, {
          [classes.productWidgetContainerLink]: !content[PRODUCTS_LIST.showButton],
        })

        const link = product.slug ? `/s/${userName}/${product.slug}` : `/s/${userName}`
        const imageClickHandler = (e) => {
          if (previewMode) {
            e.preventDefault()
          } else {
            const isNextApp = getElopageConfig('isNextApp')

            if (!isNextApp) {
              window.scrollTo(0, 0)
            }
          }
        }
        const widgetClickHandler = () => {
          if (!previewMode && !content[PRODUCTS_LIST.showButton]) {
            const isNextApp = getElopageConfig('isNextApp')

            if (!isNextApp) {
              window.scrollTo(0, 0)
            }

            historyPush(link)
          }
        }

        const covers = productsContent.find((image) => image.id === product.covers?.[0]?.id)?.attributes || {}

        const isFreeProduct = product.free || product.displayPrice === '0.0'
        return (
          <div
            className={classNames(SELLER_PRODUCTS_LIST_CLASS_NAMES.gridItemClassName, widgetClasses)}
            key={product.id}
            {...(previewMode && {
              'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.sellerProducts.productsView,
              'data-highlighter-selector': `.${SELLER_PRODUCTS_LIST_CLASS_NAMES.gridItemClassName}`,
              'data-highlighter-parent': `.${SELLER_PRODUCTS_LIST_CLASS_NAMES.gridContainerClassName}`,
            })}
          >
            <LinkComponent to={link} onClick={imageClickHandler}>
              <div
                className={classNames(SELLER_PRODUCTS_LIST_CLASS_NAMES.gridPriceContainerClassName, classes.priceBlock)}
                {...(previewMode && {
                  'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.sellerProducts.blockPrice,
                  'data-highlighter-selector': `.${SELLER_PRODUCTS_LIST_CLASS_NAMES.gridPriceContainerClassName}`,
                  'data-highlighter-parent': `.${SELLER_PRODUCTS_LIST_CLASS_NAMES.gridContainerClassName}`,
                })}
              >
                {isFreeProduct ? (
                  <span className={classes.price} translate='no'>
                    {FREE_PRICE}
                  </span>
                ) : (
                  <span className={classes.price} translate='no'>
                    {convertToPrice(product.displayPrice, product.displayCurrencyId)}
                  </span>
                )}
              </div>

              <CoverWidget
                unresponsive
                noImageClasses={noCover}
                cover={{ file: covers }}
                img={covers.custom || covers.s640}
              />
            </LinkComponent>
            <div
              className={classNames(SELLER_PRODUCTS_LIST_CLASS_NAMES.gridDetailsClassName, classes.productDetails)}
              onClick={widgetClickHandler}
              {...(previewMode && {
                'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.sellerProducts.productBackground,
                'data-highlighter-selector': `.${SELLER_PRODUCTS_LIST_CLASS_NAMES.gridDetailsClassName}`,
                'data-highlighter-parent': `.${SELLER_PRODUCTS_LIST_CLASS_NAMES.gridContainerClassName}`,
              })}
            >
              <div
                className={classNames(classes.productTitle, SELLER_PRODUCTS_LIST_CLASS_NAMES.gridTitleClassName)}
                {...(previewMode && {
                  'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.sellerProducts.productsTitles,
                  'data-highlighter-selector': `.${SELLER_PRODUCTS_LIST_CLASS_NAMES.gridTitleClassName}`,
                  'data-highlighter-parent': `.${SELLER_PRODUCTS_LIST_CLASS_NAMES.gridContainerClassName}`,
                })}
              >
                {product.name}
              </div>
              {content[PRODUCTS_LIST.showButton] && (
                <button
                  type='button'
                  onClick={(e) => handleBuyProduct(product, e)}
                  className={buttonClasses}
                  {...(previewMode && {
                    'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.sellerProducts.buyNowButton,
                    'data-highlighter-selector': `.${SELLER_PRODUCTS_LIST_CLASS_NAMES.gridButtonClassName}`,
                    'data-highlighter-parent': `.${SELLER_PRODUCTS_LIST_CLASS_NAMES.gridContainerClassName}`,
                  })}
                >
                  {content[PRODUCTS_LIST.buttonText] || buyNowText}
                </button>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

GridSellerProducts.propTypes = {
  /** Classes object for grid seller products */
  classes: PropTypes.object,
  /** Convert price function received from currency store */
  convertToPrice: PropTypes.func,
  /** Username */
  userName: PropTypes.string,
  /** Indicates whether it's Page Builder preview of component */
  previewMode: PropTypes.bool,
  /** List of products */
  productsList: PropTypes.array,
  /** Buy product function */
  handleBuyProduct: PropTypes.func,
  /** Content object */
  content: PropTypes.object,
  /** Default text for free product */
  freeDefaultText: PropTypes.string, // I18n.t('react.shared.free')
  /** Buy now button text */
  buyNowText: PropTypes.string, // I18n.t('shared.common.buy_now')
  /** Browser history object */
  historyPush: PropTypes.func,
  /** Wrapper link for url */
  LinkComponent: PropTypes.func,
}

GridSellerProducts.defaultProps = {
  freeDefaultText: 'Free',
  buyNowText: 'Buy now',
  classes: {},
  content: {},
  LinkComponent: 'a',
  convertToPrice: /* istanbul ignore next */ () => {},
  productsList: [],
  historyPush: () => {},
}

export default withStyles(gridSellerProductsStyles)(GridSellerProducts)
