import React, { Component } from 'react'
import Datetime from 'react-datetime'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'

import { useI18n } from '@elo-kit/components/i18n/i18n'

import { TIME_FORMATS } from '@elo-kit/constants/dateTime.constants'

const propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  locale: PropTypes.string,
  value: PropTypes.string,
}

const defaultProps = {
  disabled: false,
}

function formatedOutput(date) {
  if (date._d) {
    return date._d.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
    })
  }

  return moment(date).isValid() ? moment : '00:00'
}

class TimeFieldContainer extends Component {
  handleChange = (value) => {
    const { onChange } = this.props
    onChange && onChange(formatedOutput(value))
  }

  handleBlur = (value) => {
    const { onBlur } = this.props
    onBlur && onBlur(formatedOutput(value))
  }

  render() {
    const {
      locale,
      className,
      label,
      placeholder,
      autocomplete,
      disabled,
      onChange,
      onBlur,
      value,
      name,
      isInvalid,
      errors,
      I18n,
      ...props
    } = this.props

    const fieldClassNames = classNames('field date-time-field', { 'field--disabled': disabled }, className)

    const fieldControlClassNames = classNames('field__control date-time-field__control', {
      'date-time-field__control--error': isInvalid,
    })

    return (
      <div className={fieldClassNames}>
        {label && (
          <label className='field__label' htmlFor={name}>
            {label}
          </label>
        )}

        {isInvalid && (
          <div className='field__error'>
            {errors[name]}
            <i className='fas fa-exclamation-circle' />
          </div>
        )}

        <div className='date-time-field__control-wrapper'>
          <Datetime
            type='date'
            value={value || '00:00'}
            dateFormat={false}
            timeFormat={TIME_FORMATS.HHmm}
            locale={I18n.locale}
            inputProps={{
              name,
              className: fieldControlClassNames,
              placeholder,
              disabled,
            }}
            name={name}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            {...props}
          />
          <i className='far fa-calendar-alt' />
        </div>
      </div>
    )
  }
}

TimeFieldContainer.displayName = 'TimeField'
TimeFieldContainer.propTypes = propTypes
TimeFieldContainer.defaultProps = defaultProps

export const TimeField = (props) => {
  const I18n = useI18n()

  return <TimeFieldContainer I18n={I18n} {...props} />
}
