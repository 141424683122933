import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

/**
 * Ellipsis - cut text if it more than max length
 */
export const Ellipsis = ({ children, maxLength, Wrapper }) => (
  <Wrapper>
    {maxLength && children && children.length > maxLength ? `${children.substring(0, maxLength)}...` : children}
  </Wrapper>
)

Ellipsis.displayName = 'Ellipsis'
Ellipsis.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Max symbol length of text */
  maxLength: PropTypes.number,
  /** Wrapper around the component */
  Wrapper: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.oneOf([React.Fragment]),
    PropTypes.func,
  ]),
}

Ellipsis.defaultProps = {
  children: '',
  maxLength: 30,
  Wrapper: Fragment,
}
