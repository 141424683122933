import React from 'react'
import { components } from 'react-select'
import { EloCheckboxIcon } from '@elo-ui/components/icons/regular'

const DefaultOptionComponent = (props) =>
  !props.isMulti || (props.isMulti && !props.isSelected) ? (
    <components.Option {...props}>
      <span>{props.children}</span>
      {props.isSelected && <EloCheckboxIcon />}
    </components.Option>
  ) : null

export { DefaultOptionComponent }
