import React from 'react'

import './_error-info.scss'

/**
 * Error Info - show information about error
 */

interface Props {
  message?: string
}

export const ErrorInfo: React.FC<Props> = ({ message = '' }): JSX.Element => (
  <div className='error-info'>
    <i className='fas fa-exclamation-triangle' />
    <div
      dangerouslySetInnerHTML={{
        __html: message,
      }}
    />
  </div>
)
