import React from 'react'
import classNames from 'classnames'

import ElopageBlack from './assets/elopage-black.svg'
import ElopageWhite from './assets/elopage-white.svg'

import './social-proof-bubble.scss'

interface SocialProofBubbleProps {
  badge?: string
  title?: string
  text?: string
  time?: string
  hideBubble?: () => void
  customClasses?: string
  badgeBackground?: string
  badgeColor?: string
  bigBadge: boolean
}

export const SocialProofBubble: React.FC<SocialProofBubbleProps> = ({
  badge,
  title,
  text,
  time,
  hideBubble = () => {},
  customClasses,
  badgeBackground,
  badgeColor,
  bigBadge,
}) => {
  const bubbleClasses = classNames('social-proof-bubble', customClasses)

  const badgeClasses = classNames('social-proof-bubble--badge', {
    'social-proof-bubble--badge__big': bigBadge,
  })

  const eloImage = ['elopage_dark', 'reverse_mono', 'grey'].some((style) => customClasses.indexOf(style) >= 0)
    ? ElopageWhite
    : ElopageBlack

  return (
    <div className={bubbleClasses}>
      <div className='social-proof-bubble--content'>
        <div className={badgeClasses} style={{ backgroundColor: badgeBackground, color: badgeColor }}>
          {badge}
        </div>
        <div className='social-proof-bubble--text'>
          <div className='social-proof-bubble--text__header'>{title}</div>
          <div className='social-proof-bubble--text__description'>{text}</div>
          <div className='social-proof-bubble--text__description'>{time}</div>
        </div>
      </div>
      <div className='social-proof-bubble__logo'>
        <img src={eloImage} alt='Ablefy Logo' />
      </div>
      <div className='social-proof-bubble__close' onClick={hideBubble}>
        ✕
      </div>
    </div>
  )
}
