import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import { get } from 'utils/lodash.utils'
import classNames from 'classnames'

import { ascendingSortWithSecondDesc, mapToIdsArray } from '@elo-kit/utils/helpers.utils'
import { getDefaultCategories, getInitialActiveTab } from '@elo-kit/utils/contentPage.utils'
import { isNumber } from '@elo-kit/utils/validators.utils'

import {
  DEFAULT_PRODUCTS_LIST,
  BLOCK_VIEW,
  PRODUCTS_LIST,
  ALL_PRODUCTS_CATEGORY,
  ALL_PRODUCTS_CATEGORY_OPTION,
  DEFAULT_SORTING_DIR,
} from '@elo-kit/constants/contentPage.constants'

import {
  CUSTOM_CLASS_NAME_OPTION,
  SELLER_PRODUCTS_LIST_CLASS_NAMES,
  EXTRAS_PRODUCTS_CLASS_NAMES,
} from '@elo-kit/constants/customCss.constants'
import { CATEGORIES_VIEW_TYPE, BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { DEFAULT_SHOP_ITEMS_PER_PAGE } from '@elo-kit/constants/pagination.constants'
import { LoadingMask } from '@elo-kit/components/loading-mask/LoadingMask'
import { useI18n } from '@elo-kit/components/i18n/i18n'
import { CategoryTabsWithStyles } from '@elo-kit/components/page-builder/blocks/category-tabs/CategoryTabs'
import { CategoriesDropdownWithStyles } from '@elo-kit/components/page-builder/blocks/category-dropdown/CategoriesDropdown'
import { NoProductsFound } from '@elo-kit/components/page-builder/blocks/products-search/no-products-found/NoProductsFound'
import { getElopageConfig } from 'utils/elopageConfig.utils'

import sellerProductsStyles from 'shared/components/content-page/preview/blocks/seller-products/SellerProductsList.styles'
import ProductsSearch from '../../products-search/product-search'
import GridSellerProducts from '../grid-seller-products'
import ListSellerProducts from '../list-seller-products'

/**
 * Seller products list with two views (list/grid)
 */
export class SellerProductsListContainer extends Component {
  constructor(props) {
    super(props)
    const categoryId = props?.sortedCategories?.[0]?.hasOwnProperty('id')
      ? props?.sortedCategories?.[0]?.id
      : props?.sortedCategories?.[0]?.value
    const activeTab =
      props.sortedCategories?.length && !props.activeTab ? categoryId : props.activeTab || getInitialActiveTab(props)

    this.state = {
      activeTab: activeTab,
      sortedCategories: props.sortedCategories || [],
    }
  }

  componentDidMount() {
    const {
      block: { content = {} },
      fetchCategories,
      productGroupsStore,
      toggleLoading,
      fetchProducts,
      handleScopeChange,
      previewMode,
      userName,
    } = this.props
    const { activeTab } = this.state
    const isNextApp = getElopageConfig('isNextApp')

    handleScopeChange('for_shop', true)

    if (!isNextApp) {
      if (!previewMode) {
        this.setProductsNumber()

        if (content[PRODUCTS_LIST.showCategories]) {
          fetchCategories({ username: userName }).then(({ success, data }) => {
            if (success) {
              const { list = [] } = data
              this.prepareCategories(list)
            }
          })
        } else {
          toggleLoading(false)
          fetchProducts({
            productGroupId: activeTab,
            sortKey: content.sortKey,
            sortDir: content.sortDir || DEFAULT_SORTING_DIR[content.sortKey] || 'desc',
          })
        }
      } else {
        this.setDefaultCategories()
      }
    } else {
      this.prepareCategories(productGroupsStore.list)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      block: { content = {} },
      previewMode,
      fetchProducts,
    } = this.props
    const {
      block: { content: prevContent = {} },
    } = prevProps
    const isNextApp = getElopageConfig('isNextApp')

    const shouldSetDefaultCategories =
      previewMode &&
      (content.categoriesView !== prevContent.categoriesView || content.showAllProducts !== prevContent.showAllProducts)

    if (!isNextApp && shouldSetDefaultCategories) {
      this.setDefaultCategories()
    }

    if (this.props.initStore?.pagination?.page !== prevProps.initStore?.pagination?.page) {
      fetchProducts({
        productGroupId: this.state.activeTab ? this.state.activeTab : undefined,
        sortKey: content.sortKey,
        sortDir: content.sortDir || DEFAULT_SORTING_DIR[content.sortKey] || 'desc',
      })
    }
  }

  setProductsNumber = () => {
    const {
      setPagination,
      block: { content = {}, id },
    } = this.props
    if (content.itemsPerPage) {
      setPagination(
        {
          per: content.itemsPerPage || DEFAULT_SHOP_ITEMS_PER_PAGE,
        },
        id
      )
    }
  }

  prepareCategories = (list) => {
    const {
      block: { content, id: blockId },
      getByKey,
      allProductsCategory,
    } = this.props
    const { categoriesIds = [], categoriesView, showSelectedCategories } = content
    const categoriesDropdownView = categoriesView === CATEGORIES_VIEW_TYPE.dropdown
    const categoriesToShow = list.filter(({ hidden, id }) =>
      showSelectedCategories ? categoriesIds.includes(String(id)) : !hidden
    )
    let sortedCategories = ascendingSortWithSecondDesc(categoriesToShow)
    const showWithAllProductsCategory = get(content, 'showAllProducts', true)
    const showCategories = get(content, 'showCategories', false)

    sortedCategories =
      showWithAllProductsCategory || !showCategories ? [allProductsCategory, ...sortedCategories] : sortedCategories

    if (categoriesDropdownView) {
      sortedCategories = sortedCategories.map((category) => ({
        label: category.title,
        value: category.id,
      }))
    }

    this.setSortedCategories(sortedCategories)

    const { id, value } = sortedCategories[0] || {}
    const { [`block_${blockId}_group_id`]: queryCategoryId } = getByKey()
    const activeCategoryId = (!!queryCategoryId && Number(queryCategoryId)) || id || value
    const activeCategoryNotHidden = mapToIdsArray(categoriesToShow).includes(activeCategoryId)
    const activeTab = activeCategoryId && activeCategoryNotHidden ? activeCategoryId : allProductsCategory.id
    this.setActiveTab(activeTab)
  }

  setSortedCategories = (sortedCategories) => {
    this.setState({ sortedCategories })
  }

  setDefaultCategories = () => {
    const {
      block: { content },
      allProductsCategoryOption,
    } = this.props
    const { categoriesView } = content
    const showWithAllProductsCategory = get(content, 'showAllProducts', true)

    const categoriesDropdownView = categoriesView === CATEGORIES_VIEW_TYPE.dropdown

    const { defaultCategories, defaultCategoryOptions } = getDefaultCategories(
      showWithAllProductsCategory,
      allProductsCategoryOption
    )

    const sortedCategories = categoriesDropdownView ? defaultCategoryOptions : defaultCategories

    this.setSortedCategories(sortedCategories)
    const firstCategory = sortedCategories[0] || {}
    const activeTabId = isNumber(firstCategory.id) ? firstCategory.id : firstCategory.value

    this.setActiveTab(activeTabId)
  }

  setActiveTab = (id) => {
    const {
      block: { content = {} },
      resetQueryParams,
      fetchProducts,
      previewMode,
      setGroupId,
      changeCategoryTabInUrl,
    } = this.props
    const { activeTab } = this.state

    if (content[PRODUCTS_LIST.showCategories] && activeTab !== id && !previewMode) {
      changeCategoryTabInUrl(id)
    }

    this.setState({ activeTab: id }, () => {
      if (!previewMode) {
        const { activeTab: updatedActiveTab } = this.state
        const productGroupId = updatedActiveTab === ALL_PRODUCTS_CATEGORY.id ? null : updatedActiveTab
        const shouldFetchProductsByProductGroup = !getElopageConfig('isNextApp') || activeTab !== id

        setGroupId(productGroupId)
        resetQueryParams()

        this.setProductsNumber()

        if (shouldFetchProductsByProductGroup) {
          this.props.initStore.handlePaginationChange('page', 1)
          fetchProducts({
            productGroupId: content[PRODUCTS_LIST.showCategories] ? productGroupId : null,
            page: 1,
            sortKey: content.sortKey,
            sortDir: content.sortDir || DEFAULT_SORTING_DIR[content.sortKey] || 'desc',
          })
        }
      }
    })
  }

  render() {
    const {
      classes,
      block,
      previewMode,
      userName,
      handleBuyProduct,
      history,
      sellerProductsQuery,
      sellerProducts,
      sellerProductsContent,
      sellerProductsLoading,
      fetchProducts,
      view,
      productGroupsLoading,
      convertToPrice,
      getUnit,
      LinkComponent,
      convertToPriceWithoutCurrency,
      defaultProductsList,
      allProductsCategory,
      freeDefaultText = this.props.I18n.t('react.shared.free'),
      buyNowText = this.props.I18n.t('shared.common.buy_now'),
      defaultSearchPlaceholder = this.props.I18n.t('shared.common.search'),
      noProductsMessage = this.props.I18n.t('react.shared.no_products_found'),
      historyPush,
    } = this.props
    const { activeTab, sortedCategories } = this.state
    const { content } = block
    const listView = content[PRODUCTS_LIST.view] === BLOCK_VIEW.list
    const productsList = previewMode ? defaultProductsList : sellerProducts
    const productsContent = previewMode ? [] : sellerProductsContent
    const viewProps = {
      previewMode,
      userName,
      handleBuyProduct,
      content,
      history,
      productsList,
      productsContent,
      LinkComponent,
      freeDefaultText,
      buyNowText,
      convertToPrice,
      getUnit,
      convertToPriceWithoutCurrency,
      blockId: block.id,
      historyPush,
    }
    const loading = !previewMode && (productGroupsLoading || sellerProductsLoading)
    const showCategoriesTabs =
      content[PRODUCTS_LIST.showCategories] && content.categoriesView !== CATEGORIES_VIEW_TYPE.dropdown
    const showCategoriesDropdown =
      content[PRODUCTS_LIST.showCategories] && content.categoriesView === CATEGORIES_VIEW_TYPE.dropdown

    const showNoProductsFound = !previewMode && !(productsList || []).length && !!sellerProductsQuery
    const hideBlock =
      !previewMode &&
      (!productsList || []).length &&
      !content[PRODUCTS_LIST.showCategories] &&
      !sellerProductsQuery &&
      !sellerProductsLoading
    const containerClasses = classNames(classes.productsListSection, {
      [classes.hideBlock]: hideBlock,
    })
    const productGroupId = activeTab === allProductsCategory.id ? null : activeTab

    return (
      <div
        className={classNames(
          SELLER_PRODUCTS_LIST_CLASS_NAMES.containerClassName,
          content[CUSTOM_CLASS_NAME_OPTION],
          containerClasses
        )}
        id={`seller-products-${block.id}`}
      >
        {(showCategoriesDropdown || content.showSearch) && (
          <div
            className={classNames(EXTRAS_PRODUCTS_CLASS_NAMES.controlsContainerClassName, classes.controlsContainer)}
          >
            {showCategoriesDropdown && (
              <div
                {...(previewMode && {
                  'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.sellerProducts.categories,
                  'data-highlighter-selector': `.${EXTRAS_PRODUCTS_CLASS_NAMES.categoriesDropdownContainerClassName}`,
                })}
              >
                <CategoriesDropdownWithStyles
                  activeOption={String(activeTab)}
                  options={sortedCategories}
                  setOption={this.setActiveTab}
                />
              </div>
            )}

            {content.showSearch && (
              <ProductsSearch
                block={block}
                handleFetch={(data) => {
                  fetchProducts({
                    productGroupId,
                    sortKey: content.sortKey,
                    ...data,
                    page: 1,
                  })
                }}
                defaultSearchPlaceholder={defaultSearchPlaceholder}
                view={view}
                previewMode={previewMode}
                highlighterId={BLOCK_MENU_HIGHLIGHT_IDS.sellerProducts.search}
              />
            )}
          </div>
        )}

        {showCategoriesTabs && (
          <div
            {...(previewMode && {
              'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.sellerProducts.categories,
              'data-highlighter-selector': `.${EXTRAS_PRODUCTS_CLASS_NAMES.categoriesTabsCategoriesListClassName}`,
            })}
          >
            <CategoryTabsWithStyles activeTab={activeTab} tabs={sortedCategories} setActiveTab={this.setActiveTab} />
          </div>
        )}

        {listView ? <ListSellerProducts {...viewProps} /> : <GridSellerProducts {...viewProps} />}

        {!!showNoProductsFound && <NoProductsFound block={block} noProductsMessage={noProductsMessage} />}

        {loading && <LoadingMask dark />}
      </div>
    )
  }
}

SellerProductsListContainer.propTypes = {
  /** Seller product list classes object */
  classes: PropTypes.object,
  /** Block object */
  block: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    content: PropTypes.object,
  }),
  /** Property for check is PageBuilder in preview mode */
  previewMode: PropTypes.bool,
  /** User name */
  userName: PropTypes.string,
  /** Buy product function */
  handleBuyProduct: PropTypes.func,
  /** Browser history object */
  history: PropTypes.any,
  /** Seller products query received from contentPageStore */
  sellerProductsQuery: PropTypes.string,
  /** Seller products received from contentPageStore */
  sellerProducts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  /** Seller products loading received from contentPageStore */
  sellerProductsLoading: PropTypes.bool,
  /** Fetch Seller products function received from contentPageStore */
  fetchProducts: PropTypes.func,
  /** View(cabinet/shop) received from contentPageStore */
  view: PropTypes.string,
  /** Set pagination func received from contentPageStore */
  setPagination: PropTypes.func,
  /** Reset query params function received from contentPageStore */
  resetQueryParams: PropTypes.func,
  /** Fetch all product groups function received from productGroupsStore */
  fetchCategories: PropTypes.func,
  /** Toggle loading function received from productGroupsStore */
  toggleLoading: PropTypes.func,
  /** Product groups loading received from productGroupsStore */
  productGroupsLoading: PropTypes.bool,
  /** Product groups scope change function received from productGroupsStore */
  handleScopeChange: PropTypes.func,
  /** Convert price function received from currencyStore */
  convertToPrice: PropTypes.func,
  /** Get unit function received from currencyStore */
  getUnit: PropTypes.func,
  /** Set group id function received from productsStore */
  setGroupId: PropTypes.func,
  /** Wrapper link for url */
  LinkComponent: PropTypes.func,
  /** Default text for free product */
  freeDefaultText: PropTypes.string,
  /** Buy now button text */
  buyNowText: PropTypes.string,
  /** Search placeholder text */
  defaultSearchPlaceholder: PropTypes.string,
  /** No products message text */
  noProductsMessage: PropTypes.string,
  /** Change category tab url received from block.utils */
  changeCategoryTabInUrl: PropTypes.func,
  /** Function use formatMoney from utils.js */
  convertToPriceWithoutCurrency: PropTypes.func, // (value) => value.formatMoney(2, ',', '.'),
  /** Function getByKey from window.Hashovka */
  getByKey: PropTypes.func,
  /** Default products list from content page constants */
  defaultProductsList: PropTypes.array,
  /** All products category from content page constants */
  allProductsCategory: PropTypes.object,
  /** All products category option from content page constants */
  allProductsCategoryOption: PropTypes.object,
  I18n: PropTypes.any,
}

SellerProductsListContainer.defaultProps = {
  classes: {},
  block: {
    content: {},
  },
  LinkComponent: 'a', // use 'Link' in general project,
  handleScopeChange: /* istanbul ignore next */ () => {},
  fetchProducts: /* istanbul ignore next */ () => {},
  toggleLoading: /* istanbul ignore next */ () => {},
  fetchCategories: /* istanbul ignore next */ () => {}, // add fetchFullList as props
  getByKey: /* istanbul ignore next */ () => {}, // window.Hashovka.getByKey
  changeCategoryTabInUrl: /* istanbul ignore next */ () => {}, // (id) => changeCategoryTabInUrl(history, id)
  setGroupId: /* istanbul ignore next */ () => {},
  resetQueryParams: /* istanbul ignore next */ () => {},
  setPagination: /* istanbul ignore next */ () => {},
  defaultProductsList: DEFAULT_PRODUCTS_LIST, // DEFAULT_PRODUCTS_LIST
  allProductsCategory: ALL_PRODUCTS_CATEGORY, // ALL_PRODUCTS_CATEGORY
  allProductsCategoryOption: ALL_PRODUCTS_CATEGORY_OPTION, // ALL_PRODUCTS_CATEGORY_OPTION
  historyPush: () => {},
}

const SellerProductsList = (props) => {
  const I18n = useI18n()

  return <SellerProductsListContainer I18n={I18n} {...props} />
}

export default withStyles(sellerProductsStyles)(SellerProductsList)
