import React, { Component } from 'react'
// eslint-disable-next-line import/named
import { isValidNumber, format, CountryCode } from 'libphonenumber-js'
import IBAN from 'iban'
import withStyles, { ThemeProvider } from 'react-jss'
import classNames from 'classnames'
import { Nullable } from 'types/helpers'

import { validators } from 'utils/validators.utils'
import { compareTwoStrings } from '@elo-kit/utils/stringComparison.utils'
import { validateJSVAT } from '@elo-kit/utils/validatorsVat.utils'
import { I18nType, useI18n } from '@elo-kit/components/i18n/i18n'

import inputFieldWithValidationStyles from './InputFieldWithValidation.styles'

interface Classes {
  formControl?: string
  isInvalid?: string
  isValid?: string
  formGroup?: string
  requiredInputLabel?: string
  invalidFeedback?: string
  validFeedback?: string
}
interface Props {
  class?: string
  value?: string
  successMsg?: string
  validations?: any[]
  label?: string
  placeholder?: string
  minlength?: number
  maxlength?: number
  disabled?: boolean
  hideErrorMsg?: boolean
  handleValid?: (isValid: boolean) => void
  vatComplianceField?: string
  vatValidByVies?: boolean
  handleInvalid?: () => void
  isValid?: boolean
  shouldInclude?: string
  identicalTo?: string
  locale?: string
  autoFocus?: boolean
  forceDirty?: boolean
  handleInput?: (arg?: string) => void
  handleBlur?: (name?: string) => void
  name?: string
  classes?: Classes
  I18n?: I18nType
}
interface State {
  isDirty: boolean
  isValid: boolean
  errorMsg: string
}

const defaultProps = {
  validations: [],
  handleInput: /* istanbul ignore next */ () => {},
  handleValid: /* istanbul ignore next */ () => {},
  classes: {},
  I18n: {
    t: () => {},
  },
}

export class InputFieldWithValidationContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      isDirty: false,
      isValid: false,
      errorMsg: '',
    }
  }
  Input: Nullable<HTMLInputElement> = null
  static defaultProps = defaultProps

  componentDidMount() {
    const { autoFocus, value } = this.props

    if (autoFocus) {
      this.Input?.focus()
      const tempValue = this.Input?.value
      this.Input && (this.Input.value = '')
      if (tempValue != null) {
        this.Input && (this.Input.value = tempValue)
      }
    }

    if (value && value.length > 0) {
      this.setState({ isDirty: true }, () => this.validate(value))
    } else {
      this.validate('')
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { forceDirty, value, locale, validations, vatValidByVies } = this.props
    const { isDirty } = this.state

    const validationsChanged = !(
      validations?.length === prevProps?.validations?.length &&
      validations?.sort().every((newValidation, index) => newValidation === prevProps?.validations?.sort()[index])
    )
    if (forceDirty && !prevProps.forceDirty && !isDirty) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isDirty: true }, () => this.validate(value || ''))
    } else if (
      value !== prevProps.value ||
      locale !== prevProps.locale ||
      validationsChanged ||
      vatValidByVies !== prevProps.vatValidByVies
    ) {
      this.validate(value || '')
    }
  }

  onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { handleBlur, name } = this.props
    const { isDirty } = this.state

    const val = e.currentTarget.value
    const handler = () => {
      if (handleBlur) {
        handleBlur(name)
      } else {
        this.handleValue(val)
      }
    }

    if (isDirty) {
      handler()
    } else {
      this.setState({ isDirty: true }, () => handler())
    }
  }

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.handleValue(e.currentTarget.value)
  }

  handleValue = (val?: string) => {
    const { handleInput } = this.props

    handleInput && handleInput(val)
    this.validate(val || '')
  }

  bicIsValid = (val: string) => /^([a-zA-Z]{4}[a-zA-Z]{2}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?)$/.test(val)

  validate = (value: string) => {
    const {
      validations,
      handleValid,
      vatComplianceField,
      vatValidByVies,
      handleInvalid,
      isValid: valid,
      shouldInclude,
      minlength,
      maxlength,
      identicalTo,
      locale,
      I18n,
    } = this.props
    const { isDirty } = this.state

    let isValid = true
    let msgs = []
    const inputValue = typeof value === 'number' ? value : value.trim()

    if (validations && validations.indexOf('vatCompliance') >= 0) {
      if (compareTwoStrings(inputValue.toLowerCase(), vatComplianceField?.toLowerCase()) < 0.8) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.company_name_doesnt_match_with_id'))

        if (handleInvalid) {
          handleInvalid()
        }
      }
    }

    if (validations && validations.indexOf('required') >= 0) {
      if (inputValue === '') {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.required'))
      }
    }

    if (validations && validations.indexOf('include') >= 0) {
      if (shouldInclude && inputValue.indexOf(shouldInclude) < 0) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.should_include', { val: shouldInclude }))
      }
    }

    if (validations && validations.indexOf('minlength') >= 0) {
      if (minlength && minlength > inputValue.length) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.too_short', { min: minlength }))
      }
    }

    if (validations && validations.indexOf('url') >= 0) {
      const pattern =
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,}(:[0-9]{1,5})?(\/.*)?$/
      isValid = pattern.test(inputValue)
      msgs.push(I18n.t('react.shared.validations.url_invalid'))
    }

    if (validations && validations.indexOf('maxlength') >= 0) {
      if (maxlength && maxlength < inputValue.length) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.too_long', { max: maxlength }))
      }
    }

    if (validations && validations.indexOf('identical') >= 0) {
      if (String(identicalTo) !== String(inputValue)) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.not_identical'))
      }
    }

    if (validations && validations.indexOf('email') >= 0) {
      if (!validators.isEmail(inputValue)) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.invalid_email'))
      }
    }

    if (validations && validations.indexOf('phone') >= 0) {
      const number = format(inputValue, locale as CountryCode, 'INTERNATIONAL')
      // TODO:
      // @ts-ignore
      if (!isValidNumber(number)) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.invalid_phone'))
      }
    }

    if (validations && validations.indexOf('iban') >= 0) {
      if (!IBAN.isValid(inputValue)) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.invalid_iban'))
      }
    }

    if (validations && validations.indexOf('bic') >= 0) {
      if (!this.bicIsValid(inputValue)) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.invalid_bic'))
      }
    }

    if (validations && validations.indexOf('cvv') >= 0) {
      if (!validators.isInt(inputValue) || inputValue.trim().length !== 3) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.invalid_cvv'))
      }
    }

    if (validations && (validations.indexOf('vat') >= 0 || validations.indexOf('viesVatValidation') >= 0)) {
      const isVatValid = validateJSVAT(inputValue)?.isValid || valid
      const vatValid =
        validations.indexOf('vat') >= 0 && isVatValid && validations.indexOf('viesVatValidation') >= 0 && vatValidByVies
      if (!vatValid) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.invalid_vat'))
      }
    }

    if (validations && validations.indexOf('required') < 0 && inputValue === '') {
      isValid = true
      msgs = []
    }
    this.setState(
      {
        isValid,
        errorMsg: msgs.join(', '),
      },
      () => {
        if (isDirty || isValid) {
          handleValid && handleValid(isValid)
        }
      }
    )
  }

  render() {
    const {
      value,
      successMsg,
      validations,
      label,
      class: className,
      placeholder,
      minlength,
      maxlength,
      disabled,
      hideErrorMsg,
      classes,
    } = this.props
    const { isValid, isDirty, errorMsg } = this.state

    const showError = !isValid && isDirty
    const showSuccess = isValid && isDirty && value && value.length > 0 && successMsg
    const validate = validations ? validations.indexOf('required') >= 0 : false

    const inputClasses = classNames(
      classes?.formControl,
      {
        [String(classes?.isInvalid)]: showError,
        [String(classes?.isValid)]: showSuccess,
      },
      className
    )

    return (
      <div className={classes?.formGroup || ''}>
        {label && <label className={validate ? classes?.requiredInputLabel : ''}>{label}</label>}
        <div>
          <input
            required={validate}
            autoComplete='off'
            className={inputClasses}
            placeholder={placeholder}
            value={value}
            type='text'
            minLength={minlength}
            maxLength={maxlength}
            onChange={this.onChange}
            onBlur={this.onBlur}
            disabled={disabled}
            ref={(input) => {
              this.Input = input
            }}
          />
          {showError && !hideErrorMsg && (
            <div className={classes?.invalidFeedback} dangerouslySetInnerHTML={{ __html: errorMsg }} />
          )}
          {showSuccess && <div className={classes?.validFeedback}>{successMsg}</div>}
        </div>
      </div>
    )
  }
}

const InputFieldWithValidationContainerWithStyles = withStyles(inputFieldWithValidationStyles)(
  InputFieldWithValidationContainer
)

export const InputFieldWithValidation = ({ theme = {}, ...restProps }) => {
  const I18n = useI18n() as never // TODO: change as never

  return (
    <ThemeProvider theme={theme}>
      <InputFieldWithValidationContainerWithStyles I18n={I18n} {...restProps} />
    </ThemeProvider>
  )
}

InputFieldWithValidation.displayName = 'Input'
