import React from 'react'
import classNames from 'classnames'

import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'

import { Ellipsis } from '@elo-kit/components/ellipsis'

import EditTextArea from 'shared/components/page-builder/page-view/edit-text-area/EditTextArea'

interface Props {
  additionalText?: string
  titleText?: string
  classes?: Record<string, string>
  previewMode?: boolean
}

export const AudioPlayerFileInfo: React.FC<Props> = ({ additionalText, classes = {}, titleText, previewMode }) => (
  <div className={`${classes.audioTextInfo} ${classes.mobileAudioTextInfo}`}>
    <div
      className={classNames(classes.mainText)}
      {...(previewMode && {
        'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.audioPlayer.mainText,
        'data-highlighter-selector': '',
      })}
    >
      <EditTextArea value={titleText} dataKey='titleText' previewMode={previewMode}>
        {titleText}
      </EditTextArea>
    </div>
    <Ellipsis
      maxLength={25}
      Wrapper={({ children }: any) => (
        <span
          className={classNames(classes.additionalText)}
          {...(previewMode && {
            'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.audioPlayer.additionalText,
            'data-highlighter-selector': '',
          })}
        >
          {children}
        </span>
      )}
    >
      <EditTextArea value={additionalText} dataKey='additionalText' previewMode={previewMode}>
        {additionalText}
      </EditTextArea>
    </Ellipsis>
  </div>
)
