import { ALL_PRODUCTS_CATEGORY, DEFAULT_CATEGORIES, DEFAULT_CATEGORY_OPTIONS } from '../constants/contentPage.constants'

export const getDefaultCategories = (
  showAllProducts?: { defaultCategories: { id: number }[] },
  allProductsCategoryOption?: { value: number; label: string }[]
) =>
  showAllProducts
    ? {
        defaultCategories: [ALL_PRODUCTS_CATEGORY, ...DEFAULT_CATEGORIES],
        defaultCategoryOptions: [allProductsCategoryOption, ...DEFAULT_CATEGORY_OPTIONS],
      }
    : {
        defaultCategories: DEFAULT_CATEGORIES,
        defaultCategoryOptions: DEFAULT_CATEGORY_OPTIONS,
      }

export const getInitialActiveTab = (props: {
  block?: {
    content: {
      showAllProducts: {
        defaultCategories: { id: number }[]
      }
    }
  }
  previewMode: object
}) => {
  const { block, previewMode } = props

  const { defaultCategories } = getDefaultCategories(block?.content?.showAllProducts)

  return previewMode ? defaultCategories[0].id : null
}
