import { THEME_TYPE } from 'constants/general.constants'

export const FIRST_BLOCK_POSITION = -1
export const FIRST_BLOCK_INDEX = 0

export const TEMPLATES_ITEMS_PER_PAGE = 9

export const DEFAULT_HIGHLIGHT_DATA = {
  id: null,
  blockId: null,
  active: false,
}

export const EMPTY_THEME = {}

export enum SCREENS {
  desktop = 'desktop',
  tablet = 'tablet',
  mobile = 'mobile',
}

export const BUTTON_TYPES = {
  add: 'add',
  save: 'save',
  label: 'label',
}

export const COLORS = {
  eloBlack: '#4A4A4A',
  eloBlue: '#1E84D7',
  eloGreen: '#39AA75',
  eloGrey20: '#EFEDEF',
  eloOrange: '#FFB000',
  eloRed: '#FF2800',
  eloWhite: '#FFFFFF',
  eloGrey: '#71747a',
  eloGrey40: '#CDCDCD',
  eloGrey60: '#9e9e9e',
  eloGrey70: '#787878',
  eloGrey90: '#4a4a4a',
}

export const FONTS = {
  light: 'Montserrat Light',
  medium: 'Montserrat Reg',
  bold: 'Montserrat Medium',
  bolder: 'Montserrat Black',
}

export const FONT_STYLES = {
  normal: 'normal',
  bold: 'bold',
}

export const getBlockTypes = () => ({
  header: {
    icon: 'far fa-window-maximize',
    label: I18n.t('react.shared.header_and_menu'),
  },
  banner: {
    icon: 'fas fa-image',
    label: I18n.t('react.shared.banner'),
  },
  seller_products: {
    icon: 'fas fa-shopping-basket',
    label: I18n.t('react.shared.products'),
  },
  membership_products: {
    icon: 'fas fa-shopping-basket',
    label: I18n.t('react.shared.products'),
  },
  product: {
    icon: 'fas fa-box-open',
    label: I18n.t('react.shared.product'),
  },
  popular_products: {
    icon: 'fas fa-star',
    label: I18n.t('react.common.popular_products'),
  },
  testimonials: {
    icon: 'fas fa-comment-alt',
    label: I18n.t('react.common.testimonials'),
  },
  button: {
    icon: 'fas fa-power-off',
    label: I18n.t('react.shared.button.title'),
  },
  text: {
    icon: 'fas fa-font',
    label: I18n.t('react.shared.text'),
  },
  picture: {
    icon: 'fas fa-camera',
    label: I18n.t('react.common.image'),
  },
  video: {
    icon: 'fas fa-video',
    label: I18n.t('react.shared.video'),
  },
  file: {
    icon: 'fas fa-download',
    label: I18n.t('react.shared.download_file'),
  },
  file_pdf: {
    icon: 'fas fa-file-pdf',
    label: I18n.t('react.shared.file_pdf_reader'),
  },
  footer: {
    icon: 'far fa-window-maximize fa-rotate-180',
    label: I18n.t('react.shared.footer.title'),
  },
  funnel: {
    icon: 'fas fa-filter',
    label: I18n.t('react.shared.funnel'),
  },
  audio_player: {
    icon: 'fas fa-play-circle',
    label: I18n.t('react.shared.audio_player'),
  },
  affiliate: {
    icon: 'fas fa-handshake',
    label: I18n.t('react.shared.affiliate'),
  },
  contact_form: {
    icon: 'fas fa-envelope',
    label: I18n.t('react.shared.contact_form'),
  },
  conversion_tools: {
    icon: 'fas fa-bullhorn',
    label: I18n.t('react.shared.conversion_tools'),
  },
  thank_you_page: {
    icon: 'fas fa-check-circle',
    label: I18n.t('react.shared.thank_you_page'),
  },
  gap: {
    icon: 'far fa-line-height',
    label: I18n.t('react.shared.gap'),
  },
  funnel_product: {
    icon: 'fas fa-box-open',
    label: I18n.t('react.shared.funnel_product'),
  },
})

export const BLOCK_TYPES_SELECT_OPTIONS = [
  {
    value: 'text',
    label: I18n.t('react.shared.text'),
  },
  {
    value: 'picture',
    label: I18n.t('react.common.image'),
  },
  {
    value: 'video',
    label: I18n.t('react.shared.video'),
  },
  {
    value: 'file',
    label: I18n.t('react.shared.download_file'),
  },
  {
    value: 'file_pdf',
    label: I18n.t('react.shared.file_pdf_reader'),
  },
  {
    value: 'seller_products',
    label: I18n.t('react.shared.products'),
  },
  {
    value: 'testimonials',
    label: I18n.t('react.common.testimonials'),
  },
  {
    value: 'popular_products',
    label: I18n.t('react.common.popular_products'),
  },
  {
    value: 'header',
    label: I18n.t('react.shared.header_and_menu'),
  },
  {
    value: 'banner',
    label: I18n.t('react.shared.banner'),
  },
  {
    value: 'footer',
    label: I18n.t('react.shared.footer.title'),
  },
  {
    value: 'membership_products',
    label: I18n.t('react.shared.products'),
  },
  {
    value: 'product',
    label: I18n.t('react.shared.product'),
  },
  {
    value: 'button',
    label: I18n.t('react.shared.button.title'),
  },
  {
    value: 'funnel',
    label: I18n.t('react.shared.funnel'),
  },
  {
    value: 'audio_player',
    label: I18n.t('react.shared.audio_player'),
  },
  {
    value: 'affiliate',
    label: I18n.t('react.shared.affiliate'),
  },
  {
    value: 'conversion_tools',
    label: I18n.t('react.shared.conversion_tools'),
  },
  {
    value: 'thank_you_page',
    label: I18n.t('react.shared.thank_you_page'),
  },
  {
    value: 'gap',
    label: I18n.t('react.shared.gap'),
  },
  {
    value: 'funnel_product',
    label: I18n.t('react.shared.funnel_product'),
  },
]

export const DROP_TYPE = {
  col: 'column',
  row: 'row',
  rowTop: 'rowTop',
  colLeft: 'colLeft',
}

export const DEFAULT_BLOCK_TYPES = [
  'header',
  'banner',
  'popular_products',
  'testimonials',
  'button',
  'text',
  'picture',
  'video',
  'file',
  'file_pdf',
  'footer',
  'gap',
]

export const ALLOWED_BLOCK_TYPES = {
  [THEME_TYPE.membership]: [
    'header',
    'banner',
    'text',
    'button',
    'picture',
    'audio_player',
    'video',
    'file',
    'file_pdf',
    'membership_products',
    'popular_products',
    'testimonials',
    'gap',
    'footer',
    'affiliate',
  ],
  [THEME_TYPE.shop]: [
    'header',
    'banner',
    'text',
    'button',
    'picture',
    'audio_player',
    'video',
    'file',
    'file_pdf',
    'seller_products',
    'popular_products',
    'testimonials',
    'gap',
    'footer',
    'affiliate',
    'contact_form',
    'conversion_tools',
  ],
  shopProduct: [
    'header',
    'banner',
    'text',
    'button',
    'picture',
    'audio_player',
    'video',
    'file',
    'file_pdf',
    'product',
    'popular_products',
    'testimonials',
    'gap',
    'footer',
    'affiliate',
  ],
  [THEME_TYPE.thankYouPage]: [
    'header',
    'banner',
    'text',
    'button',
    'picture',
    'audio_player',
    'video',
    'file',
    'file_pdf',
    'thank_you_page',
    'popular_products',
    'seller_products',
    'testimonials',
    'gap',
    'footer',
    'affiliate',
  ],
  [THEME_TYPE.course]: [
    'banner',
    'text',
    'button',
    'picture',
    'audio_player',
    'video',
    'file',
    'file_pdf',
    'testimonials',
    'popular_products',
    'affiliate',
    'gap',
  ],
  [THEME_TYPE.funnel]: [
    'funnel',
    'banner',
    'text',
    'button',
    'picture',
    'audio_player',
    'video',
    'file',
    'file_pdf',
    'seller_products',
    'popular_products',
    'testimonials',
    'affiliate',
    'gap',
    'funnel_product',
  ],
  [THEME_TYPE.affiliate]: [
    'affiliate',
    'header',
    'banner',
    'text',
    'button',
    'picture',
    'audio_player',
    'video',
    'file',
    'file_pdf',
    'seller_products',
    'popular_products',
    'testimonials',
    'gap',
    'footer',
  ],
}

export const REJECTED_BLOCK_TYPES = {
  [THEME_TYPE.membership]: ['seller_products', 'product', 'funnel', 'thank_you_page'],
  [THEME_TYPE.shop]: ['membership_products', 'product', 'funnel', 'thank_you_page'],
  shopProduct: ['seller_products', 'membership_products', 'funnel', 'thank_you_page'],
  thankYouPage: ['seller_products', 'membership_products', 'funnel', 'product'],
  [THEME_TYPE.course]: [
    'membership_products',
    'seller_products',
    'product',
    'header',
    'footer',
    'funnel',
    'thank_you_page',
  ],
  [THEME_TYPE.funnel]: [
    'membership_products',
    'product',
    'header',
    'footer',
    'funnel',
    'thank_you_page',
    'funnel_product',
  ],
  [THEME_TYPE.affiliate]: ['membership_products', 'product', 'funnel', 'product', 'thank_you_page'],
}

export const BLOCKS_SCALE_NUMBER = 0.246

export const PAGE_SCALE_NUMBER = 0.146

export const BUILDER_SEARCH_MIN_LENGTH = 1

export const BUILDER_SELECTOR_CLASSNAME = 'builder-page-view'

export const BUILDER_TOP_PANEL_HEIGHT = 40

export const BUILDER_FIELD_TYPES = {
  uploadBackgroundImage: 'uploadBackgroundImage',
  uploadLogo: 'uploadLogo',
  color: 'color',
  itemsPerSlide: 'itemsPerSlide',
  buttonColor: 'buttonColor',
  backgroundColor: 'backgroundColor',
  transparency: 'transparency',
  font: 'font',
  fontStyle: 'fontStyle',
  sortKey: 'sortKey',
  fontSize: 'fontSize',
  buttonStyle: 'buttonStyle',
  buttonFont: 'buttonFont',
  buttonTextColor: 'buttonTextColor',
  buttonSize: 'buttonSize',
  textarea: 'textarea',
  text: 'text',
  className: 'className',
  link: 'link',
  individualLink: 'individualLink',
  view: 'view',
  itemsPerPage: 'itemsPerPage',
  sliderView: 'sliderView',
  categoriesView: 'categoriesView',
  buttonViewStyle: 'buttonViewStyle',
  buttonAnimation: 'buttonAnimation',
  twoButtonTypes: 'twoButtonTypes',
  buttonTypes: 'buttonTypes',
  paymentOptions: 'paymentOptions',
  paddingUnitsOptions: 'paddingUnitsOptions',
  selectProducts: 'selectProducts',
  padding: 'padding',
  percentage: 'percentage',
  checkbox: 'checkbox',
  pixels: 'pixels',
  shadow: 'shadow',
  funnelType: 'funnelType',
  funnelView: 'funnelView',
  selectPricingPlan: 'selectPricingPlan',
  selectCategories: 'selectCategories',
  selectAndSortProducts: 'selectAndSortProducts',
  progressColor: 'progressColor',
  audioView: 'audioView',
  cornerRadius: 'cornerRadius',
  froalaText: 'froalaText',
  funnelFroalaText: 'funnelFroalaText',
  uploadImage: 'uploadImage',
  uploadFiles: 'uploadFiles',
  uploadFile: 'uploadFile',
  uploadThumbnail: 'uploadThumbnail',
  horizontalAlignment: 'horizontalAlignment',
  verticalAlignment: 'verticalAlignment',
  uploadVideo: 'uploadVideo',
  addTestimonials: 'addTestimonials',
  addHeaderMenu: 'addHeaderMenu',
  addFooterMenu: 'addFooterMenu',
  selectProduct: 'selectProduct',
  addText: 'addText',
  addVideoCodes: 'addVideoCodes',
  uploadAudioFiles: 'uploadAudioFiles',
  buttonLinkTarget: 'buttonLinkTarget',
  selectLesson: 'selectLesson',
  videoType: 'videoType',
  uploadPdfFiles: 'uploadPdfFiles',
  downloadPdfFiles: 'downloadPdfFiles',
  sortDir: 'sortDir',
  affiliateView: 'affiliateView',
  affiliateProgram: 'affiliateProgram',
  affiliateFroalaText: 'affiliateFroalaText',
  selectInputFields: 'selectInputFields',
  formName: 'formName',
  conversionToolsView: 'conversionToolsView',
  selectItems: 'selectItems',
  selectBooleanItems: 'selectBooleanItems',
  date: 'date',
  radius: 'radius',
  layout: 'layout',
  showCancelSubscription: 'showCancelSubscription',
  border: 'border',
}

export const INPUT_FIELD_KEYS = {
  firstName: 'first_name',
  lastName: 'last_name',
  email: 'email',
  birthday: 'birthday',
  street: 'street',
  zip: 'zip',
  countryCode: 'country_code',
  phone: 'phone',
  custom: 'custom',
}

export const INPUT_FIELDS_SELECT_OPTIONS = [
  {
    label: I18n.t('react.shared.first_name'),
    value: INPUT_FIELD_KEYS.firstName,
  },
  {
    label: I18n.t('react.shared.last_name'),
    value: INPUT_FIELD_KEYS.lastName,
  },
  {
    label: I18n.t('react.shared.email'),
    value: INPUT_FIELD_KEYS.email,
  },
  {
    label: I18n.t('react.shared.birthday'),
    value: INPUT_FIELD_KEYS.birthday,
  },
  {
    label: I18n.t('react.shared.street'),
    value: INPUT_FIELD_KEYS.street,
  },
  {
    label: I18n.t('react.shared.zip'),
    value: INPUT_FIELD_KEYS.zip,
  },
  {
    label: I18n.t('react.shared.country'),
    value: INPUT_FIELD_KEYS.countryCode,
  },
  {
    label: I18n.t('react.shared.phone_number'),
    value: INPUT_FIELD_KEYS.phone,
  },
  {
    label: I18n.t('react.shared.custom_field'),
    value: INPUT_FIELD_KEYS.custom,
  },
]

export const FIELD_TYPE_KEYS = {
  text: 'text',
  textarea: 'textarea',
}

export const FIELD_TYPE_SELECT_OPTIONS = [
  {
    label: I18n.t('react.shared.text'),
    value: FIELD_TYPE_KEYS.text,
  },
  {
    label: I18n.t('react.shared.textarea'),
    value: FIELD_TYPE_KEYS.textarea,
  },
]

export const DEFAULT_INPUT_FIELDS = [
  {
    label: I18n.t('react.shared.first_name'),
    fieldForm: INPUT_FIELD_KEYS.firstName,
    fieldType: FIELD_TYPE_KEYS.text,
    required: true,
  },
  {
    label: I18n.t('react.shared.last_name'),
    fieldForm: INPUT_FIELD_KEYS.lastName,
    fieldType: FIELD_TYPE_KEYS.text,
    required: false,
  },
  {
    label: I18n.t('react.shared.email'),
    fieldForm: INPUT_FIELD_KEYS.email,
    fieldType: FIELD_TYPE_KEYS.text,
    required: false,
  },
]

export const HIGHLIGHTER_DISPLAY_TIME = 1000

export const BLOCKS_WITH_DYNAMIC_CONTENT = ['thank_you_page']

export const EDIT_TEXT_FIELD_TYPES = {
  textarea: 'textarea',
  froala: 'froala',
}
