import React, { useState, useEffect } from 'react'
import { hasWindow } from '@elo-kit/utils/browser.utils'

export const useIntersectionObserver = (
  ref: React.MutableRefObject<Element>,
  // eslint-disable-next-line
  options?: IntersectionObserverInit
  // eslint-disable-next-line
): IntersectionObserverEntry => {
  const [entry, setEntry] = useState(null)

  if (!hasWindow) return entry || {}

  const observer = new IntersectionObserver(([entry]) => setEntry(entry), options)

  useEffect(() => {
    if (!ref.current) return
    observer.observe(ref.current)
    return () => observer.disconnect()
  }, [ref.current])

  return entry || {}
}
