import React from 'react'
import withStyles, { ThemeProvider } from 'react-jss'
import classNames from 'classnames'

import { useI18n } from '@elo-kit/components/i18n/i18n'

import noDataStyles from './NoData.styles'

interface Classes {
  noDataIcon?: string
  noDataIconDarker?: string
  noData?: string
  noDataMessage?: string
}
interface NoDataContainerProps {
  message?: string
  inCollapsible?: boolean
  icon?: string
  classes?: Classes
}

export const NoDataContainer = (props: NoDataContainerProps) => {
  const I18n = useI18n()

  const { message = I18n.t('shared.common.no_data'), inCollapsible = false, icon, classes = {} } = props

  const noDataIconClasses = classNames(classes.noDataIcon, `${icon || 'fas fa-list-ul'}`, {
    [String(classes?.noDataIconDarker)]: inCollapsible,
  })
  return (
    <div className={classes.noData}>
      <i className={noDataIconClasses} />
      <div className={classes.noDataMessage}>{message}</div>
    </div>
  )
}

const NoDataContainerWithStyles = withStyles(noDataStyles)(NoDataContainer)

interface NoDataProps {
  theme?: object
}

export const NoData: React.FC<NoDataProps> = ({ theme = {}, ...restProps }) => (
  <ThemeProvider theme={theme}>
    <NoDataContainerWithStyles {...restProps} />
  </ThemeProvider>
)

NoData.displayName = 'NoData'
