import { COLORS } from '@elo-kit/constants/general.constants'

import { getFontStyles } from '@elo-kit/utils/fonts.utils'
import { cleanupFonts } from 'utils/fonts.utils'

// TODO: update with using createUseStyles
const noProductsFoundMessage = (theme) => ({
  noProductsMessage: {
    width: '100%',
    textAlign: 'center',
    color: theme.searchColor || COLORS.eloBlue,
    fontSize: `${theme.searchSize || 13}px`,
    fontFamily: cleanupFonts(theme.searchFont) || 'Montserrat Reg',
    ...getFontStyles(theme.searchStyle || 'normal'),
    paddingBottom: 20,
    opacity: 0.9,
    marginTop: 10,
  },
})

export default noProductsFoundMessage
