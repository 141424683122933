import { COLORS } from '@elo-kit/constants/general.constants'
import { getFontStyles } from '@elo-kit/utils/fonts.utils'
import { cleanupFonts } from 'utils/fonts.utils'

const lessonsTreeStyles = (theme) => ({
  productBottomContent: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 0,

    '& .custom-shop-product-lessons-tree': {
      fontFamily: cleanupFonts(theme.planFont) || 'Montserrat Medium',
      fontSize: `${theme.planSize || 14}px`,
      ...getFontStyles(theme.planStyle),
      width: '100%',

      '& .row': {
        height: 48,
        borderRadius: 4,
        backgroundColor: '#f0f0f0',
      },

      '& .list-group': {
        transition: 'max-height 0.15s ease-out',

        '&.hidden': {
          maxHeight: 0,
          overflow: 'hidden',
          transition: 'max-height 0.25s ease-in',
        },
      },

      '& .list-group-item': {
        border: 'none',
        borderRadius: 0,
        backgroundColor: '#f0f0f0',
        borderBottom: '2px solid #ffffff',
        zIndex: 1,
        display: 'flex',
        color: theme.planColor || COLORS.eloBlack,
        flexWrap: 'wrap',
        '& a': {
          cursor: 'pointer',
          color: theme.planColor || COLORS.eloBlack,
          '&.has-preview:nth-child(1)': {
            marginRight: '30px',
          },
          '&:hover': {
            textDecoration: 'none',
            opacity: 0.7,
          },
        },
        '& .lesson-name:hover': {
          cursor: 'pointer',
          opacity: 0.7,
        },
      },

      '& .children': {
        '& .list-group-item': {
          paddingLeft: 40,
          backgroundColor: '#f8f8f8',
          borderBottom: '2px solid #eaeaea',
        },

        '& .children .list-group-item': {
          paddingLeft: 60,
          borderBottom: '1px solid #eaeaea',
        },
      },

      '& .fa': {
        cursor: 'pointer',

        '&.fa-angle-right': {
          color: theme.buttonColor || COLORS.eloBlue,
        },

        '&.fa-angle-down': {
          color: '#9e9e9e',
        },
      },

      '& .preview-button': {
        width: 'auto',
        height: 'auto',
        padding: '5px 15px',
        textTransform: 'capitalize',
        borderRadius: 4,
        backgroundColor: [theme.previewButtonColor || COLORS.eloBlue, '!important'],
        borderColor: [theme.previewButtonColor || COLORS.eloBlue, '!important'],
        color: [theme.previewButtonTextColor || COLORS.eloWhite, '!important'],
        boxShadow: 'none !important',
        fontFamily: cleanupFonts(theme.previewButtonFont) || 'Montserrat Reg',
        fontSize: `${theme.previewButtonSize || 13}px`,
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        ...getFontStyles(theme.previewButtonStyle),
        wordBreak: 'break-word',

        '&:hover': {
          opacity: 0.8,
        },

        '&:active': {
          opacity: 0.6,
        },
      },
    },
  },
})

export default lessonsTreeStyles
