import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import withStyles, { ThemeProvider } from 'react-jss'
import classNames from 'classnames'

import buttonInputGroupStyles from './ButtonInputGroup.styles'

export class ButtonInputGroup extends PureComponent {
  constructor(props) {
    super(props)
    const { value, min } = props
    this.state = {
      enabled: false,
      counter: value || min || 0,
    }
  }

  componentDidUpdate(_prevProps, prevState) {
    const { counter, enabled } = this.state
    const { disabled, activateOnClick, onChange } = this.props
    if ((prevState.counter !== counter && (!disabled || activateOnClick)) || enabled) {
      this.turnOffEnabled()
      if (onChange) {
        onChange(counter)
      }
    }
  }

  turnOffEnabled = () => {
    this.setState({ enabled: false })
  }

  handleButtons = (inc) => {
    const { counter } = this.state
    const { disabled, activateOnClick } = this.props

    if (disabled && !activateOnClick) {
      return null
    }

    const incValue = inc ? 1 : -1
    const value = counter + (disabled ? 0 : incValue)
    this.setState({
      counter: this.validateValue(value),
      enabled: disabled,
    })
  }

  validateInput = (e) => {
    const value = parseInt(e.target.value || '', 10) || 0
    this.setState({ counter: this.validateValue(value) })
  }

  validateValue = (value) => {
    const { max, min } = this.props
    if (max && value > max) {
      return max
    }
    if (min && value < min) {
      return min
    }
    return value
  }

  render() {
    const { style, disabled, activateOnClick, inputProps, classes } = this.props
    const { counter } = this.state
    const isDisabled = disabled && !activateOnClick

    return (
      <div className={classes.buttonInputGroup}>
        <button className={classes.buttonCount} disabled={isDisabled} onClick={() => this.handleButtons(false)}>
          <i className={classNames('fas fa-minus', classes.buttonContent)} style={style} />
        </button>
        <input
          type='number'
          className='input-counter'
          disabled={isDisabled}
          value={disabled ? 0 : counter}
          onBlur={this.validateInput}
          onFocus={(e) => e.target.select()}
          onChange={(e) => this.setState({ counter: e.target.value })}
          {...inputProps}
        />
        <button className={classes.buttonCount} disabled={isDisabled} onClick={() => this.handleButtons(true)}>
          <i className={classNames('fas fa-plus', classes.buttonContent)} style={style} />
        </button>
      </div>
    )
  }
}

ButtonInputGroup.defaultProps = {
  inputProps: {},
  style: {},
  classes: {},
}
ButtonInputGroup.propTypes = {
  /** Additional input props */
  inputProps: PropTypes.object,
  /** Buttons style object */
  style: PropTypes.object,
  /** Min value */
  min: PropTypes.number,
  /** Max value */
  max: PropTypes.number,
  /** Disable buttons and input if activateOnClick turned off */
  disabled: PropTypes.bool,
  /** Activates on onclick */
  activateOnClick: PropTypes.bool,
  /** onChange callback */
  onChange: PropTypes.func,
  /** Value */
  value: PropTypes.number,
  /** React JSS classes */
  classes: PropTypes.object,
}

export const StyledButtonInputGroup = withStyles(buttonInputGroupStyles)((props) => <ButtonInputGroup {...props} />)

const ButtonInputGroupPreview = (props) => (
  <ThemeProvider theme={{}}>
    <StyledButtonInputGroup {...props} />
  </ThemeProvider>
)

ButtonInputGroupPreview.propTypes = {
  /** Theme object */
  theme: PropTypes.object,
  /** Additional input props */
  inputProps: PropTypes.object,
  /** Buttons style object */
  style: PropTypes.object,
  /** Min value */
  min: PropTypes.number,
  /** Max value */
  max: PropTypes.number,
  /** Disable buttons and input if activateOnClick turned off */
  disabled: PropTypes.bool,
  /** Activates on onclick */
  activateOnClick: PropTypes.bool,
  /** onChange callback */
  onChange: PropTypes.func,
  /** Value */
  value: PropTypes.number,
}

ButtonInputGroupPreview.defaultProps = {
  theme: {},
}

export default ButtonInputGroupPreview
