import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles, { ThemeProvider } from 'react-jss'
import classNames from 'classnames'
import { get } from 'utils/lodash.utils'

import {
  ALL_PRODUCTS_CATEGORY,
  BLOCK_VIEW,
  DEFAULT_PRODUCTS_LIST,
  PRODUCTS_LIST,
  ALL_PRODUCTS_CATEGORY_OPTION,
} from '@elo-kit/constants/contentPage.constants'
import {
  CUSTOM_CLASS_NAME_OPTION,
  EXTRAS_PRODUCTS_CLASS_NAMES,
  MEMBERSHIP_PRODUCTS_LIST_CLASS_NAMES,
} from '@elo-kit/constants/customCss.constants'
import { ACTIVE_PROFILES } from '@elo-kit/constants/profile.constants'
import { CATEGORIES_VIEW_TYPE, BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'

import { ascendingSort, ascendingSortWithSecondDesc, mapToIdsArray } from '@elo-kit/utils/helpers.utils'
import { getDefaultCategories, getInitialActiveTab } from '@elo-kit/utils/contentPage.utils'
import { isEmpty, isNumber } from '@elo-kit/utils/validators.utils'

import { LoadingMask } from '@elo-kit/components/loading-mask/LoadingMask'
import { useI18n } from '@elo-kit/components/i18n/i18n'
import { CategoriesDropdownWithStyles } from '@elo-kit/components/page-builder/blocks/category-dropdown/CategoriesDropdown'
import { CategoryTabsWithStyles } from '@elo-kit/components/page-builder/blocks/category-tabs/CategoryTabs'
import { NoProductsFound } from '@elo-kit/components/page-builder/blocks/products-search/no-products-found/NoProductsFound'
import membershipProductsStyles from 'shared/components/content-page/preview/blocks/membership-products/MembershipProducts.styles'
import ProductsSearch from '../../products-search/product-search'
import GridMembershipProducts from '../grid-membership-products'
import ListMembershipProducts from '../list-membership-products'

export class ProductsList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: getInitialActiveTab(this.props),
      sortedCategories: [],
      productsList: [],
      membershipProductsQuery: '',
    }
  }

  componentDidMount() {
    const { activeTab } = this.state
    const {
      previewMode,
      fetchCategories,
      toggleLoading,
      block: { content },
      payerId,
      fetchMembershipProducts,
      seller,
      membershipSellable,
      membershipViewType,
      allProductsCategory,
    } = this.props

    if (!previewMode) {
      const productGroupId = activeTab === allProductsCategory.id ? null : activeTab
      const promises = [fetchMembershipProducts({ productGroupId })]
      if (content[PRODUCTS_LIST.showCategories]) {
        const additionalFetchData =
          membershipViewType === ACTIVE_PROFILES.seller ? { ownerId: payerId } : { sellableId: membershipSellable }
        if (!isEmpty(seller)) {
          promises.push(
            fetchCategories({
              sellerId: seller.id,
              ...additionalFetchData,
            })
          )
        }
      } else {
        toggleLoading(false)
      }

      Promise.all(promises).then((responses) => {
        const [membershipProductsList, categoriesResponse] = responses

        if (categoriesResponse) {
          const { data: { list = [] } = {} } = categoriesResponse
          this.prepareCategories(list)
        }

        if (!content[PRODUCTS_LIST.showCategories]) {
          this.setProducts(membershipProductsList)
        }
      })
    } else {
      this.setDefaultCategories()
    }
  }

  componentDidUpdate(prevProps) {
    const {
      block: { content = {} },
      previewMode,
    } = this.props
    const {
      block: { content: prevContent = {} },
    } = prevProps

    const shouldSetDefaultCategories =
      previewMode &&
      (content.categoriesView !== prevContent.categoriesView || content.showAllProducts !== prevContent.showAllProducts)

    if (shouldSetDefaultCategories) {
      this.setDefaultCategories()
    }
  }

  prepareCategories = (list) => {
    const {
      block: { content },
      queryCategoryId,
      allProductsCategory,
    } = this.props
    const { categoriesIds = [], categoriesView, showSelectedCategories } = content

    const categoriesDropdownView = categoriesView === CATEGORIES_VIEW_TYPE.dropdown
    const categoriesToShow = list.filter(({ hidden, id }) =>
      showSelectedCategories ? categoriesIds.includes(String(id)) : !hidden
    )

    let sortedCategories = ascendingSortWithSecondDesc(categoriesToShow)
    const showWithAllProductsCategory = get(content, 'showAllProducts', true)

    sortedCategories = showWithAllProductsCategory ? [allProductsCategory, ...sortedCategories] : sortedCategories

    if (categoriesDropdownView) {
      sortedCategories = sortedCategories.reduce(
        (result, { id, title }) => [
          ...result,
          {
            label: title,
            value: id,
          },
        ],
        []
      )
    }

    this.setSortedCategories(sortedCategories)
    const { id, value } = sortedCategories[0] || {}
    const activeCategoryId = (!!queryCategoryId && Number(queryCategoryId)) || id || value
    const activeCategoryNotHidden = mapToIdsArray(categoriesToShow).includes(activeCategoryId)
    const activeTab = activeCategoryId && activeCategoryNotHidden ? activeCategoryId : allProductsCategory.id
    this.setActiveTab(activeTab)
  }

  setSortedCategories = (sortedCategories) => this.setState({ sortedCategories })

  setDefaultCategories = () => {
    const {
      block: { content },
      allProductsCategoryOption,
    } = this.props
    const { categoriesView } = content

    const categoriesDropdownView = categoriesView === CATEGORIES_VIEW_TYPE.dropdown
    const showWithAllProductsCategory = get(content, 'showAllProducts', true)

    const { defaultCategories, defaultCategoryOptions } = getDefaultCategories(
      showWithAllProductsCategory,
      allProductsCategoryOption
    )

    const sortedCategories = categoriesDropdownView ? defaultCategoryOptions : defaultCategories

    this.setSortedCategories(sortedCategories)
    const firstCategory = sortedCategories[0] || {}
    const activeTabId = isNumber(firstCategory.id) ? firstCategory.id : firstCategory.value

    this.setActiveTab(activeTabId)
  }

  setProducts = (list) => this.setState({ productsList: list })

  setMembershipProductsQuery = (query) => this.setState({ membershipProductsQuery: query })

  handleActiveTabChange = async (query = '') => {
    const {
      fetchMembershipProducts,
      previewMode,
      block: { content },
      allProductsCategory,
      membershipProducts,
    } = this.props

    if (!previewMode) {
      const { activeTab: updatedActiveTab } = this.state
      const productGroupId = updatedActiveTab === allProductsCategory.id ? null : updatedActiveTab
      const updatedMembershipProducts = (await fetchMembershipProducts({ productGroupId })) || membershipProducts
      const filteredByQuery = updatedMembershipProducts.filter(
        ({ name }) => !(content.showSearch && !!query.length) || (name || '').toLowerCase().includes(query)
      )
      this.setProducts(filteredByQuery)
    } else if (query.length) {
      const filteredProducts = membershipProducts.filter(({ name }) => {
        const protectedName = name || ''

        return protectedName.includes(query)
      })
      this.setProducts(filteredProducts)
    } else {
      this.setProducts(membershipProducts)
    }
  }

  setActiveTab = (id, query = '') => {
    const { activeTab } = this.state
    const { previewMode, changeCategoryTabInUrl } = this.props

    if (activeTab !== id && !previewMode) {
      changeCategoryTabInUrl(id)
    }

    this.setState({ activeTab: id }, () => this.handleActiveTabChange(query))
  }

  handleSearch = (data = {}) => {
    const { activeTab } = this.state
    const {
      block: { content },
      membershipProducts,
      allProductsCategory,
    } = this.props
    const trimedQuery = data.query ? data.query.trim().toLowerCase() : ''
    this.setMembershipProductsQuery(trimedQuery)
    const filteredByQuery = membershipProducts.filter(({ name = '' }) => {
      const protectedName = name || ''
      return protectedName.toLowerCase().includes(trimedQuery)
    })

    if (content.showCategories) {
      if (activeTab === allProductsCategory.id) {
        this.setProducts(filteredByQuery)
      } else {
        this.setActiveTab(activeTab, trimedQuery)
      }
    } else {
      this.setProducts(filteredByQuery)
    }
  }

  render() {
    const {
      block: { content },
      classes,
      block,
      previewMode,
      productGroupsLoading,
      membershipProductsLoading,
      membershipProducts,
      view,
      getProductLink,
      LinkComponent,
      yourProgressText,
      defaultProductsList,
      defaultSearchPlaceholder = this.props.I18n.t('shared.common.search'),
      noProductsMessage = this.props.I18n.t('react.shared.no_products_found'),
    } = this.props
    const { activeTab, productsList, sortedCategories, membershipProductsQuery } = this.state
    const listView = content[PRODUCTS_LIST.view] === BLOCK_VIEW.list
    const loading = !previewMode && (productGroupsLoading || membershipProductsLoading)
    const products = previewMode ? defaultProductsList : productsList
    const showCategoriesTabs =
      content[PRODUCTS_LIST.showCategories] && content.categoriesView !== CATEGORIES_VIEW_TYPE.dropdown
    const showCategoriesDropdown =
      content[PRODUCTS_LIST.showCategories] && content.categoriesView === CATEGORIES_VIEW_TYPE.dropdown
    const viewProps = {
      products: ascendingSort(products),
      showProgress: !!content[PRODUCTS_LIST.progress],
      LinkComponent,
      getProductLink,
      locales: {
        yourProgress: yourProgressText,
      },
      blockId: block.id,
      previewMode,
    }

    const hideBlock =
      !previewMode && !membershipProducts.length && !membershipProductsLoading && !content[PRODUCTS_LIST.showCategories]

    const showNoProductsFound = !previewMode && !products?.length && !!membershipProductsQuery
    const containerClasses = classNames(
      MEMBERSHIP_PRODUCTS_LIST_CLASS_NAMES.containerClassName,
      content[CUSTOM_CLASS_NAME_OPTION],
      {
        [classes.hideBlock]: hideBlock,
        [classes.productsListSection]: !hideBlock,
      }
    )

    return (
      <div className={containerClasses}>
        {(showCategoriesDropdown || content.showSearch) && (
          <div
            className={classNames(EXTRAS_PRODUCTS_CLASS_NAMES.controlsContainerClassName, classes.controlsContainer)}
          >
            {showCategoriesDropdown && (
              <div
                {...(previewMode && {
                  'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.membershipProducts.categories,
                  'data-highlighter-selector': `.${EXTRAS_PRODUCTS_CLASS_NAMES.categoriesDropdownContainerClassName}`,
                })}
              >
                <CategoriesDropdownWithStyles
                  activeOption={String(activeTab)}
                  options={sortedCategories}
                  setOption={(value) => this.setActiveTab(value, membershipProductsQuery)}
                />
              </div>
            )}

            {content.showSearch && (
              <ProductsSearch
                block={block}
                handleFetch={this.handleSearch}
                defaultSearchPlaceholder={defaultSearchPlaceholder}
                view={view}
                previewMode={previewMode}
                highlighterId={BLOCK_MENU_HIGHLIGHT_IDS.membershipProducts.search}
              />
            )}
          </div>
        )}

        {showCategoriesTabs && (
          <div
            {...(previewMode && {
              'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.membershipProducts.categories,
              'data-highlighter-selector': `.${EXTRAS_PRODUCTS_CLASS_NAMES.categoriesTabsCategoriesListClassName}`,
            })}
          >
            <CategoryTabsWithStyles activeTab={activeTab} tabs={sortedCategories} setActiveTab={this.setActiveTab} />
          </div>
        )}

        {listView ? <ListMembershipProducts {...viewProps} /> : <GridMembershipProducts {...viewProps} />}

        {showNoProductsFound && <NoProductsFound block={block} noProductsMessage={noProductsMessage} />}
        {loading && <LoadingMask dark />}
      </div>
    )
  }
}

ProductsList.propTypes = {
  /** Membership product list classes object */
  classes: PropTypes.object,
  /** Block object */
  block: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    content: PropTypes.object,
  }),
  /** Property for check is PageBuilder in preview mode */
  previewMode: PropTypes.bool,
  /** Browser history object */
  queryCategoryId: PropTypes.string,
  /** Get product link function received from contentPageStore */
  getProductLink: PropTypes.func,
  /** Membership products loading received from contentPageStore */
  membershipProductsLoading: PropTypes.bool,
  /** Fetch membership products function received from contentPageStore */
  fetchMembershipProducts: PropTypes.func,
  /** Seller data received from contentPageStore */
  seller: PropTypes.object,
  /** Membership sellable id received from contentPageStore */
  membershipSellable: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Membership view type received from contentPageStore */
  membershipViewType: PropTypes.string,
  /** Membership products received from contentPageStore */
  membershipProducts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  /** View(cabinet/shop) received from contentPageStore */
  view: PropTypes.string,
  /** Fetch all product groups function received from productGroupsStore */
  fetchCategories: PropTypes.func,
  /** Toggle loading function received from productGroupsStore */
  toggleLoading: PropTypes.func,
  /** Product groups loading received from productGroupsStore */
  productGroupsLoading: PropTypes.bool,
  /** Wrapper link for url */
  LinkComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /** Search placeholder text */
  defaultSearchPlaceholder: PropTypes.string,
  /** No products message text */
  noProductsMessage: PropTypes.string,
  /** Change category tab url received from block.utils */
  changeCategoryTabInUrl: PropTypes.func,
  /** Payer item id received from payer store */
  payerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Query string receiver from library query-string */
  queryString: PropTypes.object, // use for queryString.parse
  /** Progress default text */
  yourProgressText: PropTypes.string, // I18n.t('react.cabinet.yourProgress')
  /** Default products list from content page constants */
  defaultProductsList: PropTypes.array,
  /** All products category from content page constants */
  allProductsCategory: PropTypes.object,
  /** All products category option from content page constants */
  allProductsCategoryOption: PropTypes.object,
  I18n: PropTypes.any,
}
ProductsList.defaultProps = {
  seller: {},
  yourProgressText: 'Your progress', // I18n.t('react.cabinet.yourProgress')
  classes: {},
  block: {
    content: {},
  },
  membershipProducts: [],
  LinkComponent: 'a', // use 'Link' in general project,
  toggleLoading: /* istanbul ignore next */ () => {},
  fetchCategories: /* istanbul ignore next */ () => {}, // add fetchFullList as props
  changeCategoryTabInUrl: /* istanbul ignore next */ () => {}, // (id) => changeCategoryTabInUrl(history, id)
  fetchMembershipProducts: /* istanbul ignore next */ () => {},
  defaultProductsList: DEFAULT_PRODUCTS_LIST, // DEFAULT_PRODUCTS_LIST
  allProductsCategory: ALL_PRODUCTS_CATEGORY, // ALL_PRODUCTS_CATEGORY
  allProductsCategoryOption: ALL_PRODUCTS_CATEGORY_OPTION, // ALL_PRODUCTS_CATEGORY_OPTION,
  I18n: {
    t: () => {},
  },
}

export const MembershipProductsPreview = (props) => {
  const {
    block: { content },
    block,
    previewMode,
    categorySelectFieldContentBefore,
  } = props
  const I18n = useI18n()

  const theme = {
    ...content,
    categorySelectFieldContentBefore,
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledMembershipProductsList {...props} block={block} previewMode={previewMode} I18n={I18n} />
    </ThemeProvider>
  )
}

const StyledMembershipProductsList = withStyles(membershipProductsStyles)(ProductsList)
MembershipProductsPreview.propTypes = {
  /** Block object */
  block: PropTypes.shape({
    content: PropTypes.object,
  }),
  /** Property for check is PageBuilder in preview mode */
  previewMode: PropTypes.bool,
  /** Category content before */
  categorySelectFieldContentBefore: PropTypes.string,
}
MembershipProductsPreview.defaultProps = {
  block: {
    content: {},
  },
  categorySelectFieldContentBefore: 'Categories', // I18n.t('shared.common.category'),
}

export default MembershipProductsPreview
