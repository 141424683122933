import React, { useEffect, useRef, useState } from 'react'
import { useIntersectionObserver } from '@elo-kit/hooks/use-intersection-observer'

export const useAudioPlayerActions = (props) => {
  const { setFixedPlayerId = () => {}, block = {}, good = {}, audioPlayer, previewMode } = props

  const [audioSpeed, setAudioSpeed] = useState(1.0)
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [audioStatusClasses, setAudioStatusClasses] = useState('')
  const [currentAudioPlayer, setCurrentAudioPlayer] = useState(null)

  const audioRef = useRef(null)

  const { isIntersecting } = !previewMode && useIntersectionObserver(audioRef, { rootMargin: '15px' })

  const { audio } = currentAudioPlayer || {}
  const { audioBlockId } = audio || {}
  const { digital = {} } = good
  const { content = {} } = block
  const audioId = `${digital.id}-${block.id}`

  const handleCollapse = (e: React.MouseEvent<HTMLElement, MouseEvent>, value: boolean) => {
    e.stopPropagation()
    setIsCollapsed(value)
  }

  const closeAudioWidget = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setFixedPlayerId(null)
    handleCollapse(e, false)
    currentAudioPlayer?.setAudioStatus('pause')
    currentAudioPlayer?.pause()
  }

  const handleAudioSpeedChange = (audioSpeed: number) => {
    setAudioSpeed(audioSpeed)
    currentAudioPlayer?.setAudioSpeed(audioSpeed)
  }

  const onPlay = () => {
    setAudioStatusClasses('play elo-play')
  }

  const onPause = () => {
    setAudioStatusClasses('pause elo-pause')
  }

  useEffect(() => {
    if (!isIntersecting && currentAudioPlayer?.isPlaying()) {
      setFixedPlayerId(audioBlockId)
    }
  }, [isIntersecting, currentAudioPlayer])

  useEffect(() => {
    if (!audioPlayer && !previewMode) return
    audioPlayer.init(audioRef.current)
    setCurrentAudioPlayer(audioPlayer.getPlayerById(audioId))
    return () => audioPlayer.removePlayer(audioId)
  }, [audioRef])

  useEffect(() => {
    currentAudioPlayer?.setProgressColor(`${content.progressColor}33`)
    currentAudioPlayer?.setCurrentProgressColor(content.progressColor)
  }, [content.progressColor, currentAudioPlayer])

  return [
    { audioSpeed, isCollapsed, audioStatusClasses, currentAudioPlayer, audioRef },
    { handleCollapse, closeAudioWidget, handleAudioSpeedChange, onPlay, onPause },
  ]
}
