import { COLORS } from '@elo-kit/constants/general.constants'

const { eloGrey10, eloGrey30, eloGrey50, eloGrey60 } = COLORS

// TODO: update with using createUseStyles
const videoRenderingStyles = () => ({
  videoRenderingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '400px',
    backgroundColor: eloGrey10,
    border: `1px solid ${eloGrey30}`,
  },

  videoRendering: {
    textAlign: 'center',

    '& i': {
      fontSize: '50px',
      lineHeight: '34px',
      color: eloGrey50,
    },
  },

  videoRenderingText: {
    fontSize: 13,
    marginTop: 20,
    color: eloGrey60,
  },
})

export default videoRenderingStyles
