import React from 'react'
import classNames from 'classnames'

import { useI18n } from '@elo-kit/components/i18n/i18n'

import { AUDIO_CLASS_NAMES } from '@elo-kit/constants/customCss.constants'
import { DEFAULT_DURATION } from '@elo-kit/constants/general.constants'
import { AUDIO_TYPES, BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { COLORS } from '@elo-kit/constants/pageBuilder.constants'
import { isEmpty } from '@elo-kit/utils/validators.utils'

import { useAudioPlayerActions } from './hooks/use-audio-player-actions'

import { AudioPlayerSpeed, AudioPlayerButtons, AudioPlayerFileInfo, AudioPlayerImage } from './controls'

interface Props {
  classes: Record<string, string>
  block?: Record<string, any>
  good?: Record<string, any>
  previewMode?: boolean
  id?: string | number
  defaultSpeedText?: string
  fixableAudioPlayer?: boolean
  fixedPlayerId?: string | null
  setFixedPlayerId?: (id: string | null) => void
  audioPlayer?: any
}

const formatAudioTime = (duration: number) => {
  let minutes: string | number = Math.floor(duration / 60)
  let seconds: string | number = Math.floor(duration % 60)

  if (minutes < 10) {
    minutes = `0${minutes}`
  }

  if (seconds < 10) {
    seconds = `0${seconds}`
  }

  return `${minutes}:${seconds}`
}

export const AudioPlayerView: React.FC<Props> = (props) => {
  const I18n = useI18n()
  const {
    block = {},
    good = {},
    previewMode,
    classes = {},
    id,
    fixedPlayerId = null,
    fixableAudioPlayer = false,
    defaultSpeedText = I18n.t('react.shared.speed'),
  } = props

  const [
    { audioSpeed, isCollapsed, audioStatusClasses, currentAudioPlayer, audioRef },
    { handleCollapse, closeAudioWidget, handleAudioSpeedChange, onPlay, onPause },
  ] = useAudioPlayerActions(props)

  const { content = {} } = block || {}
  const { digital = {}, className } = good || {}
  const { isAudio, file = {} } = digital || {}

  const isDefaultView = content.audioView === AUDIO_TYPES.default

  const { audio } = currentAudioPlayer || ({} as any)
  const { audioBlockId } = audio || {}

  const fixedPos = fixableAudioPlayer && fixedPlayerId === audioBlockId

  const isEmptyGoodFile = isEmpty(file)

  const audioId = `${digital.id}-${block.id}`

  const filesContainerClasses = classNames(`content-block-filelist ${classes.filesContainer}`, {
    [classes.filesContainerNoPadding]: fixedPos,
  })

  const fileContainerClasses = classNames(
    `file-wrapper ${AUDIO_CLASS_NAMES.containerClassName} ${classes.fileContainer}`,
    { [classes.fixedPlayer]: fixedPos }
  )

  const audioProgressBarClasses = classNames(`progressbar ${classes.audioProgressBar}`, {
    [classes.backgroundProgressBar]: isEmptyGoodFile,
    [classes.widgetAudioProgressBar]: fixedPos,
  })

  const mobileAudioClasses = classNames(`mobile-player ${classes.mobileCollapsed}`, {
    [classes.hideMobileCollapsed]: fixedPos && !isCollapsed,
  })
  const fileNameClasses = classNames(`file ${classes.fileName}`, {
    [classes.mobileCollapsedFileName]: !fixedPos || !isCollapsed,
  })
  const notCollapsedPlayButton = classNames(classes.mobileNotCollapsedButton, {
    [classes.showNotCollapsedButton]: fixedPos && !isCollapsed,
  })
  const audioSpeedClasses = classNames(classes.defaultDuration, {
    [classes.hideAudioSpeed]: fixedPos && !isCollapsed,
  })
  const collapsedArrowClasses = classNames({
    'fas fa-chevron-down': isCollapsed,
    'fas fa-chevron-up': !isCollapsed,
  })

  if (!isAudio) return <div />

  return (
    <div className={filesContainerClasses}>
      <div
        id={String(id)}
        className={fileContainerClasses}
        onClick={(e) => {
          if (fixedPos) {
            handleCollapse(e, true)
          }
        }}
        {...(previewMode && {
          'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.audioPlayer.audioView,
          'data-highlighter-selector': '',
        })}
      >
        {fixedPos && (
          <div className={classes.mobileAudioClose}>
            <div className={classes.mobileAudioIcon} onClick={(e) => handleCollapse(e, !isCollapsed)}>
              <i className={collapsedArrowClasses} />
            </div>
            <div className={classes.mobileAudioIcon}>
              <i className='fas fa-times-circle' onClick={closeAudioWidget} />
            </div>
          </div>
        )}
        {fixedPos && (
          <div className={classes.audioClose}>
            <i className='fas fa-times-circle' onClick={closeAudioWidget} />
          </div>
        )}
        <div
          ref={audioRef}
          className={classNames(
            'audio-player',
            AUDIO_CLASS_NAMES.playerContainerClassName,
            classes.fileDetails,
            className
          )}
          data-id={audioId}
          data-audio-block-id={block.id}
          data-style='full'
          data-duration-full={file.duration || digital.duration}
          data-progress-color={`${content.progressColor || COLORS.eloBlue}33`}
          data-current-progress-color={content.progressColor || COLORS.eloBlue}
          data-speed={audioSpeed}
        >
          <div className={classes.audioContainer}>
            <AudioPlayerImage backgroundImage={content.backgroundImage} classes={classes} previewMode={previewMode} />
            <div className={fileNameClasses}>
              <div className={classNames(classes.fileInfoContainer, AUDIO_CLASS_NAMES.detailsContainerClassName)}>
                <AudioPlayerFileInfo
                  additionalText={content.additionalText}
                  titleText={content.titleText}
                  classes={classes}
                  previewMode={previewMode}
                />
                <div
                  className={classNames(classes.audioButtonContainer)}
                  {...(previewMode && {
                    'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.audioPlayer.color,
                    'data-highlighter-selector': '',
                  })}
                >
                  <div className={classes.audioButtons}>
                    <AudioPlayerButtons
                      audioSrc={file.original}
                      audioStatus={audioStatusClasses}
                      audioButtonClass={classes.audioButton}
                      buttonColor={content.buttonColor || COLORS.eloBlue}
                      onPause={onPause}
                      onPlay={onPlay}
                    />
                  </div>
                  <div className={classNames(AUDIO_CLASS_NAMES.infoContainerClassName, classes.audioInfo)}>
                    {!isDefaultView && <div className='duration'>{DEFAULT_DURATION}</div>}
                    <div className={audioProgressBarClasses} />
                    {!isDefaultView && (
                      <div className='all-duration'>{formatAudioTime(file.duration || digital.duration)}</div>
                    )}
                  </div>
                </div>
                <div className={audioSpeedClasses}>
                  <AudioPlayerSpeed
                    classes={classes}
                    goodId={good.id}
                    audioSpeed={audioSpeed}
                    handleAudioSpeedChange={handleAudioSpeedChange}
                    defaultSpeedText={defaultSpeedText}
                  />
                  {isDefaultView && (
                    <div className={classNames(AUDIO_CLASS_NAMES.infoContainerClassName, classes.audioInfo)}>
                      <div className='all-duration'>{formatAudioTime(file.duration || digital.duration)}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={notCollapsedPlayButton}>
              <i className={`fas audio-button ${classes.audioButton} ${audioStatusClasses || 'pause'}`}>
                <audio onPause={onPause} onPlay={onPlay} className='audio-play' preload='metadata'>
                  <source src={file.icon} type='audio/mpeg' />
                </audio>
              </i>
            </div>
          </div>

          <div className={mobileAudioClasses}>
            {isDefaultView && <div className='fields-container__horisontal-separator' />}

            <div className={classes.mobileAudioInfo}>
              {!isDefaultView && <div className='duration'>{DEFAULT_DURATION}</div>}
              <div className={audioProgressBarClasses} />
              {!isDefaultView && (
                <div className='all-duration'>{formatAudioTime(file.duration || digital.duration)}</div>
              )}
            </div>

            <div className={`${classes.mobileAudioButtonsContainer} ${classes.mobileAudioInfo}`}>
              {isDefaultView && <div className='duration'>{DEFAULT_DURATION}</div>}
              <div className={classes.mobileAudioButtons}>
                <AudioPlayerButtons
                  audioSrc={file.icon}
                  audioStatus={audioStatusClasses}
                  audioButtonClass={classes.mobileAudioPlayButton}
                  buttonColor={content.buttonColor || COLORS.eloBlue}
                  onPause={onPause}
                  onPlay={onPlay}
                />
              </div>
              {isDefaultView && (
                <div className='all-duration'>{formatAudioTime(file.duration || digital.duration)}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
