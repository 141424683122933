import React from 'react'
import classNames from 'classnames'
import withStyles, { ThemeProvider } from 'react-jss'

import progressBarStyles from './ProgressBar.styles'

const ROUND_PRECISION = 1000

interface ProgressBarProps {
  theme?: object
  label?: React.ReactNode
}
interface Classes {
  progressBarContainer: Record<string, boolean>
  progressBarItem: Record<string, boolean>
}
interface ProgressBarComponentProps {
  classes?: Classes
  min?: string | number
  now?: string | number
  max?: string | number
  label?: React.ReactNode
  className?: string
}

export const getPercentage = (now: number, min: number, max: number): number => {
  const percentage = ((now - min) / (max - min)) * 100

  return Math.round(percentage * ROUND_PRECISION) / ROUND_PRECISION
}

/**
 * Progress Bar - progress indicator
 */
export const ProgressBarComponent: React.FC<ProgressBarComponentProps> = ({
  now,
  min = 0,
  max,
  className = '',
  label = '',
  classes = {},
}) => {
  const containerClasses = classNames('progress-bar-container', classes?.progressBarContainer, className)

  return (
    <>
      <div
        className={classNames('progress-bar-item', classes?.progressBarItem)}
        style={{ width: `${getPercentage(Number(now), Number(min), Number(max))}%` }}
        aria-valuenow={Number(now)}
        aria-valuemin={Number(min)}
        aria-valuemax={Number(max)}
      />
      <div className={containerClasses}>{label ? <span>{label}</span> : null}</div>
    </>
  )
}

export const ProgressBarWithStyles = withStyles(progressBarStyles)(ProgressBarComponent)

/**
 * Pdf Reader - PDF reader component with styles
 */
export const ProgressBar: React.FC<ProgressBarProps> = (props) => {
  const { theme = {} } = props
  return (
    <ThemeProvider theme={theme}>
      <ProgressBarWithStyles {...props} />
    </ThemeProvider>
  )
}
