import React from 'react'
import { components } from 'react-select'
import { EloDeleteIcon } from '@elo-ui/components/icons/regular'

const ClearIndicator = (props) => (
  <components.ClearIndicator {...props}>
    <EloDeleteIcon />
  </components.ClearIndicator>
)

export { ClearIndicator }
