import { COLORS } from '../../constants/general.constants'

// TODO: update with using createUseStyles
const progressBarStyles = () => {
  const { eloBlue } = COLORS

  return {
    progressBarContainer: {
      display: 'flex',
      backgroundColor: eloBlue,
      height: '4px',
      width: '100%',
      position: 'absolute',
      opacity: 0.2,
    },
    progressBarItem: {
      height: '4px',
      position: 'absolute',
      backgroundColor: eloBlue,
    },
  }
}

export default progressBarStyles
