import React, { useEffect } from 'react'
import ReCaptcha from 'react-google-recaptcha'
import { DEFAULT_LOCALE } from 'constants/locale.constants'
import { ENVIRONMENT, noop } from 'constants/general.constants'
import { getElopageConfig } from 'utils/elopageConfig.utils'
import { LocalStorageService } from 'utils/local-storage.utils'

export const ReCaptchaField = ({ recaptchaRef, locale = DEFAULT_LOCALE }) => {
  const env = getElopageConfig('env')
  const isUatOrStaging = env === ENVIRONMENT.uat || env === ENVIRONMENT.staging
  const recaptchaKey =
    (isUatOrStaging && LocalStorageService.getItem('recaptchaKey', '', true)) || getElopageConfig('reCaptchaKey')

  // hack to handle unmounting component gracefully
  // better possible solutions - 1. use another library, 2. write own wrapper under google re-captcha
  useEffect(() => {
    if (recaptchaRef.current) {
      recaptchaRef.current.componentWillUnmount = noop
    }
  }, [recaptchaRef.current])

  return <ReCaptcha ref={recaptchaRef} sitekey={recaptchaKey} hl={locale} size='invisible' />
}
