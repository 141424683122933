export function compareTwoStrings(first: string, second: string) {
  const replacedFirst = first.replace(/\s+/g, '')
  const replaceSecond = second.replace(/\s+/g, '')

  if (!replacedFirst.length && !replaceSecond.length) return 1 // if both are empty strings
  if (!replacedFirst.length || !replaceSecond.length) return 0 // if only one is empty string
  if (replacedFirst === replaceSecond) return 1 // identical
  if (replacedFirst.length === 1 && replaceSecond.length === 1) return 0 // both are 1-letter strings
  if (replacedFirst.length < 2 || replaceSecond.length < 2) return 0 // if either is a 1-letter string

  const firstBigrams = new Map()
  for (let i = 0; i < replacedFirst.length - 1; i++) {
    const bigram = replacedFirst.substring(i, i + 2)
    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) + 1 : 1

    firstBigrams.set(bigram, count)
  }

  let intersectionSize = 0
  for (let i = 0; i < replaceSecond.length - 1; i++) {
    const bigram = replaceSecond.substring(i, i + 2)
    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) : 0

    if (count > 0) {
      firstBigrams.set(bigram, count - 1)
      intersectionSize++
    }
  }

  return (2.0 * intersectionSize) / (replacedFirst.length + replaceSecond.length - 2)
}
