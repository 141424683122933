import React, { Component } from 'react'
import { Tooltip } from 'reactstrap'
import classNames from 'classnames'
import Popper from '@popperjs/core'

import { IconInfo } from '@elo-kit/components/icons/IconInfo'
import { POSITIONS } from '@elo-kit/constants/general.constants'

import './custom-tooltip.scss'

interface Props {
  tooltipIcon?: (id?: string) => React.ReactNode
  placement?: POSITIONS
  body?: string
  title?: string
  component?: boolean
  id?: string
  tooltipClasses?: string
  onClick?: () => void
  disabled?: boolean
  containerClasses?: string
  iconClassName?: string
  innerClassName?: string
}

interface State {
  popoverOpen: boolean
}

export class CustomTooltip extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      popoverOpen: false,
    }
  }

  static displayName = 'CustomTooltip'

  togglePopover = () => {
    const { popoverOpen } = this.state

    this.setState({ popoverOpen: !popoverOpen })
  }

  render() {
    const {
      tooltipIcon,
      placement,
      body,
      title,
      component,
      id,
      tooltipClasses,
      onClick,
      disabled,
      containerClasses,
      iconClassName,
      innerClassName,
    } = this.props
    const { popoverOpen } = this.state

    const wrapperClasses = classNames('custom-tooltip', containerClasses)
    const iconClasses = classNames('popover-info-icon', iconClassName)
    const onClickHandler = !disabled ? onClick : undefined

    return (
      <span className={wrapperClasses} onClick={onClickHandler}>
        {tooltipIcon ? tooltipIcon(id) : <IconInfo id={id} className={iconClasses} />}
        <Tooltip
          placement={(placement as Popper.Placement) || 'top'}
          isOpen={popoverOpen}
          target={String(id)}
          toggle={this.togglePopover}
          autohide={false}
          className={tooltipClasses}
          innerClassName={innerClassName}
        >
          <div className='custom-tooltip__title'>{title}</div>
          <div className={`custom-tooltip__content ${component ? 'text-center' : ''}`}>
            {component ? body : <div dangerouslySetInnerHTML={{ __html: body }} />}
          </div>
        </Tooltip>
      </span>
    )
  }
}

CustomTooltip.displayName = 'CustomTooltip'
