import { COLORS } from '../../../constants/general.constants'

const { eloGrey50 } = COLORS

// TODO: update with using createUseStyles
const noDataStyles = () => ({
  noData: {
    textAlign: 'center',
    marginBottom: 20,
  },

  noDataIcon: {
    fontSize: 60,
    color: '#e5e2e5',
    margin: '20px 0 15px',
  },

  noDataIconDarker: {
    color: '#c8c8c8',
  },

  noDataMessage: {
    fontSize: 13,
    color: eloGrey50,
  },
})

export default noDataStyles
