import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import withStyles, { ThemeProvider } from 'react-jss'

import { POPULAR_PRODUCTS_CLASS_NAMES } from '@elo-kit/constants/customCss.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { getFirstCoverUrl } from '@elo-kit/utils/product.utils'
import { FREE_PRICE } from 'constants/general.constants'
import { CoverWidget } from '@elo-kit/components/elo-ui/cover-widget'
import { EloSlider } from '@elo-kit/components/elo-slider'

import popularProductsStyles from 'shared/components/content-page/preview/blocks/popular-products/PopularProducts.styles'

import { formatToMoney } from 'utils/money.utils'
import { isEmpty } from 'utils/validatorsShared.utils'

const SLIDER_CONFIGS = {
  oneSlide: {
    activeElementScale: 1,
    modifier: 1,
  },
  threeSlides: {
    activeElementScale: 1.185,
    modifier: 3,
  },
}

const SliderPopularProducts = (props) => {
  const {
    buttonAnimation,
    buttonText,
    classes,
    defaultBtnText,
    freeButtonText,
    getUnit,
    handleButtonClick,
    products,
    productsContent,
    inEdit,
    previewMode,
    mobileView,
    id,
    theme,
  } = props
  const { itemsPerSlide, showPrice } = theme

  const oneSlideView = mobileView || products.length < 3 || Number(itemsPerSlide) === 1

  let sliderConfigs = SLIDER_CONFIGS.threeSlides

  if (oneSlideView) {
    sliderConfigs = SLIDER_CONFIGS.oneSlide
  }

  const buttonClasses = classNames('btn', classes.button, classes.gridButton, {
    [classes[buttonAnimation]]: !!buttonAnimation,
  })

  const backgroundImageContainerClasses = classNames(
    'products-preview__background-image',
    classes.backgroundImageContainer
  )

  const slideItemClasses = classNames('slide-item my-auto', classes.slideItem)

  const bottomBlockClasses = classNames('slide-item__bottom-block', classes.productBackground)

  const slideItems = products.map((product) => {
    const { free, id: productId, name, displayCurrencyId, displayPrice } = product

    const productCovers = productsContent?.find((image) => image.id === product.covers?.[0]?.id)?.attributes || {}
    const btnText = free ? freeButtonText : buttonText
    const currencySign = getUnit(displayCurrencyId)

    return (
      <div className={classNames(POPULAR_PRODUCTS_CLASS_NAMES.sliderItemClassName, slideItemClasses)} key={productId}>
        {showPrice && (
          <div
            className={classNames(classes.priceBlock)}
            {...(previewMode && {
              'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.popularProducts.priceBlock,
              'data-highlighter-selector': '',
            })}
          >
            <span
              className={classNames(POPULAR_PRODUCTS_CLASS_NAMES.sliderPriceClassName, classes.price)}
              translate='no'
            >
              {free ? FREE_PRICE : formatToMoney(displayPrice, currencySign)}
            </span>
          </div>
        )}
        <div
          className={backgroundImageContainerClasses}
          {...(previewMode && {
            'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.popularProducts.selectedProducts,
            'data-highlighter-selector': '',
          })}
        >
          {getFirstCoverUrl(product, 's640') || !isEmpty(productCovers) ? (
            <CoverWidget
              img={product.covers?.[0]?.url || productCovers.original}
              cover={productsContent?.length ? { file: productCovers } : product.covers?.[0]}
              unresponsive
              className={classNames(POPULAR_PRODUCTS_CLASS_NAMES.gridImageClassName)}
            />
          ) : (
            <i className='far fa-image m-auto' />
          )}
        </div>
        <div
          className={classNames(POPULAR_PRODUCTS_CLASS_NAMES.sliderDetailsClassName, bottomBlockClasses)}
          {...(previewMode && {
            'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.popularProducts.productBackground,
            'data-highlighter-selector': '',
          })}
        >
          <div
            className={classNames(classes.productName, POPULAR_PRODUCTS_CLASS_NAMES.sliderTitleClassName)}
            {...(previewMode && {
              'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.popularProducts.productsTitles,
              'data-highlighter-selector': '',
            })}
          >
            {name}
          </div>
          <button
            type='button'
            onClick={(e) => handleButtonClick(product, e)}
            className={buttonClasses}
            {...(previewMode && {
              'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.popularProducts.productButton,
              'data-highlighter-selector': '',
            })}
          >
            {btnText || defaultBtnText}
          </button>
        </div>
      </div>
    )
  })

  return (
    <div
      className={classNames(POPULAR_PRODUCTS_CLASS_NAMES.sliderContainerClassName, classes.slider)}
      {...(previewMode && {
        'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.popularProducts.blockSlider,
        'data-highlighter-selector': '',
      })}
    >
      <EloSlider
        slideItems={slideItems}
        style={sliderConfigs}
        id={id}
        updateDisabled={previewMode && !inEdit}
        previewType={previewMode}
      />
    </div>
  )
}

SliderPopularProducts.propTypes = {
  /**
   * Button animation type
   */
  buttonAnimation: PropTypes.string,
  /**
   * Button text for a non-free product
   */
  buttonText: PropTypes.string, // I18n.t('shared.common.buy_now')
  classes: PropTypes.shape({
    backgroundImageContainer: PropTypes.string,
    button: PropTypes.string,
    gridButton: PropTypes.string,
    price: PropTypes.string,
    priceBlock: PropTypes.string,
    productBackground: PropTypes.string,
    productCover: PropTypes.string,
    productName: PropTypes.string,
    slideItem: PropTypes.string,
    slider: PropTypes.string,
  }),
  /** Convert price without currency function */
  convertToPriceWithoutCurrency: PropTypes.func, // (value) => value.formatMoney(2, ',', '.')
  defaultBtnText: PropTypes.string,
  freeButtonText: PropTypes.string,
  freeProductText: PropTypes.string,
  /**
   * GetUnit function received from currency storeslideItem
   */
  getUnit: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inEdit: PropTypes.bool,
  isCourseTheme: PropTypes.bool,
  /**
   * Button click function
   */
  handleButtonClick: PropTypes.func,
  mobileView: PropTypes.bool,
  previewMode: PropTypes.bool,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      free: PropTypes.bool,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    })
  ),
  theme: PropTypes.shape({
    itemsPerSlide: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    showPrice: PropTypes.bool,
  }),
}

const SliderPopularProductsWithStyles = withStyles(popularProductsStyles)(SliderPopularProducts)

export const SliderPopularProductsWithThemeProvider = ({ theme, ...restProps }) => (
  <ThemeProvider theme={theme || {}}>
    <SliderPopularProductsWithStyles
      {...{
        theme,
        ...restProps,
      }}
    />
  </ThemeProvider>
)

SliderPopularProductsWithThemeProvider.propTypes = {
  /**
   * Theme object
   */
  theme: PropTypes.shape({}),
}

export default SliderPopularProductsWithStyles
