import React from 'react'
import classNames from 'classnames'
import withStyles, { ThemeProvider } from 'react-jss'
import PropTypes from 'prop-types'

import { EXTRAS_PRODUCTS_CLASS_NAMES } from '@elo-kit/constants/customCss.constants'
import categoryTabsStyles from './CategoryTabs.styles'

/**
 * CategoryTabs - main container for the Page Builder's CategoryTabs Block
 */
export const CategoryTabs = ({ classes, activeTab, tabs, setActiveTab }) => {
  const tabDescription = (tabs.find((tab) => tab.id === activeTab) || {}).description
  return (
    <div
      className={classNames(
        EXTRAS_PRODUCTS_CLASS_NAMES.categoriesTabsContainerClassName,
        classes.categoriesListContainer
      )}
    >
      <ul
        className={classNames(
          classes.categoriesList,
          EXTRAS_PRODUCTS_CLASS_NAMES.categoriesTabsCategoriesListClassName
        )}
      >
        {tabs.map((tab) => {
          const { id, title } = tab
          const tabClasses = classNames(classes.category, activeTab === id && classes.activeCategory)

          return (
            <li className={classes.categoryContainer} key={id}>
              <span className={tabClasses} onClick={() => setActiveTab(id)}>
                {title}
              </span>
            </li>
          )
        })}
      </ul>

      {tabDescription && !!activeTab && <div className={classes.tabDescription}>{tabDescription}</div>}
    </div>
  )
}

CategoryTabs.propTypes = {
  /**
   * React JSS classes
   */
  classes: PropTypes.object,
  /**
   * Indicates selected tab
   */
  activeTab: PropTypes.number,
  /**
   * Tabs to render
   */
  tabs: PropTypes.array,
  /**
   * Function that runs on tab change
   */
  setActiveTab: PropTypes.func,
}

CategoryTabs.defaultProps = {
  classes: {},
  activeTab: null,
  tabs: [],
  setActiveTab: /* istanbul ignore next */ () => {},
}

export const CategoryTabsWithStyles = withStyles(categoryTabsStyles)(CategoryTabs)

/**
 * CategoryTabs - Page Builder's CategoryTabs Block with react-jss injection
 */
export const CategoryTabsWithThemeProvider = (props) => (
  <ThemeProvider theme={{}}>
    <CategoryTabsWithStyles {...props} />
  </ThemeProvider>
)
