import { createUseStyles } from 'react-jss'
import { BUTTON_STYLE } from '@elo-kit/constants/contentPage.constants'
import { buttonAnimations } from '@elo-kit/utils/animations.utils'
import { ZERO_DATA } from '@elo-kit/constants/general.constants'
import { getAlignment } from '@elo-kit/utils/themes.utils'
import { getFontStyles } from '@elo-kit/utils/fonts.utils'
import { cleanupFonts } from 'utils/fonts.utils'

export const useButtonStyles = createUseStyles((theme) => ({
  container: {
    padding: '10px 0',
    display: 'flex',
    justifyContent: getAlignment(theme.horizontalAlignment),
    alignItems: 'center',
    height: '100%',
    maxWidth: '100%',
    minHeight: `${theme.minHeight || ZERO_DATA}px`,
    position: 'relative',
  },
  button: {
    background: theme.buttonViewStyle === BUTTON_STYLE.outlined ? 'none' : theme.buttonColor || '#1e84d7',
    color: theme.buttonTextColor || '#FFFFFF',
    border: `2px solid ${theme.buttonColor || '#1e84d7'}`,
    padding: '12px 30px',
    maxWidth: `${theme.buttonWidth || 170}px`,
    width: `${theme.buttonWidth || 170}px`,
    borderRadius: `${theme.radius === undefined ? 30 : theme.radius}px`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: cleanupFonts(theme.buttonFont) || 'Montserrat Medium',
    ...getFontStyles(theme.buttonStyle || 'bold'),
    fontSize: `${theme.buttonSize || 20}px`,
    cursor: 'pointer',
    wordBreak: 'break-word',
    textAlign: 'center',
    lineHeight: 1,
    zIndex: 1,
    '&:focus': {
      outline: 'none',
    },
    '&:active': {
      opacity: 0.7,
    },
  },
  ...buttonAnimations(theme),
}))
