import React from 'react'
import classNames from 'classnames'

import './_old-price.scss'

interface Props {
  isWindows?: boolean
  children: React.ReactNode
}

export const OldPrice: React.FC<Props> = ({ isWindows = false, children }) => {
  const className = classNames('old-price', {
    'old-price--windows': isWindows,
  })

  return (
    <span className={className} translate='no'>
      {children}
    </span>
  )
}

OldPrice.displayName = 'OldPrice'
