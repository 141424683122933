import React, { Component } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { InfoTooltip } from '@elo-kit/components/info-tooltip/InfoTooltip'

const propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
}

const defaultProps = {
  disabled: false,
}

@observer
class PartialTextFieldContainer extends Component {
  render() {
    const {
      value,
      isInvalid,
      errorMessage,
      className,
      label,
      predefinedText,
      onChange,
      name,
      placeholder,
      autocomplete,
      disabled,
      tooltipId,
      required,
    } = this.props

    const fieldClassNames = classNames(
      'field partial-text-field',
      {
        'field--disabled': disabled,
        'field--required': required,
      },
      className
    )

    const fieldControlClassNames = classNames('field__control partial-text-field__control', {
      'partial-text-field__control--error': isInvalid,
    })

    const predifinedFieldClassNames = classNames(
      'field__control partial-text-field__control partial-text-field__predefined-text',
      { 'partial-text-field__control--error': isInvalid }
    )

    return (
      <div className={fieldClassNames}>
        <div className='partial-text-field__label-container'>
          {label && (
            <label className='field__label' htmlFor={name}>
              <span>{label}</span>
              {tooltipId && <InfoTooltip id={`${name}_popover`} body={tooltipId} />}
            </label>
          )}

          {isInvalid && (
            <div className='field__error'>
              {errorMessage}
              <i className='fas fa-exclamation-circle' />
            </div>
          )}
        </div>
        <div className='partial-text-field__text-container'>
          <span className={predifinedFieldClassNames}>{predefinedText}</span>
          <input
            id={name}
            type='text'
            className={fieldControlClassNames}
            placeholder={placeholder}
            autoComplete={autocomplete}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            disabled={disabled}
          />
        </div>
      </div>
    )
  }
}

PartialTextFieldContainer.displayName = 'PartialTextField'
PartialTextFieldContainer.propTypes = propTypes
PartialTextFieldContainer.defaultProps = defaultProps

export const PartialTextField = (props) => <PartialTextFieldContainer {...props} />
