import React from 'react'
import PropTypes from 'prop-types'
import withStyles, { ThemeProvider } from 'react-jss'
import classNames from 'classnames'
import ReactPaginate from 'react-paginate'

import sellerProductsStyles from 'shared/components/content-page/preview/blocks/seller-products/SellerProductsPaginationStyles'

export const SellerProductsPagination = (props) => {
  const { handleScrollChange, initStore, classes, pageRangeDisplayed, marginPagesDisplayed, theme, defaultPage } = props
  const totalCount = initStore?.pagination?.total
  const { page } = initStore?.pagination || {}
  const perPage = initStore?.pagination?.per
  const pageCount = Math.ceil(totalCount / perPage)
  const correctedPageNumber = page - 1
  const paginationDisable = pageCount <= defaultPage

  const nextLinkClasses = 'elo-pagination__link elo-pagination__link--next'
  const containerClasses = classNames(
    'elo-pagination-container',
    classes.paginationContainer,
    paginationDisable && classes.paginationContainerDisabled
  )

  if (totalCount === 0 || paginationDisable) {
    return null
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={containerClasses}>
        {!paginationDisable && (
          <ReactPaginate
            pageClassName='elo-pagination__page'
            activeClassName='elo-pagination__page elo-pagination__page--active'
            pageLinkClassName='elo-pagination__link'
            activeLinkClassName='elo-pagination__link elo-pagination__link--active'
            breakClassName='elo-pagination__page'
            breakLinkClassName='elo-pagination__link'
            previousLabel={<i className='fas fa-angle-left' />}
            nextLabel={<i className='fas fa-angle-right' />}
            previousClassName='elo-pagination__page'
            nextClassName='elo-pagination__page'
            previousLinkClassName={nextLinkClasses}
            nextLinkClassName={nextLinkClasses}
            containerClassName='elo-pagination'
            pageCount={pageCount}
            pageRangeDisplayed={pageRangeDisplayed}
            marginPagesDisplayed={marginPagesDisplayed}
            onPageChange={(currentPage) => {
              handleScrollChange()
              initStore.handlePaginationChange('page', currentPage?.selected + 1)
            }}
            forcePage={correctedPageNumber}
          />
        )}
      </div>
    </ThemeProvider>
  )
}

SellerProductsPagination.propTypes = {
  /** scroll on page change */
  handleScrollChange: PropTypes.func,
  /** Store object */
  initStore: PropTypes.object,
  /** Classes object */
  classes: PropTypes.object,
  /** The range of pages displayed */
  pageRangeDisplayed: PropTypes.number,
  /** The number of pages to display for margins */
  marginPagesDisplayed: PropTypes.number,
  /** Default active page */
  defaultPage: PropTypes.number,
  /** Theme object */
  theme: PropTypes.object,
}

SellerProductsPagination.defaultProps = {
  handleScrollChange: () => {},
  initStore: {},
  classes: {},
  pageRangeDisplayed: 3,
  marginPagesDisplayed: 1,
  defaultPage: 1,
}

const SellerProductsPaginationWithStyles = withStyles(sellerProductsStyles)(SellerProductsPagination)

const SellerProductsPaginationContainer = (props) => {
  const { theme } = props
  return (
    <ThemeProvider theme={theme}>
      <SellerProductsPaginationWithStyles {...props} />
    </ThemeProvider>
  )
}

SellerProductsPaginationContainer.propTypes = {
  /** Theme object */
  theme: PropTypes.object,
}

SellerProductsPaginationContainer.defaultProps = {
  theme: {},
}

export default SellerProductsPaginationContainer
