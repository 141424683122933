export const ACTIVE_PROFILES = {
  seller: 'seller',
  publisher: 'publisher',
  eloPublisher: 'elo_publisher',
  teamMember: 'team_member',
  salesTeamMember: 'sales_team_member',
  payer: 'payer',
  manager: 'manager',
}

export const ID_SELLER_WITH_RESTRICTED_COUNTRIES = 55460
export const RESTRICT_COUNTRIES = ['DE', 'AT', 'CH']

export const getSalutationOptions = () => [
  {
    value: 'mr',
    label: I18n.t('react.shared.checkout.mr'),
  },
  {
    value: 'mrs',
    label: I18n.t('react.shared.checkout.mrs'),
  },
  {
    value: 'non_binary',
    label: I18n.t('react.shared.checkout.non_binary'),
  },
  {
    value: 'dr',
    label: I18n.t('react.shared.checkout.dr'),
  },
  {
    value: 'none',
    label: I18n.t('react.shared.checkout.none'),
  },
]
