import React from 'react'

interface Props {
  buttonColor: string
}

export const Back: React.FC<Props> = ({ buttonColor }) => (
  <svg width={31} height={34}>
    <g>
      <g transform='translate(-537.000000, -954.000000)'>
        <g transform='translate(408.000000, 819.000000)'>
          <g transform='translate(129.000000, 135.000000)'>
            <path
              style={{
                fill: buttonColor,
              }}
              d='M13.3,22.4v-8.5h-1.3l-2.4,0.7l0.4,1.5l1.5-0.4v6.6H13.3z M18.1,22.5c1,0,1.7-0.3,2.3-0.8 c0.6-0.5,
              0.9-1.2,0.9-2.1c0-0.9-0.3-1.6-0.8-2s-1.3-0.7-2.1-0.7c-0.4,0-0.7,0-1.1,0.1l0,0l0.1-1.5h3.6V14h-5.2l-0.2,
              4.1l1.1,0.7 c0.5-0.2,1-0.3,1.4-0.3s0.8,0.1,1.1,0.3c0.3,0.2,0.4,0.5,0.4,0.8c0,0.4-0.1,0.7-0.4,0.9c-0.2,
              0.2-0.6,0.3-1,0.3 c-0.7,0-1.3-0.3-2-0.9l0,0L15,21.2C15.8,22.1,16.9,22.5,18.1,22.5z'
            />
            <g transform='translate(15.500000, 17.500000) scale(-1, 1) translate(-15.500000, -17.500000) '>
              <path
                style={{
                  fill: buttonColor,
                }}
                d='M15.5,33.4c-1.4,0-2.7-0.2-4-0.5c-2.8-0.7-5.3-2.2-7.3-4.3C1.5,25.7,0,21.9,0,17.9 C0,9.3,6.9,2.4,
                15.5,2.4c0.6,0,1,0.4,1,1s-0.4,1-1,1C8,4.4,2,10.4,2,17.9 c0,3.5,1.3,6.8,3.7,9.3c1.7,1.8,3.9,3.1,6.3,3.8
                 c1.1,0.3,2.3,0.5,3.5,0.5c7.5,0,13.5-6,13.5-13.5c0-0.6,0.4-1,1-1s1,0.4,1,1C31,26.4,24.1,33.4,15.5,33.4z'
              />
              <path
                style={{
                  fill: buttonColor,
                }}
                d='M12.4,0.2l4.8,2.4c0.1,0.1,0.2,0.3,0.1,0.4c0,0,0,0.1-0.1,0.1l-3.9,3.8 c-0.1,0.1-0.3,0.1-0.4,0c0,
                0-0.1-0.1-0.1-0.2l-1-6.3c0-0.2,0.1-0.3,0.2-0.3C12.3,0.2,12.3,0.2,12.4,0.2z'
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
