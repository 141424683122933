import React from 'react'
import classNames from 'classnames'

import './_grey-box.scss'

/**
 * Grey Box - show information about error
 */
interface Props {
  className?: string
  compact?: boolean
  children?: React.ReactNode
}
export const GreyBox: React.FC<Props> = ({ children = '', className = '', compact = false }) => {
  const greyBoxClasses = classNames(
    'grey-box',
    {
      'grey-box--compact': compact,
    },
    className
  )

  return <span className={greyBoxClasses}>{children}</span>
}

GreyBox.displayName = 'GreyBox'
