import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'

import { InfoTooltip } from '@elo-kit/components/info-tooltip'
import { videoStyles } from 'shared/components/content-page/preview/blocks/video/Video.styles'

import { CodeInput } from '../code-input'

/**
 * Vide Codes Inputs component
 */
class VideoCodesInputsComponent extends Component {
  componentDidUpdate(prevProps) {
    const { videoCodes } = this.props

    if (JSON.stringify(videoCodes) !== JSON.stringify(prevProps.videoCodes)) {
      this.forceUpdate()
    }
  }

  render() {
    const { dataTestId, classes, blockId, content, locales, hideVideoCodes, setVideoCodeValue, getVideoCodeData } =
      this.props

    const codeIds = []
    content?.codes?.forEach((code) => codeIds.push(code?.id))

    if (!codeIds.length || hideVideoCodes) {
      return null
    }

    return (
      <div className={classes.codesSection} data-testid={dataTestId}>
        <div className={classes.codesTitle}>
          {locales.videoCodes}
          <InfoTooltip
            id={`video-tooltip-${blockId}`}
            body={locales.videoCodesTooltip}
            className='elo-icon__relative'
          />
        </div>
        <div className={classes.codesContainer}>
          {codeIds.map((codeId, index) => {
            const blockCodeData = getVideoCodeData(blockId, codeId)
            return (
              <div className={classes.codeInputContainer} key={codeId}>
                <CodeInput
                  id={codeId}
                  value={blockCodeData?.value}
                  onChange={/* istanbul ignore next */ (value) => setVideoCodeValue(blockId, codeId, value)}
                  placeholder={`${locales.videoCodesPlaceholder} #${index + 1}`}
                  passed={blockCodeData?.passed}
                />
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

VideoCodesInputsComponent.propTypes = {
  /** Video Codes map */
  videoCodes: PropTypes.object,
  /** React JSS classes */
  classes: PropTypes.object,
  /** Content block object */
  content: PropTypes.object,
  /** Block ID */
  blockId: PropTypes.string,
  /** Locales */
  locales: PropTypes.object,
  /** Indicates whether Video Codes should be shown */
  hideVideoCodes: PropTypes.bool,
  /** Video code value setter */
  setVideoCodeValue: PropTypes.func,
  /** Video code value getter */
  getVideoCodeData: PropTypes.func,
  /** Data test ID */
  dataTestId: PropTypes.string,
}

VideoCodesInputsComponent.defaultProps = {
  videoCodes: {},
  classes: {},
  content: {},
  blockId: '',
  dataTestId: '',
  hideVideoCodes: false,
  setVideoCodeValue: () => {},
  getVideoCodeData: () => {},
  locales: {
    videoCodes: 'shared.common.video_codes',
    videoCodesTooltip: 'react.shared.help_icon.video_codes.content',
    videoCodesPlaceholder: 'react.shared.code',
  },
}

export const VideoCodesInputs = withStyles(videoStyles)(VideoCodesInputsComponent)
