import React, { Component, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withStyles, { ThemeProvider } from 'react-jss'

import { ELOPAGE_CABINETS } from '@elo-kit/constants/general.constants'
import { EXTRAS_PRODUCTS_CLASS_NAMES } from '@elo-kit/constants/customCss.constants'

import searchStyles from './ProductsSearch.styles'

import './_search-field.styles.scss'

/**
 * Search - main container for the Page Builder's ProductSearch block
 */
export const Search = (props) => {
  const [searchValue, setSearchValue] = useState('')
  const [fetchedValue, setFetchedValue] = useState('')

  const handleSearch = useCallback(() => {
    const { view, handleFetch } = props

    if ((searchValue.length >= 1 || searchValue === '') && fetchedValue !== searchValue) {
      const requestParams = view === ELOPAGE_CABINETS.shop ? { name: searchValue } : { query: searchValue }
      handleFetch(requestParams)
      setFetchedValue(searchValue)
    }
  }, [fetchedValue, props, searchValue])

  const handleChange = (event) => {
    event.preventDefault()
    setSearchValue(event.target.value)
  }

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        handleSearch()
      }
    },
    [handleSearch]
  )

  useEffect(() => {
    const { id } = props

    const searchNode = document.getElementById(id)
    if (searchNode) {
      searchNode.addEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress, props])

  useEffect(
    () => () => {
      const { id } = props
      const searchNode = document.getElementById(id)

      if (searchNode) {
        searchNode.removeEventListener('keydown', handleKeyPress)
      }
    },
    [handleKeyPress, props]
  )

  const { loading, disabled, id, classes, defaultSearchPlaceholder, testId, previewMode, highlighterId } = props

  const searchDisabled = loading || disabled
  const fieldClassNames = classNames(
    EXTRAS_PRODUCTS_CLASS_NAMES.searchContainerClassName,
    classes.searchContainer,
    'elo-search-field',
    {
      'elo-search-field--disabled': searchDisabled,
    }
  )

  return (
    <div
      className={fieldClassNames}
      {...(previewMode && {
        'data-highlighter-item': highlighterId,
        'data-highlighter-selector': '',
      })}
    >
      <div className='elo-search-field__input-wrapper'>
        <input
          id={id}
          data-testid={testId}
          type='input'
          className='elo-search-field__control'
          placeholder={defaultSearchPlaceholder}
          autoComplete='off'
          onChange={handleChange}
          disabled={searchDisabled}
          value={searchValue}
          onKeyDown={handleKeyPress}
          onBlur={handleSearch}
        />
        <i className='fas fa-search elo-search-field__icon' />
      </div>
    </div>
  )
}

Search.propTypes = {
  /**
   * Content block id
   */
  id: PropTypes.string,
  /**
   * Test id for input search
   */
  testId: PropTypes.string,
  /**
   * Function that fetch with params and return data
   */
  handleFetch: PropTypes.func,
  /**
   * Observable variable from ContentPageStore that equal ELOPAGE_CABINETS.cabinet
   */
  view: PropTypes.string,
  /**
   * Loading value for search
   */
  loading: PropTypes.bool,
  /**
   * Disabled search
   */
  disabled: PropTypes.bool,
  /**
   * React JSS classes
   */
  classes: PropTypes.object,
  /**
   * Default placeholder for search
   */
  defaultSearchPlaceholder: PropTypes.string,
  /** Property for check is PageBuilder in preview mode */
  previewMode: PropTypes.bool,
  /** Highlighter element id */
  highlighterId: PropTypes.string,
}

Search.defaultProps = {
  id: '',
  handleFetch: /* istanbul ignore next */ () => {
    /* no-op */
  },
  view: 'cabinet',
  defaultSearchPlaceholder: 'Search',
  loading: false,
  disabled: false,
  classes: {},
  highlighterId: '',
}

const SearchWithStyles = withStyles(searchStyles)(Search)

/**
 * ProductsSearch - Page Builder's Search in Products Block with react-jss injection
 * */
export class ProductsSearch extends Component {
  render() {
    const { block } = this.props

    return (
      <ThemeProvider theme={block.content}>
        <SearchWithStyles {...this.props} id={`search-block-${block.id}`} />
      </ThemeProvider>
    )
  }
}

ProductsSearch.propTypes = {
  /**
   * Content block object
   */
  block: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    content: PropTypes.object,
  }),
}

ProductsSearch.defaultProps = {
  block: {},
}

export default ProductsSearch
