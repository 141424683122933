import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useI18n } from '@elo-kit/components/i18n/i18n'
import EditTextArea from 'shared/components/page-builder/page-view/edit-text-area/EditTextArea'
import { cleanupHTMLStyleFonts } from 'utils/fonts.utils'
import { YES, NO } from '@elo-kit/constants/general.constants'
import { BLOCK_VIEW } from '@elo-kit/constants/contentPage.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { EDIT_TEXT_FIELD_TYPES } from 'shared/components/page-builder/constants/pageBuilder.constants'
import { FUNNEL_TEXT_PARAMS } from 'shared/components/page-builder/constants/block.constants'

import { stringReplaceAll } from 'utils/string.utils'

/**
 * Replaces template strings with content
 *
 * @param {String} text - Template String
 *
 * @return {String} String with replaced content
 */
export const getTextWithFunnelLinks = (text) => {
  let newText = text

  newText = stringReplaceAll(newText, '%{start_buy_link}', '<span class="postsell-link submit-funnel">')
  newText = stringReplaceAll(newText, '%{end_buy_link}', '</span>')
  newText = stringReplaceAll(newText, '%{start_continue_link}', '<span class="postsell-link skip-funnel">')
  newText = stringReplaceAll(newText, '%{end_continue_link}', '</span>')

  return newText
}

/**
 * Text funnel - Page Builder's funnel block
 */
export const TextFunnel = (props) => {
  const {
    classes,
    content: { text, type },
    previewMode,
    onAcceptClick,
    onSkipClick,
    funnelHandled,
    setFunnelHandled,
  } = props
  const I18n = useI18n()

  const isTextType = type === BLOCK_VIEW.text
  const funnelText = isTextType && text ? getTextWithFunnelLinks(text) : text
  const previewModeContent = previewMode ? (
    <div
      className={classNames(classes.noTextContainer)}
      {...(previewMode && {
        'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.funnel.customCta,
        'data-highlighter-selector': '',
      })}
    >
      <i className='fas fa-font' />
    </div>
  ) : null

  const handleAccept = useCallback(
    /* istanbul ignore next */ () => {
      if (!funnelHandled) {
        if (onAcceptClick) {
          onAcceptClick()
        }
        setFunnelHandled(true)
      }
    },
    [funnelHandled, setFunnelHandled, onAcceptClick]
  )

  const handleSkip = useCallback(
    /* istanbul ignore next */ () => {
      if (!funnelHandled) {
        if (onSkipClick) {
          onSkipClick()
        }
        setFunnelHandled(true)
      }
    },
    [funnelHandled, setFunnelHandled, onSkipClick]
  )

  const getTextClickHandler = () => {
    switch (type) {
      case YES:
        return handleAccept
      case NO:
        return handleSkip
    }
  }

  useEffect(() => {
    const submitButtons = document.getElementsByClassName('submit-funnel') || []
    const skipButtons = document.getElementsByClassName('skip-funnel') || []

    for (const button of submitButtons) {
      button.addEventListener('click', handleAccept)
    }

    for (const button of skipButtons) {
      button.addEventListener('click', handleSkip)
    }

    return () => setFunnelHandled(false)
  }, [handleAccept, handleSkip, setFunnelHandled])

  return (
    <>
      {funnelText ? (
        <div
          className={classNames(classes.textContainer)}
          onClick={getTextClickHandler()}
          {...(previewMode && {
            'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.funnel.customCta,
            'data-highlighter-selector': '',
          })}
        >
          <EditTextArea
            value={text}
            previewMode={previewMode}
            textFieldType={EDIT_TEXT_FIELD_TYPES.froala}
            message={isTextType ? I18n.t('react.cabinet.upsells.form.use_custom_buttons_desc', FUNNEL_TEXT_PARAMS) : ''}
          >
            <div dangerouslySetInnerHTML={{ __html: cleanupHTMLStyleFonts(funnelText) }} />
          </EditTextArea>
        </div>
      ) : (
        previewModeContent
      )}
    </>
  )
}

TextFunnel.displayName = 'TextFunnel'
TextFunnel.propTypes = {
  /**
   * React JSS classes
   */
  classes: PropTypes.object,
  /**
   * Content block object
   */
  content: PropTypes.object,
  /**
   * Accept click
   */
  onAcceptClick: PropTypes.func,
  /**
   * Skip click
   */
  onSkipClick: PropTypes.func,
  /**
   * Indicates whether it's Page Builder preview of component
   */
  previewMode: PropTypes.bool,
  /**
   * Indicates that funnel is handled (received from store)
   */
  funnelHandled: PropTypes.bool,
  /**
   * Set's funnel handled flag in store
   */
  setFunnelHandled: PropTypes.func,
}

TextFunnel.defaultProps = {
  classes: {},
  content: {},
  onAcceptClick: /* istanbul ignore next */ () => {},
  onSkipClick: /* istanbul ignore next */ () => {},
  setFunnelHandled: /* istanbul ignore next */ () => {},
  funnelHandled: false,
}
