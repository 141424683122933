export const THEMES = {
  default: 'default',
  custom: 'custom',
}

export const THEME_FORMS = {
  default: 'default',
  custom: 'custom',
  namotto: 'namotto',
}

export const PREVIEW_TYPES = {
  course: 'course',
  tickets: 'tickets',
  download: 'download',
  default: 'default',
}
