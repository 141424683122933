import React from 'react'
import withStyles from 'react-jss'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Progress } from 'reactstrap'

import { getBackgroundImageCss } from '@elo-kit/utils/themes.utils'
import { MEMBERSHIP_PRODUCTS_LIST_CLASS_NAMES } from '@elo-kit/constants/customCss.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { cleanupHTMLStyleFonts } from 'utils/fonts.utils'
import { PRODUCT_TYPE_IDS } from '@elo-kit/constants/products.constants'
import { cutDescription } from '@elo-kit/utils/product.utils'
import { createId } from '@elo-kit/utils/general.utils'

import membershipProductsStyles from 'shared/components/content-page/preview/blocks/membership-products/MembershipProducts.styles'

/**
 * Products items in List
 */
export const ListMembershipProducts = ({
  classes,
  products,
  getProductLink,
  showProgress,
  locales,
  LinkComponent,
  previewMode,
}) => (
  <div className={classNames(MEMBERSHIP_PRODUCTS_LIST_CLASS_NAMES.listContainerClassName, classes.list)}>
    {products.map((product, index) => {
      const { covers, description, form, name, progress, unsubscribed } = product
      const link = getProductLink(product)
      const renderProgress = form === PRODUCT_TYPE_IDS.course && showProgress
      const titleClasses = classNames(
        classes.productTitle,
        classes.listProductTitle,
        MEMBERSHIP_PRODUCTS_LIST_CLASS_NAMES.listTitleClassName
      )
      const productCover = (((covers || [])[0] || {}).file || {}).s640

      const productDescription = cutDescription(description)
      const icon = classNames(classes.accessRestrictedListIcon, 'fas fa-lock')

      const listBackgroundImageContainer = classNames(classes.listBackgroundImageContainer, {
        [classes.disableLink]: unsubscribed,
      })

      const listProductDetails = classNames(classes.listProductDetails, {
        [classes.disableLink]: unsubscribed,
      })

      return (
        <div
          className={classNames(MEMBERSHIP_PRODUCTS_LIST_CLASS_NAMES.listItemClassName, classes.item)}
          key={createId(index)}
        >
          <LinkComponent
            to={link}
            href={link}
            className={listBackgroundImageContainer}
            {...(previewMode && {
              'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.membershipProducts.productsView,
              'data-highlighter-selector': `.${MEMBERSHIP_PRODUCTS_LIST_CLASS_NAMES.listItemClassName}`,
              'data-highlighter-parent': `.${MEMBERSHIP_PRODUCTS_LIST_CLASS_NAMES.listContainerClassName}`,
            })}
          >
            {unsubscribed && (
              <div className={classes.accessRestrictedListLayer}>
                <i className={icon} />
              </div>
            )}
            {productCover ? (
              <div
                style={{ backgroundImage: getBackgroundImageCss(productCover) }}
                className={classNames(classes.productCover)}
              />
            ) : (
              <i className={classNames('far fa-image m-auto')} />
            )}
          </LinkComponent>
          <div
            className={classNames(
              MEMBERSHIP_PRODUCTS_LIST_CLASS_NAMES.listDetailsClassName,
              classes.listProductDetailsContainer
            )}
            {...(previewMode && {
              'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.membershipProducts.productBackground,
              'data-highlighter-selector': `.${MEMBERSHIP_PRODUCTS_LIST_CLASS_NAMES.listDetailsClassName}`,
              'data-highlighter-parent': `.${MEMBERSHIP_PRODUCTS_LIST_CLASS_NAMES.listContainerClassName}`,
            })}
          >
            <LinkComponent to={link} href={link} className={listProductDetails}>
              <div
                className={titleClasses}
                {...(previewMode && {
                  'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.membershipProducts.productTitle,
                  'data-highlighter-selector': `.${MEMBERSHIP_PRODUCTS_LIST_CLASS_NAMES.listTitleClassName}`,
                  'data-highlighter-parent': `.${MEMBERSHIP_PRODUCTS_LIST_CLASS_NAMES.listContainerClassName}`,
                })}
              >
                {name}
              </div>
              <div
                className={classNames(
                  classes.description,
                  MEMBERSHIP_PRODUCTS_LIST_CLASS_NAMES.listDescriptionClassName
                )}
                dangerouslySetInnerHTML={{ __html: cleanupHTMLStyleFonts(productDescription) }}
                {...(previewMode && {
                  'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.membershipProducts.description,
                  'data-highlighter-selector': `.${MEMBERSHIP_PRODUCTS_LIST_CLASS_NAMES.listDescriptionClassName}`,
                  'data-highlighter-parent': `.${MEMBERSHIP_PRODUCTS_LIST_CLASS_NAMES.listContainerClassName}`,
                })}
              />
            </LinkComponent>
            {renderProgress && (
              <div
                className={classNames('list-membership-progress', classes.productProgress)}
                {...(previewMode && {
                  'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.membershipProducts.progressbar,
                  'data-highlighter-selector': `.list-membership-progress`,
                  'data-highlighter-parent': `.${MEMBERSHIP_PRODUCTS_LIST_CLASS_NAMES.listContainerClassName}`,
                })}
              >
                <Progress value={progress} />
                <div className={classes.progressDetails}>
                  <div>{locales.yourProgress}</div>
                  <div>{progress}%</div>
                </div>
              </div>
            )}
          </div>
        </div>
      )
    })}
  </div>
)

ListMembershipProducts.displayName = 'ListMembershipProducts'
ListMembershipProducts.propTypes = {
  /** React JSS classes */
  classes: PropTypes.object,
  /** Gets product link (contentPageStore) */
  getProductLink: PropTypes.func,
  /** Products list */
  products: PropTypes.array,
  /** Locales object */
  locales: PropTypes.object,
  /** Router Link component (react-router) */
  LinkComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /** Indicates whether progress should be shown */
  showProgress: PropTypes.bool,
}

ListMembershipProducts.defaultProps = {
  locales: {
    yourProgress: 'react.cabinet.yourProgress',
  },
  products: [],
  classes: {},
  getProductLink: () => {},
  LinkComponent: 'a',
}

export default withStyles(membershipProductsStyles)(ListMembershipProducts)
