import React, { Component } from 'react'
import { ThemeProvider } from 'react-jss'

import { AnyCallback } from 'types/helpers'

import { EloSliderBody } from './EloSliderBody'

/**
 * EloSlider - default slider component
 */

interface Style {
  activeElementScale: number
  modifier: number
}
interface Props {
  id?: string | number
  slideItems: any[]
  style: Style
  updateDisabled?: boolean
  triggerSizeUpdate?: boolean
  toggleTriggerSizeUpdate?: AnyCallback
  eloSliderContainerClasses?: string
  previewType?: boolean
  onSlide?: AnyCallback
}
interface State {
  windowWidth: number
  sliderSizes: {
    width: number
    height: number
  }
}
const defaultProps = {
  style: {
    activeElementScale: 1.1,
    modifier: 1.5,
  },
  updateDisabled: false,
}
export class EloSlider extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      windowWidth: typeof window !== 'undefined' ? window.innerWidth : 0,
      sliderSizes: {
        width: 0,
        height: 0,
      },
    }
  }

  static defaultProps = defaultProps
  static displayName = 'EloSlider'

  componentDidMount() {
    this.setSliderSizes()

    window.addEventListener('resize', this.handleResize)
  }

  componentDidUpdate(prevProps: Props) {
    const { triggerSizeUpdate, toggleTriggerSizeUpdate, slideItems, updateDisabled } = this.props

    const itemsAmountWasChanged = slideItems.length !== prevProps.slideItems.length
    const itemPropsWereChanged = slideItems !== prevProps.slideItems

    if (triggerSizeUpdate && !prevProps.triggerSizeUpdate) {
      this.setSliderSizes()
      toggleTriggerSizeUpdate && toggleTriggerSizeUpdate()
    }

    if (itemsAmountWasChanged || (!updateDisabled && itemPropsWereChanged)) {
      this.setSliderSizes()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    const { windowWidth } = this.state

    if (windowWidth !== window.innerWidth) {
      this.setState(
        {
          windowWidth: window.innerWidth,
        },
        () => {
          this.setSliderSizes()
        }
      )
    }
  }

  setSliderSizes = () => {
    const { id } = this.props
    const sliderclassname = id ? `.elo-slider-${id}` : '.elo-slider'
    const sliderNode = document.querySelector(sliderclassname)
    // TODO:
    // @ts-ignore
    const sliderItems = [...(sliderNode ? sliderNode.querySelectorAll('#elo-slider-item') : [])]
    const slidesHeights = sliderItems.map((s) => (s.children[0] as HTMLElement).offsetHeight)
    const slidesWidths = sliderItems.map((s) => (s.children[0] as HTMLElement).offsetWidth)
    const height = Math.max(...(slidesHeights.length ? slidesHeights : [0]))
    const width = Math.max(...(slidesWidths.length ? slidesWidths : [0]))

    this.setState({
      sliderSizes: {
        width,
        height,
      },
    })
  }

  render() {
    const { style, slideItems } = this.props
    const { sliderSizes } = this.state

    const scales = {
      activeElementScale: slideItems.length > 1 ? style.activeElementScale : 1,
      modifier: slideItems.length > 1 ? style.modifier : 1,
    }

    const theme = {
      ...style,
      ...scales,
      ...sliderSizes,
    }

    return (
      <ThemeProvider theme={theme}>
        <EloSliderBody {...this.props} />
      </ThemeProvider>
    )
  }
}
