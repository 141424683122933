// TODO: update with using createUseStyles
const countrySelectorStyles = () => ({
  countrySelector: {
    fontSize: 16,
  },

  countrySelectorError: {
    '& .field': {
      marginBottom: 0,
    },
    '& .elo-select-field__control': {
      borderColor: '#dc3545',

      '&:hover': {
        borderColor: '#dc3545',
      },
    },
  },

  countrySelectorInvalidFeedback: {
    display: 'block',
    width: '100%',
    marginTop: 4,
    fontSize: '80%',
    color: '#dc3545',
  },
})

export default countrySelectorStyles
