import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withStyles, { ThemeProvider } from 'react-jss'

import { EXTRAS_PRODUCTS_CLASS_NAMES } from '@elo-kit/constants/customCss.constants'

import { SelectField } from '@elo-kit/components/form/select-field'

import categoriesDropdownStyles from './CategoriesDropdown.styles'

/** CategoriesDropdown - Page Builder's CategoriesDropdown Block */
const CategoriesDropdown = (props) => {
  const { activeOption, classes, options, setOption } = props

  return (
    <SelectField
      className={classNames(EXTRAS_PRODUCTS_CLASS_NAMES.categoriesDropdownContainerClassName, classes.dropdown)}
      containerClassName={classes.dropdown}
      onChange={setOption}
      options={options}
      placeholder=''
      value={activeOption}
    />
  )
}

CategoriesDropdown.propTypes = {
  /** Current value */
  activeOption: PropTypes.string,
  /** React JSS classes */
  classes: PropTypes.shape({
    dropdown: PropTypes.string,
  }),
  /** Options */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.any,
    })
  ),
  /** onChange callback */
  setOption: PropTypes.func.isRequired,
}

const withCategoriesDropdownStyles = withStyles(categoriesDropdownStyles)

export const CategoriesDropdownWithStyles = withCategoriesDropdownStyles(CategoriesDropdown)

/**
 * CategoriesDropdown - Page Builder's CategoriesDropdown Block with react-jss injection
 */
const CategoryTabsWithThemeProvider = ({ theme, ...props }) => (
  <ThemeProvider theme={{}}>
    <CategoriesDropdownWithStyles {...props} />
  </ThemeProvider>
)

CategoryTabsWithThemeProvider.propTypes = {
  theme: PropTypes.shape({}),
}

CategoryTabsWithThemeProvider.defaultProps = {
  theme: {},
}

export { CategoryTabsWithThemeProvider }
