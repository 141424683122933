import { PRODUCTS_LIST, BLOCK_VIEW } from '@elo-kit/constants/contentPage.constants'
import { COLORS } from '@elo-kit/constants/general.constants'

const { eloBlue, eloWhite } = COLORS

// TODO: update with using createUseStyles
const productListStyles = (theme) => ({
  hideBlock: { display: 'none' },

  productsListSection: {
    width: '100%',
    backgroundColor: theme[PRODUCTS_LIST.background] || eloWhite,
    minHeight: 200,
  },

  controlsContainer: {
    maxWidth: '1140px',
    width: '100%',
    margin: '0 auto 0',
    padding: 20,
    display: 'flex',
    justifyContent: 'space-between',

    '& .builder-field': {
      marginBottom: 0,
    },

    '@media (max-width: 576px)': {
      margin: '0 auto',
      flexWrap: 'wrap',
      justifyContent: 'center',

      '& .builder-field': {
        marginBottom: 20,
      },
      '& .builder-field:last-child': {
        marginBottom: 0,
      },
    },
  },

  paginationContainer: {
    backgroundColor: theme[PRODUCTS_LIST.background] || eloWhite,
    paddingTop: theme[PRODUCTS_LIST.view] === BLOCK_VIEW.list ? 30 : 10,
    paddingBottom: 30,
    marginTop: 0,
    justifyContent: 'center',
    '& .elo-pagination__page--active': {
      backgroundColor: `${theme[PRODUCTS_LIST.priceBackground] || eloBlue} !important`,
    },
    '& a.elo-pagination__link': {
      cursor: 'pointer',
      color: `${theme[PRODUCTS_LIST.priceBackground] || eloBlue}`,
      '&:hover': {
        opacity: 0.8,
      },
      '&--active': {
        color: `${theme[PRODUCTS_LIST.priceColor] || eloWhite} !important`,
      },
    },
    '& .elo-pagination__page:hover': {
      background: 'none',
    },
  },
  paginationContainerDisabled: {
    paddingBottom: 0,
  },
})

export default productListStyles
