import { createUseStyles } from 'react-jss'
import { getAlignment, getPadding } from '@elo-kit/utils/contentBlock.utils'
import { getBackgroundImageCss } from '@elo-kit/utils/themes.utils'
import { getFontStyles } from '@elo-kit/utils/fonts.utils'

import { buttonAnimations } from '@elo-kit/utils/animations.utils'

import { PADDING_UNITS, COLORS, POSITIONS, ZERO_DATA } from '@elo-kit/constants/general.constants'
import { BUTTON_STYLE, TEXT, DEFAULT_TEXT_PADDING } from '@elo-kit/constants/contentPage.constants'
import { cleanupFonts } from 'utils/fonts.utils'

export const useFunnelStyles = createUseStyles((theme = {}) => {
  const { eloGrey70, eloWhite, eloBlue } = COLORS
  const { center } = POSITIONS
  const defaultPadding = theme.paddingUnits === PADDING_UNITS.px ? DEFAULT_TEXT_PADDING : ZERO_DATA

  return {
    container: {
      padding: 10,
      display: 'flex',
      justifyContent: getAlignment(theme.horizontalAlignment),
      alignItems: 'center',
      height: '100%',
      backgroundImage: getBackgroundImageCss(theme.backgroundImage),
      maxWidth: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: `${theme.minHeight || ZERO_DATA}px`,
      position: 'relative',
    },
    containerOverlay: {
      background: theme.backgroundColor,
      opacity: theme.transparency,
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    button: {
      background: theme.buttonViewStyle === BUTTON_STYLE.filled ? theme.buttonColor || eloBlue : 'none',
      color: theme.buttonTextColor || eloBlue,
      border: `2px solid ${theme.buttonColor || eloBlue}`,
      padding: '12px 30px',
      maxWidth: `${theme.buttonWidth || 240}px`,
      width: `${theme.buttonWidth || 240}px`,
      borderRadius: `${theme.radius === undefined ? 4 : theme.radius}px`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: cleanupFonts(theme.buttonFont) || 'Montserrat Medium',
      ...getFontStyles(theme.buttonStyle || 'bold'),
      fontSize: `${theme.buttonSize || 15}px`,
      cursor: 'pointer',
      wordBreak: 'break-word',
      textAlign: 'center',
      lineHeight: 1,
      zIndex: 1,
      '&:focus': {
        outline: 'none',
      },
      '&:active': {
        opacity: 0.7,
      },
    },

    ...buttonAnimations(theme),

    textContainer: {
      position: 'relative',
      paddingTop: getPadding(theme[TEXT.paddingTop], defaultPadding, theme.paddingUnits),
      paddingBottom: getPadding(theme[TEXT.paddingBottom], defaultPadding, theme.paddingUnits),
      paddingRight: getPadding(theme[TEXT.paddingRight], defaultPadding, theme.paddingUnits),
      paddingLeft: getPadding(theme[TEXT.paddingLeft], defaultPadding, theme.paddingUnits),
      height: '100%',
      width: `${theme.width || 100}%`,
      maxWidth: theme.maxWidth ? `${theme.maxWidth}px` : 'none',
      margin: '0 auto',
      wordBreak: 'break-word',
      '& span': {
        wordBreak: 'break-word',
      },
    },
    noTextContainer: {
      minHeight: '300px',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: center,
      alignItems: center,
      fontSize: 13,
      color: eloGrey70,
      textAlign: 'center',
      wordBreak: 'break-word',
      '& i': {
        fontSize: '100px',
        color: eloWhite,
      },
    },
  }
})
