import { createUseStyles } from 'react-jss'
import { getBackgroundImageCss } from '@elo-kit/utils/themes.utils'
import { TESTIMONIALS } from '@elo-kit/constants/contentPage.constants'
import { getFontStyles } from '@elo-kit/utils/fonts.utils'
import { COLORS } from '@elo-kit/constants/general.constants'
import { cleanupFonts } from 'utils/fonts.utils'

export const useTestimonialsStyles = createUseStyles((theme) => {
  const { eloWhite } = COLORS

  return {
    userName: {
      fontFamily: cleanupFonts(theme[TESTIMONIALS.usernamesFont]) || 'Montserrat Reg',
      ...getFontStyles(theme[TESTIMONIALS.usernamesStyle]),
      color: theme[TESTIMONIALS.usernamesColor] || '#787878',
      fontSize: `${theme[TESTIMONIALS.usernamesSize] || 30}px`,
    },
    text: {
      fontFamily: cleanupFonts(theme[TESTIMONIALS.textFont]) || 'Montserrat Reg',
      ...getFontStyles(theme[TESTIMONIALS.textStyle]),
      color: theme[TESTIMONIALS.textColor] || '#787878',
      fontSize: `${theme[TESTIMONIALS.textSize] || 16}px`,
      padding: theme.fullWidth ? '0 20px' : '0 30px',
      '@media (max-width: 576px)': {
        padding: '0 10px',
        fontSize: theme[TESTIMONIALS.textSize] > 26 ? 26 : theme[TESTIMONIALS.textSize],
      },
    },
    comment: {
      padding: '0 20px',
      margin: 0,
      '@media (max-width: 576px)': {
        padding: '0 10px',
      },
    },
    backgroundImage: {
      backgroundImage: getBackgroundImageCss(theme[TESTIMONIALS.backgroundImage]),
      backgroundSize: 'cover',
      minHeight: 460,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    backgroundColor: {
      backgroundColor: theme[TESTIMONIALS.backgroundColor] || '#efedef',
      opacity: theme[TESTIMONIALS.transparency],
      width: '100%',
      height: '100%',
      position: 'absolute',
    },
    testimonialsContainer: {
      maxWidth: 1140,
      overflowX: 'hidden',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 20,
      '& .elo-slider-container': {
        display: 'flex',
        width: '900px',
      },
      '& .elo-slider-container > i': {
        color: theme[TESTIMONIALS.arrowsColor] || eloWhite,
        fontSize: 40,
        '&:hover': {
          opacity: 0.8,
          color: theme[TESTIMONIALS.arrowsColor] || eloWhite,
        },
      },

      '& #elo-slider-item': {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      },

      '@media (max-width: 576px)': {
        padding: '20px 0px',
      },
    },

    testimonialText: {
      overflow: 'hidden',

      '@media (max-width: 991px)': {
        overflow: 'auto',
      },
    },

    testimonialTextMore: {
      overflow: 'visible',
    },

    slideItem: theme.fullWidth
      ? {
          width: '800px',
          maxWidth: '800px',
          textAlign: 'center',

          '@media (max-width: 991px)': {
            maxWidth: '600px',
          },
          '@media (max-width: 768px)': {
            maxWidth: '400px',
          },
          '@media (max-width: 480px)': {
            maxWidth: '270px',
          },
          '@media (max-width: 350px)': {
            maxWidth: '250px',
          },
        }
      : {
          width: '100%',
          minWidth: '600px',
          maxWidth: '800px',
          textAlign: 'center',

          '@media (max-width: 991px)': {
            maxWidth: '600px',
            minWidth: '600px',
          },
          '@media (max-width: 768px)': {
            maxWidth: '400px',
            minWidth: '400px',
          },
          '@media (max-width: 480px)': {
            maxWidth: '270px',
            minWidth: '270px',
          },
          '@media (max-width: 350px)': {
            maxWidth: '250px',
            minWidth: '250px',
          },
        },

    showMoreBtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: 'fit-content',
      minHeight: 30,
      padding: '0',
      border: 'none',
      fontSize: 13,
      background: 'none',
      color: theme[TESTIMONIALS.arrowsColor] || eloWhite,
      margin: 'auto',
      '&:focus': {
        outline: 'none',
      },
    },

    containerPreview: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center !important',
      textAlign: 'center !important',
      justifyContent: 'center !important',
      height: '100%',
      minHeight: 460,
      '& > i': {
        fontSize: 20,
        zIndex: 2,
        top: '50%',
      },
      '& .fa-chevron-right': {
        right: 0,
      },
      '& .fa-chevron-left': {
        left: 0,
      },
    },

    preview: {
      minHeight: 460,
      width: '100%',
      color: '#787878',
      '& .testimonials-preview__background-image': {
        height: 100,
        width: 100,
        color: '#efedef',
        backgroundColor: eloWhite,
        margin: 'auto',

        '&.empty': {
          border: '1px dashed rgba(0, 0, 0, 0.15)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },

        '& > .fas.fa-user': {
          fontSize: 35,
        },
      },

      '& .testimonials-preview__customer-name': {
        fontWeight: 'bold',
        wordBreak: 'break-word',
      },

      '& .testimonials-preview__lorem-block': {
        maxWidth: '100%',
        wordBreak: 'break-word',
        '@media (max-width: 991px)': {
          '& div': {
            overflow: 'auto',
          },
        },

        '& > .fas': {
          color: theme[TESTIMONIALS.textColor] || eloWhite,
        },

        '& > .fa-quote-left': {
          left: -35,
        },

        '& > .fa-quote-right': {
          right: -35,
        },
      },
    },
  }
})
