import React, { useRef, useEffect, useState } from 'react'
import classNames from 'classnames'

import { LOCALES } from '@elo-kit/constants/locales.constants'
import { EDIT_TEXT_FIELD_TYPES } from 'shared/components/page-builder/constants/pageBuilder.constants'

import { usePageBuilderStores } from 'shared/components/page-builder/hooks/use-stores'

import './edit-text-area.scss'

const MIN_EDIT_TEXT_AREA_WIDTH = {
  [LOCALES.en]: 136,
  [LOCALES.de]: 200,
}
const MIN_EDIT_TEXT_AREA_HEIGHT = 25

interface EditTextAreaProps {
  children: any
  onClick?: () => void
  dataKey: string
  value: string
  textFieldType?: string
  previewMode: boolean
  highlightDataAttributes?: object
  message?: string
}

interface Dimension {
  width: number
  height: number
}

const useContainerDimensions = (ref: React.MutableRefObject<HTMLDivElement | null>) => {
  const [dimensions, setDimensions] = useState<Dimension>({ width: 0, height: 0 })
  const getDimensions = () => ({
    width: ref.current.clientWidth,
    height: ref.current.clientHeight,
  })

  useEffect(() => {
    if (ref.current) {
      setDimensions(getDimensions())
    }
  }, [ref.current])

  return dimensions
}

const EditTextArea = (props: EditTextAreaProps) => {
  const {
    children,
    onClick,
    dataKey = 'text',
    value,
    textFieldType = EDIT_TEXT_FIELD_TYPES.textarea,
    previewMode,
    highlightDataAttributes = {},
    message,
  } = props

  const { pageBuilderStore } = usePageBuilderStores()

  const editAreaRef = useRef<HTMLDivElement | null>(null)
  const { width, height } = useContainerDimensions(editAreaRef)

  const handleAreaDoubleClick = () => {
    pageBuilderStore.toggleIsTextModalOpen(true)
    pageBuilderStore.setText(value)
    pageBuilderStore.setTextKey(dataKey)
    pageBuilderStore.setTextFieldType(textFieldType)
    message && pageBuilderStore.setModalMessage(message)

    if (onClick) {
      onClick()
    }
  }

  const editTextAreaClasses = classNames('edit-text-area')
  const hintClasses = classNames('edit-text-area__hint')
  const minWidth = MIN_EDIT_TEXT_AREA_WIDTH[I18n.locale]

  const hintLabel =
    width >= minWidth ? (
      I18n.t('react.shared.double_click_to_edit')
    ) : (
      <i className='fas fa-pencil-alt' onClick={handleAreaDoubleClick} />
    )
  const hintWidth = width >= minWidth ? minWidth : 20

  const hintStyles = {
    width: hintWidth,
    height: height > MIN_EDIT_TEXT_AREA_HEIGHT ? null : height,
  }

  return previewMode ? (
    <div
      className={editTextAreaClasses}
      onDoubleClick={handleAreaDoubleClick}
      ref={editAreaRef}
      {...highlightDataAttributes}
    >
      <div className={hintClasses} style={hintStyles}>
        {hintLabel}
      </div>
      {children}
    </div>
  ) : (
    children
  )
}

export default EditTextArea
