import React from 'react'
import { components } from 'react-select'

import { EloCaretUpIcon, EloCaretDownIcon, EloWarningCircleIcon } from '@elo-ui/components/icons/regular'
import { EloTooltip } from '@elo-ui/components/elo-tooltip'

const DropdownIndicator = (props) => {
  const { selectProps = {} } = props
  const { menuIsOpen = false } = selectProps

  return (
    <>
      {selectProps.warningMessage && !selectProps.hasError && (
        <span className='elo-select-warning'>
          <EloTooltip placement='top' content={selectProps.warningMessage}>
            <EloWarningCircleIcon color='var(--color-utility-warning-mid)' size={20} />
          </EloTooltip>
        </span>
      )}
      <components.DropdownIndicator {...props}>
        {menuIsOpen ? <EloCaretUpIcon /> : <EloCaretDownIcon />}
      </components.DropdownIndicator>
    </>
  )
}

export { DropdownIndicator }
