import React, { Component } from 'react'
import { action, observable, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { NUMBER_FIELD_MIN, NUMBER_FIELD_MAX, TEXT_FIELD_AUTOCOMPLETE } from 'constants/forms.constants'

import { FieldError } from '@elo-kit/components/elo-ui/field-error/FieldError'
import { useI18n } from '@elo-kit/components/i18n/i18n'

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  className: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  tooltipId: PropTypes.string,
  allowEmptyValue: PropTypes.bool,
  showErrorMessage: PropTypes.bool,
  isValid: PropTypes.bool,
  disableOnBlur: PropTypes.bool,
  fractional: PropTypes.number,
  outlined: PropTypes.bool,
}

const defaultProps = {
  min: NUMBER_FIELD_MIN,
  max: NUMBER_FIELD_MAX,
  showErrorMessage: true,
  disabled: false,
  isValid: true,
  disableOnBlur: false,
  allowEmptyValue: false,
  fractional: 2,
  outlined: false,
}

const getFieldValue = ({ maxFractional = 2, maxValue = 100, value = '' }) => {
  const [integer, fractional] = String(value).split('.')
  let newValue = fractional ? Number(`${integer}.${fractional.slice(0, maxFractional)}`) : Number(value)

  switch (true) {
    case value === '':
      newValue = ''
      break
    case typeof newValue !== 'number':
      break
    case newValue > maxValue:
      newValue = maxValue
      break
  }
  return newValue
}

@observer
class FloatNumberFieldContainer extends Component {
  @observable valid = true

  @action setValid = (valid) => (this.valid = valid)

  onFieldChange = (e) => {
    const { fractional, max, required, onChange } = this.props
    const newValue = getFieldValue({
      maxFractional: fractional,
      maxValue: max,
      value: e.target.value,
    })

    onChange(newValue)

    const valid = !(required && !newValue)
    this.setValid(valid)
  }

  // Validate min only on blur
  onFieldBlur = (e) => {
    const { min, onChange, disableOnBlur, allowEmptyValue, onBlur } = this.props

    if (!disableOnBlur) {
      const isValueEmpty = allowEmptyValue && e.target.value === ''
      let newValue = isValueEmpty ? '' : Number(e.target.value)

      if (newValue <= min && !isValueEmpty) {
        newValue = min
      }

      onChange(newValue)
      onBlur && onBlur(newValue)
    }
  }

  constructor(props) {
    super(props)
    makeObservable(this)
  }

  render() {
    const {
      className,
      currency,
      disabled,
      fractional,
      isValid,
      label,
      max,
      name,
      placeholder,
      required,
      showErrorMessage,
      staticText,
      value = '',
      errorMessage,
      errorId,
      outlined,
      I18n,
    } = this.props

    const newValue = getFieldValue({
      maxFractional: fractional,
      maxValue: max,
      value,
    })

    const fieldClassNames = classNames(
      'field number-field',
      {
        'field--disabled': disabled,
        'field--required': required,
      },
      className
    )

    const fieldControlClassNames = classNames('field__control number-field__control number-field__control--float', {
      'number-field__control--error': !this.valid || !isValid,
      'number-field__control--with-currency': currency,
    })
    const stringValue = String(value)
    const staticTextClassNames = classNames('number-field__static-text', {
      [`number-field__static-text--${stringValue.includes('.') ? stringValue.length - 1 : stringValue.length}`]:
        !!stringValue.length,
    })

    const errorText = errorMessage || I18n.t('react.shared.validations.required')

    return (
      <div className={fieldClassNames}>
        {label && <div className='field__label'>{label}</div>}

        {(!this.valid || !isValid) && showErrorMessage && (
          <FieldError id={errorId} errorText={errorText} outlined={outlined} />
        )}
        <div className='number-field__control-wrapper'>
          {currency && <div className='number-field__currency input-group-prepend'>{currency}</div>}
          <input
            type='number'
            id={name}
            name={name}
            value={newValue}
            className={fieldControlClassNames}
            placeholder={placeholder}
            readOnly={disabled}
            autoComplete={TEXT_FIELD_AUTOCOMPLETE.off}
            onChange={this.onFieldChange}
            onBlur={this.onFieldBlur}
          />
          {staticText && !!stringValue && <span className={staticTextClassNames}>{staticText}</span>}
        </div>
      </div>
    )
  }
}

FloatNumberFieldContainer.displayName = 'FloatNumberField'
FloatNumberFieldContainer.propTypes = propTypes
FloatNumberFieldContainer.defaultProps = defaultProps

export const FloatNumberField = (props) => {
  const I18n = useI18n()

  return <FloatNumberFieldContainer I18n={I18n} {...props} />
}
