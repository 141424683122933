import React from 'react'
import withStyles, { ThemeProvider } from 'react-jss'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { getFirstCoverUrl } from '@elo-kit/utils/product.utils'

import { CoverWidget } from '@elo-kit/components/elo-ui/cover-widget'
import { FREE_PRICE } from 'constants/general.constants'
import { POPULAR_PRODUCTS_CLASS_NAMES } from '@elo-kit/constants/customCss.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'

import popularProductsStyles from 'shared/components/content-page/preview/blocks/popular-products/PopularProducts.styles'

export const GridPopularProducts = (props) => {
  const {
    getUnit,
    classes,
    products,
    productsContent,
    handleButtonClick,
    previewMode,
    freeButtonText,
    buttonText,
    buttonAnimation,
    theme: { showPrice },
    userName,
    formatPrice,
    LinkComponent,
  } = props

  return (
    <div className={classNames(POPULAR_PRODUCTS_CLASS_NAMES.gridContainerClassName, classes.productsContainer)}>
      {products.map((product) => {
        const buttonClasses = classNames(
          classes.button,
          classes.gridButton,
          POPULAR_PRODUCTS_CLASS_NAMES.gridButtonClassName,
          {
            [classes[buttonAnimation]]: !!buttonAnimation,
          }
        )
        const widgetClasses = classNames(classes.productWidgetContainer)
        const noCover = classNames(classes.noCoverContainer, 'far fa-image')
        const link = product.slug ? `/s/${userName}/${product.slug}` : `/s/${userName}`
        const imageClickHandler = (e) => (previewMode ? e.preventDefault() : {})

        const productCovers = productsContent?.find((image) => image.id === product.covers?.[0]?.id)?.attributes || {}
        const cover = getFirstCoverUrl(product, 's640') || productCovers.s640

        const currencySign = getUnit(product.displayCurrencyId)

        const btnText = product.free ? freeButtonText : buttonText

        return (
          <div
            className={classNames(POPULAR_PRODUCTS_CLASS_NAMES.gridItemClassName, widgetClasses)}
            key={product.id}
            {...(previewMode && {
              'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.popularProducts.selectedProducts,
              'data-highlighter-selector': `.${POPULAR_PRODUCTS_CLASS_NAMES.gridImageClassName}`,
              'data-highlighter-parent': `.${POPULAR_PRODUCTS_CLASS_NAMES.gridContainerClassName}`,
            })}
          >
            <LinkComponent to={link} href={link} onClick={imageClickHandler}>
              {showPrice && (
                <div
                  className={classNames(POPULAR_PRODUCTS_CLASS_NAMES.gridPriceClassName, classes.priceBlock)}
                  {...(previewMode && {
                    'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.popularProducts.priceBlock,
                    'data-highlighter-selector': `.${POPULAR_PRODUCTS_CLASS_NAMES.gridPriceClassName}`,
                    'data-highlighter-parent': `.${POPULAR_PRODUCTS_CLASS_NAMES.gridContainerClassName}`,
                  })}
                >
                  {product.free ? (
                    <span className={classes.price}>{FREE_PRICE}</span>
                  ) : (
                    <span className={classes.price} translate='no'>
                      {formatPrice(product.displayPrice, currencySign)}
                    </span>
                  )}
                </div>
              )}
              <CoverWidget
                img={cover}
                cover={productsContent?.length ? { file: productCovers } : product.covers?.[0]}
                noImageClasses={noCover}
                unresponsive
                className={classNames(POPULAR_PRODUCTS_CLASS_NAMES.gridImageClassName)}
              />
            </LinkComponent>
            <div
              className={classNames(POPULAR_PRODUCTS_CLASS_NAMES.gridDetailsClassName, classes.productDetails)}
              {...(previewMode && {
                'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.popularProducts.productBackground,
                'data-highlighter-selector': `.${POPULAR_PRODUCTS_CLASS_NAMES.gridDetailsClassName}`,
                'data-highlighter-parent': `.${POPULAR_PRODUCTS_CLASS_NAMES.gridContainerClassName}`,
              })}
            >
              <div
                className={classNames(POPULAR_PRODUCTS_CLASS_NAMES.gridTitleClassName, classes.productName)}
                {...(previewMode && {
                  'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.popularProducts.productsTitles,
                  'data-highlighter-selector': `.${POPULAR_PRODUCTS_CLASS_NAMES.gridTitleClassName}`,
                  'data-highlighter-parent': `.${POPULAR_PRODUCTS_CLASS_NAMES.gridContainerClassName}`,
                })}
              >
                {product.name}
              </div>
              <button
                type='button'
                onClick={(e) => handleButtonClick(product, e)}
                className={buttonClasses}
                {...(previewMode && {
                  'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.popularProducts.productButton,
                  'data-highlighter-selector': `.${POPULAR_PRODUCTS_CLASS_NAMES.gridButtonClassName}`,
                  'data-highlighter-parent': `.${POPULAR_PRODUCTS_CLASS_NAMES.gridContainerClassName}`,
                })}
              >
                {btnText}
              </button>
            </div>
          </div>
        )
      })}
    </div>
  )
}

GridPopularProducts.propTypes = {
  /**
   * GetUnit function received from currency store
   */
  getUnit: PropTypes.func,
  /**
   * grid-popular-products classes
   */
  classes: PropTypes.object,
  /**
   * Products
   */
  products: PropTypes.any,
  /**
   * Button click function
   */
  handleButtonClick: PropTypes.func,
  /**
   * Preview mode in page builder
   */
  previewMode: PropTypes.bool,
  /**
   * Price text for free product
   */
  freeProductPriceText: PropTypes.string, // I18n.t('react.shared.free')
  /**
   * Button text for free product
   */
  freeButtonText: PropTypes.string,
  /**
   * Button text for a non-free product
   */
  buttonText: PropTypes.string, // I18n.t('shared.common.buy_now')
  /**
   * Button animation type
   */
  buttonAnimation: PropTypes.string,
  /**
   * Theme object
   */
  theme: PropTypes.object,
  /**
   * Seller's username
   */
  userName: PropTypes.string,
  /**
   * Convert price function
   */
  formatPrice: PropTypes.func, // (value, currencySign) => `${value.formatMoney(2, ',', '.')}${currencySign}`
  /**
   * Wrapper link
   */
  LinkComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

GridPopularProducts.displayName = 'GridPopularProducts'
GridPopularProducts.defaultProps = {
  classes: {},
  products: [],
  theme: {},
  getUnit: /* istanbul ignore next */ () => {},
  handleButtonClick: /* istanbul ignore next */ () => {},
  buttonText: 'Buy now',
  freeProductPriceText: FREE_PRICE,
  freeButtonText: 'Buy now',
  formatPrice: /* istanbul ignore next */ (value, currencySign) => `${value}${currencySign}`,
  LinkComponent: 'a',
}

export const GridPopularProductsWithStyles = withStyles(popularProductsStyles)(GridPopularProducts)

export const GridPopularProductsWithThemeProvider = ({ theme, ...restProps }) => (
  <ThemeProvider theme={theme || {}}>
    <GridPopularProductsWithStyles
      {...{
        theme,
        ...restProps,
      }}
    />
  </ThemeProvider>
)

GridPopularProductsWithThemeProvider.propTypes = {
  /**
   * Theme object
   */
  theme: PropTypes.object,
}

export default GridPopularProductsWithStyles
