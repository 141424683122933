import { COLORS } from '../constants/general.constants'
import { STATUSES } from '../constants/status-text.constants'

import {
  LEMONWAY_EXTERNAL_STATUSES,
  LEMONWAY_INTERNAL_STATUSES,
  LEMONWAY_LEGITIMATION_DOCUMENTS_STATUSES,
  MANGOPAY_EXTERNAL_STATUSES,
  MANGOPAY_INTERNAL_STATUSES,
  MANGOPAY_LEGITIMATION_DOCUMENTS_STATUSES,
  UBO_DECLARATION_STATUSES,
} from '../constants/paymentSetting.constants'

export const getStatusLabelProps = (
  key: string
):
  | { statusLabelStyle: { color: string }; statusIconClassName: string }
  | { statusLabelStyle: object; statusIconClassName: string } => {
  const { eloBlue, eloGreen, eloOrange, eloRed } = COLORS

  switch (key) {
    case LEMONWAY_EXTERNAL_STATUSES.kyc2:
    case MANGOPAY_INTERNAL_STATUSES.legitimated:
    case MANGOPAY_EXTERNAL_STATUSES.regular:
      return {
        statusIconClassName: 'fas fa-certificate',
        statusLabelStyle: { color: eloGreen },
      }
    case LEMONWAY_EXTERNAL_STATUSES.closed:
      return {
        statusIconClassName: 'fas fa-times-circle',
        statusLabelStyle: { color: '#9e9e9e' },
      }
    case LEMONWAY_EXTERNAL_STATUSES.kyc1:
    case LEMONWAY_EXTERNAL_STATUSES.kycIncomplete:
    case MANGOPAY_EXTERNAL_STATUSES.light:
    case UBO_DECLARATION_STATUSES.incomplete:
      return {
        statusIconClassName: 'fas fa-certificate',
        statusLabelStyle: { color: eloOrange },
      }
    case LEMONWAY_EXTERNAL_STATUSES.rejected:
    case MANGOPAY_INTERNAL_STATUSES.rejected:
    case STATUSES.deactivated:
      return {
        statusIconClassName: 'fas fa-times-circle',
        statusLabelStyle: { color: eloRed },
      }
    case LEMONWAY_EXTERNAL_STATUSES.blocked:
      return {
        statusIconClassName: 'fas fa-ban',
        statusLabelStyle: { color: '#9e9e9e' },
      }
    case LEMONWAY_EXTERNAL_STATUSES.expired:
      return {
        statusIconClassName: 'fas fa-calendar-times',
        statusLabelStyle: { color: eloRed },
      }
    case LEMONWAY_INTERNAL_STATUSES.change:
      return {
        statusIconClassName: 'fas fa-info-circle',
        statusLabelStyle: { color: eloOrange },
      }
    case LEMONWAY_INTERNAL_STATUSES.inReview:
      return {
        statusIconClassName: 'fas fa-search',
        statusLabelStyle: { color: '#9e9e9e' },
      }
    case LEMONWAY_INTERNAL_STATUSES.reviewed:
      return {
        statusIconClassName: 'far fa-check-circle',
        statusLabelStyle: { color: '#ff8d00' },
      }
    case LEMONWAY_INTERNAL_STATUSES.init:
      return {
        statusIconClassName: 'fas fa-certificate',
        statusLabelStyle: { color: eloBlue },
      }
    case LEMONWAY_INTERNAL_STATUSES.requested:
    case LEMONWAY_LEGITIMATION_DOCUMENTS_STATUSES.notVerified:
    case MANGOPAY_LEGITIMATION_DOCUMENTS_STATUSES.validationAsked:
      return {
        statusIconClassName: 'fas fa-question-circle',
        statusLabelStyle: { color: eloOrange },
      }
    case LEMONWAY_LEGITIMATION_DOCUMENTS_STATUSES.sent:
      return {
        statusIconClassName: 'fas fa-envelope',
        statusLabelStyle: { color: eloGreen },
      }
    case LEMONWAY_LEGITIMATION_DOCUMENTS_STATUSES.onHold:
      return {
        statusIconClassName: 'fas fa-pause-circle',
        statusLabelStyle: { color: eloOrange },
      }
    case LEMONWAY_LEGITIMATION_DOCUMENTS_STATUSES.accepted:
    case MANGOPAY_INTERNAL_STATUSES.registered:
    case MANGOPAY_LEGITIMATION_DOCUMENTS_STATUSES.created:
    case MANGOPAY_LEGITIMATION_DOCUMENTS_STATUSES.validated:
      return {
        statusIconClassName: 'fas fa-check-circle',
        statusLabelStyle: { color: eloGreen },
      }
    case LEMONWAY_LEGITIMATION_DOCUMENTS_STATUSES.notAccepted:
      return {
        statusIconClassName: 'fas fa-ban',
        statusLabelStyle: { color: eloRed },
      }
    case LEMONWAY_LEGITIMATION_DOCUMENTS_STATUSES.unreadable:
      return {
        statusIconClassName: 'fas fa-eye-slash',
        statusLabelStyle: { color: eloRed },
      }
    case LEMONWAY_LEGITIMATION_DOCUMENTS_STATUSES.wrongType:
      return {
        statusIconClassName: 'fas fa-comment-slash',
        statusLabelStyle: { color: eloRed },
      }
    case LEMONWAY_LEGITIMATION_DOCUMENTS_STATUSES.wrongName:
      return {
        statusIconClassName: 'fas fa-user-slash',
        statusLabelStyle: { color: eloRed },
      }
    case MANGOPAY_INTERNAL_STATUSES.headQuarter:
      return {
        statusIconClassName: 'fas fa-building',
        statusLabelStyle: { color: '#9e9e9e' },
      }
    case MANGOPAY_INTERNAL_STATUSES.legalRepresentative:
      return {
        statusIconClassName: 'fas fa-user-tie',
        statusLabelStyle: { color: '#9e9e9e' },
      }
    case MANGOPAY_INTERNAL_STATUSES.documents:
      return {
        statusIconClassName: 'fas fa-file-alt',
        statusLabelStyle: { color: '#9e9e9e' },
      }
    case MANGOPAY_INTERNAL_STATUSES.ubo:
      return {
        statusIconClassName: 'fas fa-users',
        statusLabelStyle: { color: '#a8a8a8' },
      }
    case MANGOPAY_LEGITIMATION_DOCUMENTS_STATUSES.refused:
      return {
        statusIconClassName: 'fas fa-exclamation-circle',
        statusLabelStyle: { color: '#ff4747' },
      }
    default:
      return {
        statusIconClassName: '',
        statusLabelStyle: {},
      }
  }
}
