import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { BLOCK_VIEW } from '@elo-kit/constants/contentPage.constants'
import { NO } from '@elo-kit/constants/general.constants'

import { getThemeProps } from '@elo-kit/utils/block.utils'
import { useFunnelStyles } from 'shared/components/content-page/preview/blocks/funnel/Funnel.styles'

import { getFraudSessionIdentifier, insertFraudPaypalSnippet } from 'utils/ordersShared.utils'

import { ButtonFunnel } from '../button-funnel'
import { TextFunnel } from '../text-funnel'

export const Funnel = (props) => {
  const {
    block,
    block: {
      content: { product: { productId, ticketId, ticketDateId, ticketsCount } = {}, pricingPlanId, type = NO } = {},
      content,
    },
    handleFunnelReject,
    handleFunnelAccept,
    setFunnelHandled,
    funnelHandled,
    previewMode,
    noTextMessage,
    defaultButtonText,
    ordersStore,
    seller,
  } = props
  const classes = useFunnelStyles({
    theme: getThemeProps(block),
  })

  useEffect(() => {
    const fraudSessionIdentifier = getFraudSessionIdentifier({
      paypalMerchantId: seller?.paypalMerchantId,
      paypalProvider: ordersStore.item?.moneyHolder?.paypalProvider,
      paymentForm: ordersStore.item?.paymentForm,
    })

    if (!ordersStore.fraudSessionIdentifier && ordersStore.setFraudSessionIdentifier) {
      insertFraudPaypalSnippet(fraudSessionIdentifier, seller?.paypalMerchantId)
      ordersStore.setFraudSessionIdentifier(fraudSessionIdentifier)
    }
  }, [])

  const getFunnelView = () => {
    const viewProps = {
      defaultButtonText,
      classes,
      content,
      previewMode,
      setFunnelHandled,
      funnelHandled,
      onAcceptClick: /* istanbul ignore next */ () => {
        handleFunnelAccept({
          productId,
          ticketId,
          ticketDateId,
          ticketsCount,
          pricingPlanId,
        })
      },
      onSkipClick: (nodeId) => {
        handleFunnelReject(nodeId)
      },
    }

    if (type === BLOCK_VIEW.text) {
      return <TextFunnel {...{ ...viewProps }} />
    }

    switch (content.view) {
      case BLOCK_VIEW.text:
        return <TextFunnel {...{ ...viewProps }} />
      case BLOCK_VIEW.button:
      default:
        return <ButtonFunnel {...{ ...viewProps }} />
    }
  }

  const funnelBlockIsValid = () => {
    if (type !== NO) {
      if (ticketId) {
        return productId && ticketId && ticketDateId && ticketsCount && pricingPlanId
      }

      return productId && pricingPlanId
    }

    return true
  }

  const previewModeBlock = previewMode ? <div className={classes.noTextContainer}>{noTextMessage}</div> : null

  return (
    <div className={classes.container}>
      <div className={classes.containerOverlay} />
      {funnelBlockIsValid() ? getFunnelView() : previewModeBlock}
    </div>
  )
}

Funnel.displayName = 'Funnel'
Funnel.propTypes = {
  block: PropTypes.object,
  handleFunnelAccept: PropTypes.func,
  handleFunnelReject: PropTypes.func,
  previewMode: PropTypes.bool,
  noTextMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  funnelHandled: PropTypes.bool,
  setFunnelHandled: PropTypes.func,
  defaultButtonText: PropTypes.string,
}

Funnel.defaultProps = {
  block: {
    content: {},
  },
  previewMode: false,
}
