import React from 'react'
import { uuid } from 'utils/uuid'

import { useAudioPlayerStyles } from 'shared/components/content-page/preview/blocks/audio-player/AudioPlayer.styles'
import { useI18n } from '@elo-kit/components/i18n/i18n'

import { getThemeProps } from '@elo-kit/utils/block.utils'

import { AudioPlayerView } from './AudioPlayerView'

const DEFAULT_GOOD = {
  id: uuid(),
  digital: {
    isAudio: true,
    duration: 60,
    file: {},
    id: uuid(),
  },
  className: 'audio-player--default',
}

import './_audio_player.scss'

interface ContainerProps {
  classes: Record<string, string>
  block?: Record<string, any>
  previewMode?: boolean
  children: React.ReactNode
}

export const AudioPlayerContainer: React.FC<ContainerProps> = (props) => {
  const { children, classes, block = {}, previewMode } = props
  const { goods = [] } = block

  return (
    <div className={classes.container}>
      <div className={classes.containerOverlay} />
      {((!previewMode && goods.length) || previewMode) && <div>{children}</div>}
    </div>
  )
}

interface Props {
  block: Record<string, any>
  previewMode?: boolean
  defaultSpeedText?: string
  fixedPlayerId?: string
  setFixedPlayerId?: (id: string | null) => void
  audioPlayer: any
  fixableAudioPlayer?: boolean
}

export const AudioPlayer: React.FC<Props> = (props) => {
  const I18n = useI18n()
  const {
    block = {},
    previewMode = false,
    fixedPlayerId = null,
    setFixedPlayerId = () => {},
    defaultSpeedText = I18n.t('react.shared.speed'),
  } = props

  const { goods = [] } = block

  const audioGoods = goods.length ? goods : [DEFAULT_GOOD]

  const classes = useAudioPlayerStyles({
    theme: getThemeProps(block),
  })

  return (
    <AudioPlayerContainer classes={classes} block={block} previewMode={previewMode}>
      {audioGoods.map((good) => (
        <AudioPlayerView
          {...props}
          block={{
            ...block,
            content: getThemeProps(block),
          }}
          good={good}
          classes={classes}
          key={`${good.digital.id}-${block.id}`}
          id={block.id}
          fixedPlayerId={fixedPlayerId}
          setFixedPlayerId={setFixedPlayerId}
          previewMode={previewMode}
          defaultSpeedText={defaultSpeedText}
        />
      ))}
    </AudioPlayerContainer>
  )
}
