import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withStyles, { ThemeProvider } from 'react-jss'

import { useI18n } from '@elo-kit/components/i18n/i18n'

import { THEME_TYPE, ELOPAGE_CABINETS } from '@elo-kit/constants/general.constants'
import { VIDEO_CLASS_NAMES, CUSTOM_CLASS_NAME_OPTION } from '@elo-kit/constants/customCss.constants'
import { VIDEO_TYPE } from '@elo-kit/constants/contentPage.constants'

import { getThemeProps } from '@elo-kit/utils/block.utils'

import { videoStyles } from 'shared/components/content-page/preview/blocks/video/Video.styles'
import { VideoCodesInputs } from '../code-inputs'
import WistiaEmbed from '../../../components/wistia-embed'

/**
 * No Video Component
 */
export const NoVideo = withStyles(videoStyles)(({ classes, warningMessage }) => (
  <div className={classNames(VIDEO_CLASS_NAMES.emptyContainerClassName, classes.emptyVideo)}>
    {warningMessage || <i className='far fa-play-circle' />}
  </div>
))

/**
 * Video Player Container
 */
export const VideoPlayerContainer = withStyles(videoStyles)(({ classes, children }) => (
  <div className={classNames(VIDEO_CLASS_NAMES.playerContainerClassName, classes.videoPlayerContainer)}>{children}</div>
))

/**
 * Video Container
 */
export const VideoContainer = withStyles(videoStyles)(({ classes, children, dataTestId, content = {}, vimeoVideo }) => (
  <div
    className={classNames(
      VIDEO_CLASS_NAMES.containerClassName,
      content[CUSTOM_CLASS_NAME_OPTION],
      classes.videoContainer,
      vimeoVideo && classes.vimeoVideoContainer
    )}
    data-testid={dataTestId}
  >
    <div className={classes.containerOverlay} />
    {children}
  </div>
))

/**
 * Vimeo Video Component
 */
export const VimeoVideo = withStyles(videoStyles)(({ classes, vimeoVideoId, previewMode }) => {
  if (!vimeoVideoId) {
    return previewMode && <NoVideo />
  }

  let videoKey = vimeoVideoId.replace('https://vimeo.com/', '')
  const [id, hash] = videoKey.split('/')

  if (id) {
    videoKey = id
  }

  if (id && hash) {
    videoKey = `${id}?h=${hash}`
  }

  return (
    <iframe
      title='Vimeo-video'
      className={classes.vimeoVideo}
      src={`https://player.vimeo.com/video/${videoKey}`}
      width='640'
      height='360'
      frameBorder='0'
      allow='autoplay; fullscreen'
      allowFullScreen
    />
  )
})

/**
 * Page Builder Video Block
 */
export class VideoPreviewContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      shouldRerenderVideo: false,
    }
  }

  componentDidUpdate(prevProps) {
    const { shouldRerenderVideo } = this.state

    const {
      block: { goods = [], content: { playerColor, playbarDisabled } = {} },
    } = this.props

    const {
      block: {
        goods: prevGoods = [],
        content: { playerColor: prevPlayerColor, playbarDisabled: prevPlaybarDisabled } = {},
      },
    } = prevProps

    const newGood = (goods[0] && goods[0].digital) || {}
    const prevGood = (prevGoods && prevGoods[0] && prevGoods[0].digital) || {}
    const shouldRerenderVideoCheck =
      (newGood.file || {}).icon !== (prevGood.file || {}).icon ||
      newGood.id !== prevGood.id ||
      playerColor !== prevPlayerColor ||
      playbarDisabled !== prevPlaybarDisabled ||
      (newGood.file || {}).original !== (prevGood.file || {}).original

    if (shouldRerenderVideoCheck || shouldRerenderVideo) {
      this.toogleRerenderVideo()
    }
  }

  toogleRerenderVideo = () => {
    const { shouldRerenderVideo } = this.state
    this.setState(() => ({
      shouldRerenderVideo: !shouldRerenderVideo,
    }))
  }

  render() {
    const {
      block = {},
      pathname,
      previewMode,
      toJS,
      vimeoOptionKey,
      themeType,
      view,
      isAppActive,
      getVideoCodeData,
      setVideoCodeValue,
      hideVideoCodes,
      videoCodes,
      POST,
      deleteBlockVideoCodes,
      fetchVideoCodes,
      activeLessonStatusId,
      setVideoCode,
      createVideoCode,
      userEmail,
      coursePreviePathname,
      dataTestId,
      locales = {
        videoCodes: this.props.I18n.t('shared.common.video_codes'),
        videoCodesTooltip: this.props.I18n.t('react.shared.help_icon.video_codes.content'),
        videoCodesPlaceholder: this.props.I18n.t('react.shared.code'),
        warningMessage: this.props.I18n.t('react.shared.vimeo_app_is_not_booked'),
      },
    } = this.props

    const { shouldRerenderVideo } = this.state
    const { vimeoVideoId, type, codes = [] } = block.content || {}
    const { goods = [] } = block
    const good = goods[0]

    const isVimeoVideo = type === VIDEO_TYPE.vimeo
    const isVimeoAppActive = isAppActive(vimeoOptionKey)
    const shouldRenderWistiaEmbed = good && good.digital.wistiaData && good.digital.wistiaData.hashedId
    const isCoursePreview = view === ELOPAGE_CABINETS.cabinet && pathname.includes(coursePreviePathname)
    const hasCodes = !!(codes || []).length
    const isCourse = themeType === THEME_TYPE.course
    const isPayer = view === ELOPAGE_CABINETS.payer
    const showCodeInputs = hasCodes && (isCourse || isPayer || isCoursePreview)
    const customThumbnail =
      good && good.digital && good.digital.file?.name
        ? good.digital.file?.original || good.digital.file?.icon?.url
        : undefined

    return (
      <ThemeProvider theme={getThemeProps(block)}>
        <VideoContainer dataTestId={dataTestId} content={block.content} vimeoVideo={isVimeoVideo && isVimeoAppActive}>
          {!isVimeoVideo &&
            (shouldRenderWistiaEmbed && !shouldRerenderVideo ? (
              <VideoPlayerContainer>
                <WistiaEmbed
                  hashedId={good.digital.wistiaData.hashedId}
                  customThumbnail={customThumbnail}
                  digitalId={good.digital.id}
                  block={block}
                  view={view}
                  POST={POST}
                  deleteBlockVideoCodes={deleteBlockVideoCodes}
                  fetchVideoCodes={fetchVideoCodes}
                  hideVideoCodes={hideVideoCodes}
                  activeLessonStatusId={activeLessonStatusId}
                  getVideoCodeData={getVideoCodeData}
                  setVideoCode={setVideoCode}
                  createVideoCode={createVideoCode}
                  userEmail={userEmail}
                  locales={locales}
                />
                {showCodeInputs ? (
                  <VideoCodesInputs
                    content={block.content || {}}
                    blockId={block.id}
                    getVideoCodeData={getVideoCodeData}
                    setVideoCodeValue={setVideoCodeValue}
                    hideVideoCodes={hideVideoCodes}
                    videoCodes={toJS(videoCodes)}
                    locales={locales}
                  />
                ) : null}
              </VideoPlayerContainer>
            ) : (
              previewMode && <NoVideo />
            ))}

          {isVimeoVideo &&
            (isVimeoAppActive ? (
              <VimeoVideo vimeoVideoId={vimeoVideoId} previewMode={previewMode} />
            ) : (
              previewMode && <NoVideo warningMessage={locales.warningMessage} />
            ))}
        </VideoContainer>
      </ThemeProvider>
    )
  }
}

VideoPreviewContainer.propTypes = {
  /** Data Test ID */
  dataTestId: PropTypes.string,
  /** Course Previe Pathname */
  coursePreviePathname: PropTypes.string,
  /** User's email */
  userEmail: PropTypes.string,
  /** Active Lesson Status ID */
  activeLessonStatusId: PropTypes.string,
  /** Cabinet View Type */
  view: PropTypes.string,
  /** Theme Type */
  themeType: PropTypes.string,
  /** Vimeo Feature Option Key */
  vimeoOptionKey: PropTypes.string,
  /** Preview Mode Flag */
  previewMode: PropTypes.bool,
  /** Page Builder Block */
  block: PropTypes.object,
  /** Locales Map */
  locales: PropTypes.object,
  /** Mobx to JS */
  toJS: PropTypes.func,
  /** Location from history */
  pathname: PropTypes.string,
  /** Check whether App is active */
  isAppActive: PropTypes.func,
  /** Hides video codes from the video */
  hideVideoCodes: PropTypes.bool,
  /** Method for the POST call */
  POST: PropTypes.func,
  /** Deletes video codes block */
  deleteBlockVideoCodes: PropTypes.func,
  /** Method for fetching video codes */
  fetchVideoCodes: PropTypes.func,
  /** Returns video code data */
  getVideoCodeData: PropTypes.func,
  /** Sets video code */
  setVideoCode: PropTypes.func,
  /** Sets video code */
  setVideoCodeValue: PropTypes.func,
  /** Creates video code */
  createVideoCode: PropTypes.func,
  /** Video codes */
  videoCodes: PropTypes.object,
}

VideoPreviewContainer.defaultProps = {
  isAppActive: () => {},
  dataTestId: 'video-preview',
  coursePreviePathname: 'course-preview',
  block: {},
  pathname: '',
  videoCodes: {},
  I18n: {
    t: () => {},
  },
}

const VideoPreview = (props) => {
  const I18n = useI18n()

  return <VideoPreviewContainer I18n={I18n} {...props} />
}

export default VideoPreview
