import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withStyles, { ThemeProvider } from 'react-jss'

import { AUTOCOMPLETE_OFF } from '@elo-kit/constants/forms.constants'

import codeInputStyles from './CodeInput.styles'

/**
 * Video code input - enter your video code
 */
const CodeInputContainer = (props) => {
  const { dataTestId, onChange, passed, value, placeholder, id, classes } = props

  const fieldControlClassNames = classNames('elo-field__control', classes.codeInputControl, {
    [`code-input--error ${classes.codeInputControlError}`]: !!value && !passed,
    [`code-input--success ${classes.codeInputControlSuccess}`]: !!value && passed,
  })

  return (
    <div className={`elo-field ${classes.codeInput}`} data-testid={dataTestId}>
      <input
        id={id}
        data-testid={id}
        className={fieldControlClassNames}
        autoComplete={AUTOCOMPLETE_OFF}
        onChange={(e) => onChange(e.target.value)}
        disabled={passed}
        type='text'
        {...{
          placeholder,
          value,
        }}
      />

      {!!value && (passed ? <i className='fas fa-check-circle' /> : <i className='fas fa-times-circle' />)}
    </div>
  )
}

const CodeInputContainerWithStyles = withStyles(codeInputStyles)(CodeInputContainer)

CodeInputContainer.propTypes = {
  /**
   * Is being used for testing purposes
   */
  dataTestId: PropTypes.string,
  /**
   * video code input ID
   */
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  /**
   * video code input onChange event handler
   */
  onChange: PropTypes.func.isRequired,
  /**
   * is video code valid
   */
  passed: PropTypes.bool,
  /**
   * placeholder for video code input
   */
  placeholder: PropTypes.string,
  /**
   * video code value
   */
  value: PropTypes.string.isRequired,
  /** Classes object */
  classes: PropTypes.string,
}
CodeInputContainer.defaultProps = {
  dataTestId: 'code-input',
  passed: false,
  placeholder: '',
  classes: {},
}

export const CodeInput = ({ theme, ...restProps }) => (
  <ThemeProvider theme={theme}>
    <CodeInputContainerWithStyles {...restProps} />
  </ThemeProvider>
)
CodeInput.displayName = 'CodeInput'
CodeInput.propTypes = {
  theme: PropTypes.object,
}
CodeInput.defaultProps = {
  theme: {},
}
