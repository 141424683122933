import React, { Component } from 'react'
import classNames from 'classnames'
import withStyles, { ThemeProvider } from 'react-jss'

import { SelectField } from '@elo-kit/components/form/select-field'
import { I18nType, useI18n } from '@elo-kit/components/i18n/i18n'
import { EloSelect } from '@elo-ui/components/elo-select'

import { GREECE_CODES } from 'constants/countries.constants'

import countrySelectorStyles from './CountrySelector.styles'

interface CountryListItem {
  alpha2: string
  euMember?: boolean
  name: string
}
interface Country {
  code?: string
  label?: string
  eu?: boolean
  value?: string
}
interface Classes {
  countrySelector?: string
  countrySelectorError?: string
  countrySelectorInvalidFeedback?: string
}
interface Props {
  countriesList?: Array<CountryListItem>
  forceDirty?: boolean
  value?: string
  locale?: string
  validations?: Array<any>
  vatValidByVies?: string
  vatId?: string
  handleInput?: (country?: Country | string) => void
  handleValid?: (isValid?: boolean) => void
  fetchList?: () => Promise<any> // countriesStore.fetchFullList
  classes?: Classes
  className?: string
  I18n?: I18nType
  autofilledCountryCode?: string
  handleAutofillInput?: (type: string, value: Country) => void
  label?: string
  useNewSelect?: boolean
  dataTestId?: string
}
interface State {
  isDirty: boolean
  isValid: boolean
  errorMsg: string
  countries: Array<Country>
  selectedCountry?: Country | string
}

const defaultProps = {
  countriesList: [],
  validations: [],
  fetchList: /* istanbul ignore next */ () => ({
    then: () => {},
  }),
  handleInput: /* istanbul ignore next */ () => {},
  handleValid: /* istanbul ignore next */ () => {},
  classes: {},
  className: '',
  I18n: {
    t: () => {},
  },
}

export class CountrySelectorContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      isDirty: false,
      isValid: false,
      errorMsg: '',
      countries: [{}],
    }
  }

  static defaultProps = defaultProps

  componentDidMount() {
    const { countriesList, fetchList } = this.props

    if (countriesList && countriesList.length) {
      this.setCountriesList(countriesList)
    } else {
      fetchList &&
        fetchList().then((resp) => {
          const { list = [] } = resp.data || {}
          this.setCountriesList(list)
        })
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {
      forceDirty,
      value,
      locale,
      validations,
      vatValidByVies,
      vatId,
      autofilledCountryCode,
      countriesList,
      useNewSelect,
      handleAutofillInput,
    } = this.props
    const { isDirty } = this.state

    if (useNewSelect && countriesList?.length !== prevProps?.countriesList?.length) {
      this.setCountriesList(countriesList)
    }

    const validationsChanged = !(
      validations?.length === prevProps?.validations?.length &&
      validations?.sort().every((newValidation, index) => newValidation === prevProps?.validations?.sort()[index])
    )

    if (forceDirty && !prevProps.forceDirty && !isDirty) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isDirty: true }, () => this.validate(value))
    } else if (
      value !== prevProps.value ||
      locale !== prevProps.locale ||
      validationsChanged ||
      vatValidByVies !== prevProps.vatValidByVies ||
      vatId !== prevProps.vatId
    ) {
      this.validate(value || '')
    }

    if (prevProps.autofilledCountryCode !== autofilledCountryCode) {
      const country = {
        ...this.state.countries.find(({ code }) => code === autofilledCountryCode),
        code: autofilledCountryCode,
      }

      this.setState({ selectedCountry: country })
      handleAutofillInput('country', country)
    }
  }

  setCountriesList = (list: CountryListItem[]) => {
    const countries = list.map(({ alpha2, euMember, name }) => ({
      code: alpha2,
      label: name,
      eu: !!euMember,
      value: alpha2,
    }))

    this.setState({ countries }, this.forcePreselect)
  }

  onChange = (data: Country | string) => {
    const { handleInput } = this.props
    const { isDirty } = this.state
    // TODO need to fix
    // @ts-ignore
    const { value } = data || {}

    handleInput && handleInput(data)

    if (isDirty) {
      this.validate(value)
    } else {
      this.setState({ isDirty: true }, () => this.validate(value))
    }

    this.setState({ selectedCountry: data })
  }

  validate = (value = '') => {
    const { validations, handleValid, I18n } = this.props
    const { isDirty } = this.state

    let isValid = true
    const msgs = []

    if (validations && validations.indexOf('required') >= 0) {
      if (value === '') {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.required'))
      }
    }

    if (validations && validations.indexOf('countryCompliance') >= 0) {
      const { vatId } = this.props

      const isGreeceCode = GREECE_CODES.includes(value)

      const showError = isGreeceCode
        ? !vatId?.includes(GREECE_CODES[0]) && !vatId?.includes(GREECE_CODES[1])
        : !vatId?.includes(value)

      if (showError) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.country_and_vat'))
      }
    }

    this.setState(
      {
        isValid,
        errorMsg: msgs.join(', '),
      },
      () => {
        if (isDirty || isValid) {
          handleValid && handleValid(isValid)
        }
      }
    )
  }

  forcePreselect() {
    const { value: propsValue } = this.props

    if (propsValue) {
      const { countries } = this.state
      const data =
        countries.filter(({ value }) => value === propsValue)[0] || countries.filter(({ value }) => value === 'DE')[0]
      if (data) this.onChange(data)
    }
  }

  render() {
    const { validations, classes, className, I18n, label, useNewSelect, dataTestId } = this.props
    const { countries, errorMsg, isDirty, isValid, selectedCountry } = this.state
    const showError = !isValid && isDirty
    const validate = validations ? validations.indexOf('required') >= 0 : false

    const selectFieldClasses = classNames(
      classes?.countrySelector,
      {
        [String(classes?.countrySelectorError)]: showError,
      },
      className
    )

    return (
      <>
        {useNewSelect ? (
          <EloSelect.Select
            data-testid='country-selector'
            id='countrySelector'
            label={label}
            onChange={this.onChange}
            placeholder={I18n.t('shared.common.country')}
            value={selectedCountry}
            options={countries}
            required={validate}
            error={showError}
            errorText={errorMsg}
          />
        ) : (
          <div className={selectFieldClasses}>
            <SelectField
              components={{ IndicatorSeparator: () => null }}
              onChange={this.onChange}
              name='menuFontStyle'
              placeholder={I18n.t('shared.common.country')}
              value={selectedCountry}
              options={countries}
              required={validate}
              valid={!showError}
              defaultValue={selectedCountry}
              useObjectValue
              instanceId={'country-selector'}
              label={label}
              dataTestId={dataTestId}
            />
            {showError && <div className={classes?.countrySelectorInvalidFeedback}>{errorMsg}</div>}
          </div>
        )}
      </>
    )
  }
}

const CountrySelectorContainerWithStyles = withStyles(countrySelectorStyles)(CountrySelectorContainer)

export const CountrySelector = ({ theme = {}, ...restProps }) => {
  const I18n = useI18n() as never // TODO: change as never

  return (
    <ThemeProvider theme={theme}>
      <CountrySelectorContainerWithStyles I18n={I18n} {...restProps} />
    </ThemeProvider>
  )
}

CountrySelector.displayName = 'CountrySelector'
