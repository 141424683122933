import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { InfoTooltip } from '@elo-kit/components/info-tooltip/InfoTooltip'
import { useI18n } from '@elo-kit/components/i18n/i18n'

const propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  tooltipId: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  tooltipTitle: PropTypes.bool,
  className: PropTypes.string,
  tooltipParams: PropTypes.shape({}),
  handleChange: PropTypes.func,
}

const ToggleFieldContainer = (props) => {
  const { checked, className, disabled, handleChange, id, label, leftLabel, name, tooltipId, tooltipTitle, I18n } =
    props

  const toggleClasses = classNames('elo-toggle', className, {
    'elo-toggle--on': checked,
    'elo-toggle--disabled': disabled,
    'elo-toggle--left-label': leftLabel,
  })

  const switchClasses = classNames('elo-toggle__switch', {
    'elo-toggle__switch--on': checked,
    'elo-toggle__switch--disabled': disabled,
  })

  const labelClasses = classNames('elo-toggle__label', {
    'elo-toggle__label--on': checked,
  })

  const switcherClasses = classNames('elo-toggle__switcher', {
    'elo-toggle__switcher--on': checked && !disabled,
    'elo-toggle__switcher--off': !checked,
  })

  const tooltipTitles = {
    background: I18n.t('react.cabinet.help_icon.background.title'),
    my_account: I18n.t('react.cabinet.help_icon.my_account.title'),
    test_period: I18n.t('react.cabinet.help_icon.test_period.title'),
    custom_start_day: I18n.t('react.cabinet.help_icon.custom_start_day.title'),
    custom_intervals: I18n.t('react.cabinet.help_icon.custom_intervals.title'),
    absolute_dates: I18n.t('react.cabinet.help_icon.absolute_dates.title'),
  }

  const tooltipContent = {
    toggle_viban: I18n.t('react.cabinet.help_icon.toggle_viban.content'),
    multipleStages: I18n.t('react.cabinet.help_icon.multipleStages.content'),
    custom_cookies: I18n.t('react.cabinet.help_icon.custom_cookies.content'),
    background: I18n.t('react.cabinet.help_icon.background.content'),
    buyer_campaign_id: I18n.t('react.cabinet.help_icon.buyer_campaign_id.content'),
    buyer_list_id: I18n.t('react.cabinet.help_icon.buyer_list_id.content'),
    body: I18n.t('react.cabinet.help_icon.body.content'),
    tracify_cs_id: I18n.t('react.cabinet.help_icon.tracify_cs_id.content'),
    tracify_api_key: I18n.t('react.cabinet.help_icon.tracify_api_key.content'),
    tracify_is_staging: I18n.t('react.cabinet.help_icon.tracify_is_staging.content'),
    additionalText: I18n.t('react.cabinet.help_icon.additionalText.content'),
    // prettier-ignore
    free_product_download_button_v2_content: I18n.t('react.cabinet.help_icon.free_product_download_button_v2_content.content'),
    default_trust_seal: I18n.t('react.cabinet.help_icon.default_trust_seal.content'),
    own_trust_seal: I18n.t('react.cabinet.help_icon.own_trust_seal.content'),
    prefilled_info: I18n.t('react.cabinet.help_icon.prefilled_info.content'),
    enable_quizzes: I18n.t('react.cabinet.help_icon.enable_quizzes.content'),
    specific_pricing_plan: I18n.t('react.cabinet.help_icon.specific_pricing_plan.content'),
    my_account: I18n.t('react.cabinet.help_icon.my_account.content'),
    show_time_and_date: I18n.t('react.cabinet.help_icon.show_time_and_date.content'),
    test_period: I18n.t('react.cabinet.help_icon.test_period.content'),
    custom_start_day: I18n.t('react.cabinet.help_icon.custom_start_day.content'),
    custom_intervals: I18n.t('react.cabinet.help_icon.custom_intervals.content'),
    minimum_term: I18n.t('react.cabinet.help_icon.minimum_term.content'),
    absolute_dates: I18n.t('react.cabinet.help_icon.absolute_dates.content'),
    disable_first_order_date: I18n.t('react.cabinet.help_icon.disable_first_order_date.content'),
    custom_charge_day_immediate_access: I18n.t('react.cabinet.help_icon.custom_charge_day_immediate_access.content'),
    use_net_price: I18n.t('react.cabinet.help_icon.use_net_price.content'),
    sepa_immediate: I18n.t('react.cabinet.help_icon.sepa_immediate.content'),
    paypal_rest_immediate: I18n.t('react.cabinet.help_icon.paypal_rest_immediate.content'),
    sofort_debit_subscription_payments: I18n.t('react.cabinet.help_icon.sofort_debit_subscription_payments.content'),
    ideal_debit_subscription_payments: I18n.t('react.cabinet.help_icon.ideal_debit_subscription_payments.content'),
    visual_separation: I18n.t('react.cabinet.help_icon.visual_separation.content'),
    pricing_range: I18n.t('react.cabinet.help_icon.pricing_range.content'),
    test_period_hide_amounts_currency: I18n.t('react.cabinet.help_icon.test_period_hide_amounts_currency.content'),
    pay_later_paypal: I18n.t('react.cabinet.help_icon.pay_later_paypal.content'),
    redirect_affiliate_visitors: I18n.t('react.cabinet.help_icon.redirect_affiliate_visitors.content'),
  }

  return (
    <div className={toggleClasses}>
      <div className={switchClasses}>
        <input id={id} name={name} type='checkbox' onChange={handleChange} checked={!!checked} disabled={disabled} />
        <label htmlFor={id} className={switcherClasses} />
      </div>
      <label className={labelClasses} htmlFor={id}>
        {label}
      </label>
      {tooltipId && (
        <InfoTooltip
          id={`${tooltipId}_popover_${id}`}
          title={tooltipTitle ? tooltipTitles[tooltipId] : ''}
          body={tooltipContent[tooltipId]}
        />
      )}
    </div>
  )
}

ToggleFieldContainer.displayName = 'ToggleField'
ToggleFieldContainer.propTypes = propTypes

export const ToggleField = (props) => {
  const I18n = useI18n()

  return <ToggleFieldContainer I18n={I18n} {...props} />
}
