import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { getBackgroundImageCss } from '@elo-kit/utils/themes.utils'
import { TESTIMONIALS, TESTIMONIALS_TEXT_LENGTH } from '@elo-kit/constants/contentPage.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'

import { EloSlider } from '@elo-kit/components/elo-slider'
import { createId } from '@elo-kit/utils/general.utils'

/**
 * TestimonialsList - show the list of testimonials in slider
 */
class TestimonialsList extends Component {
  render() {
    const {
      classes,
      block,
      textContainerClasses,
      usernameContainerClasses,
      previewType,
      toggleTriggerSizeUpdate,
      shouldUpdateSliderHeight,
      setShowMore,
      showMore,
      showMoreItem,
      previewMode,
    } = this.props

    const defaultStyle = {
      modifier: 1,
      activeElementScale: 1,
    }

    const items = block.content.testimonials.map((testimonial, index) => {
      const { avatar, id, text = '' } = testimonial
      const showMoreButton = text.length > TESTIMONIALS_TEXT_LENGTH
      const showMoreOpen = showMore && id === showMoreItem
      const testimonialText = showMoreButton && !showMoreOpen ? text.substring(0, text.lastIndexOf(' ', 320)) : text

      const textClasses = classNames(
        classes.comment,
        `${showMoreOpen ? classes.testimonialTextMore : showMoreButton && classes.testimonialText}`
      )
      const avatarClasses = classNames(
        'rounded-circle testimonials-preview__background-image rounded-circle',
        !avatar && 'empty'
      )

      return (
        <div className={`slide-item ${classes.slideItem}`} key={createId(id, index)}>
          <div
            className={avatarClasses}
            {...(previewMode && {
              'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.testimonials.entries,
              'data-highlighter-selector': '',
            })}
          >
            {avatar ? (
              <div className='w-100 h-100 rounded-circle' style={{ backgroundImage: getBackgroundImageCss(avatar) }} />
            ) : (
              <i className='fas fa-user' />
            )}
          </div>

          <div
            className={usernameContainerClasses}
            {...(previewMode && {
              'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.testimonials.usernames,
              'data-highlighter-selector': '',
            })}
          >
            {testimonial[TESTIMONIALS.testimonialFullName]}
          </div>

          <div
            className={textContainerClasses}
            {...(previewMode && {
              'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.testimonials.testimonialsText,
              'data-highlighter-selector': '',
            })}
          >
            <div className={textClasses} id={`testimonial-${id}`}>
              {testimonialText}
            </div>
          </div>

          {showMoreButton && (
            <button type='button' className={classes.showMoreBtn} onClick={() => setShowMore(!showMore, id)}>
              {showMore ? I18n.t('react.shared.hide') : I18n.t('react.shared.show_more')}
            </button>
          )}
        </div>
      )
    })

    return (
      <>
        <div className={classes.backgroundColor} />
        <div className={classes.backgroundImage}>
          <div
            className={classes.testimonialsContainer}
            {...(previewMode && {
              'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.testimonials.blockSlider,
              'data-highlighter-selector': '',
            })}
          >
            <EloSlider
              slideItems={items}
              id={block.id}
              style={defaultStyle}
              onSlide={
                /* istanbul ignore next */ () => {
                  setShowMore(false)
                }
              }
              triggerSizeUpdate={shouldUpdateSliderHeight}
              toggleTriggerSizeUpdate={toggleTriggerSizeUpdate}
              previewType={previewType}
            />
          </div>
        </div>
      </>
    )
  }
}

TestimonialsList.propTypes = {
  /**
   * React JSS classes
   */
  classes: PropTypes.object,
  /**
   * Indicates whether it's preview type of slider
   */
  previewType: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /**
   * Class Names of text container
   */
  textContainerClasses: PropTypes.string,
  /**
   * Class Names of user container
   */
  usernameContainerClasses: PropTypes.string,
  /**
   * Indicates what locale should be shown
   */
  showMore: PropTypes.bool,
  /**
   * Show more item id or null
   */
  showMoreItem: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Indicates whether update slider heigth
   */
  shouldUpdateSliderHeight: PropTypes.bool,
  /**
   * Set show more function for item
   */
  setShowMore: PropTypes.func,
  /**
   * Callback to notify that slider was updated
   */
  toggleTriggerSizeUpdate: PropTypes.func,
  /**
   * Content block object
   */
  block: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
    content: PropTypes.object,
  }),
}

TestimonialsList.defaultProps = {
  block: {
    content: {
      testimonials: [],
    },
  },
}

export default TestimonialsList
